import { ChangeEvent } from 'react';
import { Button, handleKeyPress } from '@rabbit/elements/shared-components';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export interface SearchBarProps {
  value: string;
  placeholder: string;
  submitSearchCallback: () => void;
  handleChangeCallBack: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  button?: boolean;
}

export function SearchBar({
  value,
  placeholder,
  button,
  handleChangeCallBack,
  submitSearchCallback,
}: SearchBarProps) {
  return (
    <div>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-500"
            stroke="currentColor"
          ></MagnifyingGlassIcon>
        </div>
        <input
          type="search"
          id="search"
          className="focus:ring-primary-600 focus:border-primary-600 font-nunito text-md block w-full rounded-lg border border-gray-300 bg-white px-4 py-[13px] pl-10 text-gray-500 placeholder-gray-400 !shadow-none !outline-none !ring-0"
          placeholder={placeholder}
          onChange={(e) => handleChangeCallBack(e)}
          onKeyDown={submitSearchCallback}
          onKeyDownCapture={handleKeyPress}
          value={value}
          required
        />
        <div className="absolute right-2.5 bottom-2.5 w-[85px]">
          {button && (
            <Button
              children="Search"
              kind="primary"
              handleSubmit={submitSearchCallback}
              size="sm"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
