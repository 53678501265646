import { LIST_COUNTRIES } from '@rabbit/bizproc/react';
import {
  Button,
  Input,
  getCountryByLabel,
  InputRadio,
  InputTypeSelectAddress,
} from '@rabbit/elements/shared-components';
import { Address } from '@rabbit/data/types';
import { useAppInfo, printAddress } from '@rabbit/sage/utils/helpers';
import { FormikProps, Formik, Form } from 'formik';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';

const addressValidationSchema = Yup.object().shape({
  line1: Yup.string().trim().required('Please enter an address.'),
  line2: Yup.string().trim(),
  town: Yup.string().trim().required('Please enter a city / suburb.'),
  state: Yup.string().trim().required('Please enter a state / territory.'),
  postcode: Yup.string().trim().required('Please enter a post code.'),
  country: Yup.string().trim().required('Please enter a country.'),
});
export function AddressSection({
  onSelected,
  address,
  kind,
  onAddNew,
  onCancelNew,
  error,
}: {
  onSelected: (address?: Address) => void;
  address?: Address;
  kind: 'normal' | 'added' | 'stale';
  onAddNew?: () => void;
  onCancelNew?: () => void;
  error: boolean;
}) {
  const appInfo = useAppInfo();
  const [mode, setMode] = useState<'edit' | 'saved'>('saved');
  const [newAddress, setNewAddress] = useState<Address | undefined>(undefined);

  const addressInitialValues: Address = {
    line1: newAddress?.line1 || '',
    line2: newAddress?.line2 || '',
    town: newAddress?.town || '',
    state: newAddress?.state || '',
    postcode: newAddress?.postcode || '',
    country: newAddress?.country || appInfo.country || '',
  };

  const handleSelectorChange =
    (props: FormikProps<Address>) => async (address?: SelectAddressShape) => {
      if (address) {
        // props.resetForm();
        await props.setFieldValue('line1', address.line_1);
        await props.setFieldValue('line2', address.line_2);
        await props.setFieldValue('town', address.locality);
        await props.setFieldValue('state', address.province_name);
        await props.setFieldValue('postcode', address.postal_code);
        await props.setFieldValue(
          'country',
          getCountryByLabel(address.country_name)?.value
        );
        props.setErrors({});
      }
    };

  const handleSubmit = (values: Address) => {
    setMode('saved');
    setNewAddress(values);
    onSelected(values);
  };

  useEffect(() => {
    if (address) setNewAddress(address);
    if (!address?.line1) {
      setMode('edit');
    }
  }, [address]);

  return (
    <Formik
      initialValues={addressInitialValues}
      validationSchema={addressValidationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <>
            {mode === 'saved' && (
              <div className="flex gap-4">
                <div className="flex w-full items-center gap-4 rounded-lg border border-gray-300 p-4">
                  <InputRadio
                    settings={{
                      values: props.values,
                      onChange: () => onSelected(newAddress),
                    }}
                    name="customer_address"
                  />
                  {printAddress(newAddress)}
                </div>
                {kind === 'added' && (
                  <Button
                    kind="primary"
                    className="whitespace-nowrap px-24"
                    onClick={() => setMode('edit')}
                  >
                    Edit
                  </Button>
                )}
                {kind === 'normal' && (
                  <Button
                    kind="primary"
                    className="whitespace-nowrap px-24"
                    onClick={onAddNew}
                  >
                    Another address
                  </Button>
                )}
              </div>
            )}

            <Form>
              {mode === 'edit' && (
                <div
                  className={
                    'rounded-lg border border-gray-300 p-4' +
                    (error ? ' border-red-500' : '')
                  }
                >
                  <Input
                    type="select"
                    label="Country"
                    name="country"
                    settings={{
                      options: LIST_COUNTRIES,
                      placeholder: 'Country',
                      values: newAddress?.country,
                    }}
                  />
                  <div className="mb-2">
                    <InputTypeSelectAddress
                      errors={Object.values(props.errors).join(' ')}
                      onChange={handleSelectorChange(props)}
                      country={props.values.country || appInfo.country}
                    />
                  </div>
                  <Input
                    type="text"
                    label="Address line"
                    name="line1"
                    settings={{
                      placeholder: 'Address line 1',
                      values: newAddress?.line1,
                    }}
                  />
                  <Input
                    type="text"
                    label="Address line 2"
                    name="line2"
                    settings={{
                      placeholder: 'Address line 2',
                      values: newAddress?.line2,
                    }}
                  />
                  <div className="flex gap-4">
                    <Input
                      type="text"
                      label="City/Suburb"
                      name="town"
                      settings={{
                        placeholder: 'City',
                        values: newAddress?.town,
                      }}
                    />
                    <Input
                      type="text"
                      label="State/Territory"
                      name="state"
                      settings={{
                        placeholder: 'State',
                        values: newAddress?.state,
                      }}
                    />
                  </div>
                  <div className="flex gap-4">
                    <Input
                      type="text"
                      label="Post code"
                      name="postcode"
                      settings={{
                        placeholder: 'Post code',
                        values: newAddress?.postcode,
                      }}
                    />
                  </div>
                  <div className="mt-4 flex gap-4">
                    <Button
                      kind="primary"
                      type="submit"
                      disabled={
                        props.values.line1 === '' ||
                        props.values.line1 === undefined ||
                        Object.keys(props.errors).length > 0
                      }
                      onClick={() => handleSubmit(props.values)}
                    >
                      Save
                    </Button>
                    <Button
                      kind="red"
                      onClick={() => {
                        setMode('saved');
                        onCancelNew && kind === 'added' && onCancelNew();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
