import {
  ButtonIcon,
  CardWrapperWithHeader,
  Table,
  formatUnixTime,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import {
  calculateTotalValue,
  formatAddress,
  useAppInfo,
} from '../../../../utils/helpers';
import {
  type1AdministrativeTimeColumns,
  type1PartsUsedColumns,
  type1PostageColumns,
  type1RepairTimeColumns,
} from './ClaimCostsSectionTableColumns';
import { UserUploadedDocument } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import {
  CaseFactsShape,
  CFCF_OtherCostLog,
  CFCF_RepairWorkRegistry,
  CFCF_TravelCostLog,
  RepairWorkRegistry_PartValuesShape,
} from '@rabbit/bizproc/core';
import { ClaimCostsModalDataShape } from './ClaimCostsSection';
import { PlusIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import {
  LogRepairWorkModal,
  ReplacePartsOrProductModal,
} from '@rabbit/sage/components/organisms/case-flow-modals';
import { useContext } from 'react';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';

/* ------------------------------- Interfaces ------------------------------- */

interface ParsedPartsUserShape {
  data: object;
  description: string;
  repairer_id: string;
  part_name: string;
  model_id: string;
  parts_quantity: string;
  parts_cost: string;
  subtotal: string;
}
interface FormattedAssessmentTimeShape {
  data: object;
  repairer_id: string;
  total_repairing_time: string;
  timeStamp: string;
  cost: string;
}
interface FormattedRepairTimeShape {
  data: object;
  description: string;
  repairer_id: string;
  total_repairing_time: string;
  timeStamp: string;
  cost: string;
}
interface FormattedPostageShape {
  carrier: string;
  tracking_number: string;
  address: string | JSX.Element;
  date: string;
  cost: string;
}

interface FormattedTravelShape {
  data: object;
  description: string;
  date: string;
  cost: string;
  vat: string;
  attachments: UserUploadedDocument[];
}

interface FormattedOtherCostShape {
  data: object;
  description: string;
  date: string;
  cost: string;
  vat: string;
  attachments: UserUploadedDocument[];
}

interface FormattedAdministrativeTimeShape {
  data: object;
  repairer_id: string;
  total_repairing_time: string;
  timeStamp: string;
  cost: string;
}
export interface ClaimCostsSectionType1Props {
  caseFacts: Partial<CaseFactsShape>;
  setModalData: (data: ClaimCostsModalDataShape) => void;
}

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

export function ClaimCostsSectionType1({
  caseFacts,
  setModalData,
}: ClaimCostsSectionType1Props) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();

  /* -------------------------------------------------------------------------- */
  /*                             Results formatting                             */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Parts used ------------------------------- */
  const getPartsUserArrayParsed = () => {
    const partsUsedArray: RepairWorkRegistry_PartValuesShape[] = [];
    caseFacts?.repair_work_registry?.forEach((item) => {
      partsUsedArray.push(...item.parts_used_for_repair);
    });

    const partsUserArrayParsed: ParsedPartsUserShape[] = [];
    partsUsedArray.forEach((result, index) => {
      if (result?.part_name)
        partsUserArrayParsed.push({
          data: result,
          description: result?.description ?? '-',
          repairer_id: '-',
          part_name: result?.part_name,
          model_id: result?.model_id,
          parts_quantity: result.parts_quantity,
          parts_cost: result?.parts_cost
            ? getCurrencyFormat(
                result?.parts_cost?.amount,
                result?.parts_cost?.currency
              )
            : '-',
          subtotal:
            getCurrencyFormat(
              Number(result.parts_quantity) *
                Number(result?.parts_cost?.amount),
              result?.parts_cost?.currency
            ) ?? '-',
        });
    });
    return partsUserArrayParsed;
  };

  const partsUserArrayParsed: ParsedPartsUserShape[] =
    getPartsUserArrayParsed();
  const partsUsedTotalCost = partsUserArrayParsed.reduce((acc, curr) => {
    return (
      acc +
      //@ts-ignore todo: review this
      (curr.subtotal ? parseFloat(curr.subtotal.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  /* --------------------------- Administrative time -------------------------- */
  const administrativeTime: any[] = [];
  //@ts-ignore
  const RATE = import.meta.env.VITE_RATE_SHELTA_USER ?? 15;

  const getFormattedResultAdministrativeTime = () => {
    // Administrative time
    const newObjectForAdministrativeTime = {
      repairer: caseFacts?.delegate_repairer_name ?? '',
      administrative_time: caseFacts?.administrative_time ?? '',
      cost: caseFacts?.administrative_time ?? '',
      timeStamp: Date.now(),
    };
    administrativeTime.push(newObjectForAdministrativeTime);

    const formattedResultAdministrativeTime: FormattedAdministrativeTimeShape[] =
      [];

    administrativeTime.forEach((result) => {
      if (result?.administrative_time) {
        formattedResultAdministrativeTime.push({
          data: result,
          repairer_id: result?.repairer || '-',
          total_repairing_time: result?.administrative_time || '-',
          timeStamp: result?.timeStamp
            ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
            : '-',
          cost: result?.administrative_time
            ? getCurrencyFormat(
                calculateTotalValue(result?.administrative_time, RATE),
                appInfo.currency
              )
            : '-',
        });
      }
    });
    return formattedResultAdministrativeTime;
  };

  const formattedResultAdministrativeTime =
    getFormattedResultAdministrativeTime();

  /* ----------------------------- Assessment time ---------------------------- */
  const assessmentTime = [
    {
      repairer: caseFacts?.delegate_repairer_name,
      time_spent_assessing: caseFacts?.time_spent_assessing,
      cost: caseFacts?.time_spent_assessing,
      timeStamp: Date.now(),
    },
  ];
  const formattedResultAssessmentTime: FormattedAssessmentTimeShape[] = [];
  assessmentTime.forEach((result) => {
    if (result?.time_spent_assessing) {
      if (typeof result?.time_spent_assessing === 'string') {
        formattedResultAssessmentTime.push({
          data: result,
          repairer_id: result?.repairer || '-',
          total_repairing_time: result?.time_spent_assessing || '-',
          timeStamp: result?.timeStamp
            ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
            : '-',
          cost: result?.time_spent_assessing
            ? getCurrencyFormat(
                calculateTotalValue(result?.time_spent_assessing, RATE),
                appInfo.currency
              )
            : '-',
        });
      } else {
        result?.time_spent_assessing.forEach((timeSpent) => {
          formattedResultAssessmentTime.push({
            data: result,
            repairer_id: timeSpent.user || '-',
            total_repairing_time: timeSpent.time || '-',
            timeStamp: result?.timeStamp
              ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
              : '-',
            cost: timeSpent.time
              ? getCurrencyFormat(
                  calculateTotalValue(timeSpent.time, RATE),
                  appInfo.currency
                )
              : '-',
          });
        });
      }
    }
  });

  const finalArrayForAdministrativeTimeTable: FormattedAssessmentTimeShape[] =
    formattedResultAssessmentTime.concat(formattedResultAdministrativeTime);

  const administratitveTotalCost = finalArrayForAdministrativeTimeTable.reduce(
    (acc, curr) => {
      return (
        acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
      );
    },
    0
  );

  /* ------------------------------- Repair time ------------------------------ */
  const getFormattedResultRepairTime = () => {
    const registry: CFCF_RepairWorkRegistry =
      caseFacts?.repair_work_registry || [];
    const formattedResultRepairTime: FormattedRepairTimeShape[] = [];
    registry.forEach((result, i) => {
      if (result?.total_repairing_time)
        formattedResultRepairTime.push({
          data: result,
          description: result?.description || '-',
          repairer_id: result?.delegate_repairer_name
            ? result?.delegate_repairer_name
            : '-',
          total_repairing_time: result?.total_repairing_time
            ? result?.total_repairing_time
            : '-',
          timeStamp: result?.timeStamp
            ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
            : '-',
          cost: result?.total_repairing_time
            ? getCurrencyFormat(
                calculateTotalValue(
                  result?.total_repairing_time,
                  result.repair_rate?.amount ?? RATE
                ),
                result.repair_rate?.currency ?? appInfo.currency
              )
            : '-',
        });
    });
    return formattedResultRepairTime;
  };

  const formattedResultRepairTime: FormattedRepairTimeShape[] =
    getFormattedResultRepairTime();

  const repairTotalCost = formattedResultRepairTime.reduce((acc, curr) => {
    return (
      //@ts-ignore
      acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  /* --------------------------------- Postage -------------------------------- */
  const getFormattedResultPostage = () => {
    const postage = caseFacts?.postage_registry || [];
    const formattedResultPostage: FormattedPostageShape[] = [];
    postage.forEach((result, i) => {
      if (result?.outbound_carrier) {
        formattedResultPostage.push({
          carrier: result?.outbound_carrier ? result?.outbound_carrier : '-',
          tracking_number: result?.outbound_tracking_number
            ? result?.outbound_tracking_number
            : '-',
          address: result?.outbound_address ? (
            <div className="text-sm">
              {formatAddress(result?.outbound_address)}
            </div>
          ) : (
            '-'
          ),
          date: result?.timeStamp
            ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
            : '-',
          cost: result?.outbound_postage_cost
            ? getCurrencyFormat(
                result?.outbound_postage_cost?.amount,
                result?.outbound_postage_cost?.currency
              )
            : '-',
        });
      }
    });
    return formattedResultPostage;
  };

  const formattedResultPostage: FormattedPostageShape[] =
    getFormattedResultPostage();
  const postageTotalCost = formattedResultPostage.reduce((acc, curr) => {
    return (
      acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  /* --------------------------------- Travel --------------------------------- */
  const travel = caseFacts?.travel_cost_data;
  const formattedResultTravel: FormattedTravelShape[] = [];
  travel?.forEach((result: CFCF_TravelCostLog, i) => {
    formattedResultTravel.push({
      data: { ...result, index: i },
      description: result?.description || '-',
      date: result?.date
        ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
        : '-',
      cost: result?.amount
        ? getCurrencyFormat(result?.amount?.amount, result?.amount?.currency)
        : '-',
      vat: result?.VAT ? String(result?.VAT) : '-',
      attachments: result?.documents || [],
    });
  });

  /* ---------------------------- Other claim costs --------------------------- */
  const other = caseFacts?.other_cost_data || [];
  const formattedResultOther: FormattedOtherCostShape[] = [];
  other.forEach((result: CFCF_OtherCostLog, i) => {
    formattedResultOther.push({
      data: { ...result, index: i },
      description: result?.description || '-',
      date: result?.date
        ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
        : '-',
      cost: result?.amount
        ? getCurrencyFormat(result?.amount?.amount, result?.amount?.currency)
        : '-',
      vat: result?.VAT ? String(result?.VAT) : '-',
      attachments: result?.documents || [],
    });
  });

  const totalCost = [
    formattedResultAdministrativeTime,
    formattedResultAssessmentTime,
    formattedResultRepairTime,
    formattedResultPostage,
    formattedResultTravel,
    formattedResultOther,
  ].reduce((acc, curr) => {
    return (
      acc +
      //@ts-ignore todo review this to remove ts-ignore
      curr.reduce((subtotalAcc: number, item: { cost: string }) => {
        return (
          subtotalAcc +
          (item.cost ? parseFloat(item.cost.replace(/[^\d.-]/g, '')) : 0)
        );
      }, 0)
    );
  }, 0);

  const totalCostFromClaim = partsUsedTotalCost + (totalCost ?? 0);

  const { setModalSettings, setShowModal } = useContext(CaseflowContext) || {};

  const modalSettingOptions = {
    log_repair_work: {
      kind: 'generic' as const,
      settings: {
        title: t('Log repair work'),
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <LogRepairWorkModal />,
    },
    replace_part_or_product: {
      kind: 'generic' as const,
      settings: {
        title: t('Replace parts or product'),
        primaryButtonText: t('Confirm'),
        secondaryButtonText: t('Cancel'),
      },
      children: (
        <ReplacePartsOrProductModal
          repair_work_registry={caseFacts?.repair_work_registry ?? []}
        />
      ),
    },
  };

  const renderTableCost = (label: string, columns: any, data: any) => {
    return (
      <div>
        <CardWrapperWithHeader
          title={label}
          canCollapse={true}
          collapsedByDefault={true}
          smaller={true}
          noPadding={true}
        >
          <div className="max-w-[757px] rounded-lg border border-gray-100">
            <Table
              columns={columns}
              data={data}
              inner={true}
              enablePagination={data.length > 10}
              initialState={{
                showGlobalFilter: true,
              }}
              muiSearchTextFieldProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiTableHeadCellProps={{
                className: 'relative bg-gray-200 uppercase font-light',
              }}
              muiTableBodyCellProps={{
                className: 'px-4 py-0',
              }}
            />
          </div>
        </CardWrapperWithHeader>
      </div>
    );
  };
  return (
    <CardWrapperWithHeader
      title={`${t('Claim costs')} ${
        (totalCostFromClaim || 0) > 0
          ? `- ${t('Total')} : ${getCurrencyFormat(
              totalCostFromClaim || 0,
              appInfo.currency
            )}`
          : ''
      }`}
      collapsedByDefault={totalCostFromClaim === 0}
      headerRight={
        <div className="flex gap-4">
          <ButtonIcon
            label={t(`Log work`)}
            kind="bgWhite"
            Icon={WrenchScrewdriverIcon}
            iconLeft
            onClick={() => {
              setModalSettings?.(modalSettingOptions.log_repair_work);
              setShowModal?.(true);
            }}
          />
          <ButtonIcon
            label={t(`Add part`)}
            Icon={PlusIcon}
            iconLeft
            onClick={() => {
              setModalSettings?.(modalSettingOptions.replace_part_or_product);
              setShowModal?.(true);
            }}
          />
        </div>
      }
    >
      <div className="font-nunito flex flex-col gap-6">
        {administratitveTotalCost > 0 &&
          renderTableCost(
            `${'Administrative time'} - ${'Total'}: ${getCurrencyFormat(
              administratitveTotalCost || 0,
              appInfo.currency
            )}`,
            type1AdministrativeTimeColumns,
            finalArrayForAdministrativeTimeTable
          )}
        {repairTotalCost > 0 &&
          renderTableCost(
            `${'Repair time'} - ${'Total'}: ${getCurrencyFormat(
              repairTotalCost || 0,
              appInfo.currency
            )}`,
            type1RepairTimeColumns(setModalData),
            formattedResultRepairTime
          )}
        {partsUsedTotalCost > 0 &&
          renderTableCost(
            `${t('Parts used')} - ${'Total'}: ${getCurrencyFormat(
              partsUsedTotalCost || 0,
              appInfo.currency
            )}`,
            type1PartsUsedColumns(setModalData),
            partsUserArrayParsed
          )}
        {postageTotalCost > 0 &&
          renderTableCost(
            `${t('Shipping')} - ${'Total'}: ${getCurrencyFormat(
              postageTotalCost || 0,
              appInfo.currency
            )}`,
            type1PostageColumns,
            formattedResultPostage
          )}
      </div>
    </CardWrapperWithHeader>
  );
}

export default ClaimCostsSectionType1;
