import {
  asyncValidateSchema,
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

export interface ModalAddUserProps {
  handleClose: () => void;
  itemDetails?: any;
}

interface AddUserFormShape {
  email: string;
  first_name: string;
  last_name: string;
  rate: {
    amount: number;
    currency: string;
  };
}

export function ModalAddUser({ handleClose }: ModalAddUserProps) {
  const appInfo = useAppInfo();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter an email address'),
    first_name: Yup.string()
      .required('Please enter a first name')
      .matches(
        /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
        'First name must start with a capital letter and contain only letters.'
      ),
    last_name: Yup.string()
      .trim()
      .required('Please enter a last name')
      .matches(
        /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
        'Last name must start with a capital letter and contain only letters.'
      ),
    rate: Yup.object({
      amount: Yup.number(),
      currency: Yup.string(),
    }),
  });

  const modalSettings = {
    title: 'Add a new user',
    handleClose: () => handleClose(),
  };

  const initialValues: AddUserFormShape = {
    email: '',
    first_name: '',
    last_name: '',
    rate: {
      amount: 0,
      currency: appInfo.currency,
    },
  };

  const handleSubmit = (values: AddUserFormShape) => {
    setLoading(true);
    // TODO: submit form data
    console.log(values);
  };

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-[724px] rounded-md border bg-white"
      isLoading={loading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validationSchema={validationSchema}
        validateOnBlur
        validate={asyncValidateSchema(validationSchema)}
      >
        {({ errors, values }) => {
          return (
            <Form>
              <div className="flex flex-col gap-5 px-5 pt-5">
                <div className="flex flex-col gap-4">
                  <Input
                    type="email"
                    label="Email*"
                    name="email"
                    settings={{
                      id: 'email',
                      placeholder: 'example@example.com',
                      hint: '*required',
                    }}
                  />
                  <Input
                    type="text"
                    name="first_name"
                    label="First name*"
                    settings={{
                      placeholder: "User's first name",
                      hint: '*required',
                      forceUpperCaseFirstLetter: true,
                    }}
                  />
                  <Input
                    type="text"
                    name="last_name"
                    label="Last name*"
                    settings={{
                      placeholder: "User's last name",
                      hint: '*required',
                      forceUpperCaseFirstLetter: true,
                    }}
                  />
                  <Input
                    type="currency"
                    label="Hourly rate"
                    name="rate"
                    settings={{
                      placeholder: 'Purchase amount',
                      currency: values?.rate?.currency ?? appInfo.currency,
                    }}
                  />
                </div>
                <Button kind="primary" children="Send invite" type="submit" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
