import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import Layout from '../components/templates/Layout/Layout';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { ConfigContext } from '@rabbit/config/context';

const PublicRoutes = () => {
  const isDemoEnv = import.meta.env.DEMO;
  const contextValues = useContext(UserContext);
  const { config } = useContext(ConfigContext);

  return contextValues !== null &&
    !contextValues.isLoading &&
    contextValues.user == null ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
  <Navigate to={isDemoEnv ? SAGE_ROUTE_NAME.DASHBOARD : config.NAVIGATION.SAGE.DEFAULT_PATH} />
  );
};

export default PublicRoutes;
