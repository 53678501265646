import {
  FBD_Holding_Manufacturer,
  FBD_Holding_Private,
  FBD_Holding_Public,
  UserUploadedDocument,
} from '@rabbit/data/types';
import { md5 } from '../../shared/md5hasher';
import {
  CompletedUploadShape,
  DeleteFilePropsShape,
  DocTypeShape,
  DocTypeShapeTypes,
  PersonaTypeShape,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { v4 as uuidv4 } from 'uuid';

export const fetchAllHoldingDocs = async (holdingId: string) => {
  const [holding, holding_manufacturer, holding_private] = await Promise.all([
    FBD_Holding_Public.get(holdingId),
    FBD_Holding_Manufacturer.get(holdingId),
    FBD_Holding_Private.get(holdingId),
  ]);

  return { holding, holding_manufacturer, holding_private };
};

export const compareDocTypeWithPersona = (
  docType: DocTypeShape,
  personaType: PersonaTypeShape
) => {
  if (docType.type === 'holding' && personaType !== 'Consumer') {
    throw new Error(
      'Only consumers can upload files to their holdings. Please check your persona type.'
    );
  }
  if (docType.type === 'vendable' && personaType !== 'Manufacturer') {
    throw new Error(
      'Only manufacturers can upload files to their vendables. Please check your persona type.'
    );
  }
};

export const buildStoragePath = (
  personaType: PersonaTypeShape,
  identityId: string,
  fileCategory: UploadedFileCategories,
  // docid?: string
  docType?: DocTypeShape
) => {
  if (!personaType || !identityId || !fileCategory) return null;

  // Checks for correct path building come here

  if (
    fileCategory === UploadedFileCategories.ShippingLabel &&
    (personaType !== 'Consumer' || !docType?.docid || docType?.type !== 'case')
  )
    throw new Error(
      'Shipping labels have to be associated with a consumer persona and need a caseId.'
    );

  const basePath = `${personaType}/${identityId}`;
  if (docType?.docid) {
    return `${basePath}/${fileCategory}/${docType?.docid}/${uuidv4()}`;
  }
  return `${basePath}/${fileCategory}/Profile/${uuidv4()}`;
};

export const getFileExtension = (filename: string) => {
  return filename.split('.').pop();
};

export const parseUploadedImagesForVendable = (
  filesArr: UserUploadedDocument[]
) => {
  if (!filesArr) throw new Error('No images were provided for formatting');

  const imgUrls = filesArr.map((item) => item.url);
  const vendableImages = filesArr.map((item) => {
    return {
      key: md5(item.url),
      url: item.url,
      filename: item.metadata.name,
      type: getFileExtension(item.metadata.name) ?? 'n/a',
      version: 1,
      sizeBytes: item.metadata.size,
      width: item.dimensions?.width ?? -1,
      height: item.dimensions?.height ?? -1,
      lastModifiedMs: new Date(item.metadata.updated).valueOf(),
    };
  });
  return { imgUrls, vendableImages };
};

export const checkCorrectInputForDeleteFile = (props: DeleteFilePropsShape) => {
  const { category, docType, alterCaseFacts, unattached } = props;
  if (!category) throw new Error('No category was provided');

  if (unattached) return; // Nothing else needs to be checked
  if (
    category === UploadedFileCategories.ConsumerProofPurchase &&
    (!docType?.docid || docType?.type !== DocTypeShapeTypes.Holding)
  ) {
    throw new Error('A holdingId is required for deleting a proof of purchase');
  }
  if (category === UploadedFileCategories.ShippingLabel) {
    if (!alterCaseFacts)
      throw new Error(
        'The alterCaseFacts caseFlow handler is required for deleting a shipping label.'
      );
  }
};

export const getCompletedUploadsOfCategory = (
  completedUploads: CompletedUploadShape[],
  category: UploadedFileCategories
) => {
  return completedUploads.filter((item) => item.category === category);
};
