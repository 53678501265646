import {
  CaseFlowCaseStations,
  CaseFlowCaseStationsShape,
  CaseflowCaseStatus,
} from '@rabbit/bizproc/core';
import { Config_PartnerTypeOptions } from '@rabbit/config/enums';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';

export const STATIONS: {
  id: number;
  key: CaseFlowCaseStationsShape;
  label: string;
  status: CaseflowCaseStatus;
}[] = [
  {
    id: 0,
    key: CaseFlowCaseStations.INITIATE,
    label: 'Initiate',
    status: CaseflowCaseStatus.WAITING_FOR_REVIEW,
  },
  {
    id: 1,
    key: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
    label: 'Preliminary assessment',
    status: CaseflowCaseStatus.WAITING_FOR_REVIEW,
  },
  {
    id: 2,
    key: CaseFlowCaseStations.INITIALLY_ASSESSED,
    label: 'Initially assessed',
    status: CaseflowCaseStatus.ASSESSMENT,
  },
  {
    id: 2.1,
    key: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
    label: 'Case assigned',
    status: CaseflowCaseStatus.ASSESSMENT,
  },
  {
    id: 2.2,
    key: CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT,
    label: 'Repair invoice assessment',
    status: CaseflowCaseStatus.ASSESSMENT,
  },
  {
    id: 2.3,
    key: CaseFlowCaseStations.INVOICE_ACCEPTED,
    label: 'Invoice accepted',
    status: CaseflowCaseStatus.ASSESSMENT,
  },
  {
    id: 3,
    key: CaseFlowCaseStations.INBOUND_POST,
    label: 'Inbound Post',
    status: CaseflowCaseStatus.ASSESSMENT,
  },
  {
    id: 4,
    key: CaseFlowCaseStations.CONFIRM_QUOTATION_RESPONSE,
    label: 'Confirm Quotation Response',
    status: CaseflowCaseStatus.ASSESSMENT,
  },
  {
    id: 5,
    key: CaseFlowCaseStations.FINAL_ASSESSMENT,
    label: 'Final assessment',
    status: CaseflowCaseStatus.ASSESSMENT,
  },
  {
    id: 6,
    key: CaseFlowCaseStations.REPAIR,
    label: 'Repair',
    status: CaseflowCaseStatus.REPAIR_REPLACE,
  },
  {
    id: 6.1,
    key: CaseFlowCaseStations.IN_REPAIR,
    label: 'In repair',
    status: CaseflowCaseStatus.REPAIR_REPLACE,
  },
  {
    id: 7,
    key: CaseFlowCaseStations.THIRD_PARTY_REPAIR,
    label: '3rd Party Repair',
    status: CaseflowCaseStatus.REPAIR_REPLACE,
  },
  {
    id: 8,
    key: CaseFlowCaseStations.PENDING_OUTBOUND,
    label: 'Pending Outbound',
    status: CaseflowCaseStatus.REPAIR_REPLACE,
  },
  {
    id: 8.1,
    key: CaseFlowCaseStations.REPAIRED,
    label: 'Repaired',
    status: CaseflowCaseStatus.REPAIR_REPLACE,
  },
  {
    id: 9,
    key: CaseFlowCaseStations.OUTBOUND,
    label: 'Outbound',
    status: CaseflowCaseStatus.REPAIR_REPLACE,
  },
  {
    id: 10,
    key: CaseFlowCaseStations.CLOSED,
    label: 'Closed',
    status: CaseflowCaseStatus.REPAIR_REPLACE,
  },
];

export const LIST_COUNTRIES = [
  { label: 'Afghanistan', value: 'AF', original: 'افغانستان' },
  { label: 'Åland Islands', value: 'AX', original: 'Åland' },
  { label: 'Albania', value: 'AL', original: 'Shqipëria' },
  { label: 'Algeria', value: 'DZ', original: 'الجزائر' },
  { label: 'American Samoa', value: 'AS', original: 'American Samoa' },
  { label: 'Andorra', value: 'AD', original: 'Andorra' },
  { label: 'Angola', value: 'AO', original: 'Angola' },
  { label: 'Anguilla', value: 'AI', original: 'Anguilla' },
  { label: 'Antarctica', value: 'AQ', original: 'Antarctica' },
  {
    label: 'Antigua and Barbuda',
    value: 'AG',
    original: 'Antigua and Barbuda',
  },
  { label: 'Argentina', value: 'AR', original: 'Argentina' },
  { label: 'Armenia', value: 'AM', original: 'Հայաստան' },
  { label: 'Aruba', value: 'AW', original: 'Aruba' },
  { label: 'Australia', value: 'AU', original: 'Australia' },
  { label: 'Austria', value: 'AT', original: 'Österreich' },
  { label: 'Azerbaijan', value: 'AZ', original: 'Azərbaycan' },
  { label: 'Bahamas', value: 'BS', original: 'Bahamas' },
  { label: 'Bahrain', value: 'BH', original: 'البحرين' },
  { label: 'Bangladesh', value: 'BD', original: 'বাংলাদেশ' },
  { label: 'Barbados', value: 'BB', original: 'Barbados' },
  { label: 'Belarus', value: 'BY', original: 'Беларусь' },
  { label: 'Belgium', value: 'BE', original: 'België' },
  { label: 'Belize', value: 'BZ', original: 'Belize' },
  { label: 'Benin', value: 'BJ', original: 'Bénin' },
  { label: 'Bermuda', value: 'BM', original: 'Bermuda' },
  { label: 'Bhutan', value: 'BT', original: 'འབྲུག་ཡུལ་' },
  { label: 'Bolivia', value: 'BO', original: 'Bolivia' },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
    original: 'Bosna i Hercegovina',
  },
  { label: 'Botswana', value: 'BW', original: 'Botswana' },
  { label: 'Bouvet Island', value: 'BV', original: 'Bouvetøya' },
  { label: 'Brazil', value: 'BR', original: 'Brasil' },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO',
    original: 'British Indian Ocean Territory',
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
    original: 'Negara Brunei Darussalam',
  },
  { label: 'Bulgaria', value: 'BG', original: 'България' },
  { label: 'Burkina Faso', value: 'BF', original: 'Burkina Faso' },
  { label: 'Burundi', value: 'BI', original: 'Burundi' },
  { label: 'Cambodia', value: 'KH', original: 'កម្ពុជា' },
  { label: 'Cameroon', value: 'CM', original: 'Cameroun' },
  { label: 'Canada', value: 'CA', original: 'Canada' },
  { label: 'Cape Verde', value: 'CV', original: 'Cabo Verde' },
  { label: 'Cayman Islands', value: 'KY', original: 'Cayman Islands' },
  {
    label: 'Central African Republic',
    value: 'CF',
    original: 'République centrafricaine',
  },
  { label: 'Chad', value: 'TD', original: 'Tchad' },
  { label: 'Chile', value: 'CL', original: 'Chile' },
  { label: 'China', value: 'CN', original: '中国' },
  { label: 'Christmas Island', value: 'CX', original: 'Christmas Island' },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
    original: 'Cocos (Keeling) Islands',
  },
  { label: 'Colombia', value: 'CO', original: 'Colombia' },
  { label: 'Comoros', value: 'KM', original: 'جزر القمر' },
  { label: 'Congo', value: 'CG', original: 'Congo' },
  {
    label: 'Congo, The Democratic Republic of the',
    value: 'CD',
    original: 'République démocratique du Congo',
  },
  { label: 'Cook Islands', value: 'CK', original: 'Cook Islands' },
  { label: 'Costa Rica', value: 'CR', original: 'Costa Rica' },
  { label: "Cote D'Ivoire", value: 'CI', original: "Côte d'Ivoire" },
  { label: 'Croatia', value: 'HR', original: 'Hrvatska' },
  { label: 'Cuba', value: 'CU', original: 'Cuba' },
  { label: 'Cyprus', value: 'CY', original: 'Κύπρος' },
  { label: 'Czech Republic', value: 'CZ', original: 'Česká republika' },
  { label: 'Denmark', value: 'DK', original: 'Danmark' },
  { label: 'Djibouti', value: 'DJ', original: 'Djibouti' },
  { label: 'Dominica', value: 'DM', original: 'Dominica' },
  {
    label: 'Dominican Republic',
    value: 'DO',
    original: 'República Dominicana',
  },
  { label: 'Ecuador', value: 'EC', original: 'Ecuador' },
  { label: 'Egypt', value: 'EG', original: 'مصر‎' },
  { label: 'El Salvador', value: 'SV', original: 'El Salvador' },
  { label: 'Equatorial Guinea', value: 'GQ', original: 'Guinea Ecuatorial' },
  { label: 'Eritrea', value: 'ER', original: 'ኤርትራ' },
  { label: 'Estonia', value: 'EE', original: 'Eesti' },
  { label: 'Ethiopia', value: 'ET', original: 'ኢትዮጵያ' },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FK',
    original: 'Falkland Islands (Malvinas)',
  },
  { label: 'Faroe Islands', value: 'FO', original: 'Føroyar' },
  { label: 'Fiji', value: 'FJ', original: 'Fiji' },
  { label: 'Finland', value: 'FI', original: 'Suomi' },
  { label: 'France', value: 'FR', original: 'France' },
  { label: 'French Guiana', value: 'GF', original: 'Guyane française' },
  { label: 'French Polynesia', value: 'PF', original: 'Polynésie française' },
  {
    label: 'French Southern Territories',
    value: 'TF',
    original: 'Terres australes françaises',
  },
  { label: 'Gabon', value: 'GA', original: 'Gabon' },
  { label: 'Gambia', value: 'GM', original: 'Gambia' },
  { label: 'Georgia', value: 'GE', original: 'საქართველო' },
  { label: 'Germany', value: 'DE', original: 'Deutschland' },
  { label: 'Ghana', value: 'GH', original: 'Ghana' },
  { label: 'Gibraltar', value: 'GI', original: 'Gibraltar' },
  { label: 'Greece', value: 'GR', original: 'Ελλάδα' },
  { label: 'Greenland', value: 'GL', original: 'Kalaallit Nunaat' },
  { label: 'Grenada', value: 'GD', original: 'Grenada' },
  { label: 'Guadeloupe', value: 'GP', original: 'Guadeloupe' },
  { label: 'Guam', value: 'GU', original: 'Guam' },
  { label: 'Guatemala', value: 'GT', original: 'Guatemala' },
  { label: 'Guernsey', value: 'GG', original: 'Guernsey' },
  { label: 'Guinea', value: 'GN', original: 'Guinée' },
  { label: 'Guinea-Bissau', value: 'GW', original: 'Guiné-Bissau' },
  { label: 'Guyana', value: 'GY', original: 'Guyana' },
  { label: 'Haiti', value: 'HT', original: 'Haïti' },
  {
    label: 'Heard Island and Mcdonald Islands',
    value: 'HM',
    original: 'Heard Island and Mcdonald Islands',
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'VA',
    original: 'Città del Vaticano',
  },
  { label: 'Honduras', value: 'HN', original: 'Honduras' },
  { label: 'Hong Kong', value: 'HK', original: '香港' },
  { label: 'Hungary', value: 'HU', original: 'Magyarország' },
  { label: 'Iceland', value: 'IS', original: 'Ísland' },
  { label: 'India', value: 'IN', original: 'भारत' },
  { label: 'Indonesia', value: 'ID', original: 'Indonesia' },
  { label: 'Iran, Islamic Republic Of', value: 'IR', original: 'ایران' },
  { label: 'Iraq', value: 'IQ', original: 'العراق' },
  { label: 'Ireland', value: 'IE', original: 'Éire' },
  { label: 'Isle of Man', value: 'IM', original: 'Isle of Man' },
  { label: 'Israel', value: 'IL', original: 'ישראל' },
  { label: 'Italy', value: 'IT', original: 'Italia' },
  { label: 'Jamaica', value: 'JM', original: 'Jamaica' },
  { label: 'Japan', value: 'JP', original: '日本' },
  { label: 'Jersey', value: 'JE', original: 'Jersey' },
  { label: 'Jordan', value: 'JO', original: 'الأردن' },
  { label: 'Kazakhstan', value: 'KZ', original: 'Қазақстан' },
  { label: 'Kenya', value: 'KE', original: 'Kenya' },
  { label: 'Kiribati', value: 'KI', original: 'Kiribati' },
  {
    label: "Korea, Democratic People'S Republic of",
    value: 'KP',
    original: '조선민주주의인민공화국',
  },
  { label: 'Korea, Republic of', value: 'KR', original: '대한민국' },
  { label: 'Kuwait', value: 'KW', original: 'الكويت' },
  { label: 'Kyrgyzstan', value: 'KG', original: 'Кыргызстан' },
  {
    label: "Lao People'S Democratic Republic",
    value: 'LA',
    original: 'ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ',
  },
  { label: 'Latvia', value: 'LV', original: 'Latvija' },
  { label: 'Lebanon', value: 'LB', original: 'لبنان' },
  { label: 'Lesotho', value: 'LS', original: 'Lesotho' },
  { label: 'Liberia', value: 'LR', original: 'Liberia' },
  {
    label: 'Libyan Arab Jamahiriya',
    value: 'LY',
    original: 'الجماهيرية العربية الليبية',
  },
  { label: 'Liechtenstein', value: 'LI', original: 'Liechtenstein' },
  { label: 'Kenya', value: 'KE', original: 'Kenya' },
  { label: 'Kiribati', value: 'KI', original: 'Kiribati' },
  {
    label: "Korea, Democratic People'S Republic of",
    value: 'KP',
    original: '조선민주주의인민공화국',
  },
  { label: 'Korea, Republic of', value: 'KR', original: '대한민국' },
  { label: 'Kuwait', value: 'KW', original: 'الكويت' },
  { label: 'Kyrgyzstan', value: 'KG', original: 'Кыргызстан' },
  {
    label: "Lao People'S Democratic Republic",
    value: 'LA',
    original: 'ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ',
  },
  { label: 'Latvia', value: 'LV', original: 'Latvija' },
  { label: 'Lebanon', value: 'LB', original: 'لبنان' },
  { label: 'Lesotho', value: 'LS', original: 'Lesotho' },
  { label: 'Liberia', value: 'LR', original: 'Liberia' },
  {
    label: 'Libyan Arab Jamahiriya',
    value: 'LY',
    original: 'الجماهيرية العربية الليبية',
  },
  { label: 'Liechtenstein', value: 'LI', original: 'Liechtenstein' },
  { label: 'Lithuania', value: 'LT', original: 'Lietuva' },
  { label: 'Luxembourg', value: 'LU', original: 'Luxembourg' },
  { label: 'Macao', value: 'MO', original: '澳門' },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    value: 'MK',
    original: 'Македонија',
  },
  { label: 'Madagascar', value: 'MG', original: 'Madagasikara' },
  { label: 'Malawi', value: 'MW', original: 'Malawi' },
  { label: 'Malaysia', value: 'MY', original: 'Malaysia' },
  { label: 'Maldives', value: 'MV', original: 'ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ' },
  { label: 'Mali', value: 'ML', original: 'Mali' },
  { label: 'Malta', value: 'MT', original: 'Malta' },
  { label: 'Marshall Islands', value: 'MH', original: 'Aelōn̄ in M̧ajeļ' },
  { label: 'Martinique', value: 'MQ', original: 'Martinique' },
  { label: 'Mauritania', value: 'MR', original: 'موريتانيا' },
  { label: 'Mauritius', value: 'MU', original: 'Maurice' },
  { label: 'Mayotte', value: 'YT', original: 'Mayotte' },
  { label: 'Mexico', value: 'MX', original: 'México' },
  {
    label: 'Micronesia, Federated States of',
    value: 'FM',
    original: 'Micronesia',
  },
  { label: 'Moldova, Republic of', value: 'MD', original: 'Moldova' },
  { label: 'Monaco', value: 'MC', original: 'Monaco' },
  { label: 'Mongolia', value: 'MN', original: 'Монгол улс' },
  { label: 'Montserrat', value: 'MS', original: 'Montserrat' },
  { label: 'Morocco', value: 'MA', original: 'المغرب' },
  { label: 'Mozambique', value: 'MZ', original: 'Moçambique' },
  { label: 'Myanmar', value: 'MM', original: 'Myanmar' },
  { label: 'Namibia', value: 'NA', original: 'Namibia' },
  { label: 'Nauru', value: 'NR', original: 'Naoero' },
  { label: 'Nepal', value: 'NP', original: 'नेपाल' },
  { label: 'Netherlands', value: 'NL', original: 'Nederland' },
  {
    label: 'Netherlands Antilles',
    value: 'AN',
    original: 'Nederlandse Antillen',
  },
  { label: 'New Caledonia', value: 'NC', original: 'Nouvelle-Calédonie' },
  { label: 'New Zealand', value: 'NZ', original: 'Aotearoa' },
  { label: 'Nicaragua', value: 'NI', original: 'Nicaragua' },
  { label: 'Niger', value: 'NE', original: 'Niger' },
  { label: 'Nigeria', value: 'NG', original: 'Nigeria' },
  { label: 'Niue', value: 'NU', original: 'Niuē' },
  { label: 'Norfolk Island', value: 'NF', original: 'Norfolk Island' },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
    original: 'Sankattan Siha Na Islas Mariånas',
  },
  { label: 'Norway', value: 'NO', original: 'Norge' },
  { label: 'Oman', value: 'OM', original: 'عمان' },
  { label: 'Pakistan', value: 'PK', original: 'پاکستان' },
  { label: 'Palau', value: 'PW', original: 'Belau' },
  { label: 'Palestinian Territory, Occupied', value: 'PS', original: 'فلسطين' },
  { label: 'Panama', value: 'PA', original: 'Panamá' },
  { label: 'Papua New Guinea', value: 'PG', original: 'Papua Niugini' },
  { label: 'Paraguay', value: 'PY', original: 'Paraguay' },
  { label: 'Peru', value: 'PE', original: 'Perú' },
  { label: 'Philippines', value: 'PH', original: 'Pilipinas' },
  { label: 'Pitcairn', value: 'PN', original: 'Pitcairn Islands' },
  { label: 'Poland', value: 'PL', original: 'Polska' },
  { label: 'Portugal', value: 'PT', original: 'Portugal' },
  { label: 'Puerto Rico', value: 'PR', original: 'Puerto Rico' },
  { label: 'Qatar', value: 'QA', original: 'قطر' },
  { label: 'Reunion', value: 'RE', original: 'Réunion' },
  { label: 'Romania', value: 'RO', original: 'România' },
  { label: 'Russian Federation', value: 'RU', original: 'Россия' },
  { label: 'RWANDA', value: 'RW', original: 'Rwanda' },
  { label: 'Saint Helena', value: 'SH', original: 'Saint Helena' },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN',
    original: 'Saint Kitts and Nevis',
  },
  { label: 'Saint Lucia', value: 'LC', original: 'Saint Lucia' },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
    original: 'Saint-Pierre-et-Miquelon',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
    original: 'Saint Vincent and the Grenadines',
  },
  { label: 'Samoa', value: 'WS', original: 'Samoa' },
  { label: 'San Marino', value: 'SM', original: 'San Marino' },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
    original: 'São Tomé e Príncipe',
  },
  { label: 'Saudi Arabia', value: 'SA', original: 'المملكة العربية السعودية' },
  { label: 'Senegal', value: 'SN', original: 'Sénégal' },
  {
    label: 'Serbia and Montenegro',
    value: 'CS',
    original: 'Србија и Црна Гора',
  },
  { label: 'Seychelles', value: 'SC', original: 'Seychelles' },
  { label: 'Sierra Leone', value: 'SL', original: 'Sierra Leone' },
  { label: 'Singapore', value: 'SG', original: 'Singapura' },
  { label: 'Slovakia', value: 'SK', original: 'Slovensko' },
  { label: 'Slovenia', value: 'SI', original: 'Slovenija' },
  { label: 'Solomon Islands', value: 'SB', original: 'Solomon Aelan' },
  { label: 'Somalia', value: 'SO', original: 'Soomaaliya' },
  { label: 'South Africa', value: 'ZA', original: 'South Africa' },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    original: 'South Georgia and the South Sandwich Islands',
  },
  { label: 'Spain', value: 'ES', original: 'España' },
  { label: 'Sri Lanka', value: 'LK', original: 'ශ්‍රී ලංකාව' },
  { label: 'Sudan', value: 'SD', original: 'السودان' },
  { label: 'Suriname', value: 'SR', original: 'Suriname' },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
    original: 'Svalbard og Jan Mayen',
  },
  { label: 'Swaziland', value: 'SZ', original: 'Swaziland' },
  { label: 'Sweden', value: 'SE', original: 'Sverige' },
  { label: 'Switzerland', value: 'CH', original: 'Schweiz' },
  { label: 'Syrian Arab Republic', value: 'SY', original: 'سوريا' },
  { label: 'Taiwan, Province of China', value: 'TW', original: '台灣' },
  { label: 'Tajikistan', value: 'TJ', original: 'Тоҷикистон' },
  { label: 'Tanzania, United Republic of', value: 'TZ', original: 'Tanzania' },
  { label: 'Thailand', value: 'TH', original: 'ประเทศไทย' },
  { label: 'Timor-Leste', value: 'TL', original: 'Timor-Leste' },
  { label: 'Togo', value: 'TG', original: 'Togo' },
  { label: 'Tokelau', value: 'TK', original: 'Tokelau' },
  { label: 'Tonga', value: 'TO', original: 'Tonga' },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
    original: 'Trinidad and Tobago',
  },
  { label: 'Tunisia', value: 'TN', original: 'تونس' },
  { label: 'Turkey', value: 'TR', original: 'Türkiye' },
  { label: 'Turkmenistan', value: 'TM', original: 'Türkmenistan' },
  {
    label: 'Turks and Caicos Islands',
    value: 'TC',
    original: 'Turks and Caicos Islands',
  },
  { label: 'Tuvalu', value: 'TV', original: 'Tuvalu' },
  { label: 'Uganda', value: 'UG', original: 'Uganda' },
  { label: 'Ukraine', value: 'UA', original: 'Україна' },
  {
    label: 'United Arab Emirates',
    value: 'AE',
    original: 'الإمارات العربية المتحدة',
  },
  { label: 'United Kingdom', value: 'GB', original: 'United Kingdom' },
  { label: 'United States', value: 'US', original: 'United States of America' },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UM',
    original: 'United States Minor Outlying Islands',
  },
  { label: 'Uruguay', value: 'UY', original: 'Uruguay' },
  { label: 'Uzbekistan', value: 'UZ', original: 'Oʻzbekiston' },
  { label: 'Vanuatu', value: 'VU', original: 'Vanuatu' },
  { label: 'Venezuela', value: 'VE', original: 'Venezuela' },
  { label: 'Viet Nam', value: 'VN', original: 'Việt Nam' },
  {
    label: 'Virgin Islands, British',
    value: 'VG',
    original: 'British Virgin Islands',
  },
  {
    label: 'Virgin Islands, U.S.',
    value: 'VI',
    original: 'United States Virgin Islands',
  },
  { label: 'Wallis and Futuna', value: 'WF', original: 'Wallis-et-Futuna' },
  { label: 'Western Sahara', value: 'EH', original: 'الصحراء الغربية' },
  { label: 'Yemen', value: 'YE', original: 'اليمن' },
  { label: 'Zambia', value: 'ZM', original: 'Zambia' },
  { label: 'Zimbabwe', value: 'ZW', original: 'Zimbabwe' },
];

export const COVERAGE_OPTIONS = [
  { id: '1', value: 'Fully covered', label: 'Fully covered' },
  { id: '2', value: 'Partially covered', label: 'Partially covered' },
  { id: '3', value: 'Not covered', label: 'Not covered' },
  {
    id: '4',
    value: 'Further inspection required',
    label: 'Futher inspection required',
  },
];

export const COVERAGE_OPTIONS_FATBIKES = [
  { id: '1', value: 'Fully covered', label: 'Fully covered' },
  { id: '2', value: 'Not covered', label: 'Not covered' },
];

export const COVERAGE_GOODWILL_PARTIAL_OPTIONS = [
  { id: '1', value: 'Fully covered', label: 'Fully covered' },
];

export const COVERAGE_GOODWILL_NONE_OPTIONS = [
  { id: '1', value: 'Fully covered', label: 'Fully covered' },
  { id: '2', value: 'Partially covered', label: 'Partially covered' },
];

export const COVERAGE_FINAL_OPTIONS = [
  { id: '1', value: 'Fully covered', label: 'Fully covered' },
  { id: '2', value: 'Partially covered', label: 'Partially covered' },
  { id: '3', value: 'Not covered', label: 'Not covered' },
];

export const FAULT_TYPE = [
  {
    id: '1',
    value: 'Option 1',
    label: 'Option 1',
  },
  {
    id: '2',
    value: 'Option 2',
    label: 'Option 2',
  },
  {
    id: '3',
    value: 'Option 3',
    label: 'Option 3',
  },
  {
    id: '4',
    value: 'Option 4',
    label: 'Option 4',
  },
];

export const CONTACT_OPTIONS = [
  { id: '1', value: 'Phone', label: 'Phone' },
  { id: '2', value: 'Email', label: 'Email' },
  { id: '3', value: 'App', label: 'App' },
];

export const CAR_FUEL_HYBRID = { label: 'Hybrid', value: 'Hybrid' };
export const CAR_FUEL_ELECTRIC = { label: 'Electric', value: 'Electric' };
export const CAR_FUEL_PETROL = { label: 'Petrol', value: 'Petrol' };
export const CAR_FUEL_DIESEL = { label: 'Diesel', value: 'Diesel' };

export const CAR_FUEL_OPTIONS = [
  { id: '1', value: CAR_FUEL_HYBRID.value, label: CAR_FUEL_HYBRID.label },
  { id: '2', value: CAR_FUEL_ELECTRIC.value, label: CAR_FUEL_ELECTRIC.label },
  { id: '3', value: CAR_FUEL_PETROL.value, label: CAR_FUEL_PETROL.label },
  { id: '4', value: CAR_FUEL_DIESEL.value, label: CAR_FUEL_DIESEL.label },
];

export const CAR_TRANSMISSION_MANUAL = { label: 'Manual', value: 'M' };
export const CAR_TRANSMISSION_AUTOMATIC = { label: 'Automatic', value: 'A' };

export const CAR_TRANSMISSION_OPTIONS = [
  {
    id: '1',
    value: CAR_TRANSMISSION_MANUAL.value,
    label: CAR_TRANSMISSION_MANUAL.label,
  },
  {
    id: '2',
    value: CAR_TRANSMISSION_AUTOMATIC.value,
    label: CAR_TRANSMISSION_AUTOMATIC.label,
  },
];

export const DRIVE_TYPE_4X2 = { label: '4x2', value: '4x2' };
export const DRIVE_TYPE_4X4 = { label: '4x4', value: '4x4' };

export const DRIVE_TYPE_OPTIONS = [
  { id: '1', value: DRIVE_TYPE_4X2.value, label: DRIVE_TYPE_4X2.label },
  { id: '2', value: DRIVE_TYPE_4X4.value, label: DRIVE_TYPE_4X4.label },
];

export const OPTION_YES = { label: 'Yes', value: 'yes' };
export const OPTION_NO = { label: 'No', value: 'no' };

export const YES_NO_OPTIONS = [
  { id: '1', value: OPTION_YES.value, label: OPTION_YES.label },
  { id: '2', value: OPTION_NO.value, label: OPTION_NO.label },
];

export const VEHICLE_CATEGORY_OPTIONS = [
  { id: '1', value: 'standard', label: 'Standard' },
  { id: '2', value: 'special-risk-b', label: 'Special risk B' },
  { id: '3', value: 'special-risk-c', label: 'Special risk C' },
];

export const CLAIM_COST_OPTIONS = [
  { id: '1', value: 'administrative-time', label: 'Administrative time' },
  { id: '2', value: 'parts-used', label: 'Parts used' },
  { id: '3', value: 'repair-time', label: 'Repair time' },
  { id: '4', value: 'shipping', label: 'Shipping' },
  { id: '5', value: 'travel', label: 'Travel' },
  { id: '6', value: 'other', label: 'Other' },
];

export const LIST_CURRENCIES = [
  {
    name: 'Australian Dollar',
    symbol: 'AU',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'AUD',
    namePlural: 'Australian dollars',
  },
  {
    name: 'British Pound Sterling',
    symbol: '£',
    symbolNative: '£',
    decimalDigits: 2,
    rounding: 0,
    code: 'GBP',
    namePlural: 'British pounds sterling',
  },
  {
    name: 'Canadian Dollar',
    symbol: 'CA$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'CAD',
    namePlural: 'Canadian dollars',
  },
  {
    name: 'Euro',
    symbol: '€',
    symbolNative: '€',
    decimalDigits: 2,
    rounding: 0,
    code: 'EUR',
    namePlural: 'euros',
  },
  {
    name: 'US Dollar',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'USD',
    namePlural: 'US dollars',
  },
  // {
  //   "name": "United Arab Emirates Dirham",
  //   "symbol": "AED",
  //   "symbolNative": "د.إ.‏",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "AED",
  //   "namePlural": "UAE dirhams"
  // },
  // {
  //   "name": "Afghan Afghani",
  //   "symbol": "Af",
  //   "symbolNative": "؋",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "AFN",
  //   "namePlural": "Afghan Afghanis"
  // },
  // {
  //   "name": "Albanian Lek",
  //   "symbol": "ALL",
  //   "symbolNative": "Lek",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "ALL",
  //   "namePlural": "Albanian lekë"
  // },
  // {
  //   "name": "Armenian Dram",
  //   "symbol": "AMD",
  //   "symbolNative": "դր.",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "AMD",
  //   "namePlural": "Armenian drams"
  // },
  // {
  //   "name": "Argentine Peso",
  //   "symbol": "AR$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "ARS",
  //   "namePlural": "Argentine pesos"
  // },
  // {
  //   "name": "Azerbaijani Manat",
  //   "symbol": "man.",
  //   "symbolNative": "ман.",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "AZN",
  //   "namePlural": "Azerbaijani manats"
  // },
  // {
  //   "name": "Bosnia-Herzegovina Convertible Mark",
  //   "symbol": "KM",
  //   "symbolNative": "KM",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BAM",
  //   "namePlural": "Bosnia-Herzegovina convertible marks"
  // },
  // {
  //   "name": "Bangladeshi Taka",
  //   "symbol": "Tk",
  //   "symbolNative": "৳",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BDT",
  //   "namePlural": "Bangladeshi takas"
  // },
  // {
  //   "name": "Bulgarian Lev",
  //   "symbol": "BGN",
  //   "symbolNative": "лв.",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BGN",
  //   "namePlural": "Bulgarian leva"
  // },
  // {
  //   "name": "Bahraini Dinar",
  //   "symbol": "BD",
  //   "symbolNative": "د.ب.‏",
  //   "decimalDigits": 3,
  //   "rounding": 0,
  //   "code": "BHD",
  //   "namePlural": "Bahraini dinars"
  // },
  // {
  //   "name": "Burundian Franc",
  //   "symbol": "FBu",
  //   "symbolNative": "FBu",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "BIF",
  //   "namePlural": "Burundian francs"
  // },
  // {
  //   "name": "Brunei Dollar",
  //   "symbol": "BN$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BND",
  //   "namePlural": "Brunei dollars"
  // },
  // {
  //   "name": "Bolivian Boliviano",
  //   "symbol": "Bs",
  //   "symbolNative": "Bs",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BOB",
  //   "namePlural": "Bolivian bolivianos"
  // },
  // {
  //   "name": "Brazilian Real",
  //   "symbol": "R$",
  //   "symbolNative": "R$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BRL",
  //   "namePlural": "Brazilian reals"
  // },
  // {
  //   "name": "Botswanan Pula",
  //   "symbol": "BWP",
  //   "symbolNative": "P",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BWP",
  //   "namePlural": "Botswanan pulas"
  // },
  // {
  //   "name": "Belarusian Ruble",
  //   "symbol": "Br",
  //   "symbolNative": "руб.",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BYN",
  //   "namePlural": "Belarusian rubles"
  // },
  // {
  //   "name": "Belize Dollar",
  //   "symbol": "BZ$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "BZD",
  //   "namePlural": "Belize dollars"
  // },
  // {
  //   "name": "Congolese Franc",
  //   "symbol": "CDF",
  //   "symbolNative": "FrCD",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "CDF",
  //   "namePlural": "Congolese francs"
  // },
  // {
  //   "name": "Swiss Franc",
  //   "symbol": "CHF",
  //   "symbolNative": "CHF",
  //   "decimalDigits": 2,
  //   "rounding": 0.05,
  //   "code": "CHF",
  //   "namePlural": "Swiss francs"
  // },
  // {
  //   "name": "Chilean Peso",
  //   "symbol": "CL$",
  //   "symbolNative": "$",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "CLP",
  //   "namePlural": "Chilean pesos"
  // },
  // {
  //   "name": "Chinese Yuan",
  //   "symbol": "CN¥",
  //   "symbolNative": "CN¥",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "CNY",
  //   "namePlural": "Chinese yuan"
  // },
  // {
  //   "name": "Colombian Peso",
  //   "symbol": "CO$",
  //   "symbolNative": "$",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "COP",
  //   "namePlural": "Colombian pesos"
  // },
  // {
  //   "name": "Costa Rican Colón",
  //   "symbol": "₡",
  //   "symbolNative": "₡",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "CRC",
  //   "namePlural": "Costa Rican colóns"
  // },
  // {
  //   "name": "Cape Verdean Escudo",
  //   "symbol": "CV$",
  //   "symbolNative": "CV$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "CVE",
  //   "namePlural": "Cape Verdean escudos"
  // },
  // {
  //   "name": "Czech Republic Koruna",
  //   "symbol": "Kč",
  //   "symbolNative": "Kč",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "CZK",
  //   "namePlural": "Czech Republic korunas"
  // },
  // {
  //   "name": "Djiboutian Franc",
  //   "symbol": "Fdj",
  //   "symbolNative": "Fdj",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "DJF",
  //   "namePlural": "Djiboutian francs"
  // },
  // {
  //   "name": "Danish Krone",
  //   "symbol": "Dkr",
  //   "symbolNative": "kr",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "DKK",
  //   "namePlural": "Danish kroner"
  // },
  // {
  //   "name": "Dominican Peso",
  //   "symbol": "RD$",
  //   "symbolNative": "RD$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "DOP",
  //   "namePlural": "Dominican pesos"
  // },
  // {
  //   "name": "Algerian Dinar",
  //   "symbol": "DA",
  //   "symbolNative": "د.ج.‏",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "DZD",
  //   "namePlural": "Algerian dinars"
  // },
  // {
  //   "name": "Estonian Kroon",
  //   "symbol": "Ekr",
  //   "symbolNative": "kr",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "EEK",
  //   "namePlural": "Estonian kroons"
  // },
  // {
  //   "name": "Egyptian Pound",
  //   "symbol": "EGP",
  //   "symbolNative": "ج.م.‏",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "EGP",
  //   "namePlural": "Egyptian pounds"
  // },
  // {
  //   "name": "Eritrean Nakfa",
  //   "symbol": "Nfk",
  //   "symbolNative": "Nfk",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "ERN",
  //   "namePlural": "Eritrean nakfas"
  // },
  // {
  //   "name": "Ethiopian Birr",
  //   "symbol": "Br",
  //   "symbolNative": "Br",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "ETB",
  //   "namePlural": "Ethiopian birrs"
  // },
  // {
  //   "name": "Georgian Lari",
  //   "symbol": "GEL",
  //   "symbolNative": "GEL",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "GEL",
  //   "namePlural": "Georgian laris"
  // },
  // {
  //   "name": "Ghanaian Cedi",
  //   "symbol": "GH₵",
  //   "symbolNative": "GH₵",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "GHS",
  //   "namePlural": "Ghanaian cedis"
  // },
  // {
  //   "name": "Guinean Franc",
  //   "symbol": "FG",
  //   "symbolNative": "FG",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "GNF",
  //   "namePlural": "Guinean francs"
  // },
  // {
  //   "name": "Guatemalan Quetzal",
  //   "symbol": "GTQ",
  //   "symbolNative": "Q",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "GTQ",
  //   "namePlural": "Guatemalan quetzals"
  // },
  // {
  //   "name": "Hong Kong Dollar",
  //   "symbol": "HK$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "HKD",
  //   "namePlural": "Hong Kong dollars"
  // },
  // {
  //   "name": "Honduran Lempira",
  //   "symbol": "HNL",
  //   "symbolNative": "L",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "HNL",
  //   "namePlural": "Honduran lempiras"
  // },
  // {
  //   "name": "Croatian Kuna",
  //   "symbol": "kn",
  //   "symbolNative": "kn",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "HRK",
  //   "namePlural": "Croatian kunas"
  // },
  // {
  //   "name": "Hungarian Forint",
  //   "symbol": "Ft",
  //   "symbolNative": "Ft",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "HUF",
  //   "namePlural": "Hungarian forints"
  // },
  // {
  //   "name": "Indonesian Rupiah",
  //   "symbol": "Rp",
  //   "symbolNative": "Rp",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "IDR",
  //   "namePlural": "Indonesian rupiahs"
  // },
  // {
  //   "name": "Israeli New Sheqel",
  //   "symbol": "₪",
  //   "symbolNative": "₪",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "ILS",
  //   "namePlural": "Israeli new sheqels"
  // },
  // {
  //   "name": "Indian Rupee",
  //   "symbol": "Rs",
  //   "symbolNative": "টকা",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "INR",
  //   "namePlural": "Indian rupees"
  // },
  // {
  //   "name": "Iraqi Dinar",
  //   "symbol": "IQD",
  //   "symbolNative": "د.ع.‏",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "IQD",
  //   "namePlural": "Iraqi dinars"
  // },
  // {
  //   "name": "Iranian Rial",
  //   "symbol": "IRR",
  //   "symbolNative": "﷼",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "IRR",
  //   "namePlural": "Iranian rials"
  // },
  // {
  //   "name": "Icelandic Króna",
  //   "symbol": "Ikr",
  //   "symbolNative": "kr",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "ISK",
  //   "namePlural": "Icelandic krónur"
  // },
  // {
  //   "name": "Jamaican Dollar",
  //   "symbol": "J$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "JMD",
  //   "namePlural": "Jamaican dollars"
  // },
  // {
  //   "name": "Jordanian Dinar",
  //   "symbol": "JD",
  //   "symbolNative": "د.أ.‏",
  //   "decimalDigits": 3,
  //   "rounding": 0,
  //   "code": "JOD",
  //   "namePlural": "Jordanian dinars"
  // },
  // {
  //   "name": "Japanese Yen",
  //   "symbol": "¥",
  //   "symbolNative": "￥",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "JPY",
  //   "namePlural": "Japanese yen"
  // },
  // {
  //   "name": "Kenyan Shilling",
  //   "symbol": "Ksh",
  //   "symbolNative": "Ksh",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "KES",
  //   "namePlural": "Kenyan shillings"
  // },
  // {
  //   "name": "Cambodian Riel",
  //   "symbol": "KHR",
  //   "symbolNative": "៛",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "KHR",
  //   "namePlural": "Cambodian riels"
  // },
  // {
  //   "name": "Comorian Franc",
  //   "symbol": "CF",
  //   "symbolNative": "FC",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "KMF",
  //   "namePlural": "Comorian francs"
  // },
  // {
  //   "name": "South Korean Won",
  //   "symbol": "₩",
  //   "symbolNative": "₩",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "KRW",
  //   "namePlural": "South Korean won"
  // },
  // {
  //   "name": "Kuwaiti Dinar",
  //   "symbol": "KD",
  //   "symbolNative": "د.ك.‏",
  //   "decimalDigits": 3,
  //   "rounding": 0,
  //   "code": "KWD",
  //   "namePlural": "Kuwaiti dinars"
  // },
  // {
  //   "name": "Kazakhstani Tenge",
  //   "symbol": "KZT",
  //   "symbolNative": "тңг.",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "KZT",
  //   "namePlural": "Kazakhstani tenges"
  // },
  // {
  //   "name": "Lebanese Pound",
  //   "symbol": "LB£",
  //   "symbolNative": "ل.ل.‏",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "LBP",
  //   "namePlural": "Lebanese pounds"
  // },
  // {
  //   "name": "Sri Lankan Rupee",
  //   "symbol": "SLRs",
  //   "symbolNative": "SL Re",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "LKR",
  //   "namePlural": "Sri Lankan rupees"
  // },
  // {
  //   "name": "Lithuanian Litas",
  //   "symbol": "Lt",
  //   "symbolNative": "Lt",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "LTL",
  //   "namePlural": "Lithuanian litai"
  // },
  // {
  //   "name": "Latvian Lats",
  //   "symbol": "Ls",
  //   "symbolNative": "Ls",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "LVL",
  //   "namePlural": "Latvian lati"
  // },
  // {
  //   "name": "Libyan Dinar",
  //   "symbol": "LD",
  //   "symbolNative": "د.ل.‏",
  //   "decimalDigits": 3,
  //   "rounding": 0,
  //   "code": "LYD",
  //   "namePlural": "Libyan dinars"
  // },
  // {
  //   "name": "Moroccan Dirham",
  //   "symbol": "MAD",
  //   "symbolNative": "د.م.‏",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "MAD",
  //   "namePlural": "Moroccan dirhams"
  // },
  // {
  //   "name": "Moldovan Leu",
  //   "symbol": "MDL",
  //   "symbolNative": "MDL",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "MDL",
  //   "namePlural": "Moldovan lei"
  // },
  // {
  //   "name": "Malagasy Ariary",
  //   "symbol": "MGA",
  //   "symbolNative": "MGA",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "MGA",
  //   "namePlural": "Malagasy Ariaries"
  // },
  // {
  //   "name": "Macedonian Denar",
  //   "symbol": "MKD",
  //   "symbolNative": "MKD",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "MKD",
  //   "namePlural": "Macedonian denari"
  // },
  // {
  //   "name": "Myanma Kyat",
  //   "symbol": "MMK",
  //   "symbolNative": "K",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "MMK",
  //   "namePlural": "Myanma kyats"
  // },
  // {
  //   "name": "Macanese Pataca",
  //   "symbol": "MOP$",
  //   "symbolNative": "MOP$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "MOP",
  //   "namePlural": "Macanese patacas"
  // },
  // {
  //   "name": "Mauritian Rupee",
  //   "symbol": "MURs",
  //   "symbolNative": "MURs",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "MUR",
  //   "namePlural": "Mauritian rupees"
  // },
  // {
  //   "name": "Mexican Peso",
  //   "symbol": "MX$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "MXN",
  //   "namePlural": "Mexican pesos"
  // },
  // {
  //   "name": "Malaysian Ringgit",
  //   "symbol": "RM",
  //   "symbolNative": "RM",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "MYR",
  //   "namePlural": "Malaysian ringgits"
  // },
  // {
  //   "name": "Mozambican Metical",
  //   "symbol": "MTn",
  //   "symbolNative": "MTn",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "MZN",
  //   "namePlural": "Mozambican meticals"
  // },
  // {
  //   "name": "Namibian Dollar",
  //   "symbol": "N$",
  //   "symbolNative": "N$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "NAD",
  //   "namePlural": "Namibian dollars"
  // },
  // {
  //   "name": "Nigerian Naira",
  //   "symbol": "₦",
  //   "symbolNative": "₦",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "NGN",
  //   "namePlural": "Nigerian nairas"
  // },
  // {
  //   "name": "Nicaraguan Córdoba",
  //   "symbol": "C$",
  //   "symbolNative": "C$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "NIO",
  //   "namePlural": "Nicaraguan córdobas"
  // },
  // {
  //   "name": "Norwegian Krone",
  //   "symbol": "Nkr",
  //   "symbolNative": "kr",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "NOK",
  //   "namePlural": "Norwegian kroner"
  // },
  // {
  //   "name": "Nepalese Rupee",
  //   "symbol": "NPRs",
  //   "symbolNative": "नेरू",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "NPR",
  //   "namePlural": "Nepalese rupees"
  // },
  // {
  //   "name": "New Zealand Dollar",
  //   "symbol": "NZ$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "NZD",
  //   "namePlural": "New Zealand dollars"
  // },
  // {
  //   "name": "Omani Rial",
  //   "symbol": "OMR",
  //   "symbolNative": "ر.ع.‏",
  //   "decimalDigits": 3,
  //   "rounding": 0,
  //   "code": "OMR",
  //   "namePlural": "Omani rials"
  // },
  // {
  //   "name": "Panamanian Balboa",
  //   "symbol": "B/.",
  //   "symbolNative": "B/.",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "PAB",
  //   "namePlural": "Panamanian balboas"
  // },
  // {
  //   "name": "Peruvian Nuevo Sol",
  //   "symbol": "S/.",
  //   "symbolNative": "S/.",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "PEN",
  //   "namePlural": "Peruvian nuevos soles"
  // },
  // {
  //   "name": "Philippine Peso",
  //   "symbol": "₱",
  //   "symbolNative": "₱",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "PHP",
  //   "namePlural": "Philippine pesos"
  // },
  // {
  //   "name": "Pakistani Rupee",
  //   "symbol": "PKRs",
  //   "symbolNative": "₨",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "PKR",
  //   "namePlural": "Pakistani rupees"
  // },
  // {
  //   "name": "Polish Zloty",
  //   "symbol": "zł",
  //   "symbolNative": "zł",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "PLN",
  //   "namePlural": "Polish zlotys"
  // },
  // {
  //   "name": "Paraguayan Guarani",
  //   "symbol": "₲",
  //   "symbolNative": "₲",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "PYG",
  //   "namePlural": "Paraguayan guaranis"
  // },
  // {
  //   "name": "Qatari Rial",
  //   "symbol": "QR",
  //   "symbolNative": "ر.ق.‏",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "QAR",
  //   "namePlural": "Qatari rials"
  // },
  // {
  //   "name": "Romanian Leu",
  //   "symbol": "RON",
  //   "symbolNative": "RON",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "RON",
  //   "namePlural": "Romanian lei"
  // },
  // {
  //   "name": "Serbian Dinar",
  //   "symbol": "din.",
  //   "symbolNative": "дин.",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "RSD",
  //   "namePlural": "Serbian dinars"
  // },
  // {
  //   "name": "Russian Ruble",
  //   "symbol": "RUB",
  //   "symbolNative": "₽.",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "RUB",
  //   "namePlural": "Russian rubles"
  // },
  // {
  //   "name": "Rwandan Franc",
  //   "symbol": "RWF",
  //   "symbolNative": "FR",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "RWF",
  //   "namePlural": "Rwandan francs"
  // },
  // {
  //   "name": "Saudi Riyal",
  //   "symbol": "SR",
  //   "symbolNative": "ر.س.‏",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "SAR",
  //   "namePlural": "Saudi riyals"
  // },
  // {
  //   "name": "Sudanese Pound",
  //   "symbol": "SDG",
  //   "symbolNative": "SDG",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "SDG",
  //   "namePlural": "Sudanese pounds"
  // },
  // {
  //   "name": "Swedish Krona",
  //   "symbol": "Skr",
  //   "symbolNative": "kr",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "SEK",
  //   "namePlural": "Swedish kronor"
  // },
  // {
  //   "name": "Singapore Dollar",
  //   "symbol": "S$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "SGD",
  //   "namePlural": "Singapore dollars"
  // },
  // {
  //   "name": "Somali Shilling",
  //   "symbol": "Ssh",
  //   "symbolNative": "Ssh",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "SOS",
  //   "namePlural": "Somali shillings"
  // },
  // {
  //   "name": "Syrian Pound",
  //   "symbol": "SY£",
  //   "symbolNative": "ل.س.‏",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "SYP",
  //   "namePlural": "Syrian pounds"
  // },
  // {
  //   "name": "Thai Baht",
  //   "symbol": "฿",
  //   "symbolNative": "฿",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "THB",
  //   "namePlural": "Thai baht"
  // },
  // {
  //   "name": "Tunisian Dinar",
  //   "symbol": "DT",
  //   "symbolNative": "د.ت.‏",
  //   "decimalDigits": 3,
  //   "rounding": 0,
  //   "code": "TND",
  //   "namePlural": "Tunisian dinars"
  // },
  // {
  //   "name": "Tongan Paʻanga",
  //   "symbol": "T$",
  //   "symbolNative": "T$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "TOP",
  //   "namePlural": "Tongan paʻanga"
  // },
  // {
  //   "name": "Turkish Lira",
  //   "symbol": "TL",
  //   "symbolNative": "TL",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "TRY",
  //   "namePlural": "Turkish Lira"
  // },
  // {
  //   "name": "Trinidad and Tobago Dollar",
  //   "symbol": "TT$",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "TTD",
  //   "namePlural": "Trinidad and Tobago dollars"
  // },
  // {
  //   "name": "New Taiwan Dollar",
  //   "symbol": "NT$",
  //   "symbolNative": "NT$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "TWD",
  //   "namePlural": "New Taiwan dollars"
  // },
  // {
  //   "name": "Tanzanian Shilling",
  //   "symbol": "TSh",
  //   "symbolNative": "TSh",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "TZS",
  //   "namePlural": "Tanzanian shillings"
  // },
  // {
  //   "name": "Ukrainian Hryvnia",
  //   "symbol": "₴",
  //   "symbolNative": "₴",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "UAH",
  //   "namePlural": "Ukrainian hryvnias"
  // },
  // {
  //   "name": "Ugandan Shilling",
  //   "symbol": "USh",
  //   "symbolNative": "USh",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "UGX",
  //   "namePlural": "Ugandan shillings"
  // },
  // {
  //   "name": "Uruguayan Peso",
  //   "symbol": "$U",
  //   "symbolNative": "$",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "UYU",
  //   "namePlural": "Uruguayan pesos"
  // },
  // {
  //   "name": "Uzbekistan Som",
  //   "symbol": "UZS",
  //   "symbolNative": "UZS",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "UZS",
  //   "namePlural": "Uzbekistan som"
  // },
  // {
  //   "name": "Venezuelan Bolívar",
  //   "symbol": "Bs.F.",
  //   "symbolNative": "Bs.F.",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "VEF",
  //   "namePlural": "Venezuelan bolívars"
  // },
  // {
  //   "name": "Vietnamese Dong",
  //   "symbol": "₫",
  //   "symbolNative": "₫",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "VND",
  //   "namePlural": "Vietnamese dong"
  // },
  // {
  //   "name": "CFA Franc BEAC",
  //   "symbol": "FCFA",
  //   "symbolNative": "FCFA",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "XAF",
  //   "namePlural": "CFA francs BEAC"
  // },
  // {
  //   "name": "CFA Franc BCEAO",
  //   "symbol": "CFA",
  //   "symbolNative": "CFA",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "XOF",
  //   "namePlural": "CFA francs BCEAO"
  // },
  // {
  //   "name": "Yemeni Rial",
  //   "symbol": "YR",
  //   "symbolNative": "ر.ي.‏",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "YER",
  //   "namePlural": "Yemeni rials"
  // },
  // {
  //   "name": "South African Rand",
  //   "symbol": "R",
  //   "symbolNative": "R",
  //   "decimalDigits": 2,
  //   "rounding": 0,
  //   "code": "ZAR",
  //   "namePlural": "South African rand"
  // },
  // {
  //   "name": "Zambian Kwacha",
  //   "symbol": "ZK",
  //   "symbolNative": "ZK",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "ZMK",
  //   "namePlural": "Zambian kwachas"
  // },
  // {
  //   "name": "Zimbabwean Dollar",
  //   "symbol": "ZWL$",
  //   "symbolNative": "ZWL$",
  //   "decimalDigits": 0,
  //   "rounding": 0,
  //   "code": "ZWL",
  //   "namePlural": "Zimbabwean Dollar"
  // }
];

export const CUSTOMER_QUESTIONNAIRE = [
  {
    type: 'consumer',
    name: 'type_of_dwelling',
    label: 'Type of dwelling',
    options: [
      'Apartment',
      'Townhouse',
      'House (detached)',
      'House (semi-detached)',
      'Caravan - on site',
    ],
  },
  {
    type: 'consumer',
    name: 'living_situation',
    label: 'What best describes your living situation?',
    options: ['Renting', 'Owning'],
  },
  {
    type: 'consumer',
    name: 'gender',
    label: 'What is your gender?',
    options: [
      'Male',
      'Female',
      'Prefer not to say',
      'I describe myself another way',
    ],
  },
  {
    type: 'consumer',
    name: 'work_status',
    label: 'Current work status',
    options: [
      'Employed - Full Time',
      'Employed - Part Time or Casual',
      'Self-Employed/Freelance',
      'Homemaker',
      'Studying',
      'Intern',
      'Unemployed',
      'Retired',
      'Not able to work',
    ],
  },
];

export const HOLDING_QUESTIONNAIRE = [
  {
    type: 'manufacturer',
    name: 'purchase_inspiration',
    label: 'What inspired this purchase?',
    options: [
      'Minor backyard makeover',
      'Major renovation',
      'Special Event',
      'Recent home build',
      'Recently moved',
      'Replacement',
    ],
    series_limitation: ['shade', 'rain', 'forniture'],
  },
  {
    type: 'manufacturer',
    name: 'product_to_be_used',
    label: 'Where will this product be used?',
    options: ['Pool', 'Deck', 'Front yard', 'Back yard', 'Other'],
  },
  {
    type: 'manufacturer',
    name: 'purchase_reason',
    label: 'Reason for purchase?',
    options: [
      'As a replacement',
      'As a gift',
      'For my child',
      'For personal use',
      'For partner or spouse',
      'Other',
    ],
    series_limitation: ['beach'],
  },
  {
    type: 'manufacturer',
    name: 'product_main_use',
    label: 'What will be the main use of the product?',
    options: ['Sun protection', 'Wind protection', 'Rain protection', 'Other'],
    series_limitation: ['beach'],
  },
  {
    type: 'manufacturer',
    name: 'property_shade',
    label: 'Is there existing shade on the property?',
    options: ['Pergola', 'Awning', 'Other shade umbrella', 'None'],
    series_limitation: ['shade'],
  },
  {
    type: 'manufacturer',
    name: 'shelta_aware',
    label: 'Were you aware of Shelta prior to purchasing?',
    options: [
      'I have heard of Shelta previously',
      'I have previously purchased Shelta products',
      'I have never heard of Shelta before',
    ],
  },
  {
    type: 'manufacturer',
    name: 'purchase_satisfaction',
    label: 'How satisfied are you with the purchase?',
    options: [
      'Extremely satisfied',
      'Satisfied',
      'Neutral',
      'Unsatisfied',
      'Extremely unsatisfied',
      'Not sure yet',
    ],
  },
  {
    type: 'manufacturer',
    name: 'purchase_other_details',
    label: 'Is there anything you wish to share about this purchase?',
  },
];

export const getPartnerTypeOptions = (
  partnerType: Config_PartnerTypeOptions | undefined
) => {
  if (partnerType === Config_PartnerTypeOptions.STONE) {
    return STONE_PARTNER_TYPE_OPTIONS;
  }
  if (partnerType === Config_PartnerTypeOptions.REPAIRER) {
    return REPAIRER_PARTNER_TYPE_OPTIONS;
  }
  return DEFAULT_PARTNER_TYPE_OPTIONS;
};

export const DEFAULT_PARTNER_TYPE_OPTIONS = [
  // { id: '1', value: 'Repairer', label: 'Repairer' },
  // DEV-633: For WIRE, PW and NuCover, remove option to create repairer (car garage) until we implement the new claim flow.
  // { id: '2', value: PersonaTypeSingleLetter.Repairer, label: 'Repairer' },
  {
    id: '3',
    value: [
      PersonaTypeSingleLetter.Retailer,
      PersonaTypeSingleLetter.WarrantyDealer,
    ],
    label: 'Dealership',
  },
];

export const STONE_PARTNER_TYPE_OPTIONS = [
  {
    id: '1',
    value: [
      PersonaTypeSingleLetter.Retailer,
      PersonaTypeSingleLetter.WarrantyDealer,
    ],
    label: 'Stonemason/Cabinetmaker',
  },
];

export const REPAIRER_PARTNER_TYPE_OPTIONS = [
  {
    id: '1',
    value: [PersonaTypeSingleLetter.Repairer],
    label: 'Authorised repairer', // TODO: adding the translation fn here t() doesn't quite work yet. Figure out how to do this.
  },
];

export const CLAIM_STATUS_OPTIONS_LITE = [
  {
    id: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
    value: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
    label: 'Preliminary Assessment Required',
  },
  {
    id: CaseFlowCaseStations.INITIALLY_ASSESSED,
    value: CaseFlowCaseStations.INITIALLY_ASSESSED,
    label: 'Initially Assessed',
  },
  {
    id: CaseFlowCaseStations.IN_REPAIR,
    value: CaseFlowCaseStations.IN_REPAIR,
    label: 'In Repair',
  },
  {
    id: CaseFlowCaseStations.REPAIRED,
    value: CaseFlowCaseStations.REPAIRED,
    label: 'Repaired',
  },
  {
    id: CaseFlowCaseStations.CLOSED,
    value: CaseFlowCaseStations.CLOSED,
    label: 'Closed',
  },
];

export const CLAIM_OUTCOME_OPTIONS = [
  {
    id: '1',
    value: 'Repaired',
    label: 'Repaired',
  },
  {
    id: '2',
    value: 'Replaced',
    label: 'Replaced',
  },
  {
    id: '3',
    value: 'Repaired and replaced',
    label: 'Repaired and replaced',
  },
];

export const CLAIM_CAUSE_OPTIONS = [
  {
    id: '1',
    value: 'Design',
    label: 'Design',
  },
  {
    id: '2',
    value: 'Manufacturing',
    label: 'Manufacturing',
  },
  {
    id: '4',
    value: 'Customer',
    label: 'Customer',
  },
  {
    id: '3',
    value: 'Other',
    label: 'Other',
  },
];

export const CLAIM_STATUS_OPTIONS_FATBIKES = [
  {
    id: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
    value: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
    label: 'Preliminary assessment required',
  },
  {
    id: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
    value: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
    label: 'Repair invoice submission',
  },
  {
    id: CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT,
    value: CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT,
    label: 'Repair invoice assessment',
  },
  {
    id: CaseFlowCaseStations.INVOICE_ACCEPTED,
    value: CaseFlowCaseStations.INVOICE_ACCEPTED,
    label: 'Invoice accepted',
  },
  {
    id: CaseFlowCaseStations.IN_REPAIR,
    value: CaseFlowCaseStations.IN_REPAIR,
    label: 'In repair',
  },
  {
    id: CaseFlowCaseStations.REPAIRED,
    value: CaseFlowCaseStations.REPAIRED,
    label: 'Repaired',
  },
  {
    id: CaseFlowCaseStations.CLOSED,
    value: CaseFlowCaseStations.CLOSED,
    label: 'Closed',
  },
];
