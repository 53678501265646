import TrashIcon from '../../assets/icons/components/TrashIcon';

export interface UploadedFilePreviewProps {
  fileName: string;
  handleRemove: (fileId: string) => void;
  imgStyles?: string;
  filePreviewSrc?: string;
}

// TODO: Expand this to allow for other files, like PDFs,
// and display an icon rather than an image in those cases

export function UploadedFilePreview({
  imgStyles = 'h-40 w-40 object-cover',
  fileName,
  filePreviewSrc,
  handleRemove,
}: UploadedFilePreviewProps) {
  return (
    <div className={'h-fit'}>
      {filePreviewSrc ? (
        <img src={filePreviewSrc} alt={fileName} className={imgStyles} />
      ) : (
        <div className={imgStyles}>PDF!</div>
      )}
      <TrashIcon
        className="mt-1 mb-3 w-4 cursor-pointer"
        onClick={handleRemove}
      />
    </div>
  );
}

export default UploadedFilePreview;
