import { Address } from '@rabbit/data/types';
import { t } from 'i18next';
import { formatAddress } from '../../utils/helpers';

export interface LabelledAddressItemProps {
  addresses: Address[];
}
export function LabelledAddressItem({ addresses }: LabelledAddressItemProps) {
  const address =
    addresses.length > 1
      ? addresses.filter((i) => i.isDefault).length > 0
        ? addresses.filter((i) => i.isDefault)
        : addresses
      : addresses;

  return (
    <div>
      <p className="mt-2.5 text-sm text-gray-500">{t('Address')}</p>
      <div className="overflow-hidden text-ellipsis text-base">
        {address?.[0] ? formatAddress(address?.[0]) : '-'}
      </div>
    </div>
  );
}

export default LabelledAddressItem;
