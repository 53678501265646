import { format } from 'date-fns';

export interface ChatDateProps {
  /* Receiving date info in the unix timestamp format */
  date: number;
}

export function ChatDate({ date }: ChatDateProps) {
  return (
    <div className="my-5 flex w-full justify-center">
      <p className="font-nunito bg-primary-600 w-fit rounded-md py-1 px-3 text-center text-sm text-white">
        {format(date, 'dd MMM yyyy')}
      </p>
    </div>
  );
}

export default ChatDate;
