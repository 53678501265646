import { ComponentType, ReactNode } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export interface StepProps {
  Icon: ComponentType<any>;
  completed: boolean;
  title?: string;
  content?: ReactNode;
}

export interface StepsProgressProps {
  steps: Array<StepProps>;
  inline?: boolean;
}

export function StepsProgress(props: StepsProgressProps) {
  const { steps, inline } = props;

  return (
    <ol
      className={classNames(
        'flex flex-grow flex-col items-center justify-between md:flex-row',
        { ['!flex-row']: steps.length <= 3 }
      )}
    >
      {steps &&
        steps.map((step, index) => {
          return (
            <li
              key={index}
              className={classNames('flex flex-col items-center md:flex-row', {
                'flex-grow': index !== steps.length - 1,
                'md:pb-12': step.title,
                '!flex-row': steps.length <= 3,
                'h-[144px]': !inline,
                'h-[48px]': inline,
              })}
            >
              <div className="relative">
                <div className="relative flex flex-col items-center">
                  <div
                    className={classNames(
                      `z-1 flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-gray-100 ring-0 ring-white dark:bg-blue-900 dark:ring-gray-900`,
                      {
                        [`bg-primary-100`]: step.completed,
                      }
                    )}
                  >
                    {!step.completed ? (
                      <step.Icon className="h-[22px] w-[24px]" />
                    ) : (
                      <CheckIcon
                        className="h-[22px] w-[24px]"
                        color="#579085"
                      />
                    )}
                  </div>
                  {step?.title && (
                    <div className="font-caudex flex w-[120px] flex-col items-center text-center text-base md:absolute md:top-[115%]">
                      {step.title && <div>{step.title}</div>}
                      {step.content && <div>{step.content}</div>}
                    </div>
                  )}
                </div>
              </div>
              {index !== steps.length - 1 && (
                <div
                  className={classNames(
                    'h-12 w-[5px] flex-grow bg-gray-100 md:h-1.5 md:w-auto',
                    {
                      ['!h-1.5 !w-auto']: steps.length <= 3,
                      'bg-primary-100': step.completed,
                    }
                  )}
                ></div>
              )}
            </li>
          );
        })}
    </ol>
  );
}

export default StepsProgress;
