import { useField, useFormikContext } from 'formik';
import { InputTypeCascadeSettingsShape } from '@rabbit/elements/shared-types';
import DropdownCascade from 'react-dropdown-cascade';
import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

const dynamicStyles = {
  base: 'black font-nunito w-full rounded-lg border border-gray-300 bg-gray-50 py-3 px-2 text-base !outline-none text-gray-900',
  error: 'bg-red-50 border-red-500 text-red-700 rounded-lg',
  disabled: 'border-gray-300 text-gray-400 opacity-60 rounded-lg',
};

export interface InputTypeCascadeProps {
  name: string;
  settings: InputTypeCascadeSettingsShape;
}

export function InputTypeCascade({ name, settings, form }: any) {
  const { setFieldValue } = useFormikContext();
  const {
    options,
    placeholder,
    isMulti = false,
    isClearable = true,
    disabled = false,
    onChange: defaultOnChange,
    ...props
  } = settings;
  const [field, meta] = useField(name);
  const [dropdownValue, setDropdownValue] = useState<string | undefined>(
    undefined
  );

  let errorField = false;
  if (meta?.error) errorField = true;

  const clearValue = () => {
    setDropdownValue(undefined);
    setFieldValue(name, undefined);
  };

  const renderInput = (props: any) => {
    return (
      <div className="relative">
        <input
          {...props}
          placeholder={placeholder}
          className={classNames(dynamicStyles.base, {
            [`${dynamicStyles.error}`]: errorField,
            [`${dynamicStyles.disabled}`]: disabled,
          })}
        />
        {dropdownValue && (
          <div
            onClick={clearValue}
            className="absolute right-[5px] top-[12px] z-10 h-[20px] w-[20px] cursor-pointer"
          >
            <XMarkIcon />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <DropdownCascade
        customInput={renderInput}
        customStyles={{
          dropdown: {
            className: 'w-full',
          },
          dropdownMenu: {
            className: '!p-0 bg-gray-100 border border-gray-200 !animate-none',
          },
          dropdownMenuItem: {
            className: 'font-nunito text-base text-gray-900 bg-gray-100',
          },
          dropdownSubitem: {
            className: 'font-nunito text-base text-gray-900 bg-gray-100',
          },
        }}
        items={options}
        onSelect={(value, selectedItems) => {
          //console.log(value, selectedItems);
          setDropdownValue(value);
          setFieldValue(name, value);
        }}
        value={dropdownValue}
        disabled={disabled}
      />
    </div>
  );
}

export default InputTypeCascade;
