import { 
  DTRetailer_Public,
  FBD_Retailer_Public,
  DTVendable,
  FBD_Vendable,
  DTCategory_Public,
  FBD_Category_Public,
} from '@rabbit/data/types';
import {
  SearchOptions,
  //SearchResultsFacet,
  SearchResults,
} from './types';
import {
  SearchGeneric,
  //SearchForFacetValues,
} from './internal';

/**
 * Searches for categories.
 * @param query search query test e.g. 'umbrella'
 * @returns an array of categories
 * @example
 * SearchCategories('umbrella')
 */
export async function SearchCategories(
  query: string,
  options: SearchOptions = {}
): Promise<SearchResults<DTCategory_Public>> {
  return SearchGeneric(FBD_Category_Public, query, options);
}

/**
 * Searches for retailers.
 * @param query search query test e.g. 'tk max'
 * @returns an array of retailers
 * @example
 * SearchRetailers('tk max')
 */
export async function SearchRetailers(
  query: string,
  options: SearchOptions = {}
): Promise<SearchResults<DTRetailer_Public>> {
  return SearchGeneric(FBD_Retailer_Public, query, options);
}

/**
 * Searches for vendables.
 * @param query search query test e.g. 'sofa'
 * @returns an array of vendables
 * @example
 * SearchVendables('sofa')
 */
export async function SearchVendables(
  query: string,
  options: SearchOptions = {}
): Promise<SearchResults<DTVendable>> {
  //if (options.getCategoryData) {
  //  console.error(`options.getCategoryData IS DEPRECIATED`);
  //}
  return SearchGeneric(FBD_Vendable, query, options);
}

/**
 * Searches for vendables by UPC only.
 * @param query search query test e.g. '0000000000000'
 * @returns an array of vendables
 * @example
 * SearchVendables('sofa')
 */
export async function SearchVendablesByUpcCode(
  upc: string,
  options: SearchOptions = {}
  ): Promise<SearchResults<DTVendable>> {
  options.restrictToAttribues = ['payload.upc'];
  return SearchVendables(upc, options);
}

/**
 * Searches for vendables by id only.
 * @param query search query test e.g. '0000000000000'
 * @returns an array of vendables
 * @example
 * SearchVendablesByDocId('moto-abcd1239cx')
 */
export async function SearchVendablesByDocid(
  docid: string,
  options: SearchOptions = {}
  ): Promise<SearchResults<DTVendable>> {
  options.restrictToAttribues = ['payload.docid'];
  return SearchVendables(docid, options);
}

/**
 * Searches within a specific vendable facet.
 * Useful for polulating filter dropdowns.
 * Currently only supports colour and series.
 * @param facetName name of the facet to search within e.g. 'colour.name, series'
 * @param query search query test e.g. 'red'
 * @returns an array of facet items
 * @example
 * SearchFacet('colour','red')
 */
/*

// NOT USED RIGHT NOW

export async function SearchVendableFacet(
  facetName: string,
  query: string
): Promise<SearchResultsFacet> {
  const projectName = GetProjectName();
  return SearchForFacetValues(projectName,'vendable', facetName, query);
}
*/


export function GetUniqueCategoryList(vendables: DTVendable[]) {
  // Get all unique categories from results
  const categoriesList = vendables
    .map((result) => result.category)
    .filter(Boolean);

  // Merge all the arrays into one
  const mergedCategories = categoriesList.flat(1);

  // Remove duplicates
  const uniqueCategories = [...new Set(mergedCategories)];

  // Get item count for each category from results
  const categoryCounts = vendables.reduce((acc: any, result: any) => {
    if (result.category) {
      result.category.forEach((category: string) => {
        if (acc[category]) {
          acc[category] += 1;
        } else {
          acc[category] = 1;
        }
      });
    }
    return acc;
  }, {});

  const uniqueCategoryList = uniqueCategories.map((category, i) => {
    return {
      label: `${category?.[0].toUpperCase()}${category?.slice(1)}`,
      count: categoryCounts[`${category}`],
    };
  });

  return uniqueCategoryList;
};

