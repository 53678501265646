import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  FAULT_TYPE,
  useManageFaultCategories,
  useManageFaults,
} from '@rabbit/bizproc/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { TrashIcon } from '@heroicons/react/24/solid';

export interface ModalAddEditFaultCategoryProps {
  handleClose: () => void;
  itemDetails?: any;
  mode: 'edit' | 'new';
  setLoading: Dispatch<SetStateAction<boolean>>;
}

interface OPTION {
  id?: string;
  value: string;
  label: string;
}

interface FormDataShape {
  faultCategory: string;
  //fault_category: string[];
  details?: string;
}

export function ModalAddEditFaultCategory({
  handleClose,
  itemDetails,
  mode,
  setLoading,
}: ModalAddEditFaultCategoryProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createFaultCategory, editFaultCategory, deleteFaultCategory } =
    useManageFaultCategories(itemDetails?.docid);

  const initialValues: FormDataShape = {
    faultCategory: itemDetails ? itemDetails.title : '',
    // fault_category: todo
    details: itemDetails ? itemDetails.description : '',
  };

  const schema = Yup.object({
    faultCategory: Yup.string()
      .trim()
      .required('Please enter a fault category'),
    // fault_category: Yup.string().trim().required('Required'),
    details: Yup.string().trim(),
  });

  const handleSubmit = async (values: FormDataShape) => {
    setIsSubmitting(true);
    setLoading(true);
    try {
      if (mode === 'edit') {
        await editFaultCategory({
          title: values.faultCategory ?? '',
          // category: values.fault_category ?? [], // todo fix the type on this key later
          description: values.details ?? '',
        });
      } else if (mode === 'new') {
        await createFaultCategory({
          title: values.faultCategory ?? '',
          // category: values.fault_category ?? [], // todo fix the type on this key later
          description: values.details ?? '',
        });
      } else {
        throw new Error('Invalid mode');
      }
      toast.success('Fault saved successfully.');
    } catch (error) {
      console.log(error);
      toast.error('Error saving fault');
      setIsSubmitting(false);
      setLoading(false);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      handleClose();
    }
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    setLoading(true);
    try {
      await deleteFaultCategory(itemDetails?.docid);
      toast.success('Fault deleted successfully.');
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error('Error deleting fault');
      setLoading(false);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      handleClose();
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => (
          <form>
            <div className="mt-4 flex gap-4 px-5 pb-2">
              <div className="flex-1">
                <Input
                  type="text"
                  label="Fault category"
                  name="faultCategory"
                  settings={{
                    placeholder: 'Category name',
                    hint: '*required',
                  }}
                />
              </div>
              {/* <div className="flex-1">
                <Input
                  type="select"
                  label="Fault Category*"
                  name="fault_category"
                  settings={{
                    options: FAULT_TYPE,
                    placeholder: 'Please select an option',
                    id: 'fault_category',
                    errors: props.errors,
                  }}
                />
              </div> */}
            </div>
            {/* <div className="py-2 px-5">
              <Input
                type="select"
                label="Product(s)*"
                name="products"
                settings={{
                  id: 'products',
                  options: options,
                  placeholder: 'Select',
                  isMulti: true,
                  errors: props.errors,
                }}
              />
            </div>
            <div className="py-2 px-5">
              <Input
                type="select"
                label="Parts*"
                name="parts"
                settings={{
                  options: options,
                  placeholder: 'Select',
                  id: 'parts',
                  isMulti: true,
                  errors: props.errors,
                }}
              />
            </div> */}
            <div className="py-2 px-5">
              <Input
                type="textarea"
                label="Details*"
                name="details"
                settings={{ placeholder: 'Write text here ...' }}
              />
            </div>
            <div className="flex gap-5 py-4 px-5">
              <Button
                kind="primary"
                size="md"
                children={mode === 'edit' ? 'Save all' : 'Save'}
                disabled={isSubmitting}
                onClick={props.handleSubmit}
              />
              {mode === 'edit' && (
                <Button
                  kind="red"
                  size="md"
                  icon={<TrashIcon />}
                  children="Delete fault category"
                  disabled={isSubmitting}
                  onClick={handleDelete}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
