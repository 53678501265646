import { FBDocTypeEssentials, NoSqlDoc } from '@rabbit/firebase/doctype';
import {
  SearchOptions,
  SearchResults,
  SearchResultItem,
} from './types';
import { GetAlgoliaSearchIndex } from './client';

/**
 * Internal use only
 * Generic search function.
 * @param type document type (e.g. FBD_Vendable, FBD_Retailer_Public or FBD_Category_Public)
 * @param query search query test e.g. 'umbrella'
 * @param options search options
 * @returns an array of search results
 * @example
 * SearchGeneric(FBD_Vendable, 'umbrella')
 */
export async function SearchGeneric<TYPE extends NoSqlDoc>(
  type: FBDocTypeEssentials<TYPE>,
  query: string,
  options?: SearchOptions,
): Promise<SearchResults<TYPE>> {

  const algoliaParams = {
    page: options?.page || 0,
    hitsPerPage: options?.hitsPerPage || 10,
    facetFilters: options?.facetFilters
      ? options.facetFilters.map(
          (filter) => `payload.${filter.name}:${filter.value}`
        )
      : [],
    restrictSearchableAttributes: options?.restrictToAttribues || [],
  };

  const index = await GetAlgoliaSearchIndex(type);
  const clientResults = await index.search<SearchResultItem<TYPE>>(query, algoliaParams);

  const res: SearchResults<TYPE> = {
    numitems: clientResults.nbHits,
    page: clientResults.page,
    numpages: clientResults.nbPages,
    items: [],
  };

  for (let i = 0; i < clientResults.hits.length; i++) {
    res.items.push(clientResults.hits[i].payload as TYPE);
  }

  console.log(`SearchGeneric: got ${res.items.length} results for "${query}" on index ${index.indexName}`);
  return res;
}