import { getUnixTime } from 'date-fns';
import { z } from 'zod';
export { z } from 'zod';

// This is far from perfect since it doesn't fuss over optional properties.
// https://github.com/colinhacks/zod/issues/372 for the gas festival.

// export const schema =
//   <T>() =>
//   <S extends z.ZodType<T, any, any>>(arg: S) => {
//     return arg;
//   };

// Stubbing it cos it doesnt work either lol

export const schema =
  <T>() =>
  <S>(arg: S) => {
    return arg;
  };

export function delay(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

interface OurDateTimeInterface {
  nowUTCTimestamp: () => number;
  dateToTimestamp: (date: Date) => number;
  timestampToDate: (timestamp: number) => Date;
}

export const OurDateTime: OurDateTimeInterface = {
  nowUTCTimestamp: () => Date.now(),
  dateToTimestamp: (date: Date) => getUnixTime(date) * 1000,
  timestampToDate: (timestamp: number) => new Date(timestamp),
};

/**
 * encodeURIComponent() escapes - _ . ! ~ * ' ( ) character:
 *  reference: encodeRFC3986URIComponent
 * The function `customUriComponentEncoder` encodes a string using the RFC 3986 URI component encoding scheme.
 * @param {string} str -takes a string `str` as input
 * @returns returns encodes it using `encodeURIComponent`, and then replaces characters `!'()*` with their
 * hexadecimal representation as per RFC 3986.
 */
export function customUriComponentEncoder(str: string) {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  );
}
