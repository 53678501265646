import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
} from '@rabbit/firebase/doctype';
import { TenantLink } from '../lib/tenant';
import { DTPersona, DTPersonaPublic, FBD_Keygen_Persona } from './persona';
import { Address } from '../base/basic';
import { z } from 'zod';
import { WarrantyOfferLink } from '../lib/warranty-offer';
import { WarrantorLink } from './warrantor';

/* -------------------------------------------------------------------------- */
/*                               Warranty Dealer                              */
/* -------------------------------------------------------------------------- */

/** The entity that sells warranties (usually extended warranties) to end-users
 * on behalf of a Warrantor. While the Warrantor is responsible for the warranty
 * and claims, the dealer is responsible for selling the warranty and handling
 * the customer relationship.
 *
 * Warrantors have a list of warranty templates, which outline the terms and
 * conditions of the warranty. Dealers, in turn, are delegates of the warrantor
 * and have warranty offers, which are based on the templates but can have different
 * prices and conditions specific to the dealer.
 *
 * Dealers can have other dealer delegates of their own, which are assumed to have access
 * to all of their principals' offers unless otherwise specified. Dealers are also always
 * assumed to be linked to a tenant, which is the entity that owns the root dealer persona.
 */
export interface DTWarrantyDealer_Private extends DTPersona {
  /** Warranty dealer name, used in search and other UI elements */
  name: string;

  /** The tenant linked to this dealer persona, if any. */
  tenantLink?: TenantLink;

  /** The warrantors this dealer persona works with */
  warrantors: WarrantorLink[];

  /** The warranty offers available to this dealer.
   *  If a dealer has no offers, they are assumed to have access to all
   *  of their principals' offers.
   */
  warrantyOffers?: WarrantyOfferLink[];
}

export interface DTWarrantyDealer_Public extends DTPersonaPublic {
  /** Dealer name, used in search and other UI elements */
  name: string;

  /** Location(s) of the dealer */
  addresses?: Address[];

  /** Dealer contact phone number */
  phone?: string;

  /** Dealer contact email address */
  email?: string;

  /** Url to website */
  websiteUrl?: string;

  /** The warrantors this dealer persona works with */
  warrantors: WarrantorLink[];

  /** The tenant linked to this dealer persona, if any. */
  tenantLink?: TenantLink;
}

export const FBD_WarrantyDealer_Private =
  MakeFBDocType<DTWarrantyDealer_Private>({
    name: 'Warranty Dealer Private',
    collection: 'warrantyDealer_private',
    empty: () => {
      const result: DTWarrantyDealer_Private = {
        ...Empty_NoSqlDoc(),
        name: '',
        warrantors: [],
        delegates: {},
        identity: '',
        warrantyOffers: [],
        createTime: new Date().getTime(),
      };
      return result;
    },
    keygen: FBD_Keygen_Persona('D'),
  });

export const FBD_WarrantyDealer_Public = MakeFBDocType<DTWarrantyDealer_Public>(
  {
    name: 'Warranty Dealer Public',
    collection: 'warrantyDealer_public',
    empty: () => {
      const result: DTWarrantyDealer_Public = {
        ...Empty_NoSqlDoc(),
        name: '',
        warrantors: [],
      };
      return result;
    },
    keygen: FBD_Keygen_BANNED(
      'docid should be set to that of the related private document.'
    ),
  }
);

export type WarrantyDealerLink = string;

export const Z_WarrantyDealerLink = z.string();
