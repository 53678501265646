import { TrashIcon } from '@heroicons/react/24/solid';
import {
  Input,
  Button,
  Modal,
  asyncValidateSchema,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { Formik, Form } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

interface EditUserFormShape {
  role: string;
  rate: {
    amount: number;
    currency: string;
  };
}

export function ModalEditUser({
  handleClose,
  itemDetails,
}: {
  handleClose: () => void;
  itemDetails?: any;
}) {
  const appInfo = useAppInfo();
  const schema = Yup.object({
    role: Yup.string().required('Please enter a role'),
    rate: Yup.object({
      amount: Yup.number().min(1).required('Please enter a valid amount'),
      currency: Yup.string().required('Currency is required'),
    }).required('Please enter an hourly rate'),
  });

  const modalSettings = {
    title: 'Edit user',
    handleClose: () => handleClose(),
  };

  const initialValues: EditUserFormShape = {
    role: undefined as any,
    rate: {
      amount: 0,
      currency: appInfo.currency,
    },
  };

  const options = [
    { label: 'Administrator', value: 'Administrator' },
    { label: 'Technical user', value: 'Technical user' },
    { label: 'Enterprise user', value: 'Enterprise user' },
    { label: 'Management', value: 'Management' },
  ];

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this user. You will lose all information. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () => {
        // TODO: Add delete user implementation
        console.log('delete --> selectedRowToDelete ', selectedUser);
      },
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const deleteUser = (item: any) => {
    setIsConfirmationModalOpen(true);
    setSelectedUser(item);
  };

  const onSubmit = (values: any) => {
    setLoading(true);
    // TODO: Update user values
    console.log(values);
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  if (isConfirmationModalOpen)
    return (
      <Modal
        className="h-fit w-[350px] rounded-md bg-white"
        kind={confirmationModalSettings.kind}
        settings={confirmationModalSettings.settings}
      />
    );

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[512px] rounded-md border"
      isLoading={loading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validate={asyncValidateSchema(schema)}
        validateOnBlur
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className="flex flex-col gap-5 px-5 pt-5">
                <Input
                  type="select"
                  label="Role"
                  name="role"
                  settings={{
                    options: options,
                    placeholder: 'Please select an option',
                    errors: errors,
                    hint: '*required',
                  }}
                />
                <Input
                  type="currency"
                  label="Hourly rate"
                  name="rate"
                  settings={{
                    placeholder: 'Hourly rate',
                    hint: '*required',
                    currency: values?.rate?.currency ?? appInfo.currency,
                  }}
                />
                <div className="flex gap-4 pt-6">
                  <Button
                    className="w-1/3"
                    kind="primary"
                    children="Save"
                    type="submit"
                  />
                  <Button
                    className="w-1/3"
                    kind="red"
                    icon={<TrashIcon className="h-4 w-4" />}
                    children="Delete user"
                    onClick={() => deleteUser(itemDetails)}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
