import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { Shopify_Customer_Shape } from './base-types';

//TODO: REVIEW THIS
//todo: setup webhooks for customer updates

export interface ShopifyCustomerVersionHistory {
  version: number;
  customerFull?: Shopify_Customer_Shape;
  tcreate: number;
  tupdate: number;
}
/* -------------------------------------------------------------------------- */
/*                           Shopify Customer Schema                          */
/* -------------------------------------------------------------------------- */

export interface DTShopifyCustomer extends NoSqlDoc {
  /**
   * The Shopify shop URI, which serves as the unique identifier for the shop. E.g bananamart.myshopify.com
   */
  shopLink: string;

  /* Original Shopify fields. May or may not exist. */
  customerFull?: Shopify_Customer_Shape;

  /* The customer email, which will be the main identifier for them. */
  customerEmail: string;

  /* All the orders associated with this customer */
  orderLinks: string[];

  /* The version number for this order. Starts at 1 */
  version: number;

  versionHistory?: ShopifyCustomerVersionHistory[];
}

export const FBD_ShopifyCustomer = MakeFBDocType<DTShopifyCustomer>({
  name: 'Shopify_Customer',
  collection: 'shopify_customer',
  empty: () => {
    const result: DTShopifyCustomer = {
      ...Empty_NoSqlDoc(),
      shopLink: '',
      customerEmail: '',
      version: 1,
      orderLinks: [],
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED('Shopify_CUSTOMER key should be SHOPNAME_EMAIL'),
});
