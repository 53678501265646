import { useField, useFormikContext } from 'formik';
import UploadFilesFormv2, { UploadFilesFormV2Props } from './UploadFilesFormv2';

export interface UploaderFormikProviderProps extends UploadFilesFormV2Props {
  name: string;
}
export function UploaderFormikProvider(props: UploaderFormikProviderProps) {
  const name = props.name;
  const [field, meta] = useField(name);
  const { value, ...rest } = field;
  const { setFieldValue, setFieldTouched, setErrors } = useFormikContext();

  const formikProps = {
    fieldValue: value,
    fieldMeta: meta,
    setFieldValue,
    setFieldTouched,
    setErrors,
  };

  return (
    <>
      <UploadFilesFormv2 {...{ ...props, formikProps }} />
    </>
  );
}
