import { CaseFlowValidator } from '../validation';
import GenericRegistry from '@rabbit/utils/generic-registry';
import {
  CFAction,
  CFActionStep,
  CFActionStepGeneric,
} from '@rabbit/data/types';
import { BaseCaseFlowCase, ActionRuntime } from '../base_case_flow_case';
import { ActionExecutionRequirement } from './types';

export const ActionStepRegistry = new GenericRegistry<
  ActionStepImplementation<any>
>('caseflow_action_steps');

export type ActionStepImplementation<
  STEPTYPE extends CFActionStepGeneric<any>
> = {
  validate: (
    location: string,
    validator: CaseFlowValidator,
    step: STEPTYPE,
    action: CFAction,
    art: ActionRuntime
  ) => void;
  query?: () => ActionExecutionRequirement | null;
  interior: (cfc: BaseCaseFlowCase, step: STEPTYPE, art: ActionRuntime) => void;
};
