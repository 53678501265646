import { UserUploadedDocument } from '@rabbit/data/types';
import {
  CompletedUploadShape,
  DocTypeShape,
  UploadQueueStateShape,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';

// Todo: type actions
/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */

export const UploadQueueStateReducer = (
  state: UploadQueueStateShape,
  action: any
) => {
  switch (action.type) {
    /* ----------------------------- Ongoing uploads ---------------------------- */
    case 'ADD_NEW_UPLOAD': {
      const newOUS = [...state.ongoing, action.payload];
      return { ...state, ongoing: newOUS };
    }
    case 'SET_UPLOAD_PROGRESS': {
      const task = state.ongoing.find(
        (item) => item.key === action.payload.key
      );
      if (!task) return state;

      const newOUS = state.ongoing.map((item) =>
        item.key === action.payload.key
          ? { ...item, progress: action.payload.progress }
          : item
      );
      return { ...state, ongoing: newOUS };
    }
    case 'SET_UPLOAD_ERROR': {
      const { key, error } = action.payload;
      const task = state.ongoing.find(
        (item) => item.key === action.payload.key
      );
      if (!task) return state;

      const newOUS = state.ongoing.filter((item) => item.key !== key);
      const newCUS = [
        ...state.completed,
        {
          key,
          ogFile: task.file,
          category: task.category,
          uploadedFile: null,
          docType: task.docType,
          error,
        },
      ];
      return { ...state, ongoing: newOUS, completed: newCUS };
    }
    case 'SET_UPLOAD_COMPLETE': {
      const { key, uploadedFile, docType } = action.payload;
      const task = state.ongoing.find(
        (item) => item.key === action.payload.key
      );
      if (!task) return state;
      const newOUS = state.ongoing.filter((item) => item.key !== key);
      const newCUS = [
        ...state.completed,
        {
          key,
          ogFile: task.file,
          category: task.category,
          uploadedFile,
          docType,
          error: null,
        },
      ];
      return { ...state, ongoing: newOUS, completed: newCUS };
    }
    case 'CLEAR_ONGOING_UPLOADS': {
      const updatedOUS = state.ongoing.filter(
        (item) => !action.payload.keys.includes(item.key)
      );
      return { ...state, ongoing: updatedOUS };
    }
    case 'CLEAR_ALL_ONGOING_UPLOADS':
      return { ...state, ongoing: [] };

    // if we need it on a file by file basis
    // case 'SET_IS_UPDATING':
    //   return { ...state,  };

    /* ---------------------------- Completed uploads --------------------------- */
    case 'ADD_NEW_COMPLETED_UPLOAD': {
      const newCUS = [...state.completed, action.payload];
      return {
        ...state,
        completed: newCUS,
      };
    }
    case 'CLEAR_COMPLETED_UPLOADS': {
      const updatedCUS = state.completed.filter(
        (item) => !action.payload.keys.includes(item.key)
      );
      return { ...state, completed: updatedCUS };
    }
    case 'CLEAR_ALL_COMPLETED_UPLOADS':
      return { ...state, completed: [] };

    /* ---------------------------- Attached uploads ---------------------------- */
    case 'ADD_ATTACHED_UPLOADS': {
      const newAUS = [...state.attached, ...action.payload.uploads];
      return {
        ...state,
        attached: newAUS,
      };
    }
    case 'CLEAR_ATTACHED_UPLOADS': {
      const updatedAUS = state.attached.filter(
        (item) => !action.payload.keys.includes(item.key)
      );
      return { ...state, attached: updatedAUS };
    }
    case 'CLEAR_ALL_ATTACHED_UPLOADS':
      return { ...state, attached: [] };
    default:
      return state;
  }
};

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */

/* ------------------------------- CU Dispatch ------------------------------ */

export const addNewCompletedUpload = (
  dispatch: React.Dispatch<any>,
  key: string,
  ogFile: File,
  category: UploadedFileCategories,
  uploadedFile: UserUploadedDocument | null,
  docType?: DocTypeShape,
  error?: string | null
) => {
  dispatch({
    type: 'ADD_NEW_COMPLETED_UPLOAD',
    payload: {
      key,
      ogFile,
      uploadedFile,
      category,
      docType,
      error: error ?? null,
    },
  });
};

export const clearCompletedUploads = (
  dispatch: React.Dispatch<any>,
  keys: string[]
) => {
  dispatch({
    type: 'CLEAR_COMPLETED_UPLOADS',
    payload: {
      keys,
    },
  });
};

export const clearAllCompletedUploads = (dispatch: React.Dispatch<any>) => {
  dispatch({
    type: 'CLEAR_ALL_COMPLETED_UPLOADS',
  });
};

/* ------------------------------- OU Dispatch ------------------------------ */

export const addNewUpload = (
  dispatch: React.Dispatch<any>,
  file: File,
  key: string,
  category: UploadedFileCategories
) => {
  dispatch({
    type: 'ADD_NEW_UPLOAD',
    payload: {
      key,
      progress: 0,
      category,
      file,
    },
  });
};

export const setUploadProgress = (
  dispatch: React.Dispatch<any>,
  key: string,
  progress: number
) => {
  dispatch({
    type: 'SET_UPLOAD_PROGRESS',
    payload: {
      key,
      progress,
    },
  });
};

export const setUploadError = (
  dispatch: React.Dispatch<any>,
  key: string,
  error: string
) => {
  dispatch({
    type: 'SET_UPLOAD_ERROR',
    payload: {
      key,
      error,
    },
  });
};

export const handleUploadComplete = (
  dispatch: React.Dispatch<any>,
  key: string,
  uploadedFile: UserUploadedDocument,
  docType?: DocTypeShape
) => {
  dispatch({
    type: 'SET_UPLOAD_COMPLETE',
    payload: {
      key,
      uploadedFile,
      docType,
    },
  });
};

export const clearOngoingUploads = (
  dispatch: React.Dispatch<any>,
  keys: string[]
) => {
  dispatch({
    type: 'CLEAR_ONGOING_UPLOADS',
    payload: {
      keys,
    },
  });
};

export const clearAllOngoingUploads = (dispatch: React.Dispatch<any>) => {
  dispatch({
    type: 'CLEAR_ALL_ONGOING_UPLOADS',
  });
};

/* ------------------------------- AU Dispatch ------------------------------ */
export const addAttachedUploads = (
  dispatch: React.Dispatch<any>,
  uploads: CompletedUploadShape[]
) => {
  dispatch({
    type: 'ADD_ATTACHED_UPLOADS',
    payload: {
      uploads,
    },
  });
};

export const clearAttachedUploads = (
  dispatch: React.Dispatch<any>,
  keys: string[]
) => {
  dispatch({
    type: 'CLEAR_ATTACHED_UPLOADS',
    payload: {
      keys,
    },
  });
};

export const clearAllAttachedUploads = (dispatch: React.Dispatch<any>) => {
  dispatch({
    type: 'CLEAR_ALL_ATTACHED_UPLOADS',
  });
};
