import { useEffect, useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  formatUnixTime,
  Heading,
  LoadingSpinner,
  Modal,
  Table,
} from '@rabbit/elements/shared-components';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { ModalNewEditCustomer } from '../components/organisms/ModalNewEditCustomer/ModalNewEditCustomer';
import { useNavigate, useParams } from 'react-router-dom';
import { useConsumerProfile, useGetConsumerData } from '@rabbit/bizproc/react';
import { add, fromUnixTime, getUnixTime, sub } from 'date-fns';
import {
  capitalizeFirstLetter,
  getFavouriteStore,
  getProductCategoryFromHoldingData,
  getProductTitleFromHoldingData,
} from '../utils/helpers';
import { ProductQuestionnaireResponses, Warranty } from '@rabbit/data/types';
import placeholderImg from '../assets/images/placeholder-image.png';
import CustomerDetailsContentSection from '../components/molecules/CustomerDetailsContentSection/CustomerDetailsContentSection';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';

interface WarrantyShape {
  duration: {
    division: 'years' | 'months' | 'days';
    amount: number;
  };
  type: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerDetailViewProps {}

export function CustomerDetailView(props: CustomerDetailViewProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: consumerId } = useParams<'id'>();
  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<ProductQuestionnaireResponses | null>(null);
  const { allHoldings } = useGetConsumerData(null, consumerId) ?? {};

  const {
    //identityData,
    consumerPersonaData,
    error,
  } = useConsumerProfile(consumerId);

  useEffect(() => {
    // If there is a creation attempt error, the customer does not exist, so we redirect to the customers list
    if (error?.class === 'CREATION') navigate('/manage?tab=customers');
  }, [error]);

  const checkWarrantyStatus = (
    purchase_time: number,
    warranties: Warranty[]
  ) => {
    const warranty = BL_Warranty.getLatestWarranty(warranties);
    if (!purchase_time || !warranty) return '-';
    const expiry_date =
      warranty?.endDate ||
      getUnixTime(
        sub(
          add(fromUnixTime(purchase_time), {
            [warranty.duration.division]: warranty.duration.amount,
          }),
          {
            days: 1,
          }
        )
      );

    if (!expiry_date) return '-';
    if (expiry_date > Date.now()) return 'In warranty';
    if (expiry_date < Date.now()) return 'Warranty expired';
  };

  const consumerHoldingsData = !allHoldings
    ? []
    : allHoldings?.map((item) => {
        return {
          productImage: (
            <div
              className={classNames(
                'relative h-[120px] w-[120px] overflow-hidden bg-contain bg-center bg-no-repeat'
              )}
              style={{
                backgroundImage:
                  'url(' + (item?.img?.[0] ?? placeholderImg) + ')',
              }}
            ></div>
          ),
          productName: getProductTitleFromHoldingData(item),
          category: capitalizeFirstLetter(
            getProductCategoryFromHoldingData(item)
          ),
          warrantyStatus: checkWarrantyStatus(
            item.purchase_time,
            item.warranties
          ),
          purchaseDate: item?.purchase_time, //formatUnixTime(item?.register_time, 'dd/MM/yyyy'),
          registrationDate: item?.register_time ?? 0, //formatUnixTime(item?.register_time, 'dd/MM/yyyy'),
          ownershipEndDate: item?.purchase_time
            ? formatUnixTime(item?.purchase_time, 'dd/MM/yyyy')
            : '-',
          // TODO: manual will have a different shape soon
          userManual:
            item?.manual && item?.manual?.length > 0 ? (
              <div className="flex items-center">
                <p className="mr-3">Link</p>
                <a
                  href={String(item?.manual) ?? '#'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ArrowTopRightOnSquareIcon className="text-primary-900 h-4 w-4" />
                </a>
              </div>
            ) : (
              '-'
            ),
          survey: item?.questionnaire ? (
            <div
              className="flex cursor-pointer items-center"
              onClick={() => {
                setSelectedQuestionnaire(
                  item?.questionnaire as ProductQuestionnaireResponses
                );
              }}
            >
              <p className="mr-3">View details</p>
              <div>
                <ArrowTopRightOnSquareIcon className="text-primary-900 h-4 w-4" />
              </div>
            </div>
          ) : (
            '-'
          ),
        };
      });

  const addEditModalSettings = {
    title: t('general.editCustomer'),
    handleClose: () => setIsEditCustomerModalOpen(false),
  };

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this customer. You will lose all information. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const renderDetail = (label: string, value: any) => {
    return (
      <div className="flex flex-col" key={label}>
        <p className="font-nunito text-sm text-gray-500">{label}</p>
        <p className="font-nunito mt-2.5 overflow-hidden text-ellipsis text-base">
          {value?.length ? value : '-'}
        </p>
      </div>
    );
  };

  const [favouriteStore, setFavouriteStore] = useState<string>('');

  useEffect(() => {
    if (allHoldings)
      (async () => {
        const res = await getFavouriteStore(allHoldings);
        setFavouriteStore(res);
      })().catch((err) => console.log(err));
  }, [allHoldings]);

  useEffect(() => {
    // If there is a creation attempt error, the customer does not exist, so we redirect to the customers list
    if (error?.class === 'CREATION') navigate('/manage?tab=customers');
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <CardWrapperWithHeader
        title={t('general.customerInformation')}
        headerRight={
          <ButtonIcon
            iconLeft={true}
            label={t('general.editCustomer')}
            kind={'bgLightGreen'}
            onClick={() => setIsEditCustomerModalOpen(true)}
            Icon={ArrowTopRightOnSquareIcon}
          />
        }
      >
        {!consumerPersonaData || !allHoldings || !favouriteStore ? (
          <div>
            <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={4} />
              </div>
            </div>
            <div className="font-nunito grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={4} />
              </div>
            </div>
          </div>
        ) : (
          <CustomerDetailsContentSection
            consumer_private={consumerPersonaData}
            holdingsList={allHoldings}
            favouriteStore={favouriteStore}
          />
        )}
      </CardWrapperWithHeader>
      {t('tenantLink') === 'SHELTA' && (
        <CardWrapperWithHeader title={'Survey information'}>
          {!consumerPersonaData ? (
            <div className="grid grid-cols-4 gap-10">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
            </div>
          ) : (
            <div>
              {consumerPersonaData?.gender ? (
                <div className="grid grid-cols-4 gap-10">
                  {renderDetail('Gender', consumerPersonaData?.gender ?? '-')}
                  {renderDetail(
                    'Living situation',
                    consumerPersonaData?.living_situation ?? '-'
                  )}
                  {renderDetail(
                    'Type of dwelling',
                    consumerPersonaData?.type_of_dwelling ?? '-'
                  )}
                  {renderDetail(
                    'Work status',
                    consumerPersonaData?.work_status ?? '-'
                  )}
                </div>
              ) : (
                <Heading kind="p">
                  Survey questionnaire has not been filled.
                </Heading>
              )}
            </div>
          )}
        </CardWrapperWithHeader>
      )}
      <CardWrapperWithHeader
        noPadding={true}
        title={t('general.productsOwnedByTheCustomer')}
      >
        <Table
          columns={productsHeaders(t)}
          data={consumerHoldingsData}
          inner={true}
          initialState={{
            showGlobalFilter: true,
            sorting: [
              {
                id: 'registrationDate', //sort by age by default on page load
                desc: true,
              },
            ],
          }}
          muiSearchTextFieldProps={{
            placeholder: t('general.searchByProductNameCategory'),
          }}
          state={{
            isLoading: !allHoldings,
          }}
        />
      </CardWrapperWithHeader>
      {isEditCustomerModalOpen && (
        <div>
          <ModalNewEditCustomer
            handleClose={() => setIsEditCustomerModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            customerDetails={consumerPersonaData}
            modalSettings={addEditModalSettings}
          />
          {isConfirmationModalOpen && (
            <Modal
              className="h-fit w-[350px] rounded-md bg-white"
              kind={confirmationModalSettings.kind}
              settings={confirmationModalSettings.settings}
            />
          )}
        </div>
      )}
      {selectedQuestionnaire && (
        <Modal
          className="h-fit w-[400px] rounded-md bg-white"
          kind={'generic'}
          settings={{
            title: t('general.surveyDetails'),
            handleClose: () => setSelectedQuestionnaire(null),
          }}
          children={
            <div className="flex flex-col gap-5 px-5 py-2">
              {selectedQuestionnaire &&
              Object.entries(selectedQuestionnaire).length > 0 &&
              !Object.values(selectedQuestionnaire).every(
                (value) => value === '' || value === null || value === undefined
              )
                ? Object.entries(selectedQuestionnaire).map(([key, value]) => {
                    const formattedKey = key
                      .replace(/_/g, ' ')
                      .replace(/^\w/, (c) => c.toUpperCase());
                    if (value) return renderDetail(formattedKey, value);
                  })
                : t('general.customerDidNotSubmit')}
            </div>
          }
        />
      )}
    </div>
  );
}

export default CustomerDetailView;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

export const productsHeaders = (t: any) => [
  {
    accessorKey: 'productImage',
    header: t('general.productImage'),
  },
  {
    accessorKey: 'productName',
    header: t('general.productName'),
  },
  {
    header: t('general.category'),
    accessorKey: 'category',
  },
  {
    header: t('general.warrantyStatus'),
    accessorKey: 'warrantyStatus',
  },
  // {
  //   header: 'purchase date',
  //   accessorKey: 'purchaseDate',
  //   Cell: ({ cell }: any) => {
  //     return cell.getValue() ? formatUnixTime(cell.getValue(), 'dd/MM/yyyy') : '-';
  //   },
  // },
  {
    header: t('general.registrationDate'),
    accessorKey: 'registrationDate',
    Cell: ({ cell }: any) => {
      return formatUnixTime(cell.getValue(), 'dd/MM/yyyy');
    },
  },
  {
    header: t('general.userManual'),
    accessorKey: 'userManual',
  },
  {
    header: t('general.survey'),
    accessorKey: 'survey',
  },
];
