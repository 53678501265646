import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import { InputSettingsShape } from '@rabbit/elements/shared-types';

export interface InputWarrantyTermProps {
  name: string;
  settings: InputSettingsShape;
}

const dynamicStyles = {
  base: 'font-nunito focus:border-primary-600 w-full rounded-l-lg rounded-r-none border-[1px] border-gray-300 bg-gray-50 px-4 py-3 text-base text-gray-500 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 focus:ring-red-500 text-red-700',
};

function StyledWarrantyTermInput({
  field,
  placeholder,
  setFieldValue,
  label,
  form: { touched, errors },
  ...props
}: any) {
  let errorField = false;
  if (errors[field.name] || props.errors) errorField = true;

  const handleKeyPress = (event: any) => {
    if (event.shiftKey == true) {
      event.preventDefault();
    }
    if (
      !(
        event.keyCode == 190 ||
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode == 8 ||
        event.keyCode == 9 ||
        event.keyCode == 37 ||
        event.keyCode == 39 ||
        event.keyCode == 46
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className="flex">
      <div className="relative w-full">
        <input
          type="text"
          id={props.id}
          placeholder={props.placeholder}
          className={classNames(`${dynamicStyles.base}`, {
            [`${dynamicStyles.error}`]: errorField,
          })}
          onKeyDown={handleKeyPress}
          {...props}
          {...field}
          maxLength={2}
        />
      </div>
      <div className="font-nunito mx-auto flex w-40 flex-shrink-0 cursor-default items-center justify-center rounded-l-none rounded-r-lg border border-gray-300 bg-gray-100 py-2.5 px-4 text-center text-sm font-medium text-gray-900">
        months
      </div>
    </div>
  );
}

export function InputWarrantyTerm({ name, settings }: InputWarrantyTermProps) {
  const { setFieldValue } = useFormikContext();
  const { id, placeholder, disabled, errors, ...rest } = settings;

  return (
    <div
      className={classNames(`"relative w-full"`, {
        ['opacity-80']: disabled,
      })}
      >
      <Field  
        name={name}
        id={id}
        // note: something made the component lose formatting and re-adding the classes here is a dirty hack but a necessary one given time constraints
        // todo: fix this on a later date. If we wrap the input above inside Field (check out Input Type Password) it works, so check that one out for a possible fix - dc

        className={classNames(`${dynamicStyles.base}`, {
          [`${dynamicStyles.error}`]: errors,
          ['opacity-60']: disabled,
        })}
        component={StyledWarrantyTermInput}
        placeholder={placeholder}
        setFieldValue={setFieldValue}
        type={'warranty_term'}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
}

export default InputWarrantyTerm;
