import { TermsAndConditionsContentView } from '@rabbit/elements/shared-components';

function TermsAndConditionsView() {
  return (
    <div className="flex flex-grow flex-col justify-start">
      <div className="font-caudex text-primary-900 mb-4 text-3xl">
        Terms and Conditions
      </div>
      <TermsAndConditionsContentView />
    </div>
  );
}

export default TermsAndConditionsView;
