import {
  DTPersona,
  DTPersonaPublic,
  FBD_Consumer_Private,
  FBD_Consumer_Public,
  FBD_Manufacturer_Private,
  FBD_Manufacturer_Public,
  FBD_Repairer_Private,
  FBD_Repairer_Public,
  FBD_Retailer_Private,
  FBD_Retailer_Public,
  FBD_Warrantor_Private,
  FBD_Warrantor_Public,
  FBD_WarrantyDealer_Private,
  FBD_WarrantyDealer_Public,
  PersonaLink,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { useEffect, useState } from 'react';

export function useGetPersonaData(
  docid: PersonaLink,
  scope: ('public' | 'private')[]
) {
  const [personaPrivate, setPersonaPrivate] = useState<DTPersona | null>(null);
  const [personaPublic, setPersonaPublic] = useState<DTPersonaPublic | null>(
    null
  );

  useEffect(() => {
    const personaType = docid[0];

    if (
      !personaType ||
      !(Object.values(PersonaTypeSingleLetter) as string[]).includes(
        personaType
      )
    ) {
      return;
    }

    async function getData() {
      let privData: DTPersona | null = null;
      let pubData: DTPersonaPublic | null = null;

      switch (personaType) {
        case PersonaTypeSingleLetter.Consumer:
          if (scope.includes('private'))
            privData = await FBD_Consumer_Private.get(docid);
          if (scope.includes('public'))
            pubData = await FBD_Consumer_Public.get(docid);
          break;
        case PersonaTypeSingleLetter.Manufacturer:
          if (scope.includes('private'))
            privData = await FBD_Manufacturer_Private.get(docid);
          if (scope.includes('public'))
            pubData = await FBD_Manufacturer_Public.get(docid);
          break;
        case PersonaTypeSingleLetter.Repairer:
          if (scope.includes('private'))
            privData = await FBD_Repairer_Private.get(docid);
          if (scope.includes('public'))
            pubData = await FBD_Repairer_Public.get(docid);
          break;
        case PersonaTypeSingleLetter.Retailer:
          if (scope.includes('private'))
            privData = await FBD_Retailer_Private.get(docid);
          if (scope.includes('public'))
            pubData = await FBD_Retailer_Public.get(docid);
          break;
        case PersonaTypeSingleLetter.WarrantyDealer:
          if (scope.includes('private'))
            privData = await FBD_WarrantyDealer_Private.get(docid);
          if (scope.includes('public'))
            pubData = await FBD_WarrantyDealer_Public.get(docid);
          break;
        case PersonaTypeSingleLetter.Warrantor:
          if (scope.includes('private'))
            privData = await FBD_Warrantor_Private.get(docid);
          if (scope.includes('public'))
            pubData = await FBD_Warrantor_Public.get(docid);
          break;
        default:
          console.log('Invalid persona type');
      }

      return { privData, pubData };
    }

    (async () => {
      const { privData, pubData } = await getData();
      setPersonaPrivate(privData);
      setPersonaPublic(pubData);
    })();
  }, []);

  return { personaPrivate, personaPublic };
}
