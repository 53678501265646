import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import {
  LIST_COUNTRIES,
  useSageAPI,
  FileStorageContext,
  getCompletedUploadsOfCategory,
} from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { NewClaimModalSteps } from '../../ModalNewClaim/ModalNewClaim';
import { OurDateTime } from '@rabbit/utils/ts';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { ConfigContext } from '@rabbit/config/context';
import { SageFileUploader } from '../../upload-wrapper/SageFileUploader';
import {
  UploadedFileCategories,
  DocTypeShapeTypes,
  CompletedUploadShape,
} from '@rabbit/elements/shared-types';
import {
  UserUploadedDocument,
  FBD_Holding_Private,
  FBD_Consumer_Private,
} from '@rabbit/data/types';

export interface NewCreateHoldingFormProps {
  onHoldingCreated: (result: any) => void;
  handleClose: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onChange: (step: NewClaimModalSteps, data?: any) => void;
  data: any;
}

interface HoldingDataCase {
  holding_vendable_id: string;
  purchase_time: Date | null;
  purchase_price: {
    amount: number;
    currency: string;
  };
  purchase_location: {
    docid: string;
    country: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  purchase_country: string;
  serial_number: string;
  serial_number_proof: UserUploadedDocument[];
}

export function NewCreateHoldingForm(props: NewCreateHoldingFormProps) {
  const { config } = useContext(ConfigContext) || {};
  const { registerConsumerHoldingSage } = useSageAPI();
  const { handleClose, onChange, data, onHoldingCreated, setLoading } = props;

  const formikRef = useRef(null) as any;
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDatePurchaseDisabled, setIsDatePurchaseDisabled] = useState(false);

  const initialValues: HoldingDataCase = {
    holding_vendable_id: '',
    purchase_time: null,
    purchase_price: {
      amount: 0,
      currency: appInfo.currency,
    },
    purchase_location: null,
    store_not_listed: false,
    custom_store_name: '',
    purchase_country: appInfo.country,
    serial_number: '',
    serial_number_proof: [],
  };

  const validationSchema = Yup.object().shape({
    holding_vendable_id: Yup.string()
      .trim()
      .required(t('general.productNameCantBeEmpty')),
    purchase_location: Yup.mixed(),
    store_not_listed: Yup.boolean(),
    purchase_price: Yup.object({
      amount: Yup.number()
        .min(1, t('message.pleaseEnterValidAmount'))
        .required(t('message.pleaseEnterValidAmount')),
      currency: Yup.string().trim().required(t('message.currencyIsRequired')),
    })
      .required(t('message.pleaseEnterPurchasePrice'))
      .typeError(t('message.pleaseEnterPurchasePrice')),
    purchase_time: Yup.string().nullable().trim(),
    ...(config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER && {
      serial_number: Yup.string().trim(),
    }),
    ...(config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF && {
      serial_number_proof: Yup.array(),
    }),
    // purchase_time: Yup.date()
    //   .max(new Date(), 'Please enter a valid date')
    //   .required('Please select a purchase time')
    //   .typeError('Please enter a valid date'),
  });

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    isUpdating,
    updateHoldingWithFiles,
  } = useContext(FileStorageContext) || {};
  const { completed } = uploadQueueState ?? {};
  const serialNumberProofUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.SerialNumberProof
    );

  // generate holdingLink using Keygen here so the uploader of serial number proof can work
  const holding_private = FBD_Holding_Private.empty();
  const holding_docid = config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF
    ? FBD_Holding_Private.keygen(holding_private, FBD_Holding_Private)
    : '';

  const consumer_private = FBD_Consumer_Private.empty();
  const consumer_docid = config?.CLAIMS?.CLAIMS_FLOW?.SERIAL_NUMBER_PROOF
    ? FBD_Consumer_Private.keygen(consumer_private, FBD_Consumer_Private)
    : '';

  const newConsumerLink = data?.consumer?.id ?? consumer_docid;

  // TODO: check this on submit func to save the case
  const onSubmit = async (values: HoldingDataCase) => {
    setIsSubmitting(true);
    setLoading(true);

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.purchase_location?.docid ?? '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? ''
      : null;
    const newPurchaseCountry =
      values.custom_store_name && values.store_not_listed
        ? values.purchase_country
        : values.purchase_location?.country ?? '';

    const serialNumberProofFiles = serialNumberProofUploads?.flatMap((file) =>
      file.category === UploadedFileCategories.SerialNumberProof &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    // todo: make/update type from this
    const finalData = data.holding
      ? {
          consumer_first_name: data.consumer.splitname?.first,
          consumer_last_name: data.consumer.splitname?.last,
          consumer_address: data.consumer.address,
          consumer_email: data.consumer.email,
          consumer_telephone: data.consumer.phone,
          consumer_preferred_contact: data.consumer.preferred_contact,
          holding_vendable_id: data.holding.vendable,
          purchase_location: newPurchaseLocation,
          purchase_location_other: newPurchaseLocationOther,
          purchase_price: {
            amount: Number(values.purchase_price.amount),
            currency: values.purchase_price.currency,
          },
          purchase_time: values.purchase_time
            ? OurDateTime.dateToTimestamp(values.purchase_time)
            : 0,
          purchase_country: values.purchase_location
            ? values.purchase_location.country
            : values.purchase_country,
          consumer_holding_purchase_location: newPurchaseLocation,
          consumer_holding_purchase_country: newPurchaseCountry,
          consumer_holding_purchase_location_other: newPurchaseLocationOther,
          purchase_date: data.holding.purchase_time,
          consumer_is_commercial: data.consumer_is_commercial,
          serial: data.serial ?? '',
          serial_number_proof: serialNumberProofFiles ?? [],
        }
      : ({
          ...data,
          ...values,
          purchase_date: values.purchase_time
            ? OurDateTime.dateToTimestamp(values.purchase_time)
            : null,
          consumer_holding_purchase_location: newPurchaseLocation,
          consumer_holding_purchase_country: newPurchaseCountry,
          consumer_holding_purchase_location_other: newPurchaseLocationOther,
          serial: values.serial_number ?? '',
          serial_number_proof: serialNumberProofFiles ?? [],
        } as any);

    try {
      const response = await registerConsumerHoldingSage({
        formData: finalData,
        tenantLink: t('tenantLink'),
        newHoldingLink: holding_docid,
        newConsumerLink: newConsumerLink,
      });

      if (response) {
        if (config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF) {
          if (
            moveCompletedUploadsToAttached &&
            updateHoldingWithFiles &&
            serialNumberProofFiles &&
            serialNumberProofFiles.length > 0
          ) {
            await updateHoldingWithFiles(
              holding_docid,
              serialNumberProofFiles as UserUploadedDocument[],
              UploadedFileCategories.SerialNumberProof
            );
            moveCompletedUploadsToAttached(serialNumberProofUploads);
          }
        }

        onHoldingCreated({
          consumerLink: response.consumerLink,
          holdingLink: response.holdingLink,
          warranty: response.warranty,
        });

        handleClose();
      } else {
        throw new Error('general.failedToRegisterHolding');
      }
    } catch (err: any) {
      console.log(err);
      toast.error(
        err?.message ?? t('general.somethingWentWrongPleaseTryAgain')
      );
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formikRef && formikRef.current && isDatePurchaseDisabled) {
      formikRef.current.setFieldValue('purchase_time', null);
    }
  }, [isDatePurchaseDisabled]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {({ errors, values, setFieldValue }) => (
        <Form className="flex-col gap-3 px-5">
          <div className="mt-4 grid grid-cols-2 gap-4">
            {!data.holding && (
              <>
                <Input
                  type="autocomplete-vendable"
                  name="holding_vendable_id"
                  label={`${t('general.product')}*`}
                  settings={{
                    isMulti: false,
                    id: 'holding_vendable_id',
                    placeholder: t('general.productName'),
                    options: [],
                    errors: errors,
                    hint: t('*required'),
                    tenantLink: t('tenantLink'),
                  }}
                />
                <div className="w-full flex-col">
                  <Input
                    type="datepicker"
                    name="purchase_time"
                    label={t('general.dateOfPurchase')}
                    settings={{
                      id: 'purchase_time',
                      placeholder: t('general.dateOfPurchase'),
                      maxDate: new Date(),
                      disabled: isDatePurchaseDisabled,
                    }}
                  />
                  <div className="flex items-center pt-2">
                    <Input
                      type="checkbox"
                      name="noDateOfPurchase"
                      settings={{
                        checkboxLabel: t('general.dateNotKnown'),
                        checkboxLabelStyles: 'text-base text-gray-500',
                        onChange: () =>
                          setIsDatePurchaseDisabled(!isDatePurchaseDisabled),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={`mt-4 grid gap-4 ${
              data.holding ? 'grid-cols-1' : 'grid-cols-2'
            } `}
          >
            <Input
              type="currency"
              name="purchase_price"
              label={`${t('general.purchasePrice')}*`}
              settings={{
                id: 'purchase_price',
                placeholder: t('general.purchaseAmount'),
                currency: appInfo.currency,
                hint: '*required',
              }}
            />
            {!data.holding && (
              <div className="flex grow flex-col gap-2">
                <Input
                  type="autocomplete-location-retailer"
                  name="purchase_location"
                  label={t('general.storeOfPurchase')}
                  settings={{
                    isMulti: false,
                    placeholder: t('message.pleaseSelectAnOption'),
                    options: [],
                    errors: errors,
                    disabled: values.store_not_listed,
                  }}
                />
                <Input
                  type="checkbox"
                  name="store_not_listed"
                  settings={{
                    checkboxLabel: t('general.storeIsNotListed'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
                {values.store_not_listed && (
                  <div className="mt-2 flex flex-col gap-2">
                    <Input
                      type="text"
                      name="custom_store_name"
                      settings={{
                        id: 'custom_store_name',
                        placeholder: t('general.enterStoreName'),
                      }}
                    />
                    <Input
                      type="select"
                      label=""
                      name="purchase_country"
                      settings={{
                        options: LIST_COUNTRIES,
                        id: 'purchase_country',
                        placeholder: t(
                          'general.countryWhereProductWasPurchased'
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER && (
            <div className="my-4 flex flex-col gap-2">
              <Input
                type="text"
                label={t('general.serialNumber')}
                name="serial_number"
                settings={{
                  placeholder: t('general.serialNumber'),
                }}
              />
            </div>
          )}
          {config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF && (
            <div className="my-4 flex flex-col gap-2">
              <SageFileUploader
                label={t('general.photoOfSerialNumber')}
                identifiers={{
                  category: UploadedFileCategories.SerialNumberProof,
                  docType: {
                    docid: holding_docid,
                    type: DocTypeShapeTypes.Holding,
                  },
                  personaId: newConsumerLink,
                }}
                maxFiles={1}
                onUploadCompleted={(files) =>
                  setFieldValue('serial_number_proof', files)
                }
                onDeleteFile={() => setFieldValue('serial_number_proof', [])}
                currentFiles={values.serial_number_proof ?? []}
                shouldAutoUpdateDocs={false}
                accepts={['image/*', '.pdf']}
              />
            </div>
          )}
          <div className="mt-5 flex w-full gap-4">
            <Button
              kind="primary"
              type="submit"
              loading={isSubmitting}
              disabled={
                Object.keys(errors).length > 1 || !formikRef.current?.isValid
              }
            >
              {t('general.submit')}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              {t('general.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NewCreateHoldingForm;
