import { CFARequireFacts } from '@rabbit/data/types';
import { ActionStepImplementation, ActionStepRegistry } from '../registry';

export const ASI_RequireFacts: ActionStepImplementation<CFARequireFacts> = {
  validate: (location, validator, step) => {
    step.facts.forEach((fact) => {
      validator.ReferenceAFact(location, fact);
    });
  },
  interior: (cfc, step) => {
    // TODO: Code
  },
};

ActionStepRegistry.Register(ASI_RequireFacts, 'req_facts');
