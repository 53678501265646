import { Field, useField } from 'formik';
import { InputSettingsShape } from '@rabbit/elements/shared-types';
import classNames from 'classnames';
/* eslint-disable-next-line */
export interface TextAreaProps {
  name: string;
  settings: InputSettingsShape;
}

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 resize-none rounded-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 text-red-700',
  disabled: 'border-gray-300 text-gray-400 opacity-60',
};

function StyledTextField({ field, form: { touched, errors }, ...props }: any) {
  let errorField = false;
  if (errors[field.name] || props.errors) errorField = true;

  return (
    <textarea
      {...props}
      {...field}
      id={props.id}
      className={classNames(dynamicStyles.base, {
        [`${dynamicStyles.error}`]: errorField,
        [`${dynamicStyles.disabled}`]: props.disabled,
      })}
      placeholder={props.placeholder}
    ></textarea>
  );
}

export function InputTypeTextArea({ name, settings }: TextAreaProps) {
  const { placeholder, disabled, id, ...rest } = settings;

  return (
    <Field
      name={name}
      placeholder={placeholder}
      component={StyledTextField}
      id={id}
      disabled={disabled}
      {...rest}
    />
  );
}

export default InputTypeTextArea;
