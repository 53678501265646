import { Formik, Form } from 'formik';
// import * as Yup from 'yup';
import DragFileUpload from '../DragFileUpload/DragFileUpload';
import { Input } from '../../atoms/inputs/Input/Input';
import LoadingSpinner from '../../atoms/loaders/LoadingSpinner/LoadingSpinner';
import UploadedFileCard from '../../molecules/UploadedFileCard/UploadedFileCard';
import Modal from '../../molecules/Modals/Modal/Modal';
import {
  DocTypeShapeTypes,
  DraggedFileShape,
  FileUploadHandlersShape,
} from '@rabbit/elements/shared-types';
import { useEffect, useRef, useState } from 'react';
// todo: investigate this
import { toast } from 'react-toastify';
import { UserUploadedDocument } from '@rabbit/data/types';
import { UploadedFileCategoryShape } from '@rabbit/elements/shared-types';
export interface UploadFilesFormProps {
  label?: string;
  type?: 'holding' | 'vendable' | 'case';
  accepts?: string[];
  docId: string;
  personaId: string;
  currentFiles?: any[];
  onFilesUploaded: (files: UserUploadedDocument[], added?: boolean) => void;
  uploadHandlers?: FileUploadHandlersShape;
}

interface FormValuesShape {
  input_files: UserUploadedDocument[];
}

// const schema = Yup.object({
//   input_files: Yup.array().min(1), //.required('Required'),
// });

// NOTE: Might be worth splitting this form in two according to the type of uploaded file (e.g. "regular" or Temp)
export function UploadFilesForm(props: UploadFilesFormProps) {
  const {
    label = 'Proof of purchase',
    type = 'holding',
    accepts = ['image/*', '.pdf'],
    docId,
    personaId,
    currentFiles = [],
    onFilesUploaded,
    uploadHandlers,
  } = props;
  const formikRef = useRef(null) as any;

  const {
    category,
    uploadFiles,
    isUpdating,
    uploadProgress,
    deleteFile,
    uploadedFiles,
    updateHoldingWithFiles,
    uploadedTempFiles,
    clearFileFromHolding,
    deleteUnusedTempFiles,
    uploadTaskCat,
  } = uploadHandlers || {};

  const [initialValues] = useState<FormValuesShape>({
    input_files: currentFiles || [],
  });
  const [isUploading, setUploading] = useState(false);
  const [showDragDropUploadError, setShowDragDropUploadError] = useState(true);

  const handleUploadFiles = async (files: File[]) => {
    if (!uploadFiles || !category) return;
    try {
      await uploadFiles(files, personaId, category, {
        type: type as DocTypeShapeTypes,
        docid: docId,
      });
      toast.success(`File uploaded successfully.`);
      setUploading(true);
      setShowDragDropUploadError(false);
    } catch (e) {
      setUploading(false);
      setShowDragDropUploadError(true);
    }
  };

  const handleFileEvent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDragDropUploadError(false);
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    await handleUploadFiles(chosenFiles);
  };

  const handleDragFileEvent = async (filesArr: DraggedFileShape[]) => {
    setShowDragDropUploadError(false);
    const chosenFiles = Array.prototype.slice.call(filesArr);
    await handleUploadFiles(chosenFiles);
  };

  const onSubmit = async (values: FormValuesShape) => {
    void 0;
  };

  const [deleteModal, setDeleteModal] = useState<{
    open: boolean;
    path: string;
    name?: string;
  }>({ open: false, path: '', name: undefined });

  const handleDeleteFile = async (fullPath: string, name: string) => {
    setDeleteModal({ open: true, path: fullPath, name });
    setShowDragDropUploadError(false);
  };

  const modalSettings = {
    text: `Are you sure you want to delete ${deleteModal.name}?`,
    primaryButtonText: 'Yes',
    outlineButtonText: 'No, cancel',
    loading: isUpdating,
    handlePrimaryClick: async () => {
      if (!deleteFile) return;
      try {
        await deleteFile(deleteModal.path);
        if (category === 'consumer_proof_purchase' && clearFileFromHolding)
          await clearFileFromHolding(docId, deleteModal.path); //TODO remove also from caseFacts

        if (category === 'consumer_claim_evidence') {
          const updatedFileArr = currentFiles.filter(
            (item) => item.metadata.fullPath !== deleteModal.path
          );
          if (formikRef)
            formikRef.current.setFieldValue('input_files', updatedFileArr);
          onFilesUploaded(updatedFileArr);
        }
        if (
          category === 'postage_receipts' ||
          category === 'receipts' ||
          category === 'internal_case_files'
        ) {
          const updatedFileArr = currentFiles.filter(
            (item) => item.metadata.fullPath !== deleteModal.path
          );
          if (formikRef)
            formikRef.current.setFieldValue('input_files', updatedFileArr);
          onFilesUploaded(updatedFileArr, false);
        }
        toast.success(label + ' deleted successfully.');
      } catch (err) {
        toast.error("Couldn't delete file, please try again later.");
        console.log(err);
      } finally {
        setDeleteModal({ open: false, path: '' });
      }
    },
    handleOutlineClick: () => setDeleteModal({ open: false, path: '' }),
  };

  useEffect(() => {
    if (category === 'consumer_proof_purchase') {
      if (currentFiles.length) onFilesUploaded(currentFiles);
      if (formikRef)
        formikRef.current.setFieldValue('input_files', currentFiles);
    }
    if (
      category === 'postage_receipts' ||
      category === 'internal_case_files' ||
      category === 'receipts'
    ) {
      if (formikRef)
        formikRef.current.setFieldValue('input_files', currentFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFiles.length]);

  useEffect(() => {
    const updateWithFiles = async (category: UploadedFileCategoryShape) => {
      if (!category) return;
      if (uploadedFiles && uploadedFiles.filesArr.length > 0) {
        if (updateHoldingWithFiles && category === 'consumer_proof_purchase') {
          await updateHoldingWithFiles(docId, uploadedFiles.filesArr);
        }
      }
    };

    (async () => {
      if (category) await updateWithFiles(category);
    })().catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles?.filesArr?.length, category]);

  function concatenateAndRemoveDuplicates(array1: any[], array2: any[]) {
    const mergedArray = array1.concat(array2);
    const uniqueArray = mergedArray.reduce((result: any, obj: any) => {
      if (
        !result.some(
          (item: any) => item.metadata.fullPath === obj.metadata.fullPath
        )
      ) {
        result.push(obj);
      }
      return result;
    }, []);
    return uniqueArray;
  }

  useEffect(() => {
    if (
      uploadedTempFiles &&
      uploadedTempFiles.filesArr.length > 0 &&
      (category === 'consumer_claim_evidence' ||
        category === 'postage_receipts' ||
        category === 'receipts' ||
        category === 'internal_case_files')
    ) {
      const filterUnique = uploadedTempFiles.filesArr.filter((obj, index) => {
        return (
          uploadedTempFiles.filesArr.findIndex(
            (item) =>
              JSON.stringify(item.metadata.fullPath) ===
              JSON.stringify(obj.metadata.fullPath)
          ) === index
        );
      });
      const concatFiles = concatenateAndRemoveDuplicates(
        currentFiles,
        filterUnique
      );
      const newBodyFiles = Array.from(new Set(concatFiles));
      if (formikRef)
        formikRef.current.setFieldValue('input_files', newBodyFiles);

      onFilesUploaded(newBodyFiles as UserUploadedDocument[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedTempFiles?.filesArr.length]);

  useEffect(() => {
    if (currentFiles || uploadedFiles) setUploading(false);
  }, [currentFiles, uploadedFiles]);

  useEffect(() => {
    if (
      deleteUnusedTempFiles &&
      (category === 'consumer_claim_evidence' ||
        category === 'postage_receipts' ||
        category === 'receipts' ||
        category === 'internal_case_files')
    )
      return () => {
        (async () => await deleteUnusedTempFiles())().catch((err) =>
          console.log(err)
        );
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnMount={false}
        //validationSchema={category === 'consumer_proof_purchase' ? schema : null}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ values, errors }) => {
          return (
            <Form className="font-nunito mt-4 flex flex-col gap-5">
              <div>
                <label className="text-base font-medium text-gray-900">
                  {label}
                </label>
                {/* TODO: should be changed for claim data when BE ready */}
                {currentFiles &&
                  Array.isArray(currentFiles) &&
                  currentFiles?.map((item, index) => {
                    return (
                      <div className="mb-5 mt-3" key={index}>
                        <UploadedFileCard
                          data={item}
                          status="completed"
                          progress={100}
                          onDeleteFile={handleDeleteFile}
                        />
                      </div>
                    );
                  })}
                {uploadTaskCat === category &&
                  !!uploadProgress !== false &&
                  uploadProgress !== 100 && (
                    <div className="mb-5">
                      <UploadedFileCard
                        status="ongoing"
                        progress={uploadProgress}
                      />
                    </div>
                  )}
                <div className="mt-4">
                  <div className="md:hidden">
                    <Input
                      label=""
                      type="file"
                      name="input_files"
                      settings={{ accepts }}
                      onChange={handleFileEvent}
                    />
                  </div>
                  <div className="hidden md:block">
                    <DragFileUpload
                      accepts={accepts}
                      label={'Upload ' + label.toLowerCase()}
                      showError={showDragDropUploadError}
                      loading={
                        uploadTaskCat === category &&
                        (isUploading || isUpdating) &&
                        !deleteModal.open
                      }
                      onChange={handleDragFileEvent}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {deleteModal.open && <Modal kind={'pop-up'} settings={modalSettings} />}
    </div>
  );
}

export default UploadFilesForm;
