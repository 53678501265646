import {
  DTHoldingProxy,
  TableCover,
  Warranty,
  WarrantyStatus,
} from '@rabbit/data/types';
import {
  getProductTitleFromHoldingData,
  getStoneImages,
  renderWarrantyRemaining,
} from '../../../../utils/helpers';
import placeholderImg from '../../../../assets/images/placeholder-image.png';
import carPlaceholder from '../../../../assets/images/car-placeholder.jpg';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { TenantType } from '@rabbit/sage/views/RegistrationsView';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { ShieldCheckIcon, UserIcon } from '@heroicons/react/24/solid';
import { getWarrantyTerm } from '@rabbit/elements/shared-components';

interface RegistrationsHoldingSummarySectionProps {
  allHoldingsList?: DTHoldingProxy[];
  holding_proxy?: DTHoldingProxy;
  holdingId: string;
  storeAddress: string;
}

export default function RegistrationsHoldingSummarySection({
  allHoldingsList,
  holding_proxy,
  holdingId,
  storeAddress,
}: RegistrationsHoldingSummarySectionProps) {
  const { t } = useTranslation();
  let tenantType: TenantType = 'defaultTenants';
  if (t('tenantLink') === 'NUCOVER') tenantType = 'tableTenants';

  const renderHoldingSummary = () => {
    if (holding_proxy && allHoldingsList) {
      switch (t('tenantLink')) {
        case 'NUCOVER':
          return renderNuCoverHoldingSummary(
            allHoldingsList,
            holding_proxy,
            holdingId
          );
        default:
          const warranty = BL_Warranty.getLatestWarranty(
            holding_proxy.warranties
          );
          return renderDefaultHoldingSummary(
            storeAddress,
            t,
            tenantType,
            allHoldingsList,
            holding_proxy,
            holdingId,
            warranty
          );
      }
    }
  };

  return <div className="font-nunito">{renderHoldingSummary()}</div>;
}

const renderNuCoverHoldingSummary = (
  allHoldingsList?: DTHoldingProxy[],
  holding_proxy?: DTHoldingProxy,
  holdingId?: string
) => {
  const latestWarranty = BL_Warranty.getLatestWarranty(
    holding_proxy?.warranties
  );
  return (
    <div className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-gray-100 p-4">
      {!allHoldingsList ||
      !holdingId ||
      !holding_proxy ||
      holding_proxy.purchase_time === undefined ||
      !holding_proxy.self_registration?.srvInfo ? (
        <>
          <div className="flex w-full grow">
            <div>
              <div className="h-[138px] w-[188px]">
                {' '}
                <Skeleton
                  count={1}
                  baseColor="#fff"
                  highlightColor="#f1f1f1"
                  className="h-[138px] w-[188px]"
                />
              </div>
            </div>
            <div className="ml-5 flex w-full flex-col items-center justify-center">
              <div className="w-full">
                <Skeleton count={3} baseColor="#fff" highlightColor="#f1f1f1" />
              </div>
            </div>
          </div>

          <div className="flex w-full grow items-center gap-[75px] px-4">
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-full items-start justify-between gap-4">
          <div className="flex w-1/3 grow items-start gap-3">
            <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
              <UserIcon className="h-8 w-8 text-gray-500" />
            </div>
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl">
                {holding_proxy.consumer_name}
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-base opacity-50">
                {holding_proxy.consumer_email}
              </p>
            </div>
          </div>
          <div className="flex w-1/3 grow items-start gap-3">
            <img
              src={
                getStoneImages(
                  (
                    holding_proxy?.self_registration?.srvInfo
                      ?.productInfo as TableCover
                  ).typeofStone
                ) || placeholderImg
              }
              alt={'product '}
              className="h-[84px] w-[84px] shrink-0 rounded-md border border-gray-300 object-cover"
            />
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl font-normal">
                {
                  (
                    holding_proxy.self_registration?.srvInfo
                      ?.productInfo as TableCover
                  ).typeofStone
                }
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-lg text-gray-600">
                Area:{' '}
                {(
                  holding_proxy.self_registration?.srvInfo
                    ?.productInfo as TableCover
                ).surfaceAreaValue + 'm²'}
              </p>
            </div>
          </div>
          <div className="flex w-1/3 grow items-start gap-3">
            <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
              <ShieldCheckIcon className="h-8 w-8 text-gray-500" />
            </div>
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl">
                {latestWarranty?.status === WarrantyStatus.VOIDED
                  ? 'Voided'
                  : renderWarrantyRemaining(holding_proxy)}
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-base opacity-50">
                Service plan term: {getWarrantyTerm(holding_proxy)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const renderDefaultHoldingSummary = (
  storeAddress: string,
  t: any,
  tenantType: TenantType,
  allHoldingsList?: DTHoldingProxy[],
  holding_proxy?: DTHoldingProxy,
  holdingId?: string,
  warranty?: Warranty
) => {
  if (!allHoldingsList || !holdingId || !holding_proxy) {
    return (
      <div className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-gray-100 p-4">
        <div className="flex w-full grow">
          <div>
            <div className="h-[138px] w-[188px]">
              {' '}
              <Skeleton
                count={1}
                baseColor="#fff"
                highlightColor="#f1f1f1"
                className="h-[138px] w-[188px]"
              />
            </div>
          </div>
          <div className="ml-5 flex w-full flex-col items-center justify-center">
            <div className="w-full">
              <Skeleton count={3} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
          </div>
        </div>

        <div className="flex w-full grow items-center gap-[75px] px-4">
          <div className="w-full">
            {' '}
            <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
          </div>
          <div className="w-full">
            {' '}
            <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
          </div>
          <div className="w-full">
            {' '}
            <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-gray-100 p-4">
      <div className="grid w-full grid-cols-3 gap-4">
        <div className="flex items-start gap-3">
          {tenantType === 'carTenants' ? (
            <img
              src={carPlaceholder}
              alt={'product '}
              className="h-[84px] w-[84px]"
            />
          ) : (
            <img
              src={
                holding_proxy?.img?.[0] ??
                holding_proxy?.self_registration?.img?.[0] ??
                placeholderImg
              }
              alt={'product '}
              className="h-[84px] w-[84px] shrink-0 rounded-md border border-gray-300 object-cover"
            />
          )}
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl font-normal">
              {getProductTitleFromHoldingData(holding_proxy)}
            </p>
            <p className="w-full overflow-hidden text-ellipsis text-lg text-gray-600">
              {holding_proxy.brand}
            </p>
          </div>
        </div>
        <div className="flex grow items-start gap-3">
          <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
            <UserIcon className="h-8 w-8 text-gray-500" />
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl">
              {holding_proxy.consumer_name}
            </p>
            <p className="w-full overflow-hidden text-ellipsis text-base opacity-50">
              {holding_proxy.consumer_email}
            </p>
          </div>
        </div>

        <div className="flex grow items-start gap-3">
          <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
            <ShieldCheckIcon className="h-8 w-8 text-gray-500" />
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl">
              {warranty?.status === WarrantyStatus.VOIDED
                ? 'Voided'
                : renderWarrantyRemaining(holding_proxy)}
            </p>
            <p className="w-full overflow-hidden text-ellipsis text-base opacity-50">
              {t('general.warrantyTerm')}: {getWarrantyTerm(holding_proxy)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
