import { Heading } from '@rabbit/elements/shared-components';
import { ArrowUpIcon } from '@heroicons/react/24/outline';

export interface DashboardStatsProps {
  title: string;
  items: Array<StatsInterface>;
}

export interface StatsInterface {
  score: number;
  rate: number;
  store: string;
}

export function DashboardStats({ title, items }: DashboardStatsProps) {
  return (
    <div className="flex flex-col bg-gray-50 p-4">
      <Heading kind={'h3'}>{title}</Heading>
      <div className="mt-5">
        {items.map((item, index) => (
          <div className="mb-4 flex items-center justify-between" key={index}>
            <div className="flex-col">
              <Heading kind={'h2'}>+{item.score}</Heading>
              <p className="font-nunito text-base text-gray-500">
                {item.store}
              </p>
            </div>
            <div className="flex gap-1">
              <span className="text-primary-500 font-bold">{item.rate}%</span>
              <ArrowUpIcon className="text-primary-500 h-6 w-4 font-bold" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardStats;
