import { format } from 'date-fns';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import ButtonIcon from '../../atoms/buttons/ButtonIcon/ButtonIcon';
import {
  ArrowTopRightOnSquareIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { UserUploadedDocument } from '@rabbit/data/types';

export interface UploadedFileCardProps {
  data?: UserUploadedDocument;
  progress?: number | null;
  status?: 'ongoing' | 'completed' | 'preview' | 'deleted';
  percentage?: number;
  onDeleteFile?: (fullPath: string, name: string) => void;
}

export function UploadedFileCard({
  data,
  progress,
  status,
  percentage,
  onDeleteFile,
}: UploadedFileCardProps) {
  let obj: any;
  if (typeof data === 'object' && data !== null) {
    obj = data;
  } else {
    const dataStr = String(data);
    obj = {
      url: dataStr,
      type: dataStr,
      filename: dataStr.includes('%2F')
        ? dataStr.substring(dataStr.lastIndexOf('%2F') + 1).split('?')[0]
        : dataStr.substring(dataStr.lastIndexOf('/') + 1).split('?')[0],
      metadata: {
        fullPath: dataStr,
      },
    };
  }

  return (
    <div className="font-nunito flex w-full flex-col rounded-md border border-gray-300 bg-gray-50 p-4">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          {status !== 'deleted' && String(obj?.metadata?.contentType).includes('image') ||
          String(obj?.type).includes('jpg') ||
          String(obj?.type).includes('png') ? (
            <img
              src={obj.url}
              alt={'preview'}
              className="h-11 w-11 rounded-lg"
            />
          ) : (
            status == 'deleted' ? <ExclamationTriangleIcon className="h-10 w-10 text-red-600" /> : <DocumentTextIcon className="h-10 w-10" />
          )}
          <div className="flex-col">
            <div className="text-ellipsis break-all pb-1 text-base font-medium">
              {data && (obj?.ogFilename || obj?.filename)}
            </div>
            <div className="text-sm text-gray-700">
              {(obj?.metadata?.timeCreated &&
                format(new Date(obj?.metadata?.timeCreated), 'dd/MM/yyyy')) ||
                (obj?.lastModifiedMs &&
                  format(new Date(obj?.lastModifiedMs), 'dd/MM/yyyy'))}
            </div>
          </div>
        </div>
        <div className="">
          {(status === 'completed' || status === 'preview') && (
            <div className="flex items-center gap-2">
              <ButtonIcon
                label={'Open'}
                onClick={() => window.open(obj.url, '_blank', 'noreferrer')}
                iconLeft={true}
                Icon={ArrowTopRightOnSquareIcon}
              />
              {onDeleteFile && (
                <TrashIcon
                  className="h-5 w-5 cursor-pointer text-red-600"
                  onClick={() =>
                    onDeleteFile(
                      obj.metadata.fullPath,
                      obj?.ogFilename || obj?.filename
                    )
                  }
                />
              )}
            </div>
          )}
          {status === 'ongoing' && (
            <div className="flex-col">
              <XMarkIcon className="h-4 w-4 text-gray-900" />
              <p className="pt-2.5 text-sm text-gray-800">{percentage}</p>
            </div>
          )}
        </div>
      </div>
      {progress && progress > 0 && progress !== 100 && (
        <div className="pt-4">
          <ProgressBar width={progress.toString()} />{' '}
        </div>
      )}
    </div>
  );
}

export default UploadedFileCard;
