export * from './configuration';
export * from './gazette';
export * from './recorder';
export * from './misc';
export * from './actions';

import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { OneEachOptionalPersonaLink } from '../persona';
import { Gazette } from './gazette';

export * from './configuration';

/* -------------------------------------------------------------------------- */
/*                                CaseFlowCase                                */
/* -------------------------------------------------------------------------- */

export interface DTCaseFlowCase extends NoSqlDoc {
  /** Who is involved in this case and what role they are playing.
   * Mapping is role:personaId.
   *
   * This tells us what files to look at but is also used in security check. */
  actors: { [role: string]: string };

  /** Type of the case - defines the goal of the case, and indicates which configuration to start with. */
  casetype: string;

  /** The current state of the case. */
  state: string;

  /** Which persona has the spotlight (needs to take action next). Multiple people can have the spotlight, hence arrray. */
  spotlight: string[];

  /** The history of the whole case, containing only the public data. */
  gazette: Gazette; // TODO: Should this go somewhere else?
}

export const FBD_CaseFlowCase = MakeFBDocType<DTCaseFlowCase>({
  name: 'CaseFlowCase',
  collection: 'cf_case',
  empty: () => {
    const result: DTCaseFlowCase = {
      ...Empty_NoSqlDoc(),
      actors: {},
      casetype: '',
      state: '_birth',
      spotlight: [],
      gazette: [],
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

/* -------------------------------------------------------------------------- */
/*                            CaseFlowActorPrivate                            */
/* -------------------------------------------------------------------------- */
export enum CaseFlowActorStatus {
  active = 'active', // actively working on the case
  inactive = 'inactive', // not actively working on the case, but should still have access to case data;
  removed = 'removed', // removed from the case, should not have access to case data. This is a soft delete.
}

/** A chunk of data. Our chunk splits on the Borders for security. This is the case data belonging to the Actor.
 * An actor is a persona but you shouldn't think of it as one human. It might be a company, or a team, or a department.
 *
 * The docid is a portmandeau of the case number and the persona id.
 */
export interface DTCaseFlowActorPrivate extends NoSqlDoc {
  /** The ID of the persona that owns this acting role.
   * This is used for security check.
   */
  owner: string;

  /** The actor's current state */
  state: string;

  /** The history of the whole case, containing only the actor's private data. */
  gazette: Gazette;

  /** The actor can also have a spotlight. This is used to allocate tasks within an organisation.
   *
   * Example: The actor might be "MegaCorp" and the spotlight is on Barry the repairman, so spotlight would point to Barry's Persona.
   *
   * It's an array because we might want to have multiple spotlights. For example, a repairer might have multiple operatives allocated.
   * It's unlikely but having it as an array means firebase security rules can be simpler.
   *  */
  spotlight: string[];

  /** The last time the actor has seen the chat, in unix timestamp format */
  chatLastSeen: number;

  /** The status of the actor within the case. Not really implemented yet, except for basic
   * flagging on actor reassignment so we can use that info in the future as needed. */
  actorStatus: CaseFlowActorStatus;
}

export const FBD_CaseFlowActorPrivate = MakeFBDocType<DTCaseFlowActorPrivate>({
  name: 'DTCaseFlowActorPrivate',
  collection: 'cf_actor',
  empty: () => {
    const result: DTCaseFlowActorPrivate = {
      ...Empty_NoSqlDoc(),
      owner: '',
      state: '_birth',
      actorStatus: CaseFlowActorStatus.active,
      spotlight: [],
      gazette: [],
      chatLastSeen: 0,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'DTCaseFlowActorPrivate key should be PERSONAID_CASEID'
  ),
});

/* -------------------------------------------------------------------------- */
/*                              CaseFlowGroupChat                             */
/* -------------------------------------------------------------------------- */

// TODO: A group-chat gazette type where the case is shared between 2 or more personas

/* -------------------------------------------------------------------------- */
/*                              PrivateCaseProxy                              */
/* -------------------------------------------------------------------------- */
/** A proxy document for recording the most pertinent information about a case, held in a compiled form for easy querying. */
export interface DTCaseFlowPrivateProxy extends NoSqlDoc {
  /** The owner of the Private Case Proxy */
  owner: string;
  /** The current state of the case */
  case_state: string;
  /** The current spotlight on the case */
  case_spotlight: string[];
  /** The current state of this actor (the actor that owns this private proxy) */
  actor_state: string;
  /** The current spotlight of this actor */
  actor_spotlight: string[];
  /** If there is an unread case chat message */
  actor_chat_unread: boolean;

  /** Compiled collection of select facts. What is compiled depends on the configuration. */
  facts: { [key: string]: any };
}

export const FBD_CaseFlowPrivateProxy = MakeFBDocType<DTCaseFlowPrivateProxy>({
  name: 'DTCaseFlowPrivateProxy',
  collection: 'cf_proxy',
  empty: () => {
    const result: DTCaseFlowPrivateProxy = {
      ...Empty_NoSqlDoc(),
      owner: '',
      case_state: '_birth',
      case_spotlight: [],
      actor_state: '_birth',
      actor_spotlight: [],
      facts: {},
      actor_chat_unread: false,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'DTCaseFlowPrivateProxy key should be PERSONAID_CASEID'
  ),
});
