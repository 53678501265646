import { Gazette, Z_Gazette } from './gazette';
import { z } from 'zod';

export type RecorderCommitBlock = {
  cases: {
    [caseid: string]: {
      casetype: string;
      writes: Gazette;
    };
  };
  debug_force_time?: number;
  operating_persona: string;
  principal_persona: string;
};

export const Z_RecorderCommitBlock = z.object({
  cases: z.record(
    z.object({
      casetype: z.string(),
      writes: Z_Gazette,
    })
  ),
  debug_force_time: z.number().optional(),
  operating_persona: z.string(),
  principal_persona: z.string(),
});

export type RecorderCommitResult =
  | {
      success: true;
      map: { [key: string]: string };
      error?: string;
    }
  | {
      success: false;
      error: string;
    };

export const Z_RecorderCommitResult = z.union([
  z.object({
    success: z.literal(true),
    map: z.record(z.string()),
    error: z.string().optional(),
  }),
  z.object({
    success: z.literal(false),
    error: z.string(),
  }),
]);
