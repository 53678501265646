import DatePicker from 'react-date-picker';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import { CalendarIcon, XMarkIcon } from '@heroicons/react/24/outline';
//TODO: find a way to remove css file below and use tailwind exclusively
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './InputDatePicker.css';
import { useEffect, useState } from 'react';
import { InputDateSettingsShape } from '@rabbit/elements/shared-types';
import { useWindowDimensions } from '@rabbit/bizproc/react';

export interface InputDatePickerProps {
  name: string;
  settings: InputDateSettingsShape;
}

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 rounded-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 text-red-700',
  disabled: 'border-gray-300 text-gray-400 opacity-50',
  calendar:
    'bg-white text-sm!important font-nunito!important font-bold rounded !border-gray-300',
};

export function InputDatePicker({ name, settings }: any) {
  const { placeholder, disabled, initialDate, disableCalendar, ...rest } =
    settings;
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const [activeStartDate, setActiveStartDate] = useState<Date | undefined>(
    initialDate || undefined
  );
  const [open, setOpen] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(!field.value);

  let errorField = false;
  if (meta?.error) errorField = true;

  const { width } = useWindowDimensions();

  // useEffect(() => {
  //   if (field.value || initialDate || disabled) setShowPlaceholder(false);
  //   else setShowPlaceholder(true);
  // }, [field.value, initialDate, disabled]);

  useEffect(() => {
    // if (disabled) setShowPlaceholder(false);
    if (disabled) setShowPlaceholder(true);
  }, [field.value, disabled]);

  // need to update activeStartDate as we are now handling its value (solves navigation issue)
  const onViewOrDateChange = ({
    activeStartDate,
  }: {
    activeStartDate: Date;
  }) => {
    setActiveStartDate(activeStartDate);
  };
  return showPlaceholder ? (
    <div
      className={classNames(dynamicStyles.base, {
        [`${dynamicStyles.error}`]: errorField,
      })}
      onClick={() => !disabled && setShowPlaceholder(false)}
    >
      {placeholder}
    </div>
  ) : (
    <DatePicker
      {...field}
      {...rest}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(value: Date) => {
        if (!value) setShowPlaceholder(true);
        else setShowPlaceholder(false);
        void setFieldValue(field.name, value);
        setActiveStartDate(value ?? initialDate);
        setOpen(false);
      }}
      onActiveStartDateChange={onViewOrDateChange}
      activeStartDate={activeStartDate ? activeStartDate : null}
      onCalendarClose={() => {
        field.value === null && setShowPlaceholder(true);
        setActiveStartDate(initialDate);
      }}
      value={
        (field.value && new Date(field.value)) || initialDate || new Date()
      }
      isOpen={open}
      disableCalendar={disableCalendar}
      format="dd/MM/yyyy"
      dayPlaceholder="dd"
      monthPlaceholder="mm"
      yearPlaceholder="yyyy"
      locale="en-UK"
      calendarIcon={<CalendarIcon className="h-[14px] w-[14px]" />}
      clearIcon={<XMarkIcon className="h-[14px] w-[14px]" />}
      calendarClassName={dynamicStyles.calendar}
      className={classNames(dynamicStyles.base, {
        [`${dynamicStyles.disabled}`]: disabled,
        [`${dynamicStyles.error}`]: errorField,
      })}
      onFocus={(e: { target: { blur: () => any } }) => {
        setOpen(true);
        width < 768 && e.target.blur();
      }}
      disabled={disabled}
      autoFocus={!field.value}
    //onChangeRaw={(e: { preventDefault: () => any; }) => e.preventDefault()}
    //portalContainer={document.getElementById('root')}
    />
  );
}

export default InputDatePicker;
