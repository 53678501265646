import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { Shopify_Lifted_Product_Shape } from './shopify-vendable';

/* -------------------------------------------------------------------------- */
/*                           Shopify Vendable History Schema                          */
/* -------------------------------------------------------------------------- */

export interface DTShopifyVendableHistory extends NoSqlDoc {
  /* Original Shopify fields */
  product: Shopify_Lifted_Product_Shape;

  /* The version number for this vendable. Starts at 1 */
  version: number;

  vendableLink: string;
}

export const FBD_ShopifyVendableHistory = MakeFBDocType<DTShopifyVendableHistory>({
  name: 'Shopify_Vendable_History',
  collection: 'shopify_vendable_history',
  empty: () => {
    const result: DTShopifyVendableHistory = {
      ...Empty_NoSqlDoc(),
      product: {} as Shopify_Lifted_Product_Shape,
      version: 1,
      vendableLink: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'Shopify_Vendable_History key should be SHOPNAME_SHOPIFYPRODUCTID_VERSION'
  ),
});
