import {
  KitchenCounterIcon,
  LaptopChromebookOutlineIcon,
  RoundSmartphoneIcon,
  ChangeStatusIcon,
  LogInteractionIcon,
  LogRepaisWorkIcon,
  LogShippingIcon,
  UpdateClaimDetailsIcon,
  AssignClaimIcon,
} from '@rabbit/elements/shared-components';
/*
Sample products and product categories for card components
*/
export const sampleProductCategories = [
  {
    name: 'Smartphones',
    Icon: RoundSmartphoneIcon,
  },
  {
    name: 'Kitchen',
    Icon: KitchenCounterIcon,
  },
  { name: 'Laptop', Icon: LaptopChromebookOutlineIcon },
];

export const sampleUserProducts = [
  {
    prodId: '020323003-32032s',
    // prodName: 'Como',
    prodName: 'Moto Guzzi V85 TT Travel',
    category: 'Motocycle',
    brand: 'Shelta',
    img: 'https://storage.googleapis.com/moto-firerabbit.appspot.com/img/3cabfe23603108ae288dfa832a765c69.jpg',
    // img: 'https://picsum.photos/83/83',
    remainingWarrantyDays: 45,
    purchasedFrom: 'Rowen & Wren',
    holdingId: '',
    title: '',
    purchase_time: 0,
    purchase_location: '',
    warranty_expiry_date: 0,
  },
  {
    prodId: '120323003-32032s',
    prodName: 'Coffee Machine',
    category: 'Kitchen',
    brand: 'Nespresso',
    img: 'https://picsum.photos/84/83',
    remainingWarrantyDays: 65,
    purchasedFrom: 'Nespresso',
    holdingId: '',
    title: '',
    purchase_time: 0,
    purchase_location: '',
    warranty_expiry_date: 0,
  },
  {
    prodId: '120323003-32032s',
    prodName: 'XTREME LAPTOP with a 4090 and loads of RGB',
    category: 'Laptop',
    brand: 'Asus',
    img: 'https://picsum.photos/83/85',
    remainingWarrantyDays: 35,
    purchasedFrom: 'Amazon',
    holdingId: '',
    title: '',
    purchase_time: 0,
    purchase_location: '',
    warranty_expiry_date: 0,
  },
];

export const sampleRepairData = [
  {
    prodId: '020323003-32032s',
    // prodName: 'Como',
    title: 'Moto Guzzi V85 TT Travel',
    category: 'Smartphones',
    brand: 'Piaggio',
    img: 'https://storage.googleapis.com/moto-firerabbit.appspot.com/img/3cabfe23603108ae288dfa832a765c69.jpg',
    // img: 'https://picsum.photos/83/83',
    remainingWarrantyDays: 45,
    purchasedFrom: 'Rowen & Wren',
    // repairer: 'Barbeques Galore',
    purchase_location: 'London Motorcycles',
    lastStatusUpdate: '1 hour ago',
    repairProgress: '30',
  },
];

// timeline repairs messages for a single product on tab Repairs
export const timelineMessages = [
  {
    date: 1678986701,
    messages: [
      {
        label: 'London Motorcycles commented on',
        tagText: 'Investigating',
        message:
          "Hi, hope you're well. Just to let you know that we have identified the problem and we are going to need to substitute the stand. Thanks.",
        timestamp: 1676504054,
      },
      {
        label: 'Status updated to',
        tagText: 'Under Diagnosis',
        timestamp: 1676504054,
      },
    ],
  },
  {
    date: 1678900301,
    messages: [
      {
        label: '“company” accepted your claim',
        timestamp: 1676504054,
      },
    ],
  },
  {
    date: 1678641101,
    messages: [
      {
        label: '“company” commented on',
        tagText: 'Investigating',
        message:
          'Hi, hope youre well. Just to let you know that we have identified the problem and we are going to need to substitute the stand. Thanks. “company”',
        timestamp: 1676504054,
      },
    ],
  },
];

export const sampleProductsList = [
  {
    brand: 'Apple',
    ean: '190199472998',
    purchase_time: 1633080600,
    purchase_location: 'Radio Popular',
    purchase_country: 'Portugal',
    serial: 'F18HS1KPMG7D',
    name: 'iPhone 12 Pro 64GB',
    price: 1159.0,
    warranty_expiry_date: 1680793523,
    proof_of_purchase: null,
    claim: {
      id: '12345678',
      status: 'Repair Complete',
      date: 1679882100,
    },
    image:
      'https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-12-pro-graphite-hero?wid=940&hei=1112&fmt=png-alpha&.v=1604021660000',
    category: 'Smartphones',
    model: 'A2407',
  },
  {
    brand: 'Apple',
    ean: '190199473001',
    purchase_time: 1633448700,
    purchase_location: 'Worten',
    purchase_country: 'Portugal',
    serial: 'F18HS1KPMG7E',
    name: 'iPhone 12 Pro 128GB',
    price: 1179.0,
    warranty_expiry_date: 1680793523,
    proof_of_purchase: 'https://pii.or.id/uploads/dummies.pdf',
    claim: {
      id: '22334455',
      status: 'Pending',
      date: 1680688200,
    },
    image:
      'https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-12-pro-graphite-hero?wid=940&hei=1112&fmt=png-alpha&.v=1604021660000',
    category: 'Smartphones',
    model: 'A2407',
  },
  {
    brand: 'Apple',
    ean: '190199473018',
    purchase_time: 1635771600,
    purchase_location: 'Fnac',
    purchase_country: 'Portugal',
    serial: 'F18HS1KPMG7F',
    name: 'iPhone 12 Pro 256GB',
    price: 1189.0,
    warranty_expiry_date: 1680793523,
    proof_of_purchase: null,
    claim: {
      id: '34567890',
      status: 'In Progress',
      date: 1680485700,
    },
    image:
      'https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-12-pro-graphite-hero?wid=940&hei=1112&fmt=png-alpha&.v=1604021660000',
    category: 'Smartphones',
    model: 'A2407',
  },
];

export const chartAvgResponse = {
  options: {
    chart: {
      id: 'claims',
      width: 400,
      height: 200,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['19 May', '20 May', '21 May', '22 May', '23 May'],
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
    colors: ['#579085'],
  },
  type: 'line',
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    colors: undefined,
    width: 2,
    dashArray: 0,
  },
  // todo: make line curve on line charts
  //  stroke: {
  //     show: true,
  //     curve: 'smooth',
  //     lineCap: 'butt',
  //     colors: undefined,
  //     width: 2,
  //     dashArray: 0,
  // },
  series: [
    {
      data: [
        {
          x: '19 May',
          y: 1,
        },
        {
          x: '20 May',
          y: 2,
        },
        {
          x: '21 May',
          y: 5,
        },
        {
          x: '22 May',
          y: 3,
        },
        {
          x: '23 May',
          y: 0,
        },
      ],
    },
  ],
};

export const chartNewWarrantyRegistrations = {
  options: {
    chart: {
      id: 'claims',
      width: 400,
      height: 200,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['19 May', '20 May', '21 May', '22 May', '23 May'],
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
    colors: ['#579085'],
  },
  stroke: {
    curve: 'smooth',
  },
  series: [
    {
      data: [
        {
          x: '19 May',
          y: 10,
        },
        {
          x: '20 May',
          y: 8,
        },
        {
          x: '21 May',
          y: 10,
        },
        {
          x: '22 May',
          y: 5,
        },
        {
          x: '23 May',
          y: 8,
        },
      ],
    },
  ],
};

export const chartNewClaimsRegistrations = {
  options: {
    chart: {
      id: 'claims',
      width: 400,
      height: 200,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['19 May', '20 May', '21 May', '22 May', '23 May'],
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
    colors: ['#579085'],
  },
  stroke: {
    curve: 'smooth',
  },
  series: [
    {
      data: [
        {
          x: '19 May',
          y: 5,
        },
        {
          x: '20 May',
          y: 7,
        },
        {
          x: '21 May',
          y: 10,
        },
        {
          x: '22 May',
          y: 5,
        },
        {
          x: '23 May',
          y: 3,
        },
      ],
    },
  ],
};

export const chartNumberOfClaims = {
  options: {
    chart: {
      id: 'claims',
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Dec', 'Jan', 'Feb', 'Mar', 'Apr'],
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
    colors: ['#579085'],
  },
  stroke: {
    curve: 'smooth',
  },
  series: [
    {
      data: [
        {
          x: 'Dec',
          y: 49,
        },
        {
          x: 'Jan',
          y: 24,
        },
        {
          x: 'Feb',
          y: 20,
        },
        {
          x: 'Mar',
          y: 24,
        },
        {
          x: 'Apr',
          y: 25,
        },
      ],
    },
  ],
};
export const chartSaleToFaultRatio = {
  options: {
    chart: {
      id: 'sale to fault',
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Dez', 'Jan', 'Feb', 'Mar', 'Apr'],
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
    colors: ['#579085'],
  },
  stroke: {
    curve: 'smooth',
  },
  series: [
    {
      data: [
        {
          x: 'Dec',
          y: '3.1%',
        },
        {
          x: 'Jan',
          y: '3.5%',
        },
        {
          x: 'Feb',
          y: '2.8%',
        },
        {
          x: 'Mar',
          y: '2.2%',
        },
        {
          x: 'Apr',
          y: '1.8%',
        },
      ],
    },
  ],
};

export const chartProducts = {
  options: {
    chart: {
      id: 'basic-bar',
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            maxRotation: 90,
            minRotation: 90,
          },
        },
      ],
    },
    // xaxis: {
    //   labels: {
    //     rotate: 0,
    //     trim: true,
    //     style: {
    //       fontFamily: 'nunito',
    //       // fontSize: '12px',
    //       // cssClass: 'text-gray-400 word-wrap',
    //     },
    //   },
    // },
    xaxis: {
      labels: {
        //rotate: 0,
        trim: true,
        style: {
          fontFamily: 'nunito',
        },
        formatter: function (val: string) {
          if (typeof val !== 'string') return '-';
          if (val.length > 10) {
            return val.substring(0, 10) + '...';
          } else {
            return val.substring(0, 10);
          }
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
      tickAmount: 5,
      forceNiceScale: true,
    },
    colors: ['#579085'],
    // plotOptions: {
    //   bar: {
    //     borderRadius: 4,
    //   },
    // },
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      data: [
        {
          x: 'Sterling',
          y: 49,
        },
        {
          x: 'Sebastian',
          y: 24,
        },
        {
          x: 'Baxter',
          y: 20,
        },
        {
          x: 'Kenzie',
          y: 25,
        },
        {
          x: 'Mason',
          y: 25,
        },
      ],
    },
  ],
};

export const chartCategories = {
  options: {
    chart: {
      id: 'categories',
    },
    xaxis: {
      categories: ['Sofas', 'Armchairs', 'Beds'],
      labels: {
        rotate: 0,
        trim: true,
        hideOverlappingLabels: false,
        style: {
          fontFamily: 'nunito',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
    },
    colors: ['#579085', '#905762'],
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      name: 'Under Warranty',
      data: [17, 20, 15],
    },
    {
      name: 'Out of warranty',
      data: [11, 7, 13],
    },
  ],
};

export const chartRegistrations = {
  options: {
    chart: {
      id: 'registrations',
    },
    xaxis: {
      labels: {
        rotate: 0,
        trim: true,
        hideOverlappingLabels: false,
        style: {
          fontFamily: 'nunito',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
    },
    colors: ['#579085', '#905762'],
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      name: 'Warranty',
      data: [20, 22, 18, 24],
    },
    {
      name: 'Extended Warranty',
      data: [13, 10, 15, 17],
    },
  ],
};

export const chartCommonFaults = {
  options: {
    chart: {
      id: 'comon-faults',
    },
    xaxis: {
      categories: [
        'Electrical',
        'Water damager',
        'Frame',
        'Discoloration',
        'Mechanism',
      ],
      labels: {
        rotate: 0,
        trim: true,
        hideOverlappingLabels: false,
        style: {
          fontFamily: 'nunito',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
    },
    colors: ['#579085', '#905762'],
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      name: 'Under warranty',
      data: [19, 22, 17, 24, 24],
    },
    {
      name: 'Out of warranty',
      data: [13, 10, 14, 17, 22],
    },
  ],
};

export const chartCasesPerTeam = {
  options: {
    chart: {
      id: 'cases-per-team',
    },
    xaxis: {
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
    yaxis: {},
    colors: ['#579085', '#905762'],
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      name: 'Open',
      data: [17, 20, 16, 22, 22],
    },
    {
      name: 'Closed',
      data: [12, 8, 13, 15, 17],
    },
  ],
};

export const chartServiceLevels = {
  options: {
    colors: ['#D1D5DB', '#FF8A4C', '#F98080', '#D61F69'],
    labels: [
      'Excellent 1-2 days',
      'Acceptable 3-5 days',
      'Unsatisfactory 5+ days',
      'Not yet commenced',
    ],
    xaxis: {
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
  },
  series: [44, 55, 41, 17],
};

export const chartRepairCosts = {
  options: {
    chart: {
      id: 'repairs',
    },
    xaxis: {
      categories: ['19 May', '20 May', '21 May', '22 May', '23 May', '24 May'],
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
    colors: ['#579085', '#905762'],
  },
  series: [
    {
      name: 'Diagnosis',
      data: [14, 2, 25, 15, 25, 28],
    },
    {
      name: 'Repair',
      data: [20, 29, 37, 36, 44, 45],
    },
  ],
};

export const chartWarrantyCostsStatus = {
  options: {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: ['In warranty claim costs', 'Out of warranty claim costs'],
      labels: {
        style: {
          fontFamily: 'nunito',
          fontSize: '14px',
          cssClass: 'text-gray-400',
        },
      },
    },
    colors: ['#579085'],
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      data: [
        {
          x: 'In warranty claim costs',
          y: 1.3,
        },
        {
          x: 'Out of warranty claim costs',
          y: 0.4,
        },
      ],
    },
  ],
};

/* -------------------------------------------------------------------------- */
/*                            Category search list                            */
/* -------------------------------------------------------------------------- */

export const mockVendableCategoryList = [
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Garden and patio',
    value: 'garden-and-patio',
  },
  {
    label: 'Outdoor and patio furniture',
    value: 'outdoor-and-patio-furniture',
  },
  {
    label: 'Patio umbrellas',
    value: 'patio-umbrellas',
  },
  {
    label: 'Motors',
    value: 'motors',
  },
  {
    label: 'Pod Drives',
    value: 'pod-drives',
  },
  {
    label: 'Batteries',
    value: 'batteries',
  },
  {
    label: 'Utility-Compact Trailer',
    value: 'utility-compact-trailers',
  },
  {
    label: 'ATV',
    value: 'atv',
  },
  {
    label: 'Travel bags',
    value: 'travel-bags',
  },
  {
    label: 'Smartphones',
    value: 'smartphones',
  },
  {
    label: 'Games consoles',
    value: 'games-consoles',
  },
];
