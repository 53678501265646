import { useState } from 'react';
import {
  ButtonIcon,
  Modal,
  SearchBar,
  Table,
} from '@rabbit/elements/shared-components';
import { ModalAddUser } from '../components/organisms/ModalAddUser/ModalAddUser';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import imagePlaceholder from '../assets/images/profile-placeholder.png';
import { ModalEditUser } from '../components/organisms/ModalEditUser/ModalEditUser';

export const tableheaders = [
  {
    header: 'name',
    accessorKey: 'name',
    Cell: ({
      row,
    }: {
      row: {
        original: {
          imageUrl: string;
          name: string;
          email: string;
          status: string;
        };
      };
    }) => (
      <div className="flex items-center gap-6">
        <div className="flex gap-[10px]">
          <img
            src={row.original.imageUrl || imagePlaceholder}
            alt="user profile photo"
            width={'44px'}
            height={'44px'}
            className="h-[44px] w-[44px]"
          />
          <div className="flex flex-col">
            <p>{row.original.name}</p>
            <p className="font-nunito text-[14px] text-gray-600">
              {row.original.email}
            </p>
          </div>
        </div>
        <div>
          {row.original.status === 'Pending' ? (
            <div className="flex gap-2 rounded-xl bg-red-200 px-2 py-0.5 font-medium">
              <div className="my-auto h-[11px] w-[11px] rounded-xl bg-red-500" />
              <span className="my-auto text-sm text-red-800">
                {row.original.status}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    ),
  },
  {
    header: 'role',
    accessorKey: 'role',
  },
  {
    header: 'hourlyRate',
    accessorKey: 'hourlyRate',
    Cell: ({
      row,
    }: {
      row: {
        original: {
          hourlyRate: string;
        };
      };
    }) => <div>AUD $15</div>,
  },
  {
    accessorKey: 'actions',
    size: 10,
  },
];

export function UserGroupView() {
  const [searchValue, setSearchValue] = useState('');
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState<any>(undefined);
  const [, setCustomerDetails] = useState<any>();
  const [, setModalTitle] = useState<string>();

  const handleEditModal = (title: string, customerDetails?: any) => {
    setModalTitle(title);
    setCustomerDetails(customerDetails);
    setIsNewEditModalOpen(true);
  };

  const onEditUser = (id: number) => {
    setSelectedUserID(id);
    setEditUserModalOpen(true);
  };

  const tableContent = [
    {
      id: '1',
      name: 'Neil Sims',
      email: 'bonnie@example.com',
      role: 'Admin',
      status: 'Active',
      actions: (
        <ButtonIcon
          label={'Edit user'}
          Icon={PencilSquareIcon}
          iconLeft={true}
          onClick={() => onEditUser(1)}
        />
      ),
    },
    {
      id: '2',
      name: 'Roberta Casas',
      email: 'bonnie@example.com',
      role: 'Standard',
      status: 'Active',
      actions: (
        <ButtonIcon
          label={'Edit user'}
          Icon={PencilSquareIcon}
          iconLeft={true}
          onClick={() => onEditUser(2)}
        />
      ),
    },
    {
      id: '3',
      name: 'Micheal Gough',
      email: 'bonnie@example.com',
      role: 'Standard',
      status: 'Active',
      actions: (
        <ButtonIcon
          label={'Edit user'}
          Icon={PencilSquareIcon}
          iconLeft={true}
          onClick={() => onEditUser(2)}
        />
      ),
    },
    {
      id: '4',
      name: 'Jese Leos',
      email: 'bonnie@example.com',
      role: 'Standard',
      status: 'Active',
      actions: (
        <ButtonIcon
          label={'Edit user'}
          Icon={PencilSquareIcon}
          iconLeft={true}
          onClick={() => onEditUser(2)}
        />
      ),
    },
    {
      id: '5',
      name: 'Bonnie Green',
      email: 'bonnie@example.com',
      role: 'Standard',
      status: 'Active',
      actions: (
        <ButtonIcon
          label={'Edit user'}
          Icon={PencilSquareIcon}
          iconLeft={true}
          onClick={() => onEditUser(2)}
        />
      ),
    },
    {
      id: '6',
      name: 'Thomas Lean',
      email: 'bonnie@example.com',
      role: 'Standard',
      status: 'Pending',
      actions: (
        <ButtonIcon
          label={'Edit user'}
          Icon={PencilSquareIcon}
          iconLeft={true}
          onClick={() => onEditUser(2)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="relative flex w-full items-center justify-between py-4">
        <div className="w-[560px]">
          <SearchBar
            placeholder="Search by customer name or email"
            value={searchValue}
            submitSearchCallback={() => console.log('submit search')}
            handleChangeCallBack={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="flex space-x-4">
          <div>
            <ButtonIcon
              label={'Add User'}
              iconLeft={true}
              onClick={() => handleEditModal('New Customer')}
            />
          </div>
        </div>
      </div>
      <Table columns={tableheaders} data={tableContent} />
      {isNewEditModalOpen && (
        <div>
          <ModalAddUser handleClose={() => setIsNewEditModalOpen(false)} />
        </div>
      )}
      {editUserModalOpen && (
        <ModalEditUser
          handleClose={() => setEditUserModalOpen(false)}
          itemDetails={{ id: selectedUserID }}
        />
      )}
    </>
  );
}
