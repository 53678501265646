export interface BLITech {
  getOliveUrlFromSage: (sageUrl: string) => string;
}

export const BL_Tech: BLITech = {
  getOliveUrlFromSage,
};

function getOliveUrlFromSage(sageUrl: string): string {
  return sageUrl
    .replace('localhost:1317', 'localhost:1337')
    .replace('-sage', '-olive')
    .replace('admin-', '');
}
