import emailSent from '../../../assets/images/email-sent-arrow.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface PWDREmailSentProps {
  onChangeStep: (data: string) => void;
  data: any; //todo
}
function PWDREmailSent({ onChangeStep, data }: PWDREmailSentProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <img src={emailSent} alt={'emailSent'} className="mx-auto py-5"></img>
      <p className="text-base font-semibold">
        {t('message.checkYourEmailForResetLink')}
      </p>
      <p className="pt-2.5 text-sm font-medium">
        {location?.state?.data ?? ''}{' '}
        {t('message.ifEmailHasAccountWillReceiveResetLink')}
      </p>
      <div className="font-nunito mt-7 text-sm">
        {t('message.didntReceiveEmail')} &nbsp;
        <button
          className="text-primary-600 underline"
          onClick={() => navigate(-1)}
        >
          {t('general.sendAgain')}
        </button>
      </div>
      {/* <div className="text-sm">
        If you need help, please go to our&nbsp;
        <NavLink
          to={ROUTE_NAME.SIGNUP}
          className="text-primary-600"
          reloadDocument
        >
          Help center
        </NavLink>
      </div> */}
    </>
  );
}

export default PWDREmailSent;
