import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { Shopify_Shop_Shape } from './base-types';

interface ShopifyShopVersionHistoryShape {
  version: number;
  shopData: Shopify_Shop_Shape;
  tcreate: number;
  tupdate: number;
}

interface ShopifyIWUrlsShape {
  sage: string;
  olive: string;
}

/* -------------------------------------------------------------------------- */
/*                                 Shop schema                                */
/* -------------------------------------------------------------------------- */

export interface DTShopifyShop extends NoSqlDoc {
  /**
   * The original shopify shop data, as returned by the API
   */
  shopData?: Shopify_Shop_Shape;

  /**
   * The version number for this shop. Starts at 1
   */
  version: number;

  /**
   * The version history for this shop
   */
  versionHistory?: ShopifyShopVersionHistoryShape[];

  /** Whether the shop is currently active or not, aka, has the app installed */
  isActive: boolean;

  /** The urls for this tenant's Sage and Olive versions  */
  urls?: ShopifyIWUrlsShape;

  /** A link to the relevant tenant document on Rabbit */
  tenantLink: string;
}

export const FBD_ShopifyShop = MakeFBDocType<DTShopifyShop>({
  name: 'Shopify_Shop',
  collection: 'shopify_shop',
  empty: () => {
    const result: DTShopifyShop = {
      ...Empty_NoSqlDoc(),
      version: 1,
      shopData: {} as Shopify_Shop_Shape,
      isActive: false,
      tenantLink: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED('Shopify_Shop key should be the shop URL'),
});
