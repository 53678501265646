import { z } from 'zod';
import {
  DTWarranty,
  FBD_Consumer_Public,
  FBD_Warranty,
  FBD_WarrantyHistory,
  DTWarranty_History,
  DTWarrantyHistory_Snapshot,
  Z_ConsumerInfo,
} from '@rabbit/data/types';

import { useEffect, useState } from 'react';

export interface WarrantyHistoryShape {
  warrantyHistory: DTWarranty[] | null;
  consumer: z.infer<typeof Z_ConsumerInfo> | null;
}

export function useGetWarrantyHistory(
  warrantyLink: string,
) {
  const [warrantyDetails, setWarrantyData] = useState<WarrantyHistoryShape | null>(null);
  if (!warrantyLink) { 
    throw new Error('Warranty Link is required');
  };
  
  const getData = async(): Promise<WarrantyHistoryShape> => {
    
    const warranty = await FBD_Warranty.get(warrantyLink);
    if (!warranty) {
      throw new Error('Warranty not found');
    }
    
    const warrantyHistoryData: DTWarranty_History|null = await FBD_WarrantyHistory.get(warrantyLink);
    if (!warrantyHistoryData) {
      throw new Error('Warranty not Found');
    }

    const warrantyHistorySnapshot = warrantyHistoryData.warrantySnapshot as DTWarrantyHistory_Snapshot[];
    if (!warrantyHistorySnapshot) {
      throw new Error(`Warranty history not found for warranty: ${warrantyLink}`, );
    }
    
    const warrantyHistory = warrantyHistorySnapshot.map((snapshot) => snapshot.content);

    // fetch consumer data of warranty
    const consumerInfo = await FBD_Consumer_Public.get(warranty.consumerLink);
    if (!consumerInfo) {
      throw new Error('Warranty customer data not found');
    }

    let consumer: z.infer<typeof Z_ConsumerInfo> = {
      firstName: consumerInfo.splitname?.first ?? '',
      lastName: consumerInfo.splitname?.last ?? '',
      consumerEmail: consumerInfo.email ?? '',
      phoneNumber: consumerInfo.phone ?? '',
    };
    if (consumerInfo.address) {
      consumer = {
        ...consumer,
        address: consumerInfo.address[consumerInfo.address.length - 1]
      };
    }
    return {
      warrantyHistory,
      consumer
    };
  };

  useEffect(() => {
    (async () => {
      const data = await getData();
      setWarrantyData(data);
    })().catch((err) => console.error(err));
  }, [warrantyLink]);

  return warrantyDetails;
};