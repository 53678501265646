import { FBDocTypeEssentials, NoSqlDoc } from '@rabbit/firebase/doctype';

const DELIM = '.';
const VERSION = 3;

interface AlgoliaCreds {
  appId: string;
  searchApiKey: string;
  adminEnvVar: string;
}

const ALGOLIA_DEFAULT_LOCATION = 'eu';
const ALGOLIA_CREDS_BY_LOCATION_AND_TYPE: Record<string, AlgoliaCreds> = {
  'eu.vendable': {
    appId: 'TUDL1EJCQM',
    searchApiKey: '01610c7a584861d91306f8e5b02ce49d',
    adminEnvVar: 'VITE_ALGOLIA_RABBIT_EU_VENDABLE',
  },
  'eu.retailer': {
    appId: 'LZR5UNW6G3',
    searchApiKey: '0d09e5708c6f189a33f127ff3fb076d0',
    adminEnvVar: 'VITE_ALGOLIA_RABBIT_EU_RETAILER',
  },
  'eu.category': {
    appId: 'FJILK4J0UN',
    searchApiKey: 'c02a8197ddf8654e44af65db616d34f9',
    adminEnvVar: 'VITE_ALGOLIA_RABBIT_EU_CATEGORY',
  },
};

/**
 * Calculates the current firebase mode, server or client
 * Sees if the client / Vite mode is set
 * Then looks for a server side environment variable
 * Finally just assumes server/emulator mode
 */

function getFirebaseMode(): string {
  return [
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import.meta.env?.VITE_FIREBASE_MODE, // React only
    typeof process === 'object' ? process.env['FIREBASE_MODE'] : null, // node only
    typeof process === 'object' ? process.env['VITE_FIREBASE_MODE'] : null, // node only
    'emulator', // if all else fails node / emulator
  ]
    .filter(Boolean)
    .map((v) => v.toLowerCase())
    .shift();
}

export function DocTypeToIndexBaseName<TYPE extends NoSqlDoc>(
  type: FBDocTypeEssentials<TYPE>
) {
  return type.collectionName.toLowerCase().split('_')[0];
}

export function GetAlgoliaCreds<TYPE extends NoSqlDoc>(
  type: FBDocTypeEssentials<TYPE>
) {
  const locationCode = ALGOLIA_DEFAULT_LOCATION; // just EU for now
  const projectType = DocTypeToIndexBaseName(type);
  const locType = [locationCode, projectType].join(DELIM);

  if (!ALGOLIA_CREDS_BY_LOCATION_AND_TYPE[locType]) {
    throw new Error(
      `GetAlgoliaCreds: no ALGOLIA_LOCATIONS for locationCode=${locationCode}, projectType=${projectType}`
    );
  }

  return ALGOLIA_CREDS_BY_LOCATION_AND_TYPE[locType];
}

export function GetSearchIndexName<TYPE extends NoSqlDoc>(
  type: FBDocTypeEssentials<TYPE>
): string {
  const indexName = [
    DocTypeToIndexBaseName(type),
    getFirebaseMode(),
    'v' + VERSION,
  ].join(DELIM);

  return indexName;
}
