import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Input,
  PasswordValidationPopover,
  handlePopoverData,
  asyncValidateSchema,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { FIREBASE_AUTH_ERRORS } from '../../../utils/consts';
import { FirebaseError } from 'firebase/app';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { UserContext } from 'apps/sage/src/context/UserContext';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { DTBusiness_User_Invite } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  UserInvites_Business_DecodeInviteLink,
  UserInvites_Business_Signup,
} from '@rabbit/bizproc/client';
import i18next from 'i18next';
import { setLocale } from 'yup';

export interface SageAuthFormValuesShape {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  platform_services_terms: boolean;
  rememberMe: boolean;
}

const initialValues: SageAuthFormValuesShape = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  platform_services_terms: false,
  rememberMe: true,
};

const validationSchema = (t: any) =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18next.t('message.pleaseEnterAValidEmailAddress'))
      .required(i18next.t('message.pleaseEnterYourEmailAddress')),
    password: Yup.string()
      .min(8, i18next.t('message.passwordShouldBe8OrMoreCharacters'))
      .matches(
        /(?=.*[a-z])/,
        i18next.t('message.pleaseEnterAtLeast1LowercaseLetter')
      )
      .matches(
        /(?=.*[A-Z])/,
        i18next.t('message.pleaseEnterAtLeast1UppercaseLetter')
      )
      .matches(/^(?=.*[0-9])/, i18next.t('message.pleaseEnterAtLeast1Number'))
      .required(i18next.t('message.pleaseEnterYourPassword')),
  });

const validationSchemaSignup = (t: any) =>
  Yup.object().shape({
    first_name: Yup.string()
      .required(i18next.t('message.pleaseEnterFirstName'))
      .matches(
        /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
        i18next.t('message.firstNameMustStartWithCapitalAndContainOnlyLetters')
      ),
    last_name: Yup.string()
      .trim()
      .required(i18next.t('message.pleaseEnterLastName'))
      .matches(
        /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
        i18next.t('message.lastNameMustStartWithCapitalAndContainOnlyLetters')
      ),
    email: Yup.string()
      .email(i18next.t('message.pleaseEnterAValidEmailAddress'))
      .required(i18next.t('message.pleaseEnterYourEmailAddress')),
    password: Yup.string()
      .min(8, i18next.t('message.passwordShouldBe8OrMoreCharacters'))
      .matches(
        /(?=.*[a-z])/,
        i18next.t('message.pleaseEnterAtLeast1LowercaseLetter')
      )
      .matches(
        /(?=.*[A-Z])/,
        i18next.t('message.pleaseEnterAtLeast1UppercaseLetter')
      )
      .matches(/^(?=.*[0-9])/, i18next.t('message.pleaseEnterAtLeast1Number'))
      .required(i18next.t('message.pleaseEnterYourPassword')),
    platform_services_terms: Yup.bool().isTrue(),
  });

const parseFirebaseAuthErrors = (errorCode: string) => {
  switch (errorCode) {
    case FIREBASE_AUTH_ERRORS.EMAIL_EXISTS:
      return i18next.t('message.pleaseUseDifferentEmailToCreateYourAccount');
    case FIREBASE_AUTH_ERRORS.INVALID_PASSWORD:
      return i18next.t(
        'message.invalidCredentialsCheckEmailPasswordAndTryAgain'
      );
    case FIREBASE_AUTH_ERRORS.INVALID_EMAIL:
      return i18next.t(
        'message.invalidCredentialsCheckEmailPasswordAndTryAgain'
      );
    case FIREBASE_AUTH_ERRORS.TOO_MANY_ATTEMPTS_TRY_LATER:
      return i18next.t('message.tooManyAttemptsTryAgainLater');
    case FIREBASE_AUTH_ERRORS.USER_DELETED:
      return i18next.t(
        'message.invalidCredentialsCheckEmailPasswordAndTryAgain'
      );
    default:
      return i18next.t('message.somethingWentWrongPleaseTryAgain');
  }
};

export interface AuthFormProps {
  kind: 'Login' | 'Signup' | 'Partner-Signup';
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  authErrorMsg: string;
  setAuthErrorMsg: React.Dispatch<React.SetStateAction<string>>;
}

export function AuthForm({
  kind,
  setIsLoading,
  authErrorMsg,
  setAuthErrorMsg,
}: AuthFormProps) {
  const appInfo = useAppInfo();
  const [buttonLoading, setButtonLoading] = useState(false);
  const contextValues = useContext(UserContext);
  const { createSageRepairerUser, sageSignIn } = contextValues || {};
  const queryParams = new URLSearchParams(location.search);
  const formikRef = useRef(null) as any;
  const [inviteData, setInviteData] = useState<DTBusiness_User_Invite>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  if (!createSageRepairerUser || !sageSignIn)
    return <LoadingSpinner size="sm" />;

  const onSubmit = async (values: SageAuthFormValuesShape) => {
    setButtonLoading(true);
    setIsLoading(true);
    if (kind === 'Login') {
      try {
        await sageSignIn(values);
      } catch (err) {
        if (err instanceof FirebaseError) {
          setIsLoading(false);
          setAuthErrorMsg(parseFirebaseAuthErrors(err.code));
        } else console.log(err);
      } finally {
        setButtonLoading(false);
      }
    }
    if (kind === 'Signup' || kind === 'Partner-Signup') {
      if (!values.platform_services_terms) {
        setAuthErrorMsg(
          i18next.t(
            'message.pleaseAgreeToPlatformServicesTermsBeforeCreatingYourAccount'
          )
        );
        setIsLoading(false);
        setButtonLoading(false);
        return;
      }
      if (!checkIfAllowedEmailForUser(values.email, appInfo.environment)) {
        setAuthErrorMsg(t('message.somethingWentWrongPleaseTryAgain'));
        setIsLoading(false);
        setButtonLoading(false);
        return;
      }
      try {
        if (kind === 'Partner-Signup' && inviteData) {
          const res = await UserInvites_Business_Signup(
            inviteData,
            t('tenantLink'),
            values.password,
            values.first_name,
            values.last_name
          );
          console.log('res', res);
          if (res.ok) {
            toast.success(
              i18next.t(
                'message.accountCreatedSuccessfullyPleaseLoginToContinue'
              )
            );
            navigate(SAGE_ROUTE_NAME.LOGIN);
          } else {
            toast.error(
              t('message.somethingWentWrongPleaseTryAgain') +
                res.error.replace(/^.*Error: /, '') +
                '.'
            );
          }
        } else {
          await createSageRepairerUser(values);
        }
      } catch (err) {
        if (err instanceof FirebaseError) {
          setAuthErrorMsg(parseFirebaseAuthErrors(err.code));
          setIsLoading(false);
        } else console.log(err);
      } finally {
        setIsLoading(false);
        setButtonLoading(false);
      }
    }
  };

  useEffect(() => {
    if (kind === 'Partner-Signup') {
      const getUserDetails = async () => {
        const c = queryParams.get('c') || '',
          iv = queryParams.get('iv') || '';

        const res = await UserInvites_Business_DecodeInviteLink(c, iv);
        if (res.ok && res.data.inviteData) {
          setInviteData(res.data.inviteData as DTBusiness_User_Invite);
        }
      };
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (inviteData?.email && kind === 'Partner-Signup') {
      formikRef?.current?.setFieldValue('email', inviteData?.email);
    }
  }, [inviteData]);

  if (kind === 'Partner-Signup' && !inviteData?.docid)
    return <LoadingSpinner size="sm" />;

  return (
    <Formik
      initialValues={initialValues}
      validate={asyncValidateSchema(
        kind === 'Login' ? validationSchema(t) : validationSchemaSignup(t)
      )}
      validateOnBlur
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ errors, values }) => (
        <Form className="font-nunito flex flex-col gap-3">
          {(kind === 'Signup' || kind === 'Partner-Signup') && (
            <>
              <Input
                type="text"
                name="first_name"
                label={`${t('general.firstName')}*`}
                settings={{
                  placeholder: t('message.pleaseEnterFirstName'),
                  forceUpperCaseFirstLetter: true,
                }}
              />
              <Input
                type="text"
                name="last_name"
                label={t('general.lastName')}
                settings={{
                  placeholder: t('message.pleaseEnterLastName'),
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </>
          )}
          <Input
            type="email"
            name="email"
            label={`${t('general.email')}*`}
            settings={{
              placeholder: t('message.pleaseTypeYourEmail'),
              hint: '*required',
              disabled: kind === 'Partner-Signup',
            }}
          />
          <Input
            type="password"
            name="password"
            label={`${t('general.password')}*`}
            settings={{
              placeholder: t('message.pleaseTypeYourPassword'),
              id: 'password',
              popoverTrigger: 'hover',
              popoverPlacement: 'right',
              popoverTarget: 'popover-password',
              hint: '*required',
            }}
          />
          <PasswordValidationPopover
            id="popover-password"
            errors={handlePopoverData(errors.password, values.password)}
          />
          {(kind === 'Signup' || kind === 'Partner-Signup') && (
            <div className="my-2 w-full justify-between">
              <Input
                type="checkbox"
                name="platform_services_terms"
                settings={{
                  checkboxLabel:
                    t('message.byCreatingIWarrantyAccountYouAgreeToThe') +
                    ' <a href="' +
                    SAGE_ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT +
                    '" target="_blank" />' +
                    t('message.platformServicesTerms') +
                    '</a>.',
                  checkboxLabelStyles: 'text-base text-gray-500',
                }}
              />
            </div>
          )}
          <div className="my-2 flex w-full justify-between">
            <div className="shrink-0">
              <Input
                type="checkbox"
                name="rememberMe"
                settings={{
                  checkboxLabel: t('message.rememberMe'),
                  checkboxLabelStyles: 'text-base text-gray-500',
                }}
              />
            </div>
            <div className="shrink-0">
              <NavLink
                to={SAGE_ROUTE_NAME.FORGOT_PASSWORD}
                className="text-primary-600 text-sm"
              >
                {t('message.forgotPassword')}
              </NavLink>
            </div>
          </div>
          <Button
            kind="primary"
            type="submit"
            disabled={
              Object.keys(errors).length > 0 ||
              values.email.length === 0 ||
              buttonLoading
            }
            loading={buttonLoading}
          >
            {kind === 'Login' ? t('message.logIn') : t('message.createAccount')}
          </Button>
          {kind === 'Login' && (
            <div className="w-full justify-between text-sm">
              {t('message.byLoggingIntoIWarrantyYouAreAgreeingToThe')}{' '}
              <a
                href={SAGE_ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT}
                target="_blank"
              >
                {t('message.platformServicesTerms')}
              </a>
            </div>
          )}
          {authErrorMsg && (
            <p className="font-nunito text-sm text-red-500">{authErrorMsg}</p>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default AuthForm;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const checkIfAllowedEmailForUser = (email: string, firebase_mode: string) => {
  if (
    firebase_mode === 'EMULATOR' ||
    firebase_mode === 'LIVE' ||
    firebase_mode === 'EULIVE' ||
    firebase_mode === 'EUSANDBOX' ||
    firebase_mode === 'EULAUNCHPAD' ||
    firebase_mode === 'USLIVE' ||
    firebase_mode === 'USSANDBOX' ||
    firebase_mode === 'USLAUNCHPAD' ||
    firebase_mode === 'DEMO'
  )
    return true;
  if (
    email.includes('@iwarranty.co') ||
    email.includes('@studiographene.com') ||
    email.includes('@sharklasers.com')
  )
    return true;
  else return false;
};
