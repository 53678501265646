import classNames from 'classnames';

// Key: value returned by firebase - value: string to display
export const FirebaseAuthProviderList = {
  email: { providerKey: 'password', label: 'email' },
  phone: { providerKey: 'phone', label: 'phone' },
  google: { providerKey: 'google.com', label: 'Google' },
  facebook: { providerKey: 'facebook.com', label: 'Facebook' },
  twitter: { providerKey: 'twitter.com', label: 'Twitter' },
  github: { providerKey: 'github.com', label: 'Github' },
  apple: { providerKey: 'apple.com', label: 'Apple' },
  yahoo: { providerKey: 'yahoo.com', label: 'Yahoo' },
  microsoft: { providerKey: 'hotmail.com', label: 'Microsoft' },
};

/* -------------------------------------------------------------------------- */
/*                                   Styles                                   */
/* -------------------------------------------------------------------------- */

// Note: styling references here:
// using Tailwind with React Select https://www.jussivirtanen.fi/writing/styling-react-select-with-tailwind)
// React Select styling documentation: https://react-select.com/styles

const selectInputStyles = {
  //Base
  input: 'focus:shadow-transparent m-[0px] py-[0px]',
  singleValue: 'text-gray-900',
  controlBase:
    '!bg-gray-50 font-nunito border-[1px] !rounded-lg !min-h-[46px] px-[2px] py-[0px] ![box-shadow:none]',
  controlEnabled: 'opacity-100',
  controlDisabled: 'opacity-60',
  controlFocused:
    'focus-within:!border-primary-900 focus-within:shadow-primary-900 hover:border-primary-900',
  menu: '!bg-white border-gray-300 rounded-lg font-nunito p-2',
  option:
    '!text-gray-900 font-nunito !bg-gray-50 focus-within:bg-primary-200 active:bg-primary-200 hover:!bg-primary-200 rounded overflow-hidden my-1 !cursor-pointer',
  placeholder: 'text-gray-500 mx-[2px] font-nunito truncate',
  noOptionsMessage: '!bg-gray-50',
  //Error styles
  controlError: '!border-red-500 !bg-red-50', // was #ef4444
};

export const generateSelectStyles = (hasError = false) => {
  return {
    input: () => selectInputStyles.input,
    singleValue: () => selectInputStyles.singleValue,
    control: ({
      isDisabled,
      isFocused,
    }: {
      isDisabled: boolean;
      isFocused: boolean;
    }) =>
      classNames(selectInputStyles.controlBase, {
        [selectInputStyles.controlEnabled]: !isDisabled,
        [selectInputStyles.controlDisabled]: isDisabled,
        [selectInputStyles.controlFocused]: isFocused,
        [selectInputStyles.controlError]: hasError,
      }),
    menu: () => selectInputStyles.menu,
    option: (state: { isSelected: boolean }) =>
      selectInputStyles.option +
      (state.isSelected ? ' !border border-primary-900' : ''),
    noOptionsMessage: () => selectInputStyles.noOptionsMessage,
    placeholder: () => selectInputStyles.placeholder,
  };
};
