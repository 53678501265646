import { useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Input,
  getUnixTimeFromDate,
} from '@rabbit/elements/shared-components';
import {
  CAR_FUEL_OPTIONS,
  DRIVE_TYPE_OPTIONS,
  OPTION_NO,
  OPTION_YES,
  VEHICLE_CATEGORY_OPTIONS,
  YES_NO_OPTIONS,
} from '@rabbit/bizproc/react';
import { DTWarranty, VehicleInfo } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

export interface EditWarrantyPurchaseDetailsProps {
  handleClose: () => void;
  warranty: DTWarranty;
  nextStep: (values: VehicleInfo) => void;
}

export function EditWarrantyPurchaseDetails({
  handleClose,
  warranty,
  nextStep,
}: EditWarrantyPurchaseDetailsProps) {
  const { t } = useTranslation();
  const formikRef = useRef(null) as any;

  const productInfo = warranty?.SRVSnapshot?.srvInfo
    ?.productInfo as unknown as VehicleInfo;

  const initialValues: any = {
    regDate: getUnixTimeFromDate(productInfo?.regDate),
    cherishedRegistrationNo: productInfo?.cherishedRegistrationNo ?? '',
    fuel: productInfo?.fuel ?? '',
    driveType: productInfo?.driveType ?? '',
    commercialVehicle: productInfo?.isCommercial
      ? OPTION_YES.value
      : OPTION_NO.value,
    vehicleCategory: productInfo?.vehicleCategory ?? '',
    techCheckDate: getUnixTimeFromDate(productInfo?.techCheckDate) ?? '',
    lastServiceDate: getUnixTimeFromDate(productInfo?.lastServiceDate) ?? '',
    mileage: productInfo?.mileage
      ? {
          value: productInfo?.mileage,
          unit: productInfo?.mileageUnit,
        }
      : {
          value: '',
          unit: 'km',
        },
  };

  const validationSchema = Yup.object().shape({
    regDate: Yup.mixed().required('Required'),
    cherishedRegistrationNo: Yup.string().trim(),
    fuel: Yup.string().required('Required'),
    driveType: Yup.string().required('Required'),
    commercialVehicle: Yup.string().required('Required'),
    vehicleCategory: Yup.string().required('Required'),
    techCheckDate: Yup.mixed(),
    lastServiceDate: Yup.mixed(),
    mileage: Yup.object().shape({
      value: Yup.number().required('Required'),
      unit: Yup.string().required('Required'),
    }),
  });

  const onSubmit = (values: any) => {
    const updatedProductInfo = {
      ...productInfo,
      regDate: values.regDate,
      fuel: values.fuel,
      driveType: values.driveType,
      isCommercial: values.commercialVehicle === OPTION_YES.value,
      vehicleCategory: values.vehicleCategory,
      techCheckDate: values.techCheckDate,
      lastServiceDate: values.lastServiceDate,
      mileage: values.mileage.value,
      mileageUnit: values.mileage.unit,
      registrationNo: productInfo?.registrationNo || '', // Ensure registrationNo is always a string
      cherishedRegistrationNo:
        values.cherishedRegistrationNo || productInfo?.cherishedRegistrationNo,
    };
    nextStep(updatedProductInfo);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {({ values, errors }) => (
        <Form>
          <div className="flex flex-col gap-4 ">
            <div className="grid grid-cols-2 gap-4 gap-x-6">
              <Input
                type="datepicker"
                name="regDate"
                label={t('general.registrationDate') + '*'}
                settings={{
                  id: 'regDate',
                  placeholder: 'DD/MM/YYYY',
                  maxDate: new Date(),
                }}
              />
              <Input
                type="select"
                name="fuel"
                label={t('general.fuel') + '*'}
                settings={{
                  id: 'fuel',
                  placeholder: 'Please select an option',
                  options: CAR_FUEL_OPTIONS,
                }}
              />
              <Input
                type="select"
                name="driveType"
                label={t('general.driveType') + '*'}
                settings={{
                  id: 'driveType',
                  placeholder: 'Please select drive type',
                  options: DRIVE_TYPE_OPTIONS,
                }}
              />
              <Input
                type="select"
                name="commercialVehicle"
                label={t('general.commercialVehicle') + '*'}
                settings={{
                  id: 'commercialVehicle',
                  placeholder: 'Please select commercial vehicle',
                  options: YES_NO_OPTIONS,
                }}
              />
              <Input
                type="select"
                name="vehicleCategory"
                label={t('general.vehicleCategory') + '*'}
                settings={{
                  id: 'vehicleCategory',
                  placeholder: 'Please select vehicle category',
                  options: VEHICLE_CATEGORY_OPTIONS,
                }}
              />
              <Input
                type="datepicker"
                name="lastServiceDate"
                label={t('Service date')}
                settings={{
                  id: 'MOTDateEpiration',
                  placeholder: 'Enter last service date',
                }}
              />
              <Input
                type="datepicker"
                name="techCheckDate"
                label={t('general.motDateExpiration')}
                settings={{
                  id: 'MOTDateEpiration',
                  placeholder: 'Enter MOT date expiration here',
                }}
              />
              <Input
                type="selectAddonText"
                name="mileage"
                label={t('general.carMileage') + '*'}
                settings={{
                  id: 'mileage',
                  addonOptions: [
                    { id: '0', label: 'km', value: 'km' },
                    { id: '1', label: 'mi', value: 'mi' },
                  ],
                  placeholder: 'Enter make car mileage',
                  type: 'number',
                }}
              />
              <Input
                type="text"
                name="cherishedRegistrationNo"
                label={t('general.cherishedRegistrationPlate')}
                settings={{
                  id: 'cherishedRegistrationNo',
                  placeholder: 'Enter cherished registration plate',
                }}
              />
            </div>
            <div className="mt-4 grid grid-cols-2 gap-y-4 gap-x-6">
              <Button kind="primary" type="submit" className="bg-primary-600">
                Next
              </Button>
              <Button kind="red" type="button" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EditWarrantyPurchaseDetails;
