import {
  formatAddress,
  formatPhoneNumber,
  formatUnixTime,
  getCurrencyFormat,
  LabelledItemList,
  LabelledItemShape,
} from '@rabbit/elements/shared-components';
import {
  Address,
  DTConsumer_Private,
  DTHoldingProxy,
  SELF_REGISTERED_VENDABLE,
} from '@rabbit/data/types';
import { calculateTotalSpent, useAppInfo } from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';
import {
  getFirstName,
  getLastName,
} from 'libs/bizproc/react/src/shared/helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerDetailsContentSectionProps {
  consumer_private: DTConsumer_Private;
  holdingsList: DTHoldingProxy[];
  favouriteStore?: string;
}

export function CustomerDetailsContentSection(
  props: CustomerDetailsContentSectionProps
) {
  const { t } = useTranslation();
  const { consumer_private, holdingsList, favouriteStore } = props || {};
  const appInfo = useAppInfo();

  const firstName =
    consumer_private.splitname?.first ??
    (consumer_private.fullname && consumer_private.fullname.indexOf('@') === -1)
      ? getFirstName(consumer_private.fullname)
      : '';

  const lastName =
    consumer_private.splitname?.last ??
    (consumer_private.fullname && consumer_private.fullname.indexOf('@') === -1)
      ? getLastName(consumer_private.fullname)
      : '';

  //if (!consumer_private || isLoading) return <LoadingSpinner size="xs" />;

  const mostRecentPurchaseDate = getMostRecentPurchaseDate(holdingsList);
  const carTenants =
    t('tenantLink') === 'WARRANTYIRELAND' ||
    t('tenantLink') === 'PINNACLEWARRANTIES' ||
    t('tenantLink') === 'NUCOVER';

  const totalSpent = carTenants ? 0 : calculateTotalSpent(holdingsList);
  const preferredMethodOfCommunication = !consumer_private
    ?.preferred_contact?.[0]
    ? '-'
    : consumer_private?.preferred_contact.join(', ');

  const numberOfPurchases = holdingsList
    ?.map((holding: DTHoldingProxy) => holding?.vendable)
    .filter((holding) => holding !== SELF_REGISTERED_VENDABLE).length;

  const items: LabelledItemShape[] = [
    {
      label: t('general.firstName'),
      value: `${firstName}`,
    },
    {
      label: t('general.lastName'),
      value: `${lastName}`,
    },
    {
      label: t('general.email'),
      value: consumer_private?.email,
    },
    {
      label: t('general.phoneNumber'),
      value: formatPhoneNumber(consumer_private?.phone),
    },
    {
      label: t('general.address'),
      value: formatAddress(
        (consumer_private?.address as Address[]).find((i) => i.isDefault) ||
          (consumer_private?.address || [])[0]
      ),
    },
  ];

  if (!carTenants) {
    items.push(
      ...[
        {
          label: t('general.numberOfPurchases'),
          value: `${numberOfPurchases}`,
        },
        {
          label: t('general.favouriteStore'),
          value: `${favouriteStore}`,
        },
        {
          label: t('general.mostRecentPurchaseDate'),
          value: `${mostRecentPurchaseDate}`,
        },
        {
          label: t('general.preferredMethodOfCommunication'),
          value: `${preferredMethodOfCommunication}`,
        },
        {
          label: t('general.totalSpent'),
          value: totalSpent,
        },
        {
          label: t('general.commercialUser'),
          value: `${consumer_private?.isCommercialUser ? 'Yes' : 'No'}`,
        },
      ]
    );
  }

  return (
    <div className="font-nunito">
      {consumer_private && <LabelledItemList items={items} />}
    </div>
  );
}

export default CustomerDetailsContentSection;

const getMostRecentPurchaseDate = (holdingsList: DTHoldingProxy[]) => {
  let mostRecentDate = 0;
  holdingsList.forEach((holding: DTHoldingProxy) => {
    if (holding?.purchase_time > mostRecentDate)
      mostRecentDate = holding?.purchase_time;
  });
  if (mostRecentDate <= 0) return '-';
  return formatUnixTime(mostRecentDate, 'dd/MM/yyyy');
};
