import {
  Z_Address,
  Z_DTTenant_Private,
  Z_DTTenant_Public,
  Z_PartnerSettings,
  Z_TenantLink,
} from '@rabbit/data/types';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { z } from 'zod';
import { ICF_Base } from '../base';

/* -------------------------------------------------------------------------- */
/*                                   Tenants                                  */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Partner tenants                              */
/* -------------------------------------------------------------------------- */

/* ---------------------------------- Fetch --------------------------------- */
export const ICF_GetPartnerTenantData = z
  .object({
    userTenant: Z_TenantLink,
    partnerTenant: Z_TenantLink,
  })
  .merge(ICF_Base);

export const OCF_GetPartnerTenantData = z.object({
  public: Z_DTTenant_Public,
  private: Z_DTTenant_Private,
});

/** Generates a Business User Invite document and returns the generated document and the invite link */
export const CFC_GetPartnerTenantData = MakeCloudFunctionSpec(
  'api/legacy/cfcgetpartnertenantdata',
  ICF_GetPartnerTenantData,
  OCF_GetPartnerTenantData
);

/* --------------------------------- Update --------------------------------- */

export const ICF_UpdatePartnerTenantData = z
  .object({
    userTenant: Z_TenantLink,
    partnerTenant: Z_TenantLink,
    formData: z.object({
      orgName: z.string(),
      // email: z.string(),
      phone: z.string(),
      addresses: z.array(Z_Address).optional(),
      settings: z.array(Z_PartnerSettings),
    }),
  })
  .merge(ICF_Base);

export const OCF_UpdatePartnerTenantData = z.object({
  public: Z_DTTenant_Public,
  private: Z_DTTenant_Private,
});

/** Generates a Business User Invite document and returns the generated document and the invite link */
export const CFC_UpdatePartnerTenantData = MakeCloudFunctionSpec(
  'api/legacy/cfcupdatepartnertenantdata',
  ICF_UpdatePartnerTenantData,
  OCF_UpdatePartnerTenantData
);
