import { VehicleInfo, Warranty, WarrantyStatus } from '@rabbit/data/types';

export interface BLIWarranty {
  getLatestWarranty: (
    warranties: Warranty[] | null | undefined
  ) => Warranty | undefined;
  getVehicleRegistrationNo: (productInfo: any) => string;
}

export const BL_Warranty: BLIWarranty = {
  getLatestWarranty,
  getVehicleRegistrationNo
};

function getLatestWarranty(
  warranties: Warranty[] | null | undefined
): Warranty | undefined {
  if (!warranties || warranties.length === 0) return undefined;

  const activeWarranties = warranties.filter(
    (warranty) => warranty.status === WarrantyStatus.ACTIVE
  );

  if (activeWarranties.length === 0) {
    const sortedWarranties = warranties.sort(
      (a, b) => (b.startDate ?? 0) - (a.startDate ?? 0)
    );
    return sortedWarranties[0];
  } else if (activeWarranties.length > 1) {
    const sortedWarranties = activeWarranties.sort(
      (a, b) => (b.startDate ?? 0) - (a.startDate ?? 0)
    );
    return sortedWarranties[0];
  }

  return activeWarranties[0];
}

function getVehicleRegistrationNo(productInfo: any): string {
  // strictly for vehicles
  if (productInfo.cherishedRegistrationNo?.trim()) {
    return productInfo.cherishedRegistrationNo.toUpperCase();
  }
  return productInfo.registrationNo.toUpperCase() || '-';
}