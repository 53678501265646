import { AppInfoShape, UserUploadedDocument } from '@rabbit/data/types';
import { CaseflowInterface } from '@rabbit/sage/context/CaseflowContext';
import { LogForms } from '../ClaimCostModal';
import * as Yup from 'yup';
import {
  Heading,
  Input,
  UploadFilesForm,
} from '@rabbit/elements/shared-components';
import { useFileStorage } from '@rabbit/bizproc/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CFCF_TravelCostLog } from '@rabbit/bizproc/core';
import { t } from 'i18next';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import { ConfigInterface } from '@rabbit/config/context';

const TravelForm = (
  appInfo: AppInfoShape,
  config: ConfigInterface['config'],
  {
    caseFacts,
    caseId,
    caseFlowCase,
    caseActors,
    alterCaseFacts,
  }: CaseflowInterface,
  personaId: string,
  option?: { editMode: boolean; index: number }
): LogForms<CFCF_TravelCostLog> => {
  return {
    name: 'travel',
    schema: Yup.object().shape({
      description: Yup.string().required(t('Please enter description')).trim(),
      date: Yup.string().trim(),
      amount: Yup.object({
        amount: Yup.string().required(t('Please enter amount')),
        currency: Yup.string().required(),
      }),
      VAT: Yup.string()
        .required(t(`Please enter ${config.ACCOUNT.TAX_LABEL}`))
        .trim(),
      documents: Yup.array(Yup.mixed()),
    }),
    initialValues: {
      amount: {
        currency: appInfo.currency,
      },
    },
    onDelete: async (index, onComplete) => {
      const updatedRegistry = caseFacts?.travel_cost_data || [];
      updatedRegistry.splice(index, 1);
      try {
        await alterCaseFacts({ travel_cost_data: [...updatedRegistry] });
        toast.success(t('Record deleted successfully.'));
      } catch (err) {
        console.log(err);
        toast.error(t('Something went wrong, please try again'));
      } finally {
        onComplete();
      }
    },
    onSubmit: async (values, onComplete) => {
      const record = values;
      record.date = new Date(values.date || 0).getTime();
      let updatedRegistry: any = [];
      if (option?.editMode) {
        updatedRegistry = caseFacts?.travel_cost_data || [];
        updatedRegistry[option.index] = record;
      } else {
        updatedRegistry = [...(caseFacts?.travel_cost_data || []), record];
      }
      try {
        await alterCaseFacts({
          travel_cost_data: [...updatedRegistry],
          ...(values.internal_comment && {
            internal_comment: {
              comment: values.internal_comment,
              author: personaId,
            },
          }),
        });
        toast.success(t('Claim updated successfully.'));
      } catch (err) {
        console.log(err);
        toast.error(t('Something went wrong, please try again'));
      } finally {
        onComplete();
      }
    },
    Form: ({ setFieldValue, values }) => {
      const {
        uploadFiles,
        uploadTaskCat,
        isUpdating,
        uploadProgress,
        deleteFile,
        deleteUnusedTempFiles,
        updateHoldingWithFiles,
        uploadedTempFiles,
        clearFileFromHolding,
        clearFileFromState,
        clearAllTempFilesFromState,
      } = useFileStorage();

      const [uploadedProofOfPostage, setUploadedProofOfPostage] = useState<
        UserUploadedDocument[]
      >(values.documents);
      const onProofOfPostageUpdated = async (
        files: UserUploadedDocument[],
        _added = true
      ) => {
        if (files && files.length && caseFlowCase) {
          clearAllTempFilesFromState();
          setUploadedProofOfPostage(files);
          await setFieldValue('documents', files);
        }
      };
      return (
        <div className="flex flex-col gap-4">
          <Input
            type="text"
            label={t('Description') + '*'}
            name="description"
            settings={{
              placeholder: t('Enter description here'),
            }}
          />
          <Input
            type="datepicker"
            label={t('Date')}
            name="date"
            settings={{
              placeholder: 'DD/MM/YYYY',
            }}
          />
          <div className="grid grid-cols-2 gap-4">
            <Input
              type="currency"
              label={t('Amount') + '*'}
              name="amount"
              settings={{
                disableCurrency: true,
                placeholder: t('Amount'),
              }}
            />
            <Input
              type="text"
              label={config.ACCOUNT.TAX_LABEL + '*'}
              name="VAT"
              settings={{
                placeholder: t('Amount'),
                append: '%',
              }}
            />
          </div>
          <UploadFilesForm
            label={t('Supporting documents')}
            type="case"
            docId={caseId ?? ''}
            personaId={caseActors?.consumer ?? ''}
            currentFiles={uploadedProofOfPostage ?? []}
            onFilesUploaded={onProofOfPostageUpdated}
            uploadHandlers={{
              uploadedTempFiles,
              category: UploadedFileCategories.Receipts,
              uploadFiles,
              uploadTaskCat,
              isUpdating,
              uploadProgress,
              deleteFile,
              updateHoldingWithFiles,
              clearFileFromHolding,
              clearFileFromState,
              deleteUnusedTempFiles,
            }}
          />
          <Heading kind="p" className="text-gray-500">
            *{t('required')}
          </Heading>
        </div>
      );
    },
  };
};

export default TravelForm;
