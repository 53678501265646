import { z } from 'zod';
import {
  FBD_Consumer_Public,
  Z_ConsumerInfo,
  FBD_HoldingProxy,
  DTHoldingProxy,
  Warranty,
  PersonaTypeFullKeyValues,
  PersonaTypeSingleLetter,
  PersonaIdTypeSplitter,
  TenantLink,
} from '@rabbit/data/types';

import { useEffect, useState } from 'react';

export interface RegistrationDataShape {
  consumer: z.infer<typeof Z_ConsumerInfo> | null;
  holding: DTHoldingProxy | null;
  warranties: Warranty[] | null;
}

async function getHoldingProxyDataByRegistrationNo(registrationNo: string, premiumTenantLink: string) {
  const registrationNoQuery = FBD_HoldingProxy.query()
    .where(
      'self_registration.srvInfo.productInfo.registrationNo',
      '==',
      registrationNo
    )
    .where(
      'mfr',
      '==',
      PersonaTypeSingleLetter.Manufacturer +
        PersonaIdTypeSplitter +
        premiumTenantLink
    );

  const cherishedNoQuery = FBD_HoldingProxy.query()
    .where(
      'self_registration.srvInfo.productInfo.cherishedRegistrationNo',
      '==',
      registrationNo
    )
    .where(
      'mfr',
      '==',
      PersonaTypeSingleLetter.Manufacturer +
        PersonaIdTypeSplitter +
        premiumTenantLink
    );

  const [results1, results2] = await Promise.all([
    registrationNoQuery.getContents(),
    cherishedNoQuery.getContents()
  ]);
  const holdingProxyData = [...results1, ...results2];
  return holdingProxyData;
}

export function useGetHoldingFromRegistration(
  registrationNo: string,
  premiumTenantLink: TenantLink
) {
  const [registrationDetails, setRegistrationData] =
    useState<RegistrationDataShape | null>(null);

  const getData = async (): Promise<RegistrationDataShape> => {
    const holding_proxy = await getHoldingProxyDataByRegistrationNo(registrationNo, premiumTenantLink);
    if (!holding_proxy?.[0]) {
      return {
        consumer: null,
        holding: null,
        warranties: null,
      };
      //throw new Error('holding_proxy not found');
    }

    // fetch consumer data of holding_proxy
    const consumerInfo = await FBD_Consumer_Public.get(
      holding_proxy?.[0]?.consumer
    );
    if (!consumerInfo) {
      throw new Error('Warranty customer data not found');
    }

    let consumer: z.infer<typeof Z_ConsumerInfo> = {
      firstName: consumerInfo.splitname?.first ?? '',
      lastName: consumerInfo.splitname?.last ?? '',
      consumerEmail: consumerInfo.email ?? '',
      phoneNumber: consumerInfo.phone ?? '',
    };
    if (consumerInfo.address) {
      consumer = {
        ...consumer,
        address: consumerInfo?.address[consumerInfo.address.length - 1],
      };
    }
    return {
      consumer: consumer ?? null,
      holding: holding_proxy[0] ?? null,
      warranties: holding_proxy[0].warranties ?? null,
    };
  };

  useEffect(() => {
    if (registrationNo) {
      (async () => {
        const data = await getData();
        setRegistrationData(data);
      })().catch((err) => {
        setRegistrationData(null);
        console.error(err);
      });
    }
  }, [registrationNo]);

  return registrationDetails;
}
