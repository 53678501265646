import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { DeciderPerformDecision } from '@rabbit/bizproc/core';
import { useSageAPI, useSendEmail } from '@rabbit/bizproc/react';
import {
  Button,
  formatUnixTime,
  getCurrencyFormat,
  Heading,
  Input,
} from '@rabbit/elements/shared-components';
import {
  Address,
  DeciderOutput,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  SRVInfo,
  SRVType,
  TableCover,
} from '@rabbit/data/types';
import { useTranslation } from '@rabbit/mixmaster/react';
import * as Yup from 'yup';
import {
  getConsumerURL,
  getWarranty,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ConsumerDetailsShape } from '../CreateNewConsumerDetails/CreateNewConsumerDetails';
import { CoverageOptions } from '../CreateNewCoverageOptions/CreateNewCoverageOptions';
import { format, formatDuration, getUnixTime } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { parse } from 'tinyduration';
import {
  BPIWarranty_WarrantyType,
  RegisterWarranty_ConsumerInfo,
} from '@rabbit/elements/shared-types';
import { Formik, Form } from 'formik';

interface CreateNewRegistrationStoneSummaryProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: ConsumerDetailsShape &
    CoverageOptions & {
      surfaceArea: number;
      typeOfStone: string;
      sealing: boolean;
      consumer_address: Address;
      warranty_price: string;
    };
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface StoneDeciderPriceOutputShape {
  sealingPrice: number;
  totalPrice: number;
  warrantyPrice: number;
  GST: number;
}

export interface CreateNewRegistrationSummaryShape {
  salesRep: string;
}

export const validationSchema = Yup.object().shape({
  salesRep: Yup.string(),
});

export function CreateNewRegistrationStoneSummary({
  handleClose,
  onChange,
  data,
  setIsLoading,
}: CreateNewRegistrationStoneSummaryProps) {
  const { registerSRVHoldingWithWarranty } = useSageAPI();
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const navigate = useNavigate();
  const { SE_Sage_StoneRegistration } = useSendEmail();
  const [loading, setLoading] = useState(false);
  const [deciderOutput, setDeciderOutput] = useState<DeciderOutput>();
  const prices: StoneDeciderPriceOutputShape =
    deciderOutput?.decided ?? ({} as any); // TODO: Fix any
  const stones: Record<string, any> = {
    marble: 'Marble',
    granite: 'Granite',
    'engineered-stone': 'Engineered Stone',
    limestone: 'Limestone',
    quartzite: 'Quartzite',
    porcelain: 'Porcelain',
    travertine: 'Travertine',
  };

  const initialValues: CreateNewRegistrationSummaryShape = {
    salesRep: '',
  };

  const currency = data.warranty_template.options.pricingCurrency;

  useEffect(() => {
    setLoading(true);
    setIsLoading(true);

    const stipulated = {
      duration: {
        label: formatDuration(parse(data.warranty_duration)),
        value: data.warranty_duration,
      },
      surfaceAreaValue: data.surfaceArea,
      surfaceAreaClass:
        data.surfaceArea > 15
          ? { label: '>= 15m2', value: 'B' }
          : { label: '< 15m2', value: 'A' },
      upsellSealing: data.sealing,
    };
    try {
      const decider = DeciderPerformDecision(
        stipulated,
        data.warranty_template,
        data.warranty_offer
      );

      setDeciderOutput(decider);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  }, [data.warranty_template, data.warranty_offer]);

  const handleSubmit = async (values: any) => {
    if (!deciderOutput) throw new Error('Decider output not found');
    // TODO: Do vendable/holding registration
    setLoading(true);
    setIsLoading(true);

    const productInfo: TableCover = {
      surfaceAreaValue: data.surfaceArea,
      surfaceAreaClass:
        data.surfaceArea > 15
          ? { label: '>= 15m2', value: 'B' }
          : { label: '< 15m2', value: 'A' },
      typeofStone: stones[data.typeOfStone],
      upsellSealing: data.sealing ?? false,
    };
    const holding: SRVInfo = {
      type: SRVType.Product,
      productInfo: productInfo,
    };
    const warranty: BPIWarranty_WarrantyType = {
      startDate: data.warranty_start_date
        ? new Date(data.warranty_start_date)
        : null,
      options: [{ key: 'duration', value: data.warranty_duration }],
      salesRep: values.salesRep,
    };
    const consumerInfo: RegisterWarranty_ConsumerInfo = {
      firstName: data.first_name,
      lastName: data.last_name,
      consumerEmail: data.consumer_email,
      phoneNumber: data.phone_number,
    };
    if (data.consumer_address) consumerInfo.address = data.consumer_address;

    let regWarrantyLink: string | undefined;
    let regHoldingLink: string | undefined;
    try {
      const { warrantyLink, holdingLink } =
        await registerSRVHoldingWithWarranty({
          warrantor: getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          ),
          holding,
          templateLink: data.warranty_template.docid,
          consumer: consumerInfo,
          warranty,
          deciderOutput,
          offerLink: data.warranty_offer?.docid,
        });
      regWarrantyLink = warrantyLink;
      regHoldingLink = holdingLink;
      if (regWarrantyLink) {
        const regWarranty = await getWarranty(regWarrantyLink);
        await SE_Sage_StoneRegistration(
          data.consumer_email,
          appInfo.email_sender,
          appInfo.name,
          appInfo.email_main_template,
          data.first_name,
          data.warranty_type ? data.warranty_template.title : '-',
          data.warranty_duration
            ? formatDuration(parse(data.warranty_duration))
            : '-',
          formatUnixTime(getUnixTime(new Date()), 'dd/MM/yyyy'),
          formatUnixTime(
            getUnixTime(new Date(regWarranty?.startDate || 0)),
            'dd/MM/yyyy'
          ),
          formatUnixTime(
            getUnixTime(new Date(regWarranty?.endDate || 0)),
            'dd/MM/yyyy'
          ),
          stones[data.typeOfStone],
          data.surfaceArea + 'm²',
          data.sealing ? 'Yes' : 'No',
          `${getConsumerURL()}`,
          appInfo.templateLanguage
        );

        toast.success(
          'Registration created successfully. Redirecting to detail page...'
        );
        setTimeout(() => {
          navigate(`${SAGE_ROUTE_NAME.REGISTRATIONS}/${regHoldingLink}`);
          handleClose();
        }, 5000);
      }
    } catch (e: any) {
      console.error(e);
      setIsLoading(false);
      setLoading(false);
      toast.error(e.message);
    }
  };

  const renderPrice = (label: string, value: string) => (
    <div className="font-nunito mt-1 flex justify-between text-gray-500">
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => (
        <Form className="flex flex-col gap-4 px-5 pt-4">
          {/* Warranty info */}
          <div>
            <Heading kind="h5" className="mb-2">
              Selected service plan
            </Heading>
            <div className="flex flex-col gap-4">
              <div
                className={
                  'font-nunito flex flex-col rounded-md border border-gray-300 p-4'
                }
              >
                <div className="flex justify-between">
                  <div className="font-bold">
                    {data.warranty_template.title}
                  </div>
                </div>
                <div className="mt-2 text-gray-700">
                  Start date:{' '}
                  {format(
                    new Date(data.warranty_start_date || Date.now()),
                    'dd/MM/yyyy'
                  )}
                </div>
                <div className="mt-2 text-gray-700">
                  Term: {formatDuration(parse(data.warranty_duration))}
                </div>
              </div>
            </div>
          </div>
          {data.sealing && (
            <div>
              <Heading kind="h5" className="mb-2">
                Extra services
              </Heading>
              <div className="font-nunito rounded-md border border-gray-300 p-4 font-bold">
                Sealing
              </div>
            </div>
          )}
          {prices && (
            <div>
              <Heading kind="h5" className="mb-2">
                Total price
              </Heading>
              <div className="font-nunito rounded-md border border-gray-300 p-4 font-bold">
                {renderPrice(
                  'Service plan',
                  getCurrencyFormat(prices.warrantyPrice, currency) + ' + GST'
                )}
                {data.sealing &&
                  renderPrice(
                    'Extra services: Sealing',
                    getCurrencyFormat(prices.sealingPrice, currency) + ' + GST'
                  )}
                <div className="mt-2 border-t border-gray-300 pt-2">
                  <div className="flex justify-between font-bold">
                    <span>Total</span>
                    <span>
                      {prices.totalPrice && prices.totalPrice > 0
                        ? getCurrencyFormat(prices.totalPrice, currency)
                        : getCurrencyFormat(
                            prices.sealingPrice + prices.warrantyPrice,
                            currency
                          )}{' '}
                      + GST
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            <Heading kind="h5" className="mb-2">
              Surface summary
            </Heading>
            <div className="flex flex-col gap-4">
              <div
                className={'font-nunito rounded-md border border-gray-300 p-4'}
                onClick={() => 0}
              >
                <div className="flex h-fit items-center justify-between">
                  <div>
                    <div className="font-bold text-black">
                      Stone type: {stones[data.typeOfStone]}
                    </div>
                    <div className="mt-1 text-gray-600">
                      Area: {data.surfaceArea} m²
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Heading kind="h5" className="mb-2">
              Customer details
            </Heading>
            <div className="font-nunito flex flex-col rounded-md border border-gray-300 p-4">
              <div className="font-bold text-black">
                {data.first_name} {data.last_name}
              </div>
              <Heading kind="p">{data.consumer_email}</Heading>
              {data.consumer_address && (
                <Heading kind="p">
                  {data.consumer_address?.postcode}{' '}
                  {data.consumer_address?.line1}, {data.consumer_address?.line2}{' '}
                  {data.consumer_address?.town} {data.consumer_address?.state}{' '}
                  {data.consumer_address?.country}
                </Heading>
              )}
              <Heading kind="p">+{data.phone_number}</Heading>
            </div>
          </div>
          <div>
            <Input
              type="text"
              name="salesRep"
              label="Salesperson name"
              settings={{
                id: 'salesRep',
                placeholder: 'Enter salesperson name here',
              }}
            />
          </div>
          <div className="sticky bottom-0 flex gap-4 bg-white pt-4">
            <Button
              kind="primary"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              Register service plan
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
