import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { OneEachOptionalPersonaLink, PersonaLink } from '../persona';
import { VRMProviderInfo, Z_VRMProviderInfo } from './external-service';
import { Address, Z_Address } from '../base/basic';
import { SRVType } from './holding-info';
import { z, schema } from '@rabbit/utils/ts';

/* -------------------------------------------------------------------------- */
/*                               Tenant subtypes                              */
/* -------------------------------------------------------------------------- */

export const TenantLinkFieldName = 'tenantLink';
export const PrincipalsFieldName = 'principals';
export type TenantLink = string;
export const Z_TenantLink = z.string();

/** The tenant's service class tier.
 * Currently just 'free' (for partner Tenants, who get their access from premium ones)
 * and 'premium' for tenants with a full-fat platform customization,
 * e.g. their own subdomain, custom flows, etc */
export enum TenantServiceClass {
  /** Free tier, limited features */
  Free = 'free',

  /** Paid tier, full features */
  Premium = 'premium',
}

export type TenantSRVConfiguration = {
  type: SRVType;
  parameters: { name: string; type: string }[];
};

export interface PartnerSettings {
  /** The premium tenant these settings apply to */
  partner: TenantLink;
  /** Labour rates for Pinnacle Warranties partners.
   * Values are in GBP/hour
   */
  labourRates?: {
    default: number | null;
    tier1?: number | null;
    tier2?: number | null;
    tier3?: number | null;
    currency: string;
    // maybe more in the future?
  };
}

export const Z_PartnerSettings_LabourRates = z.object({
  default: z.number().nullable(),
  tier1: z.number().nullable().optional(),
  tier2: z.number().nullable().optional(),
  tier3: z.number().nullable().optional(),
  currency: z.string(),
});

export const Z_PartnerSettings = z.object({
  partner: Z_TenantLink,
  labourRates: Z_PartnerSettings_LabourRates.optional(),
});
/* -------------------------------------------------------------------------- */
/*                        Main Tenant document schemas                        */
/* -------------------------------------------------------------------------- */

/* --------------------------------- Private -------------------------------- */
export interface DTTenant_Private extends NoSqlDoc {
  /** Principal Personas in the organisation, they can delegate access permissions */
  rootPersonas: OneEachOptionalPersonaLink;

  /** Identifier for the connected Shopify shop, which is an uppercased shop name. eg 'UNION-AUDIO-LIMITED-1405' */
  shopifyLink?: string;

  /** The tenant's service class tier. */
  serviceClass: TenantServiceClass;

  /** Settings specific to partner tenants */
  partnerSettings?: PartnerSettings[];

  // The VRM provider that this tenant uses
  VRMProvider?: VRMProviderInfo;
}

export const Z_DTTenant_Private = schema<DTTenant_Private>()(
  z
    .object({
      rootPersonas: z.record(z.string().nullable()),
      shopifyLink: z.string().optional(),
      serviceClass: z.nativeEnum(TenantServiceClass),
      partnerSettings: z.array(Z_PartnerSettings).optional(),
      VRMProvider: Z_VRMProviderInfo.optional(),
    })
    .merge(Z_NoSqlDoc)
);

export const FBD_Tenant_Private = MakeFBDocType<DTTenant_Private>({
  name: 'Tenant_Private',
  collection: 'tenant_private',
  empty: () => {
    const result: DTTenant_Private = {
      ...Empty_NoSqlDoc(),
      rootPersonas: {},
      serviceClass: TenantServiceClass.Free,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set manually, and should be the same as the related public document.'
  ),
});

/* --------------------------------- Public --------------------------------- */
export interface DTTenant_Public extends NoSqlDoc {
  // (M)ixmaster(C)onfiguration(K)eys, public information that can be updated by the tenant, it is used by mixmaster to build the lexicon
  mck?: Record<string, string>;

  principals?: PersonaLink[];

  /** The tenant's organization name. */
  orgName?: string;

  /** Other tenants that this tenant is a partner to */
  isPartnerTo?: TenantLink[];

  /** The tenant's main email address. */
  email?: string;

  /** The tenant's main phone number. */
  phone?: string;

  /** The tenant's main addresses. */
  addresses?: Address[];

  srvConfiguration?: TenantSRVConfiguration;
}

export const Z_DTTenant_Public = schema<DTTenant_Public>()(
  z.object({
    mck: z.record(z.string()).optional(),
    principals: z.array(z.string()).optional(),
    orgName: z.string().optional(),
    isPartnerTo: z.array(z.string()).optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    addresses: z.array(Z_Address).optional(),
    srvConfiguration: z
      .object({
        type: z.nativeEnum(SRVType),
        parameters: z.array(
          z.object({
            name: z.string(),
            type: z.string(),
          })
        ),
      })
      .optional(),
  })
).merge(Z_NoSqlDoc);

export const FBD_Tenant_Public = MakeFBDocType<DTTenant_Public>({
  name: 'Tenant_Public',
  collection: 'tenant_public',
  empty: () => {
    const result: DTTenant_Public = {
      ...Empty_NoSqlDoc(),
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});
