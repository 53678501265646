import React, { ReactNode } from 'react';
import type { CarouselOptions } from 'flowbite';
import classNames from 'classnames';
import { Button } from '../../atoms/buttons/Button/Button';

// Helpers
const dynamicStyles = {
  baseIndicator: 'w-3 h-3 rounded-full',
  inactiveIndicator: 'bg-gray-500 hover:bg-primary-400',
  activeIndicator: 'bg-primary-500',
};

export const carouselOptionsInit: CarouselOptions = {
  defaultPosition: 0,
  interval: 3000,
  indicators: {
    // These settings are changed on the main component file
    activeClasses: 'bg-gray-500',
    inactiveClasses: 'bg-gray-500',
    items: [],
  },

  // Callback functions. Leaving them here as a reference
  //   onNext: () => {
  //     console.log('next slider item is shown');
  //   },
  //   onPrev: () => {
  //     console.log('previous slider item is shown');
  //   },
  //   onChange: () => {
  //     console.log('new slider item has been shown');
  //   },
};

// Components
// Wrapper component
export interface ChildWrapperProps {
  children: ReactNode;
  num: number;
}
export const ChildWrapper = React.forwardRef<HTMLDivElement, ChildWrapperProps>(
  ({ children, num }, ref) => {
    return (
      <div
        id={`c-item-${num}`}
        ref={ref}
        className="hidden duration-200 ease-in-out"
      >
        {children}
      </div>
    );
  }
);

// Slide indicator component
export interface IndicatorProps {
  num: number;
  wasSeen: boolean;
  onClick: () => any;
}

export const Indicator = React.forwardRef<HTMLButtonElement, IndicatorProps>(
  ({ num, onClick, wasSeen }, ref) => {
    return (
      <button
        id={`c-indicator-${num}`}
        ref={ref}
        onClick={onClick}
        type="button"
        className={classNames(`${dynamicStyles.baseIndicator}`, {
          [`${dynamicStyles.inactiveIndicator}`]: !wasSeen,
          [`${dynamicStyles.activeIndicator}`]: wasSeen,
        })}
        aria-current
        aria-label={`Step ${num + 1}`}
      ></button>
    );
  }
);

// Controller Buttons
export interface ControllerButtonsProps {
  continueFn: () => void;
  skipFn: () => void;
}

export const ControllerButtons = ({
  continueFn,
  skipFn,
}: ControllerButtonsProps) => {
  return (
    <div className="flex flex-col gap-4">
      <Button kind="primary" onClick={continueFn}>
        Continue
      </Button>
      <p
        className="font-nunito cursor-pointer text-center text-sm hover:text-gray-500"
        onClick={skipFn}
      >
        Skip
      </p>
    </div>
  );
};
