import { Navigate, Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import Layout from '../components/templates/Layout/Layout';
import { LoadingSpinner } from '@rabbit/elements/shared-components';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { FileStorageProviderWrapper } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';

const PrivateRoutes = (props: any) => {
  const contextValues = useContext(UserContext);
  const { t } = useTranslation();

  const [cancelable, setCancelable] = useState(false);
  useEffect(function () {
    const timeout = setTimeout(function () {
      setCancelable(true);
    }, 7200);

    return function () {
      clearTimeout(timeout);
    };
  }, []);

  if (contextValues && !contextValues.initiated && !contextValues.user)
    return (
      <LoadingSpinner
        size="lg"
        isFullScreen
        label="Loading..."
        action={{
          actionLabel: 'Stuck? Click here to return to Login.',
          actionFn: () => {
            contextValues?.handleSignout();
          },
        }}
      />
    );

  if (
    contextValues !== null &&
    !contextValues.isLoading &&
    contextValues.user === null
  )
    return <Navigate to={SAGE_ROUTE_NAME.LOGIN} />;

  if (
    !contextValues?.user ||
    !contextValues?.accessToken ||
    (!contextValues?.accessToken?.admin &&
      !contextValues?.accessToken?.T?.includes(t('tenantLink')))
  ) {
    if (cancelable) {
      return (
        <LoadingSpinner
          size="lg"
          isFullScreen
          label="Loading..."
          action={{
            actionLabel: 'Stuck? Click here to return to Login.',
            actionFn: () => {
              contextValues?.handleSignout();
            },
          }}
        />
      );
    }
    return <LoadingSpinner size="lg" isFullScreen label="Loading..." />;
  }

  if (location.pathname.includes('/print')) return <Outlet />;

  return (
    <FileStorageProviderWrapper>
      <Layout>
        <Outlet />
      </Layout>
    </FileStorageProviderWrapper>
  );
};

export default PrivateRoutes;
