import { CaseFlowConfiguration } from '@rabbit/data/types';
import i18next from 'i18next';

/* ---------------------------------- Links --------------------------------- */
export const CaseFlowConfig_Facts_DocLinks: CaseFlowConfiguration['facts'] = {
  consumer_holding: {
    type: 'DocLink', //HoldingLink?
    label: i18next.t('general.productWithIssue'),
  },
  consumer_persona_id: {
    type: 'ConsumerLink',
    label: i18next.t('general.consumerPersona'),
  },
  holding_vendable_id: {
    type: 'DocLink', // VendableLink?
    label: i18next.t('general.vendableIdForConsumerHolding'),
  },
  warranty_link: {
    type: 'DocLink',
    label: i18next.t('general.warrantyIdForConsumerHolding'),
  },
};

/* -------------------------------- Comments -------------------------------- */
export const CaseFlowConfig_Facts_Comments: CaseFlowConfiguration['facts'] = {
  internal_comment: {
    type: 'CFCF_InternalComment',
    label: i18next.t('general.internalComment'),
  },
  comment_to_customer: {
    type: 'String',
    label: i18next.t('general.commentToCustomer'),
  },
  assessment_comment: {
    type: 'String',
    label: i18next.t('general.claimAssessmentComment'),
  },
  internal_case_files: {
    type: 'InternalCaseFile[]', // todo document
    label: i18next.t('general.internalCaseFiles'),
  },
};

/* ------------------------------ Issue details ----------------------------- */
export const CaseFlowConfig_Facts_IssueDetails: CaseFlowConfiguration['facts'] =
  {
    consumer_issue_type: {
      type: 'String', // TODO: have as enum of 'defect' | water | accidental | other
      label: i18next.t('general.issueType'),
    },
    consumer_issue_type_ref: {
      type: 'String',
      label: i18next.t('general.issueTypeReference'),
    },
    consumer_issue_description: {
      type: 'String',
      label: i18next.t('general.issueDescription'),
    },
    // TODO: name isn't clear enough here. Maybe change to faults_identified one day
    holding_faults: {
      type: 'string[]',
      label: i18next.t('general.faultsIdentifiedOnHolding'),
    },
  };

/* --------------------------- Uploaded file data --------------------------- */
export const CaseFlowConfig_Facts_UploadedFiles: CaseFlowConfiguration['facts'] =
  {
    /* ------------------------- Main file repositories ------------------------- */
    // These have a list of all the files of a given type uploaded for a case
    consumer_claim_evidence: {
      type: 'UserUploadedDocument',
      label: i18next.t('general.claimEvidence'),
    },
    consumer_proof_of_purchase: {
      type: 'UserUploadedDocument',
      label: i18next.t('general.proofOfPurchase'),
    },
    /* --------------------------- File update events --------------------------- */
    // Whenever we upload or delete one or more files, we should record it here so the activity log can display it properly
    file_update_event: {
      type: 'CFCF_FileUpdateEvent[]',
      label: i18next.t('general.fileUpdateEvent'),
    },
    assessment_attachment: {
      type: 'UserUploadedDocument[]',
      label: i18next.t('general.assessmentAttachment'),
    },
    assessment_parts: {
      type: 'AssessmentPart[]',
      label: i18next.t('general.assessmentParts'),
    },
  };

/* ------------------------------ Delegate info ----------------------------- */
export const CaseFlowConfig_Facts_Delegates: CaseFlowConfiguration['facts'] = {
  delegate_repairer_name: {
    type: 'String',
    label: i18next.t('general.delegateRepairerName'),
  },
  delegate_repairer_id: {
    type: 'String',
    label: i18next.t('general.delegateRepairerId'),
  },
};

/* ------------------------------ Holding data ------------------------------ */
export const CaseFlowConfig_Facts_Holding: CaseFlowConfiguration['facts'] = {
  vendable_version_number: {
    type: 'Number',
    label: i18next.t('general.vendableVersionNumber'),
  },
  consumer_holding_category: {
    type: 'String',
    label: i18next.t('general.holdingCategory'),
  },
  consumer_holding_name: {
    type: 'String',
    label: i18next.t('general.productName'),
  },
  consumer_holding_image: {
    type: 'String',
    label: i18next.t('general.holdingImageUrl'),
  },
  consumer_holding_serial_number: {
    type: 'String',
    label: i18next.t('general.serialNumber'),
  },
  consumer_holding_purchase_country: {
    type: 'String',
    label: i18next.t('general.purchaseCountry'),
  },
  purchase_date: {
    type: 'Date',
    label: i18next.t('general.dateOfProductPurchase'),
  },
  consumer_holding_purchase_location: {
    type: 'RetailerLink',
    label: i18next.t('general.purchaseLocation'),
  },
  consumer_holding_purchase_location_other: {
    type: 'String', // TODO: Define type - is it a retailer reference???
    label: i18next.t('general.purchaseLocationOther'),
  },
  consumer_holding_purchase_price: {
    type: 'Money',
    label: i18next.t('general.purchasePrice'),
  },
  holding_warranty_term: {
    type: 'WarrantyDuration',
    label: i18next.t('general.lengthOfHoldingWarranty'),
  },
};

/* ------------------------------ Consumer data ----------------------------- */
export const CaseFlowConfig_Facts_Consumer: CaseFlowConfiguration['facts'] = {
  consumer_name: {
    type: 'String',
    label: i18next.t('general.customerName'),
  },

  consumer_email: {
    type: 'String',
    label: i18next.t('general.customerEmailAddress'),
  },

  consumer_telephone: {
    type: 'String',
    label: i18next.t('general.customerTelephoneNumber'),
  },
  consumer_preferred_contact: {
    type: 'String', // TODO: ENUM TYPE?
    label: i18next.t('general.customerPreferredContactMethod'),
  },
  outbound_address: {
    type: 'Address',
    label: i18next.t('general.consumerAddress'),
  },
};

export const CaseFlowConfig_Facts_CaseStatus: CaseFlowConfiguration['facts'] = {
  preliminary_assessment: {
    type: 'String',
    label: i18next.t('general.preliminaryWarrantyAssessment'),
  },

  final_assessment: {
    type: 'String',
    label: i18next.t('general.finalWarrantyAssessment'),
  },

  claim_outcome: {
    type: 'String',
    label: i18next.t('general.claimOutcome'),
  },

  claim_cause: {
    type: 'String',
    label: i18next.t('general.claimCause'),
  },

  goodwill_override_initial: {
    type: 'Boolean',
    label: i18next.t('general.goodwillOverride'),
  },

  goodwill_warranty_initial: {
    type: 'String',
    label: i18next.t('general.goodwillWarranty'),
  },

  goodwill_override_final: {
    type: 'Boolean',
    label: i18next.t('general.goodwillOverride'),
  },

  goodwill_warranty_final: {
    type: 'String',
    label: i18next.t('general.goodwillWarranty'),
  },

  consumer_marked_item_sent: {
    type: 'Boolean',
    label: i18next.t('general.consumerMarkedItemAsSent'),
  },
};

/* ------------------------------ Partner data ------------------------------ */
export const CaseFlowConfig_Facts_PartnerData: CaseFlowConfiguration['facts'] =
  {
    consumer_holding_purchased_from_partner: {
      type: 'CFCF_PartnerTenantData',
      label: i18next.t('general.partnerTheHoldingWasPurchasedFrom'),
    },
  };

/* ------------------------------ Cost logging ------------------------------ */
// used for claims-lite and Fatbikes
export const CaseFlowConfig_Facts_CostLogging: CaseFlowConfiguration['facts'] =
  {
    administrative_cost_data: {
      type: 'CFCF_AdministrativeCostLog',
      label: i18next.t('general.administrativeTimeCost'),
    },
    parts_used_cost_data: {
      type: 'CFCF_PartsUsed',
      label: i18next.t('general.partsUsedCost'),
    },
    repair_time_data: {
      type: 'CFCF_RepairTimeLog',
      label: i18next.t('general.repairTimeCost'),
    },
    shipping_cost_data: {
      type: 'CFCF_ShippingCostLog',
      label: i18next.t('general.shippingCost'),
    },
    travel_cost_data: {
      type: 'CFCF_TravelCostLog',
      label: i18next.t('general.travelCost'),
    },
    other_cost_data: {
      type: 'CFCF_OtherCostLog',
      label: i18next.t('general.otherCost'),
    },
  };
