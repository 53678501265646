import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { Z_Address } from '@rabbit/data/types';

const IFC_GetTenantLinkForShop = z.object({
  shopLink: z.string(),
  apiKey: z.string(),
  tenantLink: z.string(),
  environment: z.string(),
});

const OFC_GetTenantLinkForShop = z.object({
  tenantLink: z.string(),
  sageUrl: z.string(),
  oliveUrl: z.string(),
});

export const CFC_GetTenantLinkForShop = MakeCloudFunctionSpec(
  'api/legacy/cfcgettenantlinkforshop',
  IFC_GetTenantLinkForShop,
  OFC_GetTenantLinkForShop
);

const ICF_ISE_HandleFulfilledOrder = z.object({
  email: z.string(),
  name: z.string(),
  line_items: z.array(
    z.object({
      price: z.string(),
      sku: z.string().nullable(),
      id: z.number(),
      fulfillment_status: z.string().nullable(),
      name: z.string(),
      variant_id: z.number(),
      quantity: z.number(),
    })
  ),
  apiKey: z.string(),
  purchase_time: z.number(),
  purchase_country: z.string(),
  shopify_order_id: z.number(),
  shopify_customer_id: z.number(),
  shopify_shop_id: z.number(),
  targetUrlBase: z.string(),
  tenantLink: z.string(),
  currency: z.string(),
  environment: z.string(),
  // TODO: Proper type is below, but using it blocks the function from running properly when called. Investigate why.
  address: z.any().optional(),
  // address: Z_Address.optional(),
  phoneNo: z.string().optional().nullable(),
});

const OCF_ISE_HandleFulfilledOrder = z.object({
  message: z.string(),
});

export const CFC_ISE_HandleFulfilledOrder = MakeCloudFunctionSpec(
  'api/legacy/cfcisehandlefulfilledorder',
  ICF_ISE_HandleFulfilledOrder,
  OCF_ISE_HandleFulfilledOrder
);
