import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { TenantLink } from './tenant';
import { PersonaLink } from '../persona';

/* -------------------------------------------------------------------------- */
/*                                   Faults                                   */
/* -------------------------------------------------------------------------- */

/** A type of issue or defect with a given product, grouped into fault categories  */
export interface DTFault extends NoSqlDoc {
  /** The name of the fault */
  title: string;

  /** A description of the fault */
  description?: string;

  /** An array of fault category ids */
  category: string[];

  /** A link to the tenant that owns this fault */
  tenantLink?: TenantLink;

  /** A list of principals that have access to this fault */
  principals?: PersonaLink[];
}

export const FBD_Fault = MakeFBDocType<DTFault>({
  name: 'Fault',
  collection: 'fault',
  empty: () => {
    const result: DTFault = {
      ...Empty_NoSqlDoc(),
      title: '',
      category: [],
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});
