import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import {
  Button,
  Heading,
  Input,
  LoadingSpinner,
  getAgeFromDate,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import {
  PrincipalsFieldName,
  PersonaTypeSingleLetter,
  DTWarranty_Template,
  DeciderOutput,
  DTWarranty_Offer,
  DeciderApprovedOptionInfo,
  SingleApprovedOptionInfo,
  SingleApprovedOptionInfoPairs,
  DTOptionsSingleOption,
  MilesToKilometersRatio,
} from '@rabbit/data/types';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarRegistrationShape } from '../../ModalNewRegistration/ModalNewRegistration';
import Select from 'react-select';
import { generateSelectStyles } from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import { DeciderPerformDecision } from '@rabbit/bizproc/core';
import { useSageAPI } from '@rabbit/bizproc/react';
import {
  RegistrationsWarrantyOptionShape,
} from '@rabbit/elements/shared-types';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { toTitleCase, useAppInfo } from '@rabbit/sage/utils/helpers';
export interface CreateNewCoverageOptionsProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape & {
    mileage: string;
    mileageUnit: string;
  };
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export interface CoverageOptions {
  warranty_type: string;
  warranty_duration: any;
  warranty_claim_limit: any;
  warranty_template: DTWarranty_Template;
  warranty_start_date?: string;
  warranty_offer?: DTWarranty_Offer;
}

const optionDescription = (offer: RegistrationsWarrantyOptionShape) => {
  // Find the 'Duration' option from the approvedOptions array
  const durationOptions = offer.approvedOptions.find(
    (option) => option.label === 'Duration'
  );

  if (durationOptions && durationOptions.optionInfo) {
    // Extract allowed durations from the optionInfo
    const allowedDurations = durationOptions.optionInfo.map(
      (info) => info.option
    );

    return allowedDurations
      .map((option, index) => {
        // Handle different cases based on the position in the array
        if (index === allowedDurations.length - 1) {
          // Last item
          return allowedDurations.length > 1
            ? `or ${option.label}`
            : `${option.label}`;
        } else if (index === allowedDurations.length - 2) {
          // Second to last item
          return `${option.label?.split(' ')[0]} `;
        } else {
          // All other items
          return `${option.label?.split(' ')[0]}, `;
        }
      })
      .join(''); // Join all parts into a single string
  } else {
    // Return empty string if no duration options found
    return '';
  }
};

const validationSchema = Yup.object().shape({
  warranty_start_date: Yup.string().nullable(),
});

export function CreateNewCoverageOptions({
  handleClose,
  onChange,
  data,
  setIsLoading,
}: CreateNewCoverageOptionsProps) {
  const { getApprovedWarrantyOffers, isReady } = useSageAPI();
  const [selectedOption, setSelectedOption] = useState<CoverageOptions>();
  const [activeOption, setActiveOption] = useState<string>();
  const [deciderOutput, setDeciderOutput] = useState<DeciderOutput>();
  const [offers, setOffers] = useState<RegistrationsWarrantyOptionShape[]>();
  const { warrantyDealerPersona, warrantorPersona } = useGetMySagePersonas();
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const showVAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_VAT') === "true";
  const activeTenantId = warrantyDealerPersona?.warrantyDealer_private?.tenantLink || 
    warrantorPersona?.warrantor_private?.tenantLink;
  const handleOptionSelected = (
    template: DTWarranty_Template,
    duration: any | undefined,
    claimLimit: any | undefined,
    offer: DTWarranty_Offer
  ) => {
    if (!duration || !claimLimit) {
      setSelectedOption(undefined);
      setDeciderOutput(undefined);
      return;
    };

    
    const finalDuration = {
      label: duration.label
        ?.split(' ')
        .map((i: any) => toTitleCase(i))
        .join(' '),
      value: duration.value,
    };

    const convertedMileage =
      data.mileageUnit && data.mileageUnit === 'mi'
        ? Number(data.mileage) * MilesToKilometersRatio
        : data.mileage;
    const finalClaimLimit = {
      label: claimLimit.label,
      value: claimLimit.value,
    };
    
    const stipulated = {
      age: getAgeFromDate(data.regDate),
      mileage: convertedMileage,
      duration: finalDuration,
      claimLimit: finalClaimLimit
    };
  
    const output = DeciderPerformDecision(stipulated, template, offer);
    if (output) {
      setDeciderOutput(output);
    }
    setSelectedOption({
      warranty_duration: duration,
      warranty_claim_limit: claimLimit,
      warranty_type: template.docid,
      warranty_template: template,
      warranty_offer: offer,
    });
  };

  useEffect(() => {
    if (isReady) {
      void (async () => {
        if (!activeTenantId) return;
        const convertedMileage =
          data.mileageUnit && data.mileageUnit === 'mi'
            ? Number(data.mileage) * 1.609344
            : data.mileage;

        try {
          const { offers, labourRates } = await getApprovedWarrantyOffers(
            {
              params: {
                age: getAgeFromDate(data.regDate),
                mileage: Number(convertedMileage),
              },
              dimensions: ['claimLimit', 'duration'],
              partnerTenantLink: activeTenantId,
              warrantorLink: getRootPersonaFromLexicon(
                t(PrincipalsFieldName),
                PersonaTypeSingleLetter.Warrantor
              ),
            }
          );
          setOffers(offers);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [activeTenantId, isReady]);

  const handleSubmit = (values: any) => {
    onChange(4, { ...selectedOption, ...values });
  };

  if (!offers || !activeTenantId)
    return (
      <div className="py-10">
        <LoadingSpinner size="md" />
      </div>
    );
  
  return (
    <div className="px-5 pt-4">
      {offers && offers.length === 0 ? (
        <>
          <div className="font-nunito mb-4 flex flex-col gap-2 rounded-md bg-red-100 p-4 text-red-700">
            <span className="flex items-center gap-2 font-semibold">
              <ExclamationTriangleIcon className="h-4 w-4 text-red-700" />
              Car does not qualify
            </span>
            <div className="text-red-700">
              This car does not meet the minimum requirements for any of the
              available options.
            </div>
          </div>
          <div className="mt-4 flex w-full gap-4">
            <Button kind="primary" type="submit" onClick={handleClose}>
              Ok
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <Heading kind="h4" className="mb-2">
            Available options
          </Heading>
          <Formik
            initialValues={{
              warranty_start_date: (data as any).warranty_start_date,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {(props) => (
              <Form>
                <div className="flex flex-col gap-4">
                  {offers?.map((offer) => (
                    <>
                      <SelectableOption
                        key={offer.template.docid}
                        title={offer.offer?.title ?? offer.title}
                        description={optionDescription(offer)}
                        selected={
                          selectedOption?.warranty_type === offer.template.docid
                        }
                        active={activeOption === offer.template.docid}
                        name={offer.template.docid}
                        template={offer.template}
                        offer={offer.offer ?? ({} as DTWarranty_Offer)}
                        approvedOptions={offer.approvedOptions}
                        onChange={handleOptionSelected}
                        onOpen={(active) => {
                          setActiveOption(offer.template.docid);
                          active && setSelectedOption(undefined);
                        }}
                      />
                      {/* <Input type="text" label="Claim limit" name="warranty_claim_limit"  settings={{}} /> */}
                    </>
                  ))}
                  <Input
                    type="datepicker"
                    name="warranty_start_date"
                    label="Warranty start date"
                    settings={{
                      id: 'warranty_start_date',
                      placeholder: 'DD/MM/YYYY',
                    }}
                  />
                </div>
                {deciderOutput && (
                  <div className="font-nunito my-2 flex text-lg font-bold text-black">
                    Total:{' '}
                    {deciderOutput.decided.approval
                      ? getCurrencyFormat(
                          (deciderOutput.decided.warrantyPrice as number),
                          appInfo.currency
                        ) + (showVAT ? 
                        ` (${getCurrencyFormat(
                          Number(deciderOutput.decided.warrantyPrice) +
                            Number(deciderOutput.decided.VAT),
                          appInfo.currency
                        )} incl. VAT)` : '')
                      : '-'}
                  </div>
                )}
                <div className="sticky bottom-0 flex w-full gap-4 bg-white pt-4">
                  <Button
                    kind="primary"
                    type="submit"
                    disabled={!selectedOption}
                  >
                    Continue
                  </Button>
                  <Button kind="red" type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

function SelectableOption({
  title,
  description,
  selected: picked,
  active,
  name,
  template,
  offer,
  approvedOptions,
  onChange,
  onOpen,
}: {
  title: string;
  description: string;
  selected: boolean;
  active: boolean;
  name: string;
  template: DTWarranty_Template;
  offer: DTWarranty_Offer;
  approvedOptions: DeciderApprovedOptionInfo[];
  onChange: (
    template: DTWarranty_Template,
    term: string | undefined,
    claimLimit: string | undefined,
    offer: DTWarranty_Offer
  ) => void;
  onOpen: (open: boolean) => void;
}) {
  const claimLimitRef = useRef(null) as any;
  const durationOptions = approvedOptions.find((i: any) => i.label === 'Duration');
  const { t } = useTranslation();
  const tenantType = t('tenantLink');

  const [selectedTerm, setSelectedTerm] = useState<any | undefined>();
  const [selectedClaimLimit, setSelectedClaimLimit] = useState<any | undefined>();

  const options = durationOptions?.optionInfo?.map(
    (obj: SingleApprovedOptionInfo, index: number) => ({
      id: index + '',
      label: obj.option.label,
      value: obj.option.value,
    })
  );
  
  const selectedOptionBasedOnDuration = durationOptions?.optionInfo?.find(
    (i: SingleApprovedOptionInfo) => i.option.value === selectedTerm?.value
  );
  const selectedDuration = selectedOptionBasedOnDuration?.option.value;

  const claimLimit = selectedOptionBasedOnDuration
    ? selectedOptionBasedOnDuration.availablePairs.find(
        (i: SingleApprovedOptionInfoPairs) => i.label === 'Claim limit'
      )
    : null;
  
  const claimLimitOptions =
  claimLimit?.indices?.map(
    (indice: DTOptionsSingleOption, index: number) => ({
      id: index + '',
      label: indice.label,
      value: indice.value,
    })
  ) ?? [];

  const handleOpen = () => {
    active === false && onOpen(!active);
    onChange(
      template,
      selectedTerm,
      selectedClaimLimit,
      offer
    );
  };
  useEffect(() => {
    onChange(
      template,
      selectedTerm,
      selectedClaimLimit,
      offer
    );
    onOpen(!active);
  }, [selectedTerm, selectedClaimLimit]);

  return (
    <div
      key={name}
      className={
        'font-nunito hover:border-primary-900 w-full cursor-pointer rounded-md border border-gray-300 px-4 pt-4' +
        (active ? ' border-primary-600' : ' overflow-hidden')
      }
      onClick={handleOpen}
    >
      <div className="font-medium">{title}</div>
      <div className="mt-[10px] text-gray-700">{description}</div>
      <div
        className={
          'mt-4 transition-all duration-200' +
          (active ? ' h-[96px]' : ' h-[0px]')
        }
      >
        <div className="grid grid-cols-3 gap-4 pb-4">
          <div className="flex flex-col">
            <div className="font-nunito mb-2 text-base text-gray-900 ">
              Term*
            </div>
            <Select
              classNames={generateSelectStyles()}
              options={options}
              value={options?.find(
                (i: DTOptionsSingleOption) => i.value === selectedTerm?.value
              )}
              onChange={(v) => {
                setSelectedTerm(v);
                claimLimitRef?.current?.clearValue();
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="font-nunito mb-2 text-base text-gray-900 ">
              Claim limit*
            </div>
            <Select
              ref={claimLimitRef}
              classNames={generateSelectStyles()}
              options={claimLimitOptions}
              value={claimLimitOptions.find(
                (i: DTOptionsSingleOption) =>
                  i.value === selectedClaimLimit?.value
              )}
              onChange={(v) => setSelectedClaimLimit(v)}
              isDisabled={claimLimitOptions.length === 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewCoverageOptions;
