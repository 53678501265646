import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';

export type QueryDataDimension = {
  key: string;
  title: string;
  length: number;
  labels: string[];
};

export type TableOfQueryData = {
  dimensions: QueryDataDimension[];
  data: any[];
};

// I am not pretending to design what the best query parameters are going to be
const ICF_BigQueryTable = z.object({
  report: z.string(),
});

const OCF_BigQueryTable = z.object({
  table: z.any(), // This will be TableOfQueryData but this is POC so I skip zod, sorry
});

export const CFC_BigQueryTable = MakeCloudFunctionSpec(
  'cfcbigquerytable',
  ICF_BigQueryTable,
  OCF_BigQueryTable
);
