export function TrashIcon({
  className,
  onClick,
}: {
  className?: string;
  onClick: (any: any) => any;
}) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8 0.400024C10.4844 0.400193 10.175 0.488226 9.90658 0.654263C9.63816 0.820301 9.42126 1.05779 9.28018 1.34012L8.04938 3.80002H2.29998C1.84911 3.80002 1.41671 3.97913 1.09789 4.29794C0.779082 4.61676 0.599976 5.04916 0.599976 5.50002C0.599976 5.95089 0.779082 6.38329 1.09789 6.70211C1.41671 7.02092 1.84911 7.20002 2.29998 7.20002V24.2C2.29998 25.1018 2.65819 25.9666 3.29581 26.6042C3.93344 27.2418 4.79824 27.6 5.69998 27.6H19.3C20.2017 27.6 21.0665 27.2418 21.7041 26.6042C22.3418 25.9666 22.7 25.1018 22.7 24.2V7.20002C23.1508 7.20002 23.5832 7.02092 23.9021 6.70211C24.2209 6.38329 24.4 5.95089 24.4 5.50002C24.4 5.04916 24.2209 4.61676 23.9021 4.29794C23.5832 3.97913 23.1508 3.80002 22.7 3.80002H16.9506L15.7198 1.34012C15.5787 1.05779 15.3618 0.820301 15.0934 0.654263C14.8249 0.488226 14.5156 0.400193 14.2 0.400024H10.8ZM7.39998 10.6C7.39998 10.1492 7.57908 9.71675 7.89789 9.39794C8.21671 9.07913 8.64911 8.90002 9.09998 8.90002C9.55084 8.90002 9.98324 9.07913 10.3021 9.39794C10.6209 9.71675 10.8 10.1492 10.8 10.6V20.8C10.8 21.2509 10.6209 21.6833 10.3021 22.0021C9.98324 22.3209 9.55084 22.5 9.09998 22.5C8.64911 22.5 8.21671 22.3209 7.89789 22.0021C7.57908 21.6833 7.39998 21.2509 7.39998 20.8V10.6ZM15.9 8.90002C15.4491 8.90002 15.0167 9.07913 14.6979 9.39794C14.3791 9.71675 14.2 10.1492 14.2 10.6V20.8C14.2 21.2509 14.3791 21.6833 14.6979 22.0021C15.0167 22.3209 15.4491 22.5 15.9 22.5C16.3508 22.5 16.7832 22.3209 17.1021 22.0021C17.4209 21.6833 17.6 21.2509 17.6 20.8V10.6C17.6 10.1492 17.4209 9.71675 17.1021 9.39794C16.7832 9.07913 16.3508 8.90002 15.9 8.90002Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default TrashIcon;
