import { CaseFlowConfiguration } from '@rabbit/data/types';
import { CaseFlowConfigurationRegistry } from './configuration';

// Test configurations need to be in the same library as the registry to work, apparently. This is a bit of a pain, but it's what it is. -DC

/* -------------------------------------------------------------------------- */
/*                         Absolute basic repair case                         */
/* -------------------------------------------------------------------------- */

export const AbsoluteBasicRepair: CaseFlowConfiguration = {
  actors: {
    consumer: {
      tech_description: 'holder of broken item',
    },
    repairer: {
      tech_description: 'fixer of broken item',
    },
  },
  facts: {
    consumer_holding: {
      type: 'DocLink',
      label: 'Product with issue',
    },
    consumer_holding_name: {
      type: 'String',
      label: 'Product',
    },

    consumer_problem: {
      type: 'String',
      label: 'Customer description of problem',
    },

    consumer_address: {
      type: 'String',
      label: 'Customer Address',
    },
  },
  stations: {
    _birth: {
      actions: {},
    },
  },
};

CaseFlowConfigurationRegistry.Register(
  AbsoluteBasicRepair,
  'absolute_basic_repair'
);

/* -------------------------------------------------------------------------- */
/*                             Repair with a Proxy                            */
/* -------------------------------------------------------------------------- */

export const RepairWithProxy: CaseFlowConfiguration = {
  ...AbsoluteBasicRepair,
  actors: {
    ...AbsoluteBasicRepair.actors,
    repairer: {
      ...AbsoluteBasicRepair.actors['repairer'],
      proxy: {
        facts: [
          'consumer_problem', // Used for unit test
          'consumer_holding_name',
        ],
      },
    },
  },
};

CaseFlowConfigurationRegistry.Register(
  RepairWithProxy,
  'test_repair_with_proxy'
);

/* -------------------------------------------------------------------------- */
/*                          Repair with two stations                          */
/* -------------------------------------------------------------------------- */

export const RepairWithTwoStates: CaseFlowConfiguration = {
  ...AbsoluteBasicRepair,
  stations: {
    _birth: {
      actions: {
        GoToDeath: {
          label: 'Go to death',
          steps: [
            {
              type: 'go_station',
              station: '_death',
            },
          ],
          available_to: ['repairer', 'consumer'],
        },
      },
    },
    _death: {
      actions: {
        BackToLife: {
          label: 'Reincarnate',
          steps: [
            {
              type: 'go_station',
              station: '_birth',
            },
          ],
          available_to: ['repairer', 'consumer'],
        },
      },
    },
  },
};

CaseFlowConfigurationRegistry.Register(
  RepairWithTwoStates,
  'test_repair_with_two_states'
);

/* -------------------------------------------------------------------------- */
/*                        Repair With Action Parameters                       */
/* -------------------------------------------------------------------------- */
// Purpose: Configuration containing actions that require parameters to be passed

export const RepairWithActionParameters: CaseFlowConfiguration = {
  ...AbsoluteBasicRepair,
  stations: {
    ...AbsoluteBasicRepair.stations,
    _birth: {
      actions: {
        GoToDeath: {
          available_to: ['repairer', 'consumer'],
          params: {
            repairer_param: {
              type: 'String',
              label: 'Repairer parameter',
              required: true,
            },
            optional_param: {
              type: 'String',
              label: 'Optional parameter',
              required: false,
            },
          },
          label: 'Go to death',
          steps: [
            {
              type: 'go_station',
              station: '_death',
            },
            {
              type: 'set_actor',
              role: 'repairer',
              persona_value: '[[repairer_param]]',
            },
          ],
        },
      },
    },
    _death: {
      actions: {},
    },
  },
};

CaseFlowConfigurationRegistry.Register(
  RepairWithActionParameters,
  'test_repair_with_action_parameters'
);

/* -------------------------------------------------------------------------- */
/*                         Repair with Global Actions                         */
/* -------------------------------------------------------------------------- */
export const RepairWithGlobalActions: CaseFlowConfiguration = {
  ...RepairWithTwoStates,
  stations: {
    ...RepairWithTwoStates.stations,
    secretland: {
      actions: {},
    },
  },
  global_actions: {
    GoToSecretLand: {
      label: 'Go to secret land',
      steps: [
        {
          type: 'go_station',
          station: 'secretland',
        },
      ],
      available_to: ['repairer', 'consumer'],
    },
  },
};

CaseFlowConfigurationRegistry.Register(
  RepairWithGlobalActions,
  'test_repair_with_global_actions'
);

/* -------------------------------------------------------------------------- */
/*                          Repair with Requirements                          */
/* -------------------------------------------------------------------------- */

export const RepairWithRequirements: CaseFlowConfiguration = {
  ...RepairWithGlobalActions,
  facts: {
    ...RepairWithGlobalActions.facts,
    example_required_fact: {
      type: 'String',
      label: 'Example required fact',
    },
  },
  stations: {
    ...RepairWithGlobalActions.stations,
    _birth: {
      actions: {
        GoToDeath: {
          label: 'Go to death',
          steps: [
            // TEST 1: You cannot go to death until you have added the required fact
            {
              type: 'req_facts',
              facts: ['example_required_fact'],
            },
            {
              type: 'go_station',
              station: '_death',
            },
          ],
          available_to: ['repairer', 'consumer'],
        },
      },
    },
    secretland: {
      actions: {},
    },
    _death: {
      actions: {},
    },
  },
  global_actions: {
    GoToSecretLand: {
      label: 'Go to secret land',
      steps: [
        // TEST 2: You cannot leave secret land once you get there.
        {
          type: 'req_station',
          exclude: ['secretland'],
        },
        {
          type: 'go_station',
          station: 'secretland',
        },
      ],
      available_to: ['repairer', 'consumer'],
    },
  },
};

CaseFlowConfigurationRegistry.Register(
  RepairWithRequirements,
  'test_repair_with_requirements'
);
/* -------------------------------------------------------------------------- */
/*                        Repair with Delegation Setup                        */
/* -------------------------------------------------------------------------- */
// Purpose: Configuration that can handle a larger repair organisation with delegates

export const RepairInALargerOrganisation: CaseFlowConfiguration = {
  ...AbsoluteBasicRepair,
  actors: {
    ...AbsoluteBasicRepair.actors,
    repairer: {
      ...AbsoluteBasicRepair.actors['repairer'],
      proxy: {
        facts: [
          'consumer_holding_name',
          'consumer_problem',
          'delegate_repairer',
        ],
      },
    },
  },
  global_actions: {
    // Take ownership action - to be called by a delegate repairer when they want to "own" a case
    repairer_take_ownership: {
      label: 'Take ownership',
      params: {
        delegate_repairer: {
          type: 'String',
          label: 'Delegate Repairer Persona',
          required: true,
        },
      },
      steps: [
        {
          type: 'set_fact',
          fact: 'delegate_repairer',
          value: '[[delegate_repairer]]',
        },
        {
          type: 'alter_spotlight',
          clear: true,
          add: '[[delegate_repairer]]',
        },
      ],
      available_to: ['repairer', 'consumer'],
    },
    // Set the spotlight to point at the consumer
    spotlight_on_consumer: {
      label: 'Spotlight on consumer',
      params: {
        consumer: {
          type: 'String',
          label: 'Consumer Persona',
          get_from: 'actor:consumer',
        },
      },
      steps: [
        {
          type: 'alter_spotlight',
          clear: true,
          add: '[[consumer]]',
        },
      ],
      available_to: ['repairer', 'consumer'],
    },
    // Set the spotlight to point at the repairer, which may be the delegate repairer if it has been set as a fact
    spotlight_on_repairer: {
      label: 'Spotlight on repairer',
      params: {
        repairer: {
          type: 'String',
          label: 'Repairer Persona',
          required: true,
          get_from: ['fact:delegate_repairer', 'actor:repairer'],
        },
      },
      steps: [
        {
          type: 'alter_spotlight',
          clear: true,
          add: '[[repairer]]',
        },
      ],
      available_to: ['repairer', 'consumer'],
    },
  },
  facts: {
    ...AbsoluteBasicRepair.facts,
    delegate_repairer: {
      type: 'String',
      label: 'Delegate repairer',
    },
  },
  stations: {
    ...AbsoluteBasicRepair.stations,
    _birth: {
      actions: {
        SubmitRepair: {
          available_to: ['repairer', 'consumer'],
          params: {
            repairer_param: {
              type: 'String',
              label: 'Repairer parameter',
              required: true,
            },
          },
          label: 'Submit Repair',
          steps: [
            {
              type: 'go_station',
              station: 'repair',
            },
            {
              type: 'set_actor',
              role: 'repairer',
              persona_value: '[[repairer_param]]',
            },
            // Set the spotlight too
            {
              type: 'alter_spotlight',
              clear: true,
              add: '[[repairer_param]]',
            },
          ],
        },
      },
    },
    repair: {
      actions: {},
    },
  },
};

CaseFlowConfigurationRegistry.Register(
  RepairInALargerOrganisation,
  'test_repair_in_a_larger_organisation'
);
