import { CFAAlterSpotlight, CFASetFact } from '@rabbit/data/types';
import { ActionStepImplementation, ActionStepRegistry } from '../registry';

export const ASI_SetFact: ActionStepImplementation<CFASetFact> = {
  validate: (location, validator, step, action, art) => {
    art.ValidatePotentialParams(step.fact, location, validator);
    art.ValidatePotentialParams(step.value, location, validator);
  },

  interior: (cfc, step, art) => {
    art.cfc.Alter_Facts({
      [art.FillParamsInString(step.fact)]: art.FillParamsInString(step.value),
    });
  },
};

ActionStepRegistry.Register(ASI_SetFact, 'set_fact');
