import {
  CFC_CheckSagePersonaExistence_WithEmail,
  CFC_RefreshIdentityClaims,
  ICF_CheckSagePersonaExistence_WithEmail,
  ICF_RefreshIdentityClaims,
  OCF_CheckSagePersonaExistence_WithEmail,
  OCF_RefreshIdentityClaims,
} from '@rabbit/bizproc/core';
import { APICallResultBase } from '@rabbit/elements/shared-types';
import { z } from 'zod';

export async function Auth_CheckSagePersonaExistence_WithEmail({
  requestId,
  apiVersion,
  email,
}: z.infer<typeof ICF_CheckSagePersonaExistence_WithEmail>): Promise<
  {
    data: z.infer<typeof OCF_CheckSagePersonaExistence_WithEmail>;
  } & APICallResultBase
> {
  const result = await CFC_CheckSagePersonaExistence_WithEmail.call({
    requestId,
    apiVersion,
    email,
  });

  if (!result.ok) throw new Error(result.error);
  return result;
}

/* ----------------------------- Claims refresh ----------------------------- */
export async function Claims_RefreshForIdentity({
  requestId,
  apiVersion,
  uid,
}: z.infer<typeof ICF_RefreshIdentityClaims>): Promise<
  { data: z.infer<typeof OCF_RefreshIdentityClaims> } & APICallResultBase
> {
  const result = await CFC_RefreshIdentityClaims.call({
    requestId,
    apiVersion,
    uid,
  });

  if (!result.ok) throw new Error(result.error);
  return result;
}
