import { Heading } from '@rabbit/elements/shared-components';

export interface BreadCrumbsProps {
  title: string;
}

export function BreadCrumbs({ title }: BreadCrumbsProps) {
  return (
    <div className="border-b-[1px] py-4 pl-8">
      <Heading kind={'h2'}>{title}</Heading>
    </div>
  );
}

export default BreadCrumbs;
