import {
  DS_If,
  DS_Set,
  DS_AndOr,
  DT_AnyValue,
  DeciderDimensionDetails,
  DeciderInput,
  DeciderMenuArray,
  DeciderMenuResult,
  DeciderOutput,
  DeciderStep,
  DeciderTypeWithLayout,
  DeciderWorkspace,
} from '@rabbit/data/types';
import {
  CheckValueAgainstTyping,
  GetDimensionDetailFromTyping,
  GetFinalValue,
  GetFinalValueNumber,
  GetOptionIndexFromVariable,
  GetTypingFromPath,
} from './utility';

function RunOperation(a: DT_AnyValue, b: DT_AnyValue, op: string) {
  let result = false;

  // Handle singleOption comparisons
  if (typeof a === 'object' || typeof b === 'object') {
    if (op !== '==' && op !== '!=')
      throw new Error(
        'RunOperation failed, can only compare objects with == or !='
      );
    if (typeof a !== typeof b)
      throw new Error(
        'RunOperation failed, can only compare objects with other objects. Typeof a: ' +
          typeof a +
          ' typeof b: ' +
          typeof b
      );

    switch (op) {
      case '==':
        result = JSON.stringify(a) === JSON.stringify(b);
        break;
      case '!=':
        result = JSON.stringify(a) !== JSON.stringify(b);
        break;
    }
  } else {
    switch (op) {
      case '>':
        result = a > b;
        break;
      case '<':
        result = a < b;
        break;
      case '==':
        result = a === b;
        break;
      case '!=':
        result = a !== b;
        break;
      case '>=':
        result = a >= b;
        break;
      case '<=':
        result = a <= b;
        break;
    }
  }

  return result;
}

function RunAndOrStep(step: DS_AndOr, workspace: DeciderWorkspace) {
  const a = GetFinalValue(step.a, workspace);
  const b = GetFinalValue(step.b, workspace);

  return RunOperation(a, b, step.op);
}

function RunIfStep(step: DS_If, workspace: DeciderWorkspace) {
  const a = GetFinalValue(step.a, workspace);
  const b = GetFinalValue(step.b, workspace);

  let result = false;
  result = RunOperation(a, b, step.op);

  if (step.and && step.and.length > 0) {
    for (const andStep of step.and) {
      const andResult = RunAndOrStep(andStep, workspace);
      if (!andResult) {
        result = false;
        break;
      }
    }
  }

  if (step.or && step.or.length > 0) {
    for (const orStep of step.or) {
      const orResult = RunAndOrStep(orStep, workspace);
      if (orResult) {
        result = true;
        break;
      }
    }
  }

  if (result) {
    if (Array.isArray(step.then)) {
      RunStepArray(step.then, workspace);
    } else {
      RunStep(step.then, workspace);
    }
  }
}

function PerformLookup(id: string, workspace: DeciderWorkspace) {
  const lookup = workspace.input.schedule.lookups[id];
  if (!lookup) {
    throw new Error(`Lookup ${id} not found in schedule`);
  }

  // Go through the dimensions and resolve them using workspace variables
  const indices: number[] = [];
  for (const dimension in lookup.dimensions) {
    const index = GetOptionIndexFromVariable(
      lookup.dimensions[dimension],
      workspace
    );
    indices.push(index);
  }

  // Look it up
  const valueTable = workspace.input.lookups?.[id]?.values || lookup.values;

  let current = valueTable;
  for (const index of indices) {
    if (!Array.isArray(current)) {
      throw new Error('Tragedy');
    }
    current = current[index];
  }

  if (Array.isArray(current)) {
    throw new Error('Tragedy');
  }

  return current;
}

function RunSetStep(step: DS_Set, workspace: DeciderWorkspace) {
  let value: any;
  if (typeof step.value === 'object') {
    if (step.value.source) {
      switch (step.value.source) {
        case 'decisionOptions':
          {
            // todo verify if the inputted option is part of the decision's options
            value = step.value.option;
          }
          break;
        case 'lookup':
          value = PerformLookup(step.value.lookup, workspace);
          break;
        case 'math':
          {
            const a = GetFinalValueNumber(step.value.a, workspace);
            const b = GetFinalValueNumber(step.value.b, workspace);
            switch (step.value.op) {
              case '+':
                value = a + b;
                break;
              case '-':
                value = a - b;
                break;
              case '*':
                value = a * b;
                break;
              case '/':
                value = a / b;
                break;
            }
          }
          break;
      }
    }
  } else {
    value = GetFinalValue(step.value, workspace);
  }
  // TODO: Typecheck it before we set it
  workspace.variables[step.set] = value;
}

function RunStep(step: DeciderStep, workspace: DeciderWorkspace) {
  if ((step as any).op !== undefined) {
    RunIfStep(step as DS_If, workspace);
    return;
  }
  if ((step as any).set !== undefined) {
    RunSetStep(step as DS_Set, workspace);
    return;
  }
}

function RunStepArray(steps: DeciderStep[], workspace: DeciderWorkspace) {
  for (const step of steps) {
    RunStep(step, workspace);
  }
}

export function PerformDecision(input: DeciderInput): DeciderOutput {
  const workspace = MakeWorkspace(input);

  // Run the formula
  RunStepArray(input.schedule.formula, workspace);

  // Copy decisions to output
  for (const key in input.schedule.decisions) {
    const value = workspace.variables[`d.${key}`];
    if (value === null) {
      throw new Error(
        `Decision ${key} was not decided. Everything must be decided.`
      );
    }
    workspace.output.decided[key] = value;
  }

  return workspace.output;
}

export function MakeWorkspace(input: DeciderInput): DeciderWorkspace {
  const workspace: DeciderWorkspace = {
    input,
    variables: {},
    output: { decided: {}, stipulated: { ...input.stipulated } },
  };

  // Load variables from input
  for (const key in input.stipulated) {
    // Make sure it is stipulated
    const stipulation = workspace.input.schedule.stipulations[key];
    const stipulated = input.stipulated[key];
    if (typeof stipulation === 'undefined') {
      throw new Error(`Stipulation ${key} not found in schedule`);
    }

    if (stipulated !== null) {
      // type check
      const outcome = CheckValueAgainstTyping(stipulated, stipulation);
      if (outcome !== 'OK') {
        throw new Error(`Stipulation [${key}]: ${outcome}`);
      }
    }

    workspace.variables[`s.${key}`] = input.stipulated[key];
  }

  // Fill in any missing stipulations with null
  for (const key in input.schedule.stipulations) {
    if (typeof workspace.variables[`s.${key}`] === 'undefined') {
      workspace.variables[`s.${key}`] = null;
    }
    if (workspace.variables[`s.${key}`] === null) {
      const defaultVal = input.schedule.stipulations[key].default;
      if (defaultVal !== undefined) {
        workspace.variables[`s.${key}`] = defaultVal;
        workspace.output.stipulated[key] = defaultVal;
      }
    }
  }

  // Fill out decisions with nulls
  for (const key in input.schedule.decisions) {
    workspace.variables[`d.${key}`] = null;
    const defaultVal = input.schedule.decisions[key].default;
    if (defaultVal !== undefined) {
      // TODO: TYPE CHECK IT HERE
      workspace.variables[`d.${key}`] = defaultVal;
    }
  }
  return workspace;
}

function MakeMenuValues(
  input: DeciderInput,
  dimensions: string[]
): DeciderMenuArray {
  if (dimensions.length === 0) {
    return PerformDecision(input).decided;
  }

  const values = [];
  const thisDimension = dimensions[0];
  const thisTyping = GetTypingFromPath(`s.${thisDimension}`, input.schedule);
  if (thisTyping.type !== 'options') {
    throw new Error('Only "options" type is supported');
  }

  for (let i = 0; i < thisTyping.options.length; i++) {
    // We alter the input with each option
    input.stipulated[thisDimension] = thisTyping.options[i];
    values.push(MakeMenuValues(input, dimensions.slice(1)));
  }
  return values;
}

export function MakeMenu(
  input: DeciderInput,
  dimensions: string[]
): DeciderMenuResult {
  // typing of our menu dimensions
  const dimensionDetail: DeciderDimensionDetails[] = [];
  for (const dimension of dimensions) {
    const typing = GetTypingFromPath(`s.${dimension}`, input.schedule);
    dimensionDetail.push(GetDimensionDetailFromTyping(typing));
  }

  // typing of all our output variables
  const typing: { [key: string]: DeciderTypeWithLayout } = {};
  for (const key in input.schedule.decisions) {
    typing[`${key}`] = GetTypingFromPath(`d.${key}`, input.schedule);
  }

  // the menu values
  const values = MakeMenuValues(input, dimensions);

  return { values, dimensionDetail, typing };
}
