import { RetailerLink, WarrantorLink } from '../persona';
import { TenantLink } from './tenant';
import { DateTime } from '../base/basic';
import { DTWarranty } from './warranty';
import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';

export interface DTWarrantyHistory_Snapshot {
  version: number;
  tupdate: DateTime;
  content: DTWarranty;
  // reason: string;
}

export interface DTWarranty_History extends NoSqlDoc {
  /** The current version number */
  version: number;

  /** Unique identifier for the voided warranty document */
  warrantyLink: string;

  /** Timestamp of when the warranty was marked as voided */
  tupdate: DateTime;

  /** Optional reason for voiding the warranty */
  reason?: string;

  /** Copy of the original warranty data */
  warrantySnapshot: DTWarrantyHistory_Snapshot[];

  /** The retailer who sold this warranty and registered it */
  retailerLink?: RetailerLink;

  /** The warrantor responsible for handling this warranty and any associated claims */
  warrantorLink: WarrantorLink;

  /** The (usually premium) tenant this warranty instance is associated with */
  tenantLink: TenantLink;
}

export const FBD_WarrantyHistory = MakeFBDocType<DTWarranty_History>({
  name: 'Warranty History',
  collection: 'warranty_history',
  empty: () => {
    const result: DTWarranty_History = {
      ...Empty_NoSqlDoc(),
      version: 1,
      warrantyLink: '',
      tupdate: new Date().getTime(),
      reason: '',
      warrantySnapshot: [],
      retailerLink: '',
      warrantorLink: '',
      tenantLink: '',
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});
