export function CloudUploadIcon({ className }: { className: string }) {
  return (
    <svg
      className={className}
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.89999 10.4C2.18361 10.3991 1.49484 10.1236 0.975407 9.63024C0.455974 9.13689 0.145395 8.46322 0.107576 7.74783C0.069757 7.03245 0.307576 6.32979 0.772096 5.78442C1.23662 5.23906 1.8925 4.89248 2.60479 4.81602C2.39506 3.99353 2.52065 3.12141 2.95393 2.39152C3.38721 1.66162 4.0927 1.13375 4.91519 0.924016C5.73768 0.714282 6.6098 0.839872 7.33969 1.27316C8.06958 1.70644 8.59746 2.41193 8.80719 3.23442C9.29635 3.16631 9.79429 3.19957 10.27 3.33215C10.7458 3.46472 11.1892 3.69377 11.5726 4.00504C11.956 4.31631 12.2713 4.70315 12.4989 5.14149C12.7264 5.57983 12.8613 6.06031 12.8952 6.55302C12.9291 7.04573 12.8612 7.54014 12.6958 8.00551C12.5304 8.47087 12.2711 8.89723 11.9339 9.25806C11.5967 9.6189 11.1888 9.90649 10.7357 10.1029C10.2826 10.2994 9.79387 10.4005 9.29999 10.4H7.29999V7.53042L8.33439 8.56482C8.48527 8.71054 8.68735 8.79118 8.89711 8.78935C9.10687 8.78753 9.30752 8.7034 9.45584 8.55507C9.60417 8.40674 9.68831 8.20609 9.69013 7.99634C9.69195 7.78658 9.61132 7.5845 9.46559 7.43362L7.06559 5.03362C6.91557 4.88364 6.71212 4.79939 6.49999 4.79939C6.28786 4.79939 6.08441 4.88364 5.93439 5.03362L3.53439 7.43362C3.38866 7.5845 3.30803 7.78658 3.30985 7.99634C3.31167 8.20609 3.39581 8.40674 3.54413 8.55507C3.69246 8.7034 3.89311 8.78753 4.10287 8.78935C4.31263 8.79118 4.51471 8.71054 4.66559 8.56482L5.69999 7.53122V10.4H2.89999Z"
        fill="white"
      />
    </svg>
  );
}

export default CloudUploadIcon;
