import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

export interface ChevronButtonProps {
  kind: 'back' | 'forward';
  onClick?: () => any;
  className?: string;
}

const dynamicStyles = {
  base: 'h-[24px] w-[24px] cursor-pointer',
};

export function ChevronButton({
  kind,
  onClick,
  className,
}: ChevronButtonProps) {
  return (
    <div onClick={onClick} className={className}>
      {kind === 'back' ? (
        <ChevronLeftIcon className={dynamicStyles.base} />
      ) : (
        <ChevronRightIcon className={dynamicStyles.base} />
      )}
    </div>
  );
}

export default ChevronButton;
