import { AtomBucketPersistor } from './types';

/** A layer for access to the persistor.
 * Separated out to its own file so we can make a different persistor for React Native (asyncStorgae)
 */

export const Persistor: AtomBucketPersistor<any> = {
  get: async (key: string) => {
    return JSON.parse(localStorage.getItem(key)?.toString() || 'null');
  },
  set: async (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getAllKeys: async () => {
    return Object.keys(localStorage);
  },
  delete: async (key: string) => {
    localStorage.removeItem(key);
  },
};
