export function IWarrantyLogoIcon({ className }: { className: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
    >
      <path
        d="M15.5181 6.38296L3.24003 18.8224C2.31192 19.7626 2.31192 21.2872 3.24003 22.2274L14.8254 33.9652C15.7535 34.9054 17.2583 34.9054 18.1863 33.9652L30.4645 21.5257C31.3925 20.5854 31.3925 19.0609 30.4645 18.1206L18.879 6.38296C17.9509 5.44266 16.4462 5.44266 15.5181 6.38296Z"
        fill="#FFD466"
        fillOpacity="0.69"
      />
      <path
        d="M25.276 6.56383L9.87981 12.2413C8.64642 12.6961 8.01049 14.0778 8.4594 15.3273L14.7517 32.8424C15.2006 34.092 16.5644 34.7363 17.7977 34.2815L33.194 28.6041C34.4273 28.1493 35.0633 26.7676 34.6143 25.5179L28.3221 8.0029C27.8731 6.75331 26.5094 6.10902 25.276 6.56383Z"
        fill="#80B458"
        fillOpacity="0.6"
      />
      <path
        d="M33.4062 11.2168H17.2457C15.9331 11.2168 14.8691 12.2948 14.8691 13.6246V32.4053C14.8691 33.7352 15.9331 34.8132 17.2457 34.8132H33.4062C34.7188 34.8132 35.7829 33.7352 35.7829 32.4053V13.6246C35.7829 12.2948 34.7188 11.2168 33.4062 11.2168Z"
        fill="#035948"
        fillOpacity="0.5"
      />
    </svg>
  );
}

export default IWarrantyLogoIcon;
