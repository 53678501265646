import classNames from 'classnames';

export interface ProgressBarProps {
  width: string;
}

const dynamicStyles = {
  container: 'h-[5px] rounded-md bg-primary-300 w-full',
  progress: 'bg-primary-800 rounded-[36px] h-[100%]',
};

export function ProgressBar({ width }: ProgressBarProps) {
  return (
    <div className={classNames(`${dynamicStyles.container}`)}>
      <div
        className={classNames(`${dynamicStyles.progress}`)}
        style={{ width: `${width}%` }}
      ></div>
    </div>
  );
}

export default ProgressBar;
