import { FBDT_KeygenFunction } from './types';

let generic_counter = 0;

export function BasicHashMaker(input: string): string {
  // As suggested by ChatGPT
  const base = 62;
  const targetLen = 21;
  const charset =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  // empty array of zeroes, length is targetLen
  const counters = Array(targetLen).fill(0);
  let ptr = 0;

  for (let j = 0; j < 8; j++) {
    for (let i = 0; i < input.length; i++) {
      let char = input.charCodeAt(i);

      char = char << j;
      char = (char % 256) + ~~(char / 256);

      counters[ptr] = (counters[ptr] + ~~(char / 16)) % base;
      ptr = (ptr + 1) % targetLen;
      counters[ptr] = (counters[ptr] + (char % 16)) % base;
      ptr = (ptr + 1) % targetLen;
    }
  }

  const result = counters.map((c) => charset.charAt(c)).join('');

  return result;
}

export const FBDTKeygenGeneric: FBDT_KeygenFunction<any> = (body: any) => {
  // Return a super random number using date and random
  return BasicHashMaker(`${Date.now()}-${Math.random()}-${generic_counter++}`);
};

export function FBD_Keygen_BANNED(reason: string): FBDT_KeygenFunction<any> {
  // Return a super random number using date and random
  return (body: any, type) => {
    throw new Error(
      `FBD: Document Auto Keygen [${type.name}] BANNED: ${reason}`
    );
  };
}
