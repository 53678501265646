import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import {
  DR_CaseFlowPrivateProxy,
  getPersonasFromToken,
  isAdmin,
} from '@rabbit/data/portal';
import { where, query, orderBy } from '@firebase/firestore';
import { t } from 'i18next';
import {
  PersonaIdTypeSplitter,
  PersonaLink,
  PrincipalsFieldName,
} from '@rabbit/data/types';
import { isSameDay } from 'date-fns';
import { ConfigContext } from '@rabbit/config/context';
import { useContext } from 'react';

/** Fetches a list of cases open for a given repairer persona. Will likely be modified to accept different persona types in the future */
export function useGetCaseProxyList(
  // pageSize: number,
  allCases: boolean,
  filters?: {
    beginDate?: Date | null;
    endDate?: Date | null;
    unassignedOnly?: boolean;
    staleOnly?: boolean;
  },
  operatingPersona?: PersonaLink | null,
  accessToken?: string | null
) {
  const { config } = useContext(ConfigContext);
  const tenantLink = localStorage.getItem('activeTenant') ?? '';
  // Used for paginated queries, temporarily disabled
  //const [totalCaseCount, setTotalCaseCount] = useState(0);

  const getDateTo = () => {
    if (!filters?.endDate || isSameDay(filters?.endDate, new Date())) {
      return 999999999999999;
    }
    return filters?.endDate.getTime();
  };

  const dateFrom = filters?.beginDate ? filters?.beginDate.getTime() : 0;
  const dateTo = getDateTo();

  const queryParams = [
    where('tcreate', '>=', dateFrom),
    where('tcreate', '<=', dateTo),
    orderBy('tcreate'),
    orderBy('tupdate'),
  ];

  const personas = isAdmin(accessToken)
    ? t(PrincipalsFieldName).split(',')
    : getPersonasFromToken(accessToken);

  /* Right now there's always only one case proxy, owned by root warrantor (or repairer for Shelta)
   So we're just pushing the persona to the array to make sure the query works
   The other personas from the Token are still included to make sure this doesn't inadvertently break
  anything for Shelta (even though right now all of their cases are owned by the root repairer) - DC */

  personas.push(
    config.CLAIMS.PROXY_OWNER_PERSONA + PersonaIdTypeSplitter + tenantLink
  );
  queryParams.push(where('owner', 'in', personas));

  if (filters?.unassignedOnly)
    queryParams.unshift(
      where('facts.delegate_repairer_id', '==', 'Unassigned')
    );

  if (operatingPersona && !allCases)
    queryParams.unshift(
      where('facts.delegate_repairer_id', '==', operatingPersona)
    );

  const caseProxyQuery = query(
    DR_CaseFlowPrivateProxy.collection(),
    ...queryParams
  );

  const caseList = useFirestoreQueryData(
    [
      'caseProxyList',
      allCases ? 'allRepairers' : operatingPersona,
      filters?.unassignedOnly ? 'unassigned' : 'allCases',
      `${dateFrom + '-' + dateTo}`,
    ],
    caseProxyQuery,
    { subscribe: true }
  );

  return {
    caseList,
    //totalCaseCount
  };
}
