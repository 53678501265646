import { Button } from '../../atoms/buttons/Button/Button';
import image404 from '../../assets/images/404.png';
import { useNavigate } from 'react-router-dom';

export interface NotFoundViewProps {}

export function NotFoundView({}: NotFoundViewProps) {
  const navigate = useNavigate();
  return (
    <div className="flex w-full flex-grow flex-col items-center justify-center gap-5 px-4 text-center md:pt-0">
      <div className="font-caudex pt-12 text-5xl font-bold md:pt-0">404</div>
      <div className="">
        <img
          src={image404}
          alt={'Page not found'}
          className="m-auto h-[186px]"
        ></img>
      </div>
      <div className="flex flex-col gap-2 text-lg">
        <div className="font-caudex font-bold">
          We’re very sorry. The page you were looking for doesn't exist.
        </div>
        <div className="font-nunito text-gray-500">
          Please press the button below to go back to the homepage
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Button kind="primary" onClick={() => navigate('/')}>
          Back to homepage
        </Button>
      </div>
    </div>
  );
}

export default NotFoundView;
