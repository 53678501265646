import { useTranslation } from 'react-i18next';
import EditWarrantyPW from '../forms/EditWarrantyPW/EditWarrantyPW';

export interface ModalEditWarrantyProps {
  warrantyLink: string;
  handleClose: () => void;
}

function ModalEditWarranty({
  warrantyLink,
  handleClose,
}: ModalEditWarrantyProps) {
  const { t } = useTranslation();
  
  return (
   t('tenantLink') === 'PINNACLEWARRANTIES' ? 
    <EditWarrantyPW warrantyLink={warrantyLink} handleClose={handleClose} />
    :
    <div>This user cannot edit warranties.</div>
  );
}

export default ModalEditWarranty;