import { useFileStorage } from '@rabbit/bizproc/react';
import { UserUploadedDocument } from '@rabbit/data/types';
import {
  Button,
  Modal,
  UploadFilesForm,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import { titleCase } from '@rabbit/sage/utils/helpers';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { LogForms } from '../ClaimCostModal/ClaimCostModal';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import { t } from 'i18next';

export default function DocumentsEditModal({
  index,
  name,
  handleClose,
}: {
  index: number;
  name: LogForms<any>['name'];
  handleClose: () => void;
}) {
  const context = useContext(CaseflowContext);
  const { caseFacts, caseFlowCase, caseId, caseActors, alterCaseFacts } =
    context || {};
  const {
    uploadFiles,
    uploadTaskCat,
    isUpdating,
    uploadProgress,
    deleteFile,
    deleteUnusedTempFiles,
    updateHoldingWithFiles,
    uploadedTempFiles,
    clearFileFromHolding,
    clearFileFromState,
    clearAllTempFilesFromState,
  } = useFileStorage();

  const documentsNameMap: Record<string, UserUploadedDocument[]> = {
    shipping: caseFacts?.shipping_cost_data?.[index].documents || [],
    travel: caseFacts?.travel_cost_data?.[index].documents || [],
    other: caseFacts?.other_cost_data?.[index].documents || [],
  };
  const [uploadedProofOfPostage, setUploadedProofOfPostage] = useState<
    UserUploadedDocument[]
  >(documentsNameMap[name]);

  // Saving registry here (internally) so it's not too confusing
  const register: Record<string, string> = {
    shipping: 'shipping_cost_data',
    travel: 'travel_cost_data',
    other: 'other_cost_data',
  };

  const onProofOfPostageUpdated = async (
    files: UserUploadedDocument[],
    _added = true
  ) => {
    if (files && caseFlowCase && alterCaseFacts) {
      clearAllTempFilesFromState();
      setUploadedProofOfPostage(files);
      const registry = register[name];
      const updatedRecord = {
        ...(caseFacts as any)?.[registry]?.[index],
      };
      updatedRecord.documents = files;
      const updatedRegistry = (caseFacts as any)?.[registry] || [];
      updatedRegistry[index] = updatedRecord;
      try {
        await alterCaseFacts({ [registry]: [...updatedRegistry] });
      } catch (err) {
        console.log(err);
        toast.error('Something went wrong, please try again');
      }
    }
  };

  return (
    <Modal
      settings={{
        title: `${titleCase(name)} - Attachments`,
        handleClose,
      }}
      className="w-full max-w-[768px]"
      kind="generic"
    >
      <Formik onSubmit={() => {}} initialValues={{}}>
        <Form className="flex w-full flex-col px-4">
          <UploadFilesForm
            label={t('Supporting documents')}
            type="case"
            docId={caseId ?? ''}
            personaId={caseActors?.consumer ?? ''}
            currentFiles={uploadedProofOfPostage ?? []}
            onFilesUploaded={onProofOfPostageUpdated}
            uploadHandlers={{
              uploadedTempFiles,
              category: UploadedFileCategories.Receipts,
              uploadFiles,
              uploadTaskCat,
              isUpdating,
              uploadProgress,
              deleteFile,
              updateHoldingWithFiles,
              clearFileFromHolding,
              clearFileFromState,
              deleteUnusedTempFiles,
            }}
          />
          <div className="mt-4 flex gap-3">
            <Button
              kind="primary"
              type="button"
              disabled={isUpdating}
              onClick={handleClose}
            >
              Ok
            </Button>
            <Button
              kind="outline_red"
              type="submit"
              onClick={handleClose}
              disabled={isUpdating}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
}
