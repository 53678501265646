import { ChangeLanguage, HasLanguageBooted } from './language';

type RecipeComponent = 'manufacturer' | 'retailer';
const RecipeProcessingOrder: RecipeComponent[] = ['manufacturer', 'retailer'];

const RecipeEntries: { [key: string]: string } = {};

let RefreshTimeout: null | ReturnType<typeof setTimeout> = null;

function ConsiderRefreshingRecipe(fast = false) {
  if (!HasLanguageBooted()) return; // Recipe will be refreshed on boot

  if (fast) {
    if (RefreshTimeout) {
      clearTimeout(RefreshTimeout);
      RefreshTimeout = null;
    }
    void RefreshRecipe();
    return;
  }

  // debouncer
  if (RefreshTimeout) return;
  RefreshTimeout = setTimeout(() => {
    RefreshTimeout = null;
    void RefreshRecipe();
  }, 100);
}

export function MixMasterAlterRecipe(
  component: RecipeComponent,
  value: string
) {
  if (RecipeEntries[component] === value) return; // already set to this value
  RecipeEntries[component] = value;
  localStorage.setItem(`mm_${component}`, value);
  ConsiderRefreshingRecipe();
}

async function RefreshRecipe() {
  await ChangeLanguage(RecipeToLanguageString());
}

export function RecipeToLanguageString() {
  let language = 'base';
  for (const component of RecipeProcessingOrder) {
    const value = RecipeEntries[component];
    if (value) {
      if (language === 'base') {
        language = value;
      } else {
        language += `*${value}`;
      }
    }
  }
  return language;
}

function Init() {
  for (const component of RecipeProcessingOrder) {
    const item = localStorage.getItem(`mm_${component}`);
    if (item) {
      RecipeEntries[component] = item;
    }
  }
}

Init();

(window as any).MixMasterSet = (language: string) => {
  throw new Error("REMOVED - use instead: MMManufacturer('manufacturer')");
  // localStorage.setItem('mm_manufacturer', language);
};

(window as any).MMManufacturer = (language: string) => {
  localStorage.setItem('mm_manufacturer', language);
};

// const language = localStorage.getItem('language');
// if (language && language.length > 0) {
//   await ChangeLanguage(language);
// } else {
//   await ChangeLanguage('base');
// }
