import { useEffect, useState } from 'react';
import { DRDocType } from '@rabbit/firebase/react';
import {
  EditingInstanceConfig,
  EditingInstanceHook,
  PortalDocumentEditorError,
  PortalEditingDocumentEntry,
  PortalEditingDocumentStatus,
} from './types';
import { AttachEditingInstance } from './editing-instance';
import { NoSqlDoc } from '@rabbit/firebase/doctype';
import { NewkeyAllocator } from '../newkey';

// SEE USEPORTALDOCUMENTEDITOR BELOW for introduction

/* Portal document implementation notes

We want to support these actions:
* Recover from previous sessions
* Subscribe to changes in firebase
* Allow new documents, both with keys and without

*/

/* -------------------------------------------------------------------------- */
/*                                   Options                                  */
/* -------------------------------------------------------------------------- */

export type FullPortalDocumentEditorOptions = {
  /** Create the document if it doesn't exist.
   *
   * NO: Don't create - if it doesn't exist then error condition will be set
   * ALWAYS_KEYED: Always create and use the given key. If the document already exists then an error condition will be set.
   * ALWAYS_KEYLESS: Always create with no key. Firebase will assign the key.
   * IF_NOT_EXISTS: Create if it doesn't exist. Will work most of the time.
   *
   * Defaults to NO */
  create: 'ALLOW' | 'DENY';
};

export type PortalDocumentEditorOptions =
  Partial<FullPortalDocumentEditorOptions>;

export const DefaultPortalDocumentEditorOptions: FullPortalDocumentEditorOptions =
  {
    create: 'DENY',
  };

/* -------------------------------------------------------------------------- */
/*                              Hook Return Type                              */
/* -------------------------------------------------------------------------- */

export type PortalDocumentEditorReturn<T extends NoSqlDoc> = {
  /** The body of the document. Will be null if document is not editable. Otherwise it is an object
   * of the type set by the incoming type parameter. If the document is new then it will be an empty
   * object created by DRDocType.empty.
   *
   * Any edits to this object will be reverted to the "local record" upon next render.
   * To commit your edit, call update(newBody). For convenience you may edit the existing body
   * immediately before updating as it is a copy of the "local record"
   *  */
  body: T | null;

  /** The status of the document, giving more detail of the situation as described in the enum PortalDocumentEditorStatus.
   * A few different states indicate document is ready to edit but you can just check for body===null to see if it is ready.
   */
  status: PortalEditingDocumentStatus;

  error: PortalDocumentEditorError;

  /** Update the body, save it to local record.
   *
   * You may edit the body immediately before calling this function, but the body will be reverted to the "local record" upon next render.
   * So make sure to not allow any async stages between your edits and the update.
   */
  update: (newBody: T) => void;

  /** Commit the current local record to Firebase Record. */
  commit: () => Promise<void>;

  /** The document ID of the document being shown. When creating a new document this will change upon commit. */
  docid: string;

  /** Simple way to check if it is safe to start editing the document */
  isReady: boolean;

  /** For debugging */
  entry: PortalEditingDocumentEntry<T> | null;
};

/* -------------------------------------------------------------------------- */
/*                           usePortalDocumentEditor                          */
/* -------------------------------------------------------------------------- */

/** The Portal Document Editor
 *
 * This is a hook that lets you edit a document in react. Whilst editing the document is stored locally
 * in localstorage until it is saved. It will be recoverable if the page is refreshed.
 *
 * So there exists a few copies of the document:
 * 1. The official document in firebase - "Firebase Record" - which may not exist if the document is new
 * 2. A copy of the official document in localstorage - "Local Record" - where every edit is committed
 * 3. A copy of the local record in the hook - "Body" - which can only be altered immediately before updating Local Record.
 *
 *
 */

export function usePortalDocumentEditor<T extends NoSqlDoc>(
  type: DRDocType<T>,
  docid: string,
  options?: Partial<FullPortalDocumentEditorOptions>
) {
  const opts = { ...DefaultPortalDocumentEditorOptions, ...options };

  const [ourDocid, setOurDocid] = useState(NewkeyAllocator(docid, type));

  useEffect(() => {
    setOurDocid(NewkeyAllocator(docid, type));
  }, [docid]);

  const [editingInstance, setEditingInstance] = useState(
    null as EditingInstanceHook<T> | null
  );

  const [theCopyDocument, setTheCopyDocument] = useState({} as T);
  const [documentEntry, setdocumentEntry] = useState(
    null as PortalEditingDocumentEntry<T> | null
  );

  useEffect(() => {
    const Config: EditingInstanceConfig<T> = {
      entryChanged: (newEntry) => {
        console.log('usePortalDocumentEditor: docChanged called', newEntry);
        setdocumentEntry(newEntry);
        setTheCopyDocument(JSON.parse(JSON.stringify(newEntry.local)));
      },
      allowCreate: opts.create === 'ALLOW',
    };

    const hook = AttachEditingInstance(type, ourDocid, Config);
    setEditingInstance(hook);

    return () => {
      console.log('usePortalDocumentEditor: UNMOUNTING');
      hook.unsubscribe();
    };
  }, [ourDocid, type, options]);

  const update = (newBody: T) => {
    if (!editingInstance)
      throw new Error("Shouldn't update without being ready");
    editingInstance.update(JSON.parse(JSON.stringify(newBody)));
    // Set state to a copy of the new body because when it is committed to localstorage our edit check below will not trigger since they will be the "same".
  };
  const commit = async () => {
    if (!editingInstance)
      throw new Error("Shouldn't commit without being ready");
    await editingInstance.commit();
  };

  if (!documentEntry) {
    const emptyResult: PortalDocumentEditorReturn<T> = {
      body: null,
      docid: ourDocid,
      status: PortalEditingDocumentStatus.preparing,
      error: null,
      update,
      commit,
      entry: null,
      isReady: false,
    };
    return emptyResult;
  }

  // Check to see if someone has edited the body object. If they have then replace it with the official
  // document. Lets us use the body object as a local copy rather than creating a whole new object for one change.
  // Might consider using immer but I don't know enough about it.
  if (JSON.stringify(documentEntry.local) !== JSON.stringify(theCopyDocument)) {
    setTheCopyDocument(JSON.parse(JSON.stringify(documentEntry.local)));
  }

  const result: PortalDocumentEditorReturn<T> = {
    body:
      documentEntry.status === PortalEditingDocumentStatus.preparing ||
      documentEntry.status === PortalEditingDocumentStatus.error
        ? null
        : theCopyDocument,
    update,
    commit,
    status: documentEntry.status,
    error: documentEntry.error,
    docid: documentEntry.docid,
    entry: documentEntry,
    isReady:
      documentEntry.status === 'NEW' ||
      documentEntry.status === 'SAVED' ||
      documentEntry.status === 'EDITED',
  };

  return result;
}
