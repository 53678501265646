import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
} from '@rabbit/firebase/doctype';
import { NoSqlDoc } from '@rabbit/firebase/doctype';

export interface DTVeil extends NoSqlDoc {
  kv: { [key: string]: string };
}

export const FBD_Veil = MakeFBDocType<DTVeil>({
  name: 'MixMaster-Veil',
  collection: 'mixmaster-veil',
  empty: () => {
    const result: DTVeil = {
      ...Empty_NoSqlDoc(),
      kv: {},
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED('docid should be set manually'),
});

export interface DTCachedLexiconPartition extends NoSqlDoc {
  kv: { [key: string]: string };
}

export const FBD_Lexicon = MakeFBDocType<DTCachedLexiconPartition>({
  name: 'MixMaster-CachedLexiconPartition',
  collection: 'mixmaster-cached',
  empty: () => {
    const result: DTCachedLexiconPartition = {
      ...Empty_NoSqlDoc(),
      kv: {},
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});

export type GenericLexicon = { [key: string]: string };
