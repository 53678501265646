import {
  CFAction,
  CFActionStep,
  CaseFlowConfiguration,
  FlowEditableForPersona,
} from '@rabbit/data/types';
import { ActionStepRegistry } from '../action';
import { ActionRuntime } from '../base_case_flow_case';

type IssueEntry = {
  location: string;
  description: string;
};

export class CaseFlowValidator {
  facts: string[] = [];
  issues: IssueEntry[] = [];
  config: CaseFlowConfiguration;

  constructor(config: CaseFlowConfiguration) {
    this.config = config;
  }

  AddIssue(location: string, description: string) {
    this.issues.push({ location, description });
  }

  ReferenceAFact(location: string, factName: string) {
    if (!this.facts.includes(factName)) {
      this.AddIssue(
        location,
        `Fact "${factName}" is referenced but not defined.`
      );
    }
  }

  ReferenceAStation(location: string, stationName: string) {
    if (!this.config.stations[stationName]) {
      this.AddIssue(
        location,
        `Station "${stationName}" is referenced but not defined.`
      );
    }
  }
}

function ValidateFact(
  location: string,
  validator: CaseFlowValidator,
  factName: string
) {
  const fact = validator.config.facts[factName];

  if (validator.facts.includes(factName)) {
    validator.AddIssue(
      location,
      `Fact type ${factName} is already registered.`
    );
    return;
  }

  // TODO: Type checking of fact

  validator.facts.push(factName);
}

function RegisterAndValidateFacts(validator: CaseFlowValidator) {
  const location = 'facts';

  if (!validator.config.facts) {
    validator.AddIssue(location, 'No facts are defined.');
    return;
  }

  for (const factName in validator.config.facts) {
    ValidateFact(location, validator, factName);
  }
}

function ValidateActionStep(
  location: string,
  validator: CaseFlowValidator,
  step: CFActionStep,
  action: CFAction,
  art: ActionRuntime
) {
  const entry = ActionStepRegistry.Items[step.type];
  if (!entry) {
    validator.AddIssue(
      location,
      `Step type "${step.type}" is not a valid step.`
    );
    return;
  }

  entry.validate(location, validator, step, action, art);
}

function ValidateAction(
  location: string,
  validator: CaseFlowValidator,
  action: CFAction
) {
  if (!action.available_to) {
    validator.AddIssue(
      location,
      'Action must state which roles it is available to.'
    );
  } else {
    // Make sure real roles are defined
    action.available_to.forEach((roleName) => {
      if (!validator.config.actors[roleName]) {
        validator.AddIssue(
          location + '.available_to',
          `Role "${roleName}" is referenced but not defined.`
        );
      }
    });
  }

  const steps = action.steps;

  if (!steps) {
    validator.AddIssue(location, 'No steps are defined.');
    return;
  }

  const art = new ActionRuntime(action, {} as any);

  steps.forEach((step, idx) => {
    ValidateActionStep(location + ':' + idx, validator, step, action, art);
  });
}

function ValidateEditable(
  location: string,
  validator: CaseFlowValidator,
  editable: FlowEditableForPersona,
  editableName: string
) {
  editable.forEach((factName) => {
    validator.ReferenceAFact(location, factName);
  });
}

function ValidateStation(
  location: string,
  validator: CaseFlowValidator,
  stationName: string
) {
  const station = validator.config.stations[stationName];

  // Check the editables
  const allEditables = station.editable;
  if (allEditables) {
    // Check out this set of typescript gymnastics
    const keys = Object.keys(allEditables) as (keyof typeof allEditables)[];
    keys.forEach((editableName) => {
      const editable = allEditables[editableName];
      if (!editable) return;
      ValidateEditable(
        location + '.editable.' + editableName,
        validator,
        editable,
        editableName
      );
    });
  }

  for (const actionName in station.actions) {
    const theAction = station.actions[actionName];
    ValidateAction(location + '.' + actionName, validator, theAction);
  }
}

function ValidateStations(validator: CaseFlowValidator) {
  const location = 'stations';

  if (!validator.config.stations) {
    validator.AddIssue(location, 'No stations are defined.');
    return;
  }

  // Make sure there is a _birth station
  if (!validator.config.stations['_birth']) {
    validator.AddIssue(location, 'No _birth station is defined.');
  }

  // Go thru all the stations and validate them
  for (const stationName in validator.config.stations) {
    ValidateStation(location + '.stations', validator, stationName);
  }
}

function ValidateActors(validator: CaseFlowValidator) {
  const location = 'actors';

  if (!validator.config.actors) {
    validator.AddIssue(location, 'No actors are defined.');
    return;
  }

  const roles = Object.keys(validator.config.actors);
  roles.forEach((roleName) => {
    const role = validator.config.actors[roleName];
    if (!role.tech_description) {
      validator.AddIssue(
        location + '.' + roleName,
        `Actor "${roleName}" has no tech_description.`
      );
    }

    if (role.proxy) {
      role.proxy.facts.forEach((factName) => {
        if (!validator.facts.includes(factName)) {
          validator.AddIssue(
            `${location}.${roleName}.proxy`,
            `Fact "${factName}" is referenced but not defined.`
          );
        }
      });
    }
  });
}

export function ValidateCaseFlowConfiguration(config: CaseFlowConfiguration) {
  const validator = new CaseFlowValidator(config);

  RegisterAndValidateFacts(validator);
  ValidateActors(validator);
  ValidateStations(validator);

  return validator.issues;
}
