import { Heading } from '@rabbit/elements/shared-components';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';

export interface CardWithTitleAndChartProps {
  score: string;
  subtitle: string;
  rate: number;
  children: any;
}
export function CardWithTitleAndChart({
  score,
  subtitle,
  rate,
  children,
}: CardWithTitleAndChartProps) {
  return (
    <div className="flex flex-col bg-gray-50 p-4">
      <div className="flex items-center justify-between">
        <div className="flex-col">
          <Heading kind={'h2'}>{score}</Heading>
          <p className="font-nunito text-base text-gray-500">{subtitle}</p>
        </div>
        <div className="flex gap-1">
          <span className="text-primary-500 font-bold">{rate}%</span>
          {rate > 0 ? (
            <ArrowUpIcon className="text-primary-500 h-6 w-4 font-bold" />
          ) : (
            <ArrowDownIcon className="text-primary-500 h-6 w-4 font-bold" />
          )}
        </div>
      </div>
      <div className="mt-2">{children}</div>
    </div>
  );
}

export default CardWithTitleAndChart;
