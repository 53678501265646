import { Input } from '@rabbit/elements/shared-components';
import { Formik } from 'formik';

export interface ProductsAndPartsFiltersProps {
  title: string;
  items: Array<FiltersArrShape>;
  onChange: (value: string) => void;
  activeCategory: string;
}

export interface FiltersArrShape {
  checkboxName: string;
  label: string;
  count: number;
}

export function ProductsAndPartsFilters({
  title,
  items,
  onChange,
  activeCategory,
}: ProductsAndPartsFiltersProps) {
  const initialValues = {
    [activeCategory]: true,
  };

  return (
    <Formik initialValues={initialValues} disabled onSubmit={() => void 0}>
      {(props: any) => (
        <div className="font-nunito flex min-w-[240px] max-w-[560px] flex-col bg-white p-3 shadow-md">
          <div className="mb-3">{title}</div>
          {items?.map((item, index) => {
            return (
              <div key={index} className="mb-2 flex items-center">
                <Input
                  type="checkbox"
                  name={item.label}
                  settings={{
                    disabled: !activeCategory.length
                      ? false
                      : activeCategory === item.label
                      ? false
                      : true,
                    onChange: onChange,
                    checkboxLabel: item.label,
                    checkboxLabelStyles: 'pl-2',
                  }}
                />
                <span
                  className={
                    props.values[item.label]
                      ? 'pl-1'
                      : 'text-gray-500 opacity-30'
                  }
                >
                  ({item.count})
                </span>
              </div>
            );
          })}
        </div>
      )}
    </Formik>
  );
}

export default ProductsAndPartsFilters;
