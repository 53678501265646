import { Address } from '@rabbit/data/types';
import { ModalNewRegistration_ConfigShape_CharlieOven } from './charlieoven';
import { ModalNewRegistration_ConfigShape_Generic } from './common-generic-flow';

/* -------------------------------------------------------------------------- */
/*                                Generic types                               */
/* -------------------------------------------------------------------------- */

/* ------------------------------- Field names ------------------------------ */

export enum CreateNewConsumerForm_FieldNames_Generic {
  consumer_first_name = 'consumer_first_name',
  consumer_last_name = 'consumer_last_name',
  consumer_email = 'consumer_email',
  consumer_telephone = 'consumer_telephone',
  consumer_preferred_contact = 'consumer_preferred_contact',
  consumer_address = 'consumer_address',
  consumer_address_country = 'consumer_address.country',
  consumer_address_line1 = 'consumer_address.address_line_1',
  consumer_address_line2 = 'consumer_address.address_line_2',
  consumer_address_town = 'consumer_address.town',
  consumer_address_state = 'consumer_address.state',
  consumer_address_postcode = 'consumer_address.postcode',
}

/* ------------------------------- Form values ------------------------------ */

// Is extended by tenant configs
export interface CreateNewConsumerForm_FormValues_Generic {
  consumer_first_name: string;
  consumer_last_name: string;
  consumer_email: string;
  consumer_telephone: string;
  consumer_telephone_e164: string;
  consumer_preferred_contact: string[];
  consumer_address: Address;
}

/* -------------------------------------------------------------------------- */
/*                                Charlie Oven                                */
/* -------------------------------------------------------------------------- */

export enum CreateNewConsumerForm_FieldNames_CharlieOven {
  consumer_is_commercial = 'consumer_is_commercial',
}

export interface CreateNewConsumerForm_FormValues_CharlieOven
  extends CreateNewConsumerForm_FormValues_Generic {
  consumer_is_commercial: string;
}

/* -------------------------------------------------------------------------- */
/*                               Combined types                               */
/* -------------------------------------------------------------------------- */

export type CreateNewConsumerForm_FormValues =
  CreateNewConsumerForm_FormValues_Generic &
    CreateNewConsumerForm_FormValues_CharlieOven;

export const CreateNewConsumerForm_FieldNames = {
  ...CreateNewConsumerForm_FieldNames_Generic,
  ...CreateNewConsumerForm_FieldNames_CharlieOven,
};

export type CreateNewConsumerForm_FieldNamesShape =
  | CreateNewConsumerForm_FieldNames_Generic
  | CreateNewConsumerForm_FieldNames_CharlieOven;

export type ModalNewRegistration_ConfigShape =
  ModalNewRegistration_ConfigShape_Generic &
    ModalNewRegistration_ConfigShape_CharlieOven;
