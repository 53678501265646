import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import { Dispatch, SetStateAction } from 'react';

export const validationSchema = Yup.object().shape({
  type: Yup.string().trim().required('Select type of stone'),
  area: Yup.number().positive("Enter a valid number").required('Enter stone area')
    .typeError('Enter a valid number'),
  sealing: Yup.boolean()
});

export interface CreateNewSelectStoneProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: any;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface ConsumerDataShape {
  type: string;
  area: string;
  sealing: boolean;
}

export function CreateNewSelectStone(props: CreateNewSelectStoneProps) {
  const { onChange, handleClose } = props;

  const initialValues: ConsumerDataShape = {
    sealing: false
  } as any;

  const onSubmit = async (values: ConsumerDataShape, { setFieldError }: FormikProps<ConsumerDataShape>) => {
    const { type, area, sealing } = values;
    if (!Number(area)) return setFieldError('area', 'Enter a valid number');
    onChange(1, { surfaceArea: Number(area), typeOfStone: type, sealing });
  };

  const stoneTypeOptions = [
    { id: '0', label: 'Marble', value: 'marble' },
    { id: '1', label: 'Granite', value: 'granite' },
    { id: '2', label: 'Engineered Stone', value: 'engineered-stone' },
    { id: '3', label: 'Limestone', value: 'limestone' },
    { id: '4', label: 'Quartzite', value: 'quartzite' },
    { id: '5', label: 'Porcelain', value: 'porcelain' },
    { id: '6', label: 'Travertine', value: 'travertine' },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit as any}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => (
        <Form className="flex-col gap-3 p-5">
          <div className="mb-4 flex gap-4">
            <Input
              type="select"
              label="Type of stone*"
              name="type"
              settings={{
                id: 'type',
                placeholder: 'Please select an option',
                options: stoneTypeOptions,
                hint: '*required',
              }}
            />
            <Input
              type="text"
              label="Area*"
              name="area"
              settings={{
                id: 'area',
                placeholder: 'Please enter area',
                hint: '*required',
                allowSpecialCharacter: true,
                append: "m²"
              }}
            />
          </div>
          <div className="mb-4 flex">
            <Input
              type="checkbox"
              name="sealing"
              settings={{
                id: "sealing",
                checkboxLabel: "Add sealing"
              }}
            />
          </div>
          <div className="mt-5 flex w-full gap-4">
            <Button
              kind="primary"
              type="submit"
              disabled={
                Object.keys(props.errors).length > 0 ||
                  Object.keys(props.values).length === 0
                  ? true
                  : false
              }
            >
              Continue
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateNewSelectStone;
