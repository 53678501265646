import {
  ConsumerLink,
  RetailerLink,
  WarrantorLink,
  Z_ConsumerLink,
  Z_RetailerLink,
  Z_WarrantorLink,
} from '../persona';
import {
  Money,
  UploadedDocumentUrls,
  Z_Money,
  Z_UploadedDocumentUrls,
  DateTime,
  Z_Address,
} from '../base/basic';
import {
  HoldingLink,
  SelfRegistration,
  Z_HoldingLink,
  Z_SelfRegistration,
} from './holding';
import {
  DTWarranty_Template,
  WarrantyTemplateLink,
  Z_DTWarranty_Template,
  Z_WarrantyTemplateLink,
} from './warranty-template';
import { TenantLink, Z_TenantLink } from './tenant';
import { DeciderOutput, Z_DeciderOutput } from '../decider/types';
import { z, schema } from '@rabbit/utils/ts';
import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { OurDateTime } from '@rabbit/utils/ts';
import { WarrantyOfferLink, Z_WarrantyOfferLink } from './warranty-offer';

/* -------------------------------------------------------------------------- */
/*                                  Warranty                                  */
/* -------------------------------------------------------------------------- */
export interface AppliedWarrantyOption {
  key: string;
  value: string;
}

export const Z_AppliedWarrantyOption = z.object({
  key: z.string(),
  value: z.union([z.string(), z.number(), z.boolean()]),
});

export enum WarrantyStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  SUSPENDED = 'suspended',
  CANCELLED = 'cancelled',
  VOIDED = 'voided',
  ARCHIVED = 'archived',
}

export enum WarrantyHistoryReason {
  EDITED = 'edited',
  VOIDED = 'voided',
  // Add other reasons as needed
}

export const Z_WarrantyStatus = z.nativeEnum(WarrantyStatus);

/** An instance of a warranty for a given holding, awarded to a consumer by a manufacturer
 * or sold to them by a retailer. It is linked to both a warranty template (which defines it) and
 * a holding (which it is associated with).
 */
export interface DTWarranty extends NoSqlDoc {
  /** The warranty template for this warranty instance */
  templateLink: WarrantyTemplateLink;

  /** The name of the template (or offer) used to generate this warranty instance */
  templateName: string;

  /** The warranty offer used to generate this warranty instance, if applicable */
  offerLink?: WarrantyOfferLink;

  /** The holding this warranty instance is associated with */
  holdingLink: HoldingLink;

  /** The self-registration data for the holding associated with this warranty, as recorded when the holding and warranty were created */
  SRVSnapshot?: SelfRegistration | null;

  /** The consumer this warranty instance is associated with */
  consumerLink: ConsumerLink;

  /** The warrantor responsible for handling this warranty and any associated claims */
  warrantorLink: WarrantorLink;

  /** The retailer who sold this warranty and registered it */
  retailerLink?: RetailerLink;

  /** The (usually premium) tenant this warranty instance is associated with */
  tenantLink: TenantLink;

  /** A copy of warranty template for this warranty instance, as it was when this warranty instance was first created */
  snapshot: DTWarranty_Template;

  /** @deprecated - Decider will be used from now on - The specific option values applied to this warranty when it was created */
  appliedOptions: AppliedWarrantyOption[];

  /** The start date of the warranty instance */
  startDate: number;

  /** The end date of the warranty instance */
  endDate: number;

  /** The duration of the warranty, stored in ISO 8601 format */
  duration: string;

  /** The current status of the warranty */
  status: WarrantyStatus;

  /** The price paid for this warranty, if applicable */
  price?: Money;

  /** Evidence of the warranty, eg. a receipt */
  evidence?: UploadedDocumentUrls;

  /** When using Decider to determine a warranty, put the output here for future reference */
  decider?: DeciderOutput;

  /** Who last updated this vendable, TenantLink or PersonaLink */
  updatedBy: string;

  /** warrantor's sales rep name who issued the warranty */
  salesRep?: string;

  /** Timestamp when the warranty instance was registered.*/
  registeredTime: DateTime;

  /** Whether the warranty covers commercial use of the associated holding */
  commercialUse?: boolean; // DC - Speaking in purely logical terms, this should be in holding (as well?) but I can't see the use for it there right now and it's easy enough to migrate in the future if needed
}

export const FBD_Warranty = MakeFBDocType<DTWarranty>({
  name: 'Warranty',
  collection: 'warranty',
  empty: () => {
    const result: DTWarranty = {
      ...Empty_NoSqlDoc(),
      templateLink: '',
      templateName: '',
      holdingLink: '',
      warrantorLink: '',
      tenantLink: '',
      startDate: new Date().getTime(),
      endDate: new Date().getTime(),
      consumerLink: '',
      appliedOptions: [],
      snapshot: {} as DTWarranty_Template,
      status: WarrantyStatus.DRAFT,
      duration: 'P0D',
      updatedBy: '',
      salesRep: '',
      registeredTime: OurDateTime.nowUTCTimestamp(),
    };
    return result;
  },
  keygen: FBDTKeygenGeneric, // DC: WarrantyTemplate id + holding id
});

export type WarrantyLink = string;

export const Z_WarrantyLink = z.string();

export const Z_Warranty = schema<DTWarranty>()(
  z
    .object({
      templateLink: Z_WarrantyTemplateLink,
      templateName: z.string().optional(),
      offerLink: Z_WarrantyOfferLink.optional(),
      holdingLink: Z_HoldingLink,
      consumerLink: Z_ConsumerLink,
      warrantorLink: Z_WarrantorLink,
      retailerLink: z.optional(Z_RetailerLink),
      tenantLink: Z_TenantLink,
      snapshot: Z_DTWarranty_Template,
      appliedOptions: z.array(Z_AppliedWarrantyOption),
      startDate: z.number(),
      endDate: z.number(),
      status: Z_WarrantyStatus,
      price: z.optional(Z_Money),
      evidence: z.optional(Z_UploadedDocumentUrls),
      decider: z.any().optional(), // todo
      //decider: Z_DeciderOutput.optional(),
      SRVSnapshot: Z_SelfRegistration.optional(),
      salesRep: z.string().or(z.null()).or(z.undefined()),
      registeredTime: z.number(),
      commercialUse: z.boolean().optional(),
    })
    .merge(Z_NoSqlDoc)
);

export const Z_ConsumerInfo = z.object({
  firstName: z.string(),
  lastName: z.string(),
  consumerEmail: z.string().email(),
  phoneNumber: z.string(),
  address: z.optional(Z_Address),
});
