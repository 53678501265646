import { NoSqlDoc } from '../../../../../firebase/doctype/src';

export enum PortalEditingDocumentStatus {
  /** Booting up - not ready to edit yet */
  preparing = 'PREPARING',
  /** There is a problem. Check the error field. */
  error = 'ERROR',
  /** Document is new (not found in firebase) */
  new = 'NEW',
  /** Document is saved in firebase. This state used when first loading an existing document, and after it has been saved to firebase. */
  saved = 'SAVED',
  /** Document has been edited locally but not saved to firebase. */
  edited = 'EDITED',
}

export type PortalDocumentEditorErrorClass = 'FIREBASE' | 'CREATION';

export type PortalDocumentEditorError = {
  class: PortalDocumentEditorErrorClass;
  detail: string;
  fullmessage: string;
  humanmessage: string;
} | null;

export type PortalEditingDocumentEntry<T extends NoSqlDoc = any> = {
  /** The firebase record as saved in the cloud. Used for comparison purposes - eg local edits, updates by other users. */
  firebase?: T;

  /** The document as edited by the user. */
  local: T;

  status: PortalEditingDocumentStatus;

  lastTouched: number;

  error: PortalDocumentEditorError;

  cloudsaved: boolean;

  docid: string;

  version: 2;
};

export type EditingInstanceConfig<TYPE extends NoSqlDoc> = {
  entryChanged: (doc: PortalEditingDocumentEntry<TYPE>) => void;
  allowCreate?: boolean;
};

export type EditingInstanceHook<TYPE> = {
  update: (newBody: TYPE) => void;
  commit: () => Promise<void>;
  unsubscribe: () => void;
};
