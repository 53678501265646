import {
  ApplyFirebaseFunctions,
  FBFCollectionRef,
  FBFDefaultDocumentType,
  FBFDocumentRef,
  FBFDocumentResult,
  FBFQuery,
  FirebaseFunctionsSet,
  HttpsCallable,
} from '@rabbit/firebase/adapter';
import {
  collection,
  CollectionReference,
  doc,
  query,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  orderBy,
  limit,
  setDoc,
  where,
  Firestore,
  deleteDoc,
  QueryConstraint,
} from 'firebase/firestore';
import { httpsCallable, Functions } from '@firebase/functions';
import {
  FBA_React_CollectionRefSecret,
  FBA_React_DocumentRefSecret,
} from './types';
import { apiCallable } from './apiCallable';
import { globalApiPrefix } from '@rabbit/firebase/doctype';

console.log('FirebaseWrapper - Frontend');

let firebaseStore: Firestore;
let firebaseFunctions: Functions;

export function SetFirestore(firestore: Firestore, functions: Functions) {
  firebaseStore = firestore;
  firebaseFunctions = functions;
}

class FBF_React_Query<T> extends FBFQuery<T> {
  async get() {
    const constraintArray: QueryConstraint[] = [];

    for (const constraint of this.constraints) {
      switch (constraint.kind) {
        case 'where':
          constraintArray.push(
            where(constraint.field, constraint.operator, constraint.value)
          );
          break;
        case 'orderBy':
          constraintArray.push(orderBy(constraint.field, constraint.direction));
          break;
        case 'limit':
          constraintArray.push(limit(constraint.count));
          break;
      }
    }

    const q = query(
      collection(firebaseStore, this.collection),
      ...constraintArray
    );

    const snapshot = await getDocs(q);

    const result: FBFDocumentResult<T>[] = [];
    for (const doc of snapshot.docs) {
      result.push({
        body: doc.data() as T,
      });
    }

    return result;
  }
}

const FirebaseReactFunctions: FirebaseFunctionsSet = {
  collection: <T = FBFDefaultDocumentType>(collectionName: string) => {
    const result: FBFCollectionRef<T, FBA_React_CollectionRefSecret<T>> = {
      _secret: {
        ref: collection(
          firebaseStore,
          collectionName
        ) as CollectionReference<T>,
      },
    };
    return result;
  },
  doc: <T = FBFDefaultDocumentType>(collectionName: string, id: string) => {
    const result: FBFDocumentRef<T, FBA_React_DocumentRefSecret<T>> = {
      _secret: {
        ref: doc(firebaseStore, collectionName, id) as DocumentReference<T>,
      },
      id,
    };
    return result;
  },
  getDoc: async <T = FBFDefaultDocumentType>(
    doc: FBFDocumentRef<T, FBA_React_DocumentRefSecret<T>>
  ) => {
    const snapshot = (await getDoc(doc._secret.ref)) as DocumentSnapshot<T>;

    if (snapshot.exists()) {
      return {
        body: snapshot.data() as T,
      };
    }
    return {
      body: null,
    };
  },
  setDoc: async <T = FBFDefaultDocumentType>(
    doc: FBFDocumentRef<T, FBA_React_DocumentRefSecret<T>>,
    body: T
  ) => {
    await setDoc(doc._secret.ref, body);
  },
  deleteDoc: async <T = FBFDefaultDocumentType>(
    doc: FBFDocumentRef<T, FBA_React_DocumentRefSecret<T>>
  ) => {
    await deleteDoc(doc._secret.ref);
  },
  query: <T = FBFDefaultDocumentType>(collectionName: string) => {
    return new FBF_React_Query<T>(collectionName);
  },
  cloudcall: async (name: string, params: any) => {
    if (!CloudFunctionCollection[name]) {
      CloudFunctionCollection[name] = httpCallFactory(name);
    }
    return await CloudFunctionCollection[name](params);
  },
};

const CloudFunctionCollection: { [key: string]: HttpsCallable } = {};

ApplyFirebaseFunctions(FirebaseReactFunctions);

function httpCallFactory(name: string) {
  if (name.startsWith(globalApiPrefix)) {
    return apiCallable(firebaseFunctions, name);
  } else {
    return httpsCallable(firebaseFunctions, name);
  }
}
