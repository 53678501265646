import { z, schema } from '@rabbit/utils/ts';
import {
  FBDTKeygenGeneric,
  FBDT_KeygenFunction,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                                 Delegation                                 */
/* -------------------------------------------------------------------------- */
export enum Permissions {
  /** permissions from Notion https://www.notion.so/iwarranty/Security-a8c3b0832cc345a891457e3f3e780f0b?pvs=4 */
  TenantsView = 'tenants_view',
  TenantsEdit = 'tenants_edit',
  UsersView = 'users_view',
  UsersEdit = 'users_edit',
  UsersDelete = 'users_delete',
  CasesView = 'cases_view',
  CasesEdit = 'cases_edit',
  DashboardView = 'dashboard_view',
  RegistrationsView = 'registrations_view',
  RegistrationsEdit = 'registrations_edit',
  RetailersView = 'retailers_view',
  RetailersEdit = 'retailers_edit',
  RepairersView = 'repairers_view',
  RepairersEdit = 'repairers_edit',
  RolesView = 'roles_view',
  RolesEdit = 'roles_edit',
  ConsumersView = 'consumers_view',
  ConsumersEdit = 'consumers_edit',
  WarrantyOffersView = 'warranty_offers_view',
  WarrantyOffersEdit = 'warranty_offers_edit',
  VendablesDetailView = 'vendables_detail_view',
  VendablesDetailEdit = 'vendables_detail_edit',

  // A special technical to allow a user to see their own stuff
  Owner = 'owner',
}

export const Z_Permissions = z.nativeEnum(Permissions);

export type DelegationMapping = {
  [userId: string]: Permissions[];
};

const Z_DelegationMapping = schema<DelegationMapping>()(
  z.record(z.array(Z_Permissions))
);

/* -------------------------------------------------------------------------- */
/*                                  DTPersona                                 */
/* -------------------------------------------------------------------------- */

/** The base definition for a persona.
 * You never create one of these. You create a DTConsumer, DTManufacturer, etc.
 */
export interface DTPersona extends NoSqlDoc {
  /** List of all the personas that have delegate access to this persona's stuff. */
  delegates: DelegationMapping;

  /** Identity linked to the persona. If there is no identity (eg manufacturers) then it is empty string. */
  identity: string;

  /** If set to 1 then persona will not be widely accessible (eg don't appear in searches) */
  internal?: number;

  /** Time the persona was created (unix timestamp) */
  createTime: number;

  /** Principals, so links to Personas that can read and write(!) this document */
  principals?: PersonaLink[];
}

/** The base definition for a public persona.
 * You never create one of these. You create a DTRepairer_Public, DTManufacturer_Public, etc.
 */
export interface DTPersonaPublic extends NoSqlDoc {
  /** Principals, so links to Personas that can read and write(!) this document */
  principals?: PersonaLink[];
}

export const Z_DTPersona = schema<DTPersona>()(
  z
    .object({
      delegates: Z_DelegationMapping,
      identity: z.string(),
      internal: z.number().optional(),
      createTime: z.number(),
    })
    .merge(Z_NoSqlDoc)
);

export const Z_DTPersonaPublic = schema<DTPersonaPublic>()(
  z.object({
    principals: z.array(z.string()).optional(),
  })
);

export type PersonaDefinition = {
  name: string;
};

/** Link to a persona */
export type PersonaLink = string;

export const Z_PersonaLink = z.string();

export function FBD_Keygen_Persona(
  type_letter: string
): FBDT_KeygenFunction<any> {
  // Return a super random number using date and random
  return (body: any, type) => {
    return `${type_letter}:${FBDTKeygenGeneric(body, type)}`;
  };
}
