import ausFlag from '../../../assets/icons/aus-flag.svg';
import gbpFlag from '../../../assets/icons/gbp-flag.svg';
import usdFlag from '../../../assets/icons/usd-flag.svg';
import CurrencyInput from 'react-currency-input-field';
import { Field, useFormikContext } from 'formik';
import classNames from 'classnames';
import { InputTypeCurrencySettingsShape } from '@rabbit/elements/shared-types';
import {
  getCurrencyByCode,
  handleKeyPress,
} from '@rabbit/elements/shared-components';
import { LIST_CURRENCIES } from '@rabbit/bizproc/react';
import { tr } from 'date-fns/locale';
import { useEffect } from 'react';

//TODO: Turn currency flag into a select element with a default value of currency

export interface InputTypeCurrencyProps {
  name: string;
  settings: InputTypeCurrencySettingsShape;
}

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 rounded-r-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 focus:ring-red-500 text-red-700',
};

function StyledCurrencyInput({
  field,
  placeholder,
  setFieldValue,
  currency,
  form: { touched, errors },
  parentName,
  ...props
}: any) {
  let errorField = false;
  if (errors[parentName]?.amount) errorField = true;

  return (
    <CurrencyInput
      //{...field}
      //{...props}
      className={classNames(`${dynamicStyles.base}`, {
        [`${dynamicStyles.error}`]: errorField,
      })}
      // name={field.name}
      value={field.value}
      placeholder={placeholder}
      onValueChange={(value) => {
        const commaIndex = value?.indexOf(',');

        if (value !== undefined) {
          if (
            commaIndex === value?.length - 2 ||
            commaIndex === value?.length - 3
          ) {
            return setFieldValue(field.name, value?.replace(',', '.'));
          }
        }
        setFieldValue(field.name, value ? Number(value) : '');
      }}
      // groupSeparator={currency === 'EUR' ? '.' : ','}
      decimalSeparator={currency === 'EUR' ? ',' : '.'}
      intlConfig={{
        locale: currency === 'EUR' ? 'de-DE' : 'en-UK',
        currency: currency
          ? currency
          : import.meta.env.VITE_DEFAULT_COUNTRY
          ? import.meta.env.VITE_DEFAULT_COUNTRY
          : 'AUD',
      }}
      allowDecimals={true}
      prefix={getCurrencyByCode(currency)?.symbolNative}
      maxLength={9}
      disabled={props.disabled}
      // fixedDecimalLength={2}
      // transformRawValue={(value) => {
      //   return value.replace(/[^\d.,]/g, '');
      // }}
    />
  );
}

export function InputTypeCurrency({ name, settings }: InputTypeCurrencyProps) {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const currencyMeta = getFieldMeta(`${name}.currency`);
  const {
    id,
    placeholder,
    currency,
    inputTypeCurrencyOverrideOptions,
    disableCurrency,
    disabled,
    ...rest
  } = settings;

  const getFlag = () => {
    // Added the currency prop to the settings object to allow for a default currency to be passed in
    // Leaving the condition below here as both an example and a reminder that the currency bit needs to be turned into a select element

    if (currency === 'AUD') return ausFlag;
    if (currency === 'USD') return usdFlag;

    if (!import.meta.env.VITE_DEFAULT_COUNTRY) return ausFlag;
    switch (import.meta.env.VITE_DEFAULT_COUNTRY) {
      case 'GBP':
        return gbpFlag;
      case 'USD':
        return gbpFlag;
      default:
        return ausFlag;
    }
  };

  const onCurrencyChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    void setFieldValue(`${name}.currency`, value);
  };

  return (
    <div className="flex">
      <select
        onChange={onCurrencyChange}
        value={(currencyMeta.value as string) || currency}
        className={
          'font-nunito max-w-[135px] rounded-l-lg border border-gray-300 bg-gray-100 text-base text-gray-500'
        }
        disabled={disabled || disableCurrency}
      >
        {!inputTypeCurrencyOverrideOptions &&
          LIST_CURRENCIES.map((currencyObj, index) => {
            return (
              <option value={currencyObj.code} key={index}>
                {currencyObj.code}
              </option>
            );
          })}
        {inputTypeCurrencyOverrideOptions &&
          inputTypeCurrencyOverrideOptions.map((option) => {
            return (
              <option value={option.value} key={option.id}>
                {option.label}
              </option>
            );
          })}
      </select>
      {/* <div className="z-1 inline-flex flex-shrink-0 items-center rounded-l-lg border border-gray-300 bg-gray-100 py-2.5 px-4 text-center text-sm font-medium text-gray-900 dark:border-gray-700 dark:text-white ">
        <img src={getFlag()} alt="flag" className="mr-2 h-[14px] w-5"></img>
        {currency
          ? currency
          : import.meta.env.VITE_DEFAULT_COUNTRY
          ? import.meta.env.VITE_DEFAULT_COUNTRY
          : 'AUD'}{' '}
      </div>
      <div
        id="dropdown"
        className="hidden bg-white rounded-lg shadow z-1 w-44 divide-y divide-gray-100 dark:bg-gray-700"
      ></div> */}
      <div className="relative w-full grow">
        <Field
          name={`${name}.amount`}
          parentName={name}
          component={StyledCurrencyInput}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
          currency={currency || currencyMeta.value}
          onKeyDown={handleKeyPress}
          disabled={disabled}
          {...rest}
        />
      </div>
    </div>
  );
}

export default InputTypeCurrency;
