import {
  Annotation,
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  //Marker,
} from 'react-simple-maps';

//const geoUrl = 'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';
  const geoUrl = 'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json';

  // const markers = [
  //   {
  //     markerOffset: -40,
  //     name: "Sydney",
  //     coordinates: [-28.8688, -148.2093]
  //   },
  // ];

const MapChart = () => {
  return (
    <ComposableMap
      width={900}
      height={600}
      projection="geoEqualEarth"
      projectionConfig={{
        rotate: [250.8688, 220.2093, 190],
        scale: 400,
      }}
    >
      <Graticule stroke="#EAEAEC" />
      <Geographies geography={geoUrl} fill="#8FB5AD">
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#9998A3"
              stroke="#EAEAEC"
            />
          ))
        }
      </Geographies>
      {/* {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <g
            fill="none"
            stroke="#8FB5AD"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fill: "#8FB5AD", fontSize: 32 }}
          >
            {name}
          </text>
        </Marker>
      ))} */}
      <Annotation
        //subject={[-28.8688, -148.2093]}
        subject={[63.8688, -215.2093]}
        dx={-40}
        dy={20}
        connectorProps={{
          stroke: '#8FB5AD',
          strokeWidth: 6,
          strokeLinecap: 'round',
        }}
        fill="#8FB5AD"
      >
        <text
          x="00"
          y="30"
          textAnchor="end"
          alignmentBaseline="central"
          fill="#8FB5AD"
          className="font-caudex text-3xl font-bold"
        >
          {'Los Angeles'}
        </text>
      </Annotation>
    </ComposableMap>
  );
};

export default MapChart;