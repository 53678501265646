import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                                   Caches                                   */
/* -------------------------------------------------------------------------- */

export interface DTCache extends NoSqlDoc {
  data: any;
  requestId?: string,
  source?: any;
}

export enum CacheClient {
  VehicleInfo = 'VehicleInfo',
}

export const FBD_Cache = MakeFBDocType<DTCache>({
  name: 'Cache',
  collection: 'cache',
  empty: () => {
    const result: DTCache = {
      ...Empty_NoSqlDoc(),
      data: null,
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});
