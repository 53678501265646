import {
  CFC_GetApprovedWarrantyOffers,
  CFC_GetAvailableWarrantyTemplates,
  CFC_RegisterSRVHoldingWithWarranty,
  CFC_RegisterWarranty,
  CFC_VoidWarranty,
  CFC_GetAllWarrantyOffers_Full,
  ICF_GetAllWarrantyOffers_Full,
  OCF_GetAllWarrantyOffers_Full,
  ICF_CreateWarrantyOfferForPartner,
  CFC_CreateWarrantyOfferForPartner,
  OCF_CreateWarrantyOfferForPartner,
  OCF_VoidWarranty,
  ICF_CommercialUseStatusWarrantyUpdate,
  OCF_CommercialUseStatusWarrantyUpdate,
  CFC_CommercialUseStatusWarrantyUpdate,
} from '@rabbit/bizproc/core';

import {
  APICallParamsBase,
  APICallResultBase,
  Warranty_GetApprovedOffersParams,
  Warranty_GetApprovedOffersResult,
  Warranty_GetAvailableTemplatesParams,
  Warranty_GetAvailableTemplatesResult,
  Warranty_RegisterSRVHoldingWithWarrantyParams,
  Warranty_RegisterWarrantyParams,
  Warranty_RegisterWarrantyResult,
  Warranty_VoidParams,
} from '@rabbit/elements/shared-types';
import { z } from 'zod';

export async function Warranty_GetAvailableTemplates({
  requestId,
  apiVersion,
  warrantor,
  parameters,
}: Warranty_GetAvailableTemplatesParams & APICallParamsBase): Promise<
  Warranty_GetAvailableTemplatesResult & APICallResultBase
> {
  const result = await CFC_GetAvailableWarrantyTemplates.call({
    requestId,
    apiVersion,
    warrantor,
    parameters,
  });
  if (!result.ok) throw new Error(result.error);

  return result as Warranty_GetAvailableTemplatesResult & APICallResultBase; // TODO: [DEV-511]
}

export async function Warranty_RegisterWarranty({
  requestId,
  apiVersion,
  warrantor,
  holding,
  templateLink,
  consumer,
  warranty,
  deciderOutput,
}: Warranty_RegisterWarrantyParams & APICallParamsBase): Promise<
  APICallResultBase & Warranty_RegisterWarrantyResult
> {
  const result = await CFC_RegisterWarranty.call({
    requestId,
    apiVersion,
    warrantor,
    holding,
    templateLink,
    consumer,
    warranty,
    deciderOutput,
  });
  if (!result.ok) throw new Error(result.error);
  return result;
}

// same as above, but for srvs and tenants using the decider only.
// Meant to eventually replace the above when the decider is used everywhere.
// using separate functions to make the transition simpler
export async function Warranty_RegisterSRVHoldingWithWarranty({
  requestId,
  apiVersion,
  warrantor,
  holding,
  templateLink,
  consumer,
  warranty,
  deciderOutput,
  offerLink,
  warrantyLink,
}: Warranty_RegisterSRVHoldingWithWarrantyParams & APICallParamsBase): Promise<
  APICallResultBase & Warranty_RegisterWarrantyResult
> {
  const result = await CFC_RegisterSRVHoldingWithWarranty.call({
    requestId,
    apiVersion,
    warrantor,
    holding,
    templateLink,
    consumer,
    warranty,
    deciderOutput,
    offerLink,
    warrantyLink,
  });
  if (!result.ok) throw new Error(result.error);
  return result;
}

export async function Warranty_GetApprovedOffers({
  requestId,
  apiVersion,
  params,
  dimensions,
  partnerTenantLink,
  warrantorLink,
}: Warranty_GetApprovedOffersParams & APICallParamsBase): Promise<
  Warranty_GetApprovedOffersResult & APICallResultBase
> {
  const result = await CFC_GetApprovedWarrantyOffers.call({
    requestId,
    apiVersion,
    params,
    dimensions,
    partnerTenantLink,
    warrantorLink,
  });
  if (!result.ok) throw new Error(result.error);

  // TODO: [DEV-511]
  return result as Warranty_GetApprovedOffersResult & APICallResultBase;
}

export async function Warranty_GetAllOffers({
  requestId,
  apiVersion,
  partnerTenantLink,
  warrantorLink,
}: z.infer<typeof ICF_GetAllWarrantyOffers_Full>): Promise<
  { data: z.infer<typeof OCF_GetAllWarrantyOffers_Full> } & APICallResultBase
> {
  const result = await CFC_GetAllWarrantyOffers_Full.call({
    requestId,
    apiVersion,
    partnerTenantLink,
    warrantorLink,
  });
  if (!result.ok) throw new Error(result.error);
  return result;
}

export const Warranty_CreateWarrantyOfferForPartner = async (
  params: z.infer<typeof ICF_CreateWarrantyOfferForPartner>
): Promise<
  {
    data: z.infer<typeof OCF_CreateWarrantyOfferForPartner>;
  } & APICallResultBase
> => {
  const result = await CFC_CreateWarrantyOfferForPartner.call(params);
  if (!result.ok) throw new Error(result.error);
  return result;
};

export async function Warranty_VoidWarranty({
  requestId,
  apiVersion,
  warrantyLink,
}: Warranty_VoidParams & APICallParamsBase): Promise<
  {
    data: z.infer<typeof OCF_VoidWarranty>;
  } & APICallResultBase
> {
  const result = await CFC_VoidWarranty.call({
    requestId,
    apiVersion,
    warrantyLink,
  });
  if (!result.ok) throw new Error(result.error);
  return result;
}

export async function Warranty_UpdateFromCommercialStatus(
  params: z.infer<typeof ICF_CommercialUseStatusWarrantyUpdate>
): Promise<
  {
    data: z.infer<typeof OCF_CommercialUseStatusWarrantyUpdate>;
  } & APICallResultBase
> {
  const result = await CFC_CommercialUseStatusWarrantyUpdate.call(params);
  if (!result.ok) throw new Error(result.error);
  return result;
}
