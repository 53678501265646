import { Formik } from 'formik';
import {
  ChatInputWrapper,
  Input,
  ChatDate,
  ChatMessage,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { GEChat } from '@rabbit/data/types';
import { fromUnixTime } from 'date-fns';
import { useEffect } from 'react';
import { useCaseFlowCase, useFileStorage } from '@rabbit/bizproc/react';
import { CaseFlowCaseEditingID } from '@rabbit/bizproc/react';
import classNames from 'classnames';

export interface ChatProps {
  messageHistory: GEChat[] | undefined;
  onSubmitMessage: (arg0: string) => void;
  activePersona: string | null;
  id: CaseFlowCaseEditingID;
  updateChatLastSeen: () => void;
  isReadOnly?: boolean;
}
export interface MessageGroupProps {
  day: number;
  messages: MessageProps[];
}
export interface MessageProps {
  a: string;
  body: string;
  k: number;
  t: number;
  attachment?: string;
}

interface FormValues {
  chat_input: string;
}

const initialValues: FormValues = {
  chat_input: '',
};

export function Chat({
  messageHistory,
  onSubmitMessage,
  activePersona,
  id,
  updateChatLastSeen,
  isReadOnly=false
}: ChatProps) {
  const { caseFlowCase } = useCaseFlowCase(id);
  const { uploadFiles, uploadedFiles, clearAllFilesFromState, isUpdating } =
    useFileStorage();

  const getDaysArray = (messageHistory: GEChat[] | undefined) => {
    const daysMap = new Map();

    messageHistory &&
      messageHistory.forEach((obj) => {
        let timestamp = obj.t / 1000;
        // NOW! That's what I call a dirty hack!
        if (timestamp < 505572647) {
          timestamp = Date.now() / 1000;
        }
        const date = fromUnixTime(timestamp);
        const day = date.getDate();

        if (!daysMap.has(day)) {
          daysMap.set(day, {
            day: new Date(date.getFullYear(), date.getMonth(), day),
            messages: [],
          });
        }

        daysMap.get(day).messages.push({ ...obj, t: timestamp });
      });

    return Array.from(daysMap.values());
  };

  const isCustomer = activePersona?.startsWith('C:');

  const messagesPerDays = getDaysArray(messageHistory);

  const handleUploadFiles = async (files: File[]) => {
    await uploadFiles(files, activePersona ?? '', 'chat_attachment');
  };

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    void handleUploadFiles(chosenFiles);
  };

  useEffect(() => {
    if (uploadedFiles?.filesArr) {
      caseFlowCase?.Alter_Chat('', uploadedFiles?.filesArr?.[0].url ?? '');
      void caseFlowCase?.Commit();
      clearAllFilesFromState();
    }
  }, [uploadedFiles?.filesArr]);

  return (
    <div className="flex h-full max-h-[560px] flex-col justify-end md:max-h-[430px] lg:max-h-[640px] lg:rounded-md lg:border lg:border-gray-200">
      <div className="flex flex-col-reverse overflow-auto px-3 pb-5 lg:pb-2">
        <div>
          {messagesPerDays &&
            messagesPerDays.map((messageGroup: MessageGroupProps, index) => {
              return (
                <div key={index}>
                  <ChatDate date={messageGroup.day} />
                  {messageGroup.messages.map((message: MessageProps, index) => (
                    <ChatMessage
                      key={index}
                      wasSentByUser={message.a === activePersona ? true : false}
                      message={message.body}
                      timestamp={message.t}
                      image={message.attachment}
                    />
                  ))}
                </div>
              );
            })}
        </div>
      </div>
      {isUpdating && (
        <div className="pb-2">
          <LoadingSpinner size="xs" />
        </div>
      )}
      <Formik initialValues={initialValues} onSubmit={() => void 0}>
        {({ values, resetForm }) => (
          <ChatInputWrapper
            className={classNames(
              'flex w-full gap-4 rounded-md bg-gray-50 p-5 lg:static',
              {
                [`fixed bottom-0 left-0`]: isCustomer,
                'hidden': isReadOnly
              }
            )}
            handleImgSelection={handleFileEvent}
            handleSubmit={() => {
              if (values.chat_input.trim() === '') return;
              onSubmitMessage(values.chat_input);
              resetForm();
            }}
          >
            <form
              className="w-full"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  if (values.chat_input.trim() === '') return;
                  onSubmitMessage(values.chat_input);
                  resetForm();
                }
              }}
            >
              <Input
                type="text"
                name="chat_input"
                settings={{
                  id: 'chat_input',
                  placeholder: 'Write text here ...',
                  allowSpecialCharacter: true,
                  onFocus: updateChatLastSeen,
                }}
              />
            </form>
          </ChatInputWrapper>
        )}
      </Formik>
    </div>
  );
}

export default Chat;
