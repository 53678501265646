export interface ToggleProps {
  checked: boolean;
  onChange: () => void;
  label?: string;
  disabled?: any;
  name?: string;
}

export function Toggle({
  checked,
  onChange,
  label,
  disabled,
  name,
}: ToggleProps) {
  return (
    <div className={'flex items-start gap-2' + (disabled ? ' opacity-70' : '')}>
      {label && (
        <span className="font-nunito mb-2 text-base text-gray-900">
          {label}
        </span>
      )}
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          className="peer sr-only"
          name={name}
          value=""
          defaultChecked={checked}
          onClick={onChange}
          disabled={disabled}
        />
        <div className="peer-checked:bg-primary-900 h-6 w-11 rounded-full bg-gray-200  after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-full"></div>
      </label>
    </div>
  );
}

export default Toggle;
