import {
  DTVendable,
  DTVendable_Private,
  DTWarranty_Template,
  FBD_Vendable,
  FBD_Vendable_Private,
  FBD_Warranty_Template,
  GenerateTenantRootPersonaLink,
  PersonaIdTypeSplitter,
  PersonaTypeFullKeyValues,
  PersonaTypeSingleLetter,
  TenantLinkFieldName,
  VendableFull,
} from '@rabbit/data/types';
import {
  GetUniqueCategoryList,
  SearchVendables,
} from '@rabbit/search/cherchons';
import { t } from 'i18next';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useSageAPI } from '../../hooks/api/useSageAPI';
import { useLocation } from 'react-router-dom';

export interface AlgoliaVendableList {
  items: DTVendable[];
  numitems: number;
  numpages: number;
  page: number;
}
interface FacetFiltersShape {
  name: string;
  value: string;
}

interface CategoryDataShape {
  label: string;
  count: number;
}
interface VendableSearchParamsShape {
  searchQuery: string;
  hitsPerPage: number;
  page: number;
  facetFilters: FacetFiltersShape[];
}
type RefreshListShape = false | number; // The number is the time in ms to wait before refreshing

interface CreateOrUpdateVendableParamsShape {
  publicData: Partial<DTVendable>;
  privateData: Partial<DTVendable_Private>;
  vendableId?: string;
  newVendableId?: string;
}
interface VendableEditorContextShape {
  shouldRefresh: false | number;
  setShouldRefresh: React.Dispatch<React.SetStateAction<RefreshListShape>>;
  vendableList: DTVendable[];
  totalHits: number;
  totalPages: number;
  getSingleVendable: (docid: string) => Promise<VendableFull | null>;
  searchParams: VendableSearchParamsShape;
  changeSearchParams: (newParams: Partial<VendableSearchParamsShape>) => void;
  createOrUpdateVendable: (
    params: CreateOrUpdateVendableParamsShape
  ) => Promise<string | undefined>;
  categoryList: CategoryDataShape[];
  getSingleWarrantyTemplate: (
    docid: string
  ) => Promise<DTWarranty_Template | null>;
  getWarrantyTemplates: () => Promise<DTWarranty_Template[]>;
}

const VendableEditorContext = createContext<VendableEditorContextShape>(
  {} as VendableEditorContextShape
);
// const VendableEditorContext = createContext<VendableEditorContextShape | null>(
//   null
// );

type VendableEditorProviderWrapperProps = {
  children: React.ReactNode;
};

/* -------------------------------------------------------------------------- */
/*                              Provider Wrapper                              */
/* -------------------------------------------------------------------------- */

const VendableEditorProviderWrapper = ({
  children,
}: VendableEditorProviderWrapperProps) => {
  const location = useLocation();

  const { createOrUpdateVendable: COUVendable } = useSageAPI();
  const [searchParams, setSearchParams] = useState<VendableSearchParamsShape>({
    hitsPerPage: 10,
    page: 0,
    searchQuery: '',
    facetFilters: [],
  });
  const [shouldRefresh, setShouldRefresh] = useState<RefreshListShape>(0);
  const [vendableList, setVendableList] = useState<DTVendable[]>([]);
  const [totalHits, setTotalHits] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [categoryList, setCategoryList] = useState<CategoryDataShape[]>([]);

  const { searchQuery, hitsPerPage, page, facetFilters } = searchParams;

  // For now at least, we only want to search the active premium tenant's vendables, so we always add it
  facetFilters.push({
    name: 'mfr',
    value: GenerateTenantRootPersonaLink(
      PersonaTypeFullKeyValues.manufacturer,
      t(TenantLinkFieldName)
    ),
  });

  useEffect(() => {
    if (
      shouldRefresh !== false &&
      location.pathname + location.search === '/manage?tab=products'
    ) {
      // This fetch should only be called when the user is on the products tab
      // And this is an admittedly hacky way to ensure that it works with the current FE architecture
      // For that portion of the application. Hard, hard pass on doing it right now, so this works
      // TODO: evaluate if we should move the ROUTE_NAME constants from Olive and Sage to a shared location
      // So we can use them below (and possibly in other places too)

      // Since we're using Algolia, we need to throttle a request after a vendable update a little bit
      // so we give Algolia time to update its index
      setTimeout(async () => {
        const res: AlgoliaVendableList = await SearchVendables(searchQuery, {
          hitsPerPage,
          page,
          facetFilters,
        });

        setVendableList(res.items);
        const categoryList = GetUniqueCategoryList(res.items);

        setCategoryList(categoryList);
        setTotalHits(res.numitems);
        setTotalPages(res.numpages);
        setShouldRefresh(false);
      }, shouldRefresh);
    }
  }, [
    shouldRefresh,
    searchQuery,
    hitsPerPage,
    page,
    facetFilters,
    location.pathname,
    location.search,
  ]);

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */

  /** Fetches a single vendable's data from the public and private collections */
  async function getSingleVendable(docid: string) {
    const vendablePublic = await FBD_Vendable.get(docid);
    const vendablePrivate = await FBD_Vendable_Private.get(docid);

    if (!vendablePublic || !vendablePrivate) {
      console.log('Error fetching vendable data');
      return null;
    }
    return { pub: vendablePublic, priv: vendablePrivate };
  }

  async function getSingleWarrantyTemplate(docid: string) {
    const warrantyTemplate = await FBD_Warranty_Template.get(docid);

    if (!warrantyTemplate) {
      console.log('Error fetching warranty template data');
      return null;
    }
    return warrantyTemplate;
  }

  /** Fetches the available warranty templates for the active premium tenant */
  async function getWarrantyTemplates() {
    const warrantyTemplates = await FBD_Warranty_Template.query()
      .where(
        'warrantorLink',
        '==',
        PersonaTypeSingleLetter.Warrantor +
          PersonaIdTypeSplitter +
          t('tenantLink')
      )
      .getContents();

    return warrantyTemplates;
  }

  /** Changes the search params used for vendable searches */
  function changeSearchParams(newParams: Partial<VendableSearchParamsShape>) {
    setSearchParams({ ...searchParams, ...newParams });
    setShouldRefresh(0);
  }

  /** Creates or updates a vendable for the active premium tenant. If no docid is passed, it'll create a new vendable.  */
  async function createOrUpdateVendable(
    params: CreateOrUpdateVendableParamsShape
  ) {
    const { publicData, privateData, vendableId, newVendableId } = params;
    try {
      const res = await COUVendable({
        tenantLink: t('tenantLink'),
        publicData,
        privateData,
        id: vendableId,
        newId: newVendableId,
      });

      return res.docid;
    } catch (err) {
      console.error('Error creating or updating vendable', err);
      throw new Error('Error creating or updating vendable');
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                               Context return                               */
  /* -------------------------------------------------------------------------- */
  const contextValues = useMemo(
    () => ({
      shouldRefresh,
      setShouldRefresh,
      vendableList,
      totalHits,
      totalPages,
      getSingleVendable,
      searchParams,
      changeSearchParams,
      createOrUpdateVendable,
      categoryList,
      getSingleWarrantyTemplate,
      getWarrantyTemplates,
    }),
    [
      shouldRefresh,
      setShouldRefresh,
      vendableList,
      totalHits,
      totalPages,
      getSingleVendable,
      searchParams,
      changeSearchParams,
      createOrUpdateVendable,
      categoryList,
      getWarrantyTemplates,
      getSingleWarrantyTemplate,
    ]
  );

  return (
    <VendableEditorContext.Provider value={contextValues}>
      {children}
    </VendableEditorContext.Provider>
  );
};

export { VendableEditorContext, VendableEditorProviderWrapper };
