import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Button,
  Input,
  InputTypeSelectAddress,
  getCountryByLabel,
} from '@rabbit/elements/shared-components';
import {
  CONTACT_OPTIONS,
  LIST_COUNTRIES,
  ReactCaseFlowCase,
} from '@rabbit/bizproc/react';
import ClaimDetailEditor from '../../../../utils/ClaimDetailEditor';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { useAppInfo } from '@rabbit/sage/utils/helpers';

export interface UpdateCustomerDetailsClaimProps {
  claimDetails: any; //TODO
  handleClose: () => void;
  caseFlowCase: ReactCaseFlowCase;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export interface FormValuesShape {
  first_name: string;
  last_name: string;
  consumer_email: string;
  consumer_telephone: string;
  consumer_telephone_e164: string;
  consumer_preferred_contact: any[];
  postcode: string;
  line1: string;
  line2: string;
  town: string;
  state: string;
  country: string;
}

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Please enter a first name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'First name must start with a capital letter and contain only letters.'
    ),
  last_name: Yup.string()
    .trim()
    .required('Please enter a last name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'Last name must start with a capital letter and contain only letters.'
    ),
  consumer_email: Yup.string()
    .trim()
    .email('Please enter a valid email address'),
  consumer_telephone: Yup.string().trim(),
  consumer_preferred_contact: Yup.array(),
  line1: Yup.string().trim(),
  line2: Yup.string().trim(),
  town: Yup.string().trim(),
  state: Yup.string().trim(),
  postcode: Yup.string().trim(),
  country: Yup.string().trim(),
});

export function UpdateCustomerDetailsForm({
  claimDetails,
  handleClose,
  caseFlowCase,
  setLoading,
}: UpdateCustomerDetailsClaimProps) {
  const appInfo = useAppInfo();
  const context = useContext(CaseflowContext);
  const { consumerPersonaData } = context || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<SelectAddressShape | null>({} as SelectAddressShape);

  const formikRef = useRef(null) as any;

  const updateSubmitting = (state: boolean) => {
    setIsSubmitting(state);
    setLoading(state);
  };

  const initialValues: FormValuesShape = {
    first_name: consumerPersonaData?.fullname?.split(' ')[0] || '',
    last_name: consumerPersonaData?.fullname?.split(' ')[1] || '',
    consumer_email: consumerPersonaData?.email ?? '',
    consumer_telephone: consumerPersonaData?.phone ?? '',
    consumer_telephone_e164: consumerPersonaData?.phone ?? '',
    consumer_preferred_contact: consumerPersonaData?.preferred_contact ?? [],
    line1:
      (consumerPersonaData?.address &&
        consumerPersonaData?.address[0]?.line1) ??
      '',
    line2:
      (consumerPersonaData?.address &&
        consumerPersonaData?.address[0]?.line2) ??
      '',
    town:
      (consumerPersonaData?.address && consumerPersonaData?.address[0]?.town) ??
      '',
    state:
      (consumerPersonaData?.address &&
        consumerPersonaData?.address[0]?.county) ??
      '',
    postcode:
      (consumerPersonaData?.address &&
        consumerPersonaData?.address[0]?.postcode) ??
      '',
    country:
      (consumerPersonaData?.address &&
        consumerPersonaData?.address[0]?.country) ??
      appInfo.country ??
      '',
  };

  const onSubmit = (values: any) => {
    updateSubmitting(true);

    const data = {
      ...values,
      consumer_telephone:
        values.consumer_telephone_e164 ?? values.consumer_telephone,
      consumer_address: {
        postcode: values.postcode,
        line1: values.line1,
        line2: values.line2,
        town: values.town,
        state: values.state,
        country: values.country,
      },
    };

    onSubmit(data);
  };

  useEffect(() => {
    if (!selectedAddress) {
      formikRef.current.resetForm();
      return;
    }
    if (formikRef && selectedAddress?.postal_code) {
      formikRef.current.setFieldValue('line1', selectedAddress.line_1);
      formikRef.current.setFieldValue('line2', selectedAddress.line_2);
      formikRef.current.setFieldValue('town', selectedAddress.locality);
      formikRef.current.setFieldValue('state', selectedAddress.province_name);
      formikRef.current.setFieldValue('postcode', selectedAddress.postal_code);
      formikRef.current.setFieldValue(
        'country',
        getCountryByLabel(selectedAddress.country_name)?.value ?? ''
      );
    }
  }, [selectedAddress]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {({ values, errors }) => (
        <Form>
          <div className="flex-col">
            <div className="flex gap-4">
              <Input
                type="text"
                name="first_name"
                label="First name*"
                settings={{
                  id: 'first_name',
                  placeholder: 'First name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
              <Input
                type="text"
                name="last_name"
                label="Last name*"
                settings={{
                  id: 'last_name',
                  placeholder: 'Last name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            <div className="mt-4">
              <Input
                type="email"
                name="consumer_email"
                label="Email*"
                settings={{
                  id: 'consumer_email',
                  placeholder: 'email@example.com',
                  disabled: true,
                  hint: '*required',
                }}
              />
            </div>
            <div className="mt-4 flex items-end gap-4">
              <Input
                type="phone"
                name="consumer_telephone"
                label="Phone number"
                settings={{
                  id: 'consumer_telephone',
                  placeholder: 'Phone number',
                }}
              />
              <Input
                type="select"
                name="consumer_preferred_contact"
                label="Preferred contact method"
                settings={{
                  isMulti: true,
                  id: 'consumer_preferred_contact',
                  placeholder: 'Please select',
                  options: CONTACT_OPTIONS,
                  errors: errors,
                }}
              />
            </div>
            <div className="mt-4">
              <div className="mb-2">
                <label className="font-nunito text-base font-medium">
                  Customer address
                </label>
              </div>
              <Input
                type="select"
                label="Country"
                name="country"
                settings={{
                  options: LIST_COUNTRIES,
                  placeholder: 'Country',
                }}
              />
              <InputTypeSelectAddress
                errors={errors['country']}
                onChange={(address) => setSelectedAddress(address)}
                country={values.country}
              />
            </div>
            <div className="mt-4">
              <Input
                type="text"
                label="Address line"
                name="line1"
                settings={{
                  placeholder: 'Address line 1',
                }}
              />
            </div>
            <div className="mt-4">
              <Input
                type="text"
                label="Address line 2"
                name="line2"
                settings={{
                  placeholder: 'Address line 2',
                }}
              />
            </div>
            <div className="mt-4 flex gap-3">
              <Input
                type="text"
                label="City/Suburb"
                name="town"
                settings={{ placeholder: 'City ' }}
              />
              <Input
                type="text"
                label="State/Territory"
                name="state"
                settings={{
                  placeholder: 'State',
                }}
              />
            </div>
            <div className="mt-4 flex gap-3">
              <Input
                type="text"
                label="Post code"
                name="postcode"
                settings={{
                  id: 'postcode',
                  placeholder: 'Post code',
                }}
              />
            </div>
            <div className="mt-6 flex w-1/3 gap-5">
              <Button
                kind="primary"
                type="submit"
                loading={isSubmitting}
                className="bg-primary-600"
              >
                Update claim
              </Button>
              <Button
                kind="red"
                type="button"
                loading={isSubmitting}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
          {isSubmitting && caseFlowCase && (
            <ClaimDetailEditor
              caseFlowCase={caseFlowCase}
              target={'customer_details'}
              formData={values}
              setIsSubmitting={updateSubmitting}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default UpdateCustomerDetailsForm;
