import { CFASetActor, PersonaTypeSingleLetter } from '@rabbit/data/types';
import { ActionStepImplementation, ActionStepRegistry } from '../registry';

export const ASI_SetActor: ActionStepImplementation<CFASetActor> = {
  validate: (location, validator, step, action, art) => {
    // TODO: Ensure actor is legit
    const theActor = art.ValidatePotentialParams(
      step.persona_value,
      location,
      validator
    );

    if (!validator.config.actors[step.role]) {
      validator.AddIssue(location, `Role "${step.role}" is not defined.`);
    }
  },

  interior: (cfc, step, art) => {
    const theActor = art.FillParamsInString(step.persona_value);

    // TODO: More validation here maybe?
    art.cfc.Alter_Actor(step.role, theActor);
  },
};

ActionStepRegistry.Register(ASI_SetActor, 'set_actor');
