import { schema, z } from '@rabbit/utils/ts';

export enum VRMProviderType {
  MotorCheck = 'MotorCheck',
  CarWeb = 'CarWeb',
}

export type VRMProviderInfo = {
  type: VRMProviderType;
  parameters: Record<string, string>;
};

export const Z_VRMProviderInfo = schema<VRMProviderInfo>()(
  z.object({
    type: z.nativeEnum(VRMProviderType),
    parameters: z.record(z.string()),
  })
);
