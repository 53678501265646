import { format } from 'date-fns';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import ButtonIcon from '../../atoms/buttons/ButtonIcon/ButtonIcon';
import {
  ArrowTopRightOnSquareIcon,
  DocumentTextIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { UserUploadedDocument } from '@rabbit/data/types';
import {
  CompletedUploadShape,
  OngoingUploadShape,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';

interface UploadedFileCardProps {
  kind: 'ongoing' | 'completed' | 'existing';
  uploadCategory: UploadedFileCategories;
  docid: string;
  data: UserUploadedDocument | OngoingUploadShape | CompletedUploadShape;
  onDeleteFile?: (
    fullPath: string,
    name: string,
    uploadCategory: UploadedFileCategories,
    docid: string
  ) => void;
}

export function UploadedFileCardv2({
  kind,
  data,
  uploadCategory,
  docid,
  onDeleteFile,
}: UploadedFileCardProps) {
  console.log('data', data);
  return (
    <div className="font-nunito flex w-full flex-col rounded-md border border-gray-300 bg-gray-50 p-4">
      {kind === 'ongoing' && (
        <InnerOngoingUpload data={data as OngoingUploadShape} />
      )}
      {kind === 'completed' && (
        <InnerCompletedUpload
          data={data as CompletedUploadShape}
          onDeleteFile={onDeleteFile}
          uploadCategory={uploadCategory}
          docid={docid}
        />
      )}
      {kind === 'existing' && (
        <InnerExistingUpload
          data={data as UserUploadedDocument}
          onDeleteFile={onDeleteFile}
          docid={docid}
          uploadCategory={uploadCategory}
        />
      )}
    </div>
  );
}

export default UploadedFileCardv2;

function InnerOngoingUpload({ data }: { data: OngoingUploadShape }) {
  const { key, progress, file } = data || {};
  const { preview, name, lastModified } = file || {};

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          {preview && preview.type === 'image' ? (
            <img
              src={preview.url}
              alt={'preview'}
              className="h-11 w-11 rounded-lg"
            />
          ) : (
            <DocumentTextIcon className="h-10 w-10" />
          )}
          <div className="flex-col">
            <div className="text-ellipsis break-all pb-1 text-base font-medium">
              {name}
            </div>
            <div className="text-sm text-gray-700">
              {lastModified && format(new Date(lastModified), 'dd/MM/yyyy')}
            </div>
          </div>
        </div>
      </div>
      {progress !== null && progress !== undefined && (
        <div className="pt-4">
          <ProgressBar width={progress === 0 ? '0' : progress.toString()} />{' '}
        </div>
      )}
    </>
  );
}

interface InnerCompletedUploadProps {
  uploadCategory: UploadedFileCategories;
  data: CompletedUploadShape;
  docid: string;
  onDeleteFile?: (
    fullPath: string,
    name: string,
    uploadCategory: UploadedFileCategories,
    docid: string
  ) => void;
}
function InnerCompletedUpload({
  data,
  uploadCategory,
  docid,
  onDeleteFile,
}: InnerCompletedUploadProps) {
  const { uploadedFile, ogFile } = data || {};
  const { ogFilename, metadata, url } = uploadedFile || {};
  const { fullPath, timeCreated, contentType } = metadata || {};

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          {String(contentType).includes('image') ? (
            <img src={url} alt={'preview'} className="h-11 w-11 rounded-lg" />
          ) : (
            <DocumentTextIcon className="h-10 w-10" />
          )}
          <div className="flex-col">
            <div className="text-ellipsis break-all pb-1 text-base font-medium">
              {ogFilename}
            </div>
            <div className="text-sm text-gray-700">
              {timeCreated && format(new Date(timeCreated), 'dd/MM/yyyy')}
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex items-center gap-2">
            <ButtonIcon
              label={'Open'}
              onClick={() => window.open(url, '_blank', 'noreferrer')}
              iconLeft={true}
              Icon={ArrowTopRightOnSquareIcon}
            />
            {onDeleteFile && fullPath && ogFilename && (
              <TrashIcon
                className="h-5 w-5 cursor-pointer text-red-600"
                onClick={() =>
                  onDeleteFile(fullPath, ogFilename, uploadCategory, docid)
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
interface InnerExistingUploadProps {
  data: UserUploadedDocument;
  uploadCategory: UploadedFileCategories;
  docid: string;
  onDeleteFile?: (
    fullPath: string,
    name: string,
    uploadCategory: UploadedFileCategories,
    docid: string
  ) => void;
}
function InnerExistingUpload({
  data,
  uploadCategory,
  docid,
  onDeleteFile,
}: InnerExistingUploadProps) {
  const { ogFilename, metadata, url } = data || {};
  const { fullPath, timeCreated, contentType } = metadata || {};

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          {String(contentType).includes('image') ? (
            <img src={url} alt={'preview'} className="h-11 w-11 rounded-lg" />
          ) : (
            <DocumentTextIcon className="h-10 w-10" />
          )}
          <div className="flex-col">
            <div className="text-ellipsis break-all pb-1 text-base font-medium">
              {ogFilename}
            </div>
            <div className="text-sm text-gray-700">
              {timeCreated && format(new Date(timeCreated), 'dd/MM/yyyy')}
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex items-center gap-2">
            <ButtonIcon
              label={'Open'}
              onClick={() => window.open(url, '_blank', 'noreferrer')}
              iconLeft={true}
              Icon={ArrowTopRightOnSquareIcon}
            />
            {onDeleteFile && fullPath && ogFilename && (
              <TrashIcon
                className="h-5 w-5 cursor-pointer text-red-600"
                onClick={() =>
                  onDeleteFile(fullPath, ogFilename, uploadCategory, docid)
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
