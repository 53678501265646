import {
  DR_Repairer_Private,
  DR_Repairer_Public,
  useGetMySagePersonas,
  usePortalDocumentEditor,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import { useEffect, useState } from 'react';

/** Allows users to follow cases on Sage, as well as to get a list of followed cases.
 * Currently, this is only used by the repairer persona.
 */
export function useCaseFollowing() {
  const { repairerPersona } = useGetMySagePersonas();
  const repairerId = repairerPersona?.personaId || '';
  const { body, commit, isReady, update } = usePortalMultipleDocumentEditor({
    public: {
      type: DR_Repairer_Public,
      docid: repairerId,
    },
    private: { type: DR_Repairer_Private, docid: repairerId },
  });
  const followedCaseIds = body.private?.casesFollowed || [];

  // Deep clone the previous body to avoid losing any data
  const prevPublic = body.public ? JSON.parse(JSON.stringify(body.public)) : {};
  const prevPrivate = body.private
    ? JSON.parse(JSON.stringify(body.private))
    : {};

  // TODO: Implement this when/if necessary - dc
  //async function getFollowedCaseData() {}

  async function followCase(caseId: string) {
    if (!repairerId) throw new Error('No repairer ID found');
    if (followedCaseIds.includes(caseId))
      throw new Error('Case already followed');

    if (isReady)
      try {
        followedCaseIds.push(caseId);
        await update({
          private: {
            ...prevPrivate,
            casesFollowed: followedCaseIds,
          },
          public: {
            ...prevPublic,
          },
        });
        await commit();
      } catch (e) {
        throw new Error('Failed to follow case: ' + e);
      }
  }

  async function unfollowCase(caseId: string) {
    if (!repairerId) throw new Error('No repairer ID found');
    if (!followedCaseIds.includes(caseId)) throw new Error('Case not followed');
    if (isReady)
      try {
        followedCaseIds.splice(followedCaseIds.indexOf(caseId), 1);
        await update({
          private: {
            ...prevPrivate,
            casesFollowed: followedCaseIds,
          },
          public: {
            ...prevPublic,
          },
        });
        await commit();
      } catch (e) {
        throw new Error('Failed to follow case: ' + e);
      }
  }

  return {
    followedCaseIds: body?.private?.casesFollowed || [],
    // getFollowedCaseData,
    followCase,
    unfollowCase,
  };
}
