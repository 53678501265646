import { Dispatch, SetStateAction } from 'react';
import { NewClaimModalSteps } from '../../ModalNewClaim/ModalNewClaim';
import { DTConsumer_Public, DTHoldingProxy } from '@rabbit/data/types';
import NCCHF_Base from './variations/NCCHF_Base';
import NCCHF_Fatbikes from './variations/NCCHF_Fatbikes';
import BaseConfig from '@rabbit/config/base';

export interface NewClaimCreateHoldingFormProps {
  onHoldingCreated: (result: any) => void;
  handleClose: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onChange: (step: NewClaimModalSteps, data?: any) => void;
  data: { holding: DTHoldingProxy; consumer: DTConsumer_Public };
  kind: typeof BaseConfig.CLAIMS.CLAIMS_FLOW.CREATE_HOLDING_FORM;
}

export function NewClaimCreateHoldingForm(
  props: NewClaimCreateHoldingFormProps
) {
  const { kind } = props;
  return (
    <>
      {kind === 'BASE' && <NCCHF_Base {...props} />}
      {kind === 'TYPE1' && <NCCHF_Fatbikes {...props} />}
    </>
  );
}

export default NewClaimCreateHoldingForm;
