import { PencilIcon } from '@heroicons/react/24/solid';
import {
  CardWrapperWithHeader,
  TimelineUpdates,
  LoadingSpinner,
  ButtonIcon,
  Input,
  Toggle,
  Button,
} from '@rabbit/elements/shared-components';
import InternalCommentModal from '../../../organisms/case-flow-modals/InternalCommentModal/InternalCommentModal';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { ReactElement, useContext, useState } from 'react';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { RepairerCommentModal } from '@rabbit/sage/components/organisms/case-flow-modals/RepairerCommentModal/RepairerCommentModal';
import { useTranslation } from 'react-i18next';

// export interface ClaimActivitySectionProps {}
type FilterOptionType = 'InternalCommentsOnly' | 'EmailCorrespondence' | 'All';

export function ClaimActivitySection() {
  // props: ClaimActivitySectionProps
  const context = useContext(CaseflowContext);
  const appInfo = useAppInfo();
  const {
    caseUpdates,
    operatingPersona,
    setModalSettings,
    setShowModal,
    alterCaseFacts,
    executeAction,
    caseFlowCase,
    holdingData,
    caseId,
  } = context || {};

  const { t } = useTranslation();
  const [timelineState, setTimelineState] = useState<FilterOptionType>('All');
  const [appliedTimelineState, setAppliedTimelineState] =
    useState<FilterOptionType>('All');
  const [showFilterDropDown, setShowFilterDropDown] = useState(false);
  if (
    !context ||
    !caseUpdates ||
    !setModalSettings ||
    !setShowModal ||
    !alterCaseFacts ||
    !executeAction ||
    !caseFlowCase ||
    !operatingPersona ||
    !holdingData ||
    !caseId
  )
    return (
      <CardWrapperWithHeader title="Activity">
        <LoadingSpinner size="xs" />
      </CardWrapperWithHeader>
    );

  const isRepairer = operatingPersona.startsWith(
    PersonaTypeSingleLetter.Repairer + PersonaIdTypeSplitter
  );

  const commentLabel = isRepairer
    ? t('general.addComment')
    : t('message.addInternalComment');

  //TODO check this
  //const caseSpotlight = caseFlowCase?.GetCaseSpotlight();
  // const hasActivityUpdate = caseSpotlight
  //   ? caseSpotlight[0]?.[0] === 'R'
  //   : false;

  const filterOptions: { label: string; value: FilterOptionType }[] = [
    { label: t('general.internalComments'), value: 'InternalCommentsOnly' },
    { label: t('general.emailCorrespondence'), value: 'EmailCorrespondence' },
  ];

  const modalSettingsOptions = {
    internal_comment: {
      kind: 'generic' as const,
      settings: {
        title: commentLabel,
        primaryButtonText: t('general.send'),
        secondaryButtonText: t('general.close'),
      },
      children: isRepairer ? (
        <RepairerCommentModal />
      ) : (
        <InternalCommentModal />
      ),
    },
  };

  const DropDownContainer = (props: {
    children: ReactElement;
    title: string;
    onClose: () => void;
  }) => (
    <div className="fixed left-5 z-10 mt-4 flex h-fit w-[calc(100vw_-_40px)] flex-col rounded-lg bg-white p-5 shadow-lg sm:absolute sm:left-0 sm:w-[280px]">
      <div className="flex items-center justify-between">
        <p className="font-nunito text-lg font-normal text-gray-500">
          {props.title}
        </p>
        <XMarkIcon
          onClick={props.onClose}
          className="h-[18px] w-[18px] cursor-pointer bg-transparent text-gray-400"
        />
      </div>
      {props.children}
    </div>
  );

  return (
    <CardWrapperWithHeader
      title={t('general.activity')}
      noPadding
      canOverflow={true}
      headerRight={
        <div className="flex gap-2">
          <div className="relative">
            <ButtonIcon
              kind="bgLightGreen"
              type="primary"
              label={t('general.filters')}
              Icon={FunnelIcon}
              iconLeft
              onClick={() => setShowFilterDropDown(!showFilterDropDown)}
            />
            {showFilterDropDown && (
              <DropDownContainer
                title={t('general.filters')}
                onClose={() => setShowFilterDropDown(false)}
              >
                <div className="font-nunito font-normal text-gray-500">
                  {filterOptions.map((option) => (
                    <div key={option.label}>
                      <div className="mt-2 flex items-center gap-2 text-gray-900">
                        <input
                          className="text-primary-900 h-4 w-4 rounded border-gray-300 bg-gray-100 focus:ring-transparent"
                          type="checkbox"
                          checked={timelineState === option.value}
                          onChange={(e) =>
                            e.target.checked && setTimelineState(option.value)
                          }
                        />
                        {option.label}
                      </div>
                    </div>
                  ))}
                  <div className="font-nunito mt-4 flex gap-4 text-base">
                    <Button
                      kind="primary"
                      size="sm"
                      onClick={() => setAppliedTimelineState(timelineState)}
                    >
                      Apply filters
                    </Button>
                    <Button
                      kind="outline"
                      size="sm"
                      onClick={() => {
                        setTimelineState('All');
                        setAppliedTimelineState('All');
                        setShowFilterDropDown(false);
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </DropDownContainer>
            )}
          </div>
          <ButtonIcon
            type="primary"
            label={commentLabel}
            Icon={PencilIcon}
            iconLeft
            onClick={() => {
              setModalSettings(modalSettingsOptions.internal_comment);
              setShowModal(true);
            }}
          />
        </div>
      }
    >
      <TimelineUpdates
        updates={caseUpdates}
        customer={false}
        appInfo={appInfo}
        state={appliedTimelineState}
        caseFlowCase={caseFlowCase}
        operatingPersona={operatingPersona}
      />
    </CardWrapperWithHeader>
  );
}

export default ClaimActivitySection;
