import { useState } from 'react';
import { Modal } from '@rabbit/elements/shared-components';
import { useLocation } from 'react-router-dom';
import { ModalAddEditVendable } from '../components/organisms/ModalNewEditProduct/ModalTypes/ModalAddEditVendable';
import { VendableEditorProviderWrapper } from '@rabbit/bizproc/react';
import VendableDetailsTemplate from '../components/templates/VendableDetailsTemplate/VendableDetailsTemplate';
import { t } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VendableDetailsViewProps {}

export function VendableDetailsView(props: VendableDetailsViewProps) {
  const location = useLocation();
  const vendableId = location.pathname.split('/')[2];

  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);

  return (
    <VendableEditorProviderWrapper>
      <VendableDetailsTemplate
        vendableId={vendableId}
        setIsEditProductModalOpen={setIsEditProductModalOpen}
      />
      {isEditProductModalOpen && (
        <Modal
          kind={'generic'}
          className="h-fit max-w-[90vw] rounded-md bg-white md:w-3/4 lg:w-2/4"
          settings={{
            handleClose: () => setIsEditProductModalOpen(false),
            title: t('Edit product'),
          }}
        >
          <ModalAddEditVendable
            handleClose={() => setIsEditProductModalOpen(false)}
            vendableId={vendableId}
          />
        </Modal>
      )}
    </VendableEditorProviderWrapper>
  );
}

export default VendableDetailsView;
