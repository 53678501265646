import { ReactNode, useEffect, useState } from 'react';
import {
  ChevronButton,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import bgStart from '../../../assets/images/bg_start.svg';
import bgEnd from '../../../assets/images/bgEnd.png';
import { useTranslation } from 'react-i18next';
import { SearchVendablesByDocid } from '@rabbit/search/cherchons';
import { AppInfoShape, DTVendable } from '@rabbit/data/types';
import placeholder from '../../../assets/images/no_products_yet.svg';

export interface AuthContainerProps {
  children: ReactNode;
  goBack?: null | (() => any);
  title?: string;
  background?: boolean;
  extraClasses?: string;
  currentStep?: number | null;
  loading?: boolean;
  appInfo?: AppInfoShape;
}

export function AuthContainer({
  children,
  goBack,
  title,
  background = false,
  extraClasses = '',
  currentStep,
  loading,
}: AuthContainerProps) {
  const { t } = useTranslation();
  const [vendableLoading, setVendableLoading] = useState(false);
  const [vendable, setVendable] = useState<DTVendable>();
  const cobrand = t('CFG_COBRAND_LOGO');
  const name = t('CFG_COBRAND_SHORT_NAME');
  const defaultImage =
    t('CFG_COBRAND_LANDING_IMAGE_PRODUCT_PLACEHOLDER') || placeholder;

  const getProductDetails = async (docid: string) => {
    const facetFilters = [];
    facetFilters.push({
      name: 'mfr',
      value: `M:${t('tenantLink')}`,
    });

    const options = {
      hitsPerPage: 25,
      page: 0,
      facetFilters,
    };
    const data = await SearchVendablesByDocid(docid, options);
    setVendable(data.items[0]);
    setVendableLoading(false);
  };

  useEffect(() => {
    if (localStorage.vendableId) {
      setVendableLoading(true);
      getProductDetails(localStorage.vendableId);
      localStorage.removeItem('vendableId');
    }
  }, []);

  const BackAndTitle = (
    <div className="relative mb-6 flex w-full flex-row">
      {goBack && (
        <ChevronButton
          className="absolute flex h-full cursor-pointer flex-wrap content-center"
          kind="back"
          onClick={goBack}
        />
      )}

      {title && (
        <div className="mx-auto text-center">
          <Heading kind="h2">{title}</Heading>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex grow flex-col bg-[#F5F6F6] lg:content-center">
      <div
        style={{
          backgroundImage:
            !currentStep || currentStep === 1
              ? `${background ? 'url(' + bgStart + ')' : 'none'}`
              : '',
        }}
        className={`relative mt-5 w-full ${extraClasses} ${
          currentStep === 1
            ? 'md:m-auto md:grid md:w-[1008px] md:grid-cols-2 md:gap-12'
            : 'md:m-auto md:w-[480px]'
        }`}
      >
        {currentStep === 1 && (
          <div
            className={`relative rounded-[6px] bg-white px-8 py-12 md:px-16 md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)]`}
          >
            {vendableLoading && <LoadingSpinner size="sm" overlay={true} />}
            <div className="flex flex-col">
              <img
                src={cobrand}
                className="max-h-[72px] w-full object-contain"
              />
              <div className={`flex flex-col ${vendable ? 'gap-10' : ''}`}>
                <div>
                  <div
                    className={`flex items-center ${
                      vendable ? 'h-[360px]' : 'h-[400px]'
                    } w-full`}
                  >
                    <img
                      src={
                        vendable
                          ? vendable.img && vendable.img.length > 0
                            ? vendable.img[0]
                            : defaultImage
                          : defaultImage
                      }
                      className="h-full w-full object-contain"
                    />
                  </div>
                  {vendable && (
                    <div className="font-nunito text-center text-2xl font-medium">
                      {vendable.title}
                    </div>
                  )}
                </div>
                <div className="font-nunito text-center text-lg">
                  <strong>{name}</strong> has partnered with{' '}
                  <strong className="text-primary-900">iWarranty</strong> to
                  help you manage all your products and warranties in one place.
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`relative rounded-[6px] bg-white px-8 py-12 md:px-16 md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)] ${
            currentStep !== 9 ? 'md:h-full' : ''
          }`}
        >
          {loading && <LoadingSpinner overlay={true} size="sm" />}
          {currentStep === 9 && <img src={bgEnd} alt={'lastScreenBg'}></img>}
          {(goBack || title) && BackAndTitle}
          {children}
        </div>
      </div>
    </div>
  );
}

export default AuthContainer;
