import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { VehicleInfo } from '@rabbit/data/types';
import {
  MANDRILL_TEMPLATES,
  useSageAPI,
  useSendEmail,
} from '@rabbit/bizproc/react';
import {
  Button,
  formatUnixTime,
  getCurrencyFormat,
  Heading,
  Input,
  getAgeFromDate,
} from '@rabbit/elements/shared-components';
import {
  Address,
  MileageUnit,
  Money,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  SRVInfo,
  SRVType,
  DeciderOutput,
  MilesToKilometersRatio,
} from '@rabbit/data/types';
import { useTranslation } from '@rabbit/mixmaster/react';
import { parse } from 'tinyduration';
import {
  getConsumerURL,
  getWarranty,
  useAppInfo,
  toTitleCase,
} from '@rabbit/sage/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { format, formatDuration, getUnixTime } from 'date-fns';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { CarRegistrationShape } from '../../ModalNewRegistration/ModalNewRegistration';
import { ConsumerDetailsShape } from '../CreateNewConsumerDetails/CreateNewConsumerDetails';
import { CoverageOptions } from '../CreateNewCoverageOptions/CreateNewCoverageOptions';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import {
  BPIWarranty_WarrantyType,
  RegisterWarranty_ConsumerInfo,
} from '@rabbit/elements/shared-types';
import { BL_Warranty, DeciderPerformDecision } from '@rabbit/bizproc/core';

export interface CreateNewRegistrationSummaryShape {
  salesRep: string;
}

export const validationSchema = Yup.object().shape({
  salesRep: Yup.string(),
});

interface CreateNewRegistrationSummaryProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape &
    ConsumerDetailsShape &
    CoverageOptions & {
      consumer_address: Address;
      mileage: string;
      mileageUnit: string;
      last_service_date: string;
      tech_check_date: string;
      purchase_price: Money;
    };
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}
export function CreateNewRegistrationSummary({
  handleClose,
  onChange,
  data,
  setIsLoading,
}: CreateNewRegistrationSummaryProps) {
  const { registerSRVHoldingWithWarranty } = useSageAPI();
  const [expand, setExpand] = useState(false);
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const navigate = useNavigate();
  const { SE_Sage_VehicleRegistration } = useSendEmail();
  const [loading, setLoading] = useState(false);
  const [deciderOutput, setDeciderOutput] = useState<DeciderOutput>();

  useEffect(() => {
    const convertedMileage =
      data.mileageUnit && data.mileageUnit === 'mi'
        ? Number(data.mileage) * MilesToKilometersRatio
        : data.mileage;

    const duration = {
      label: data.warranty_duration.label
        .split(' ')
        .map((i: any) => toTitleCase(i))
        .join(' '),
      value: data.warranty_duration.value,
    };

    const claimLimit = {
      label: data.warranty_claim_limit.label,
      value: data.warranty_claim_limit.value,
    };

    const stipulated = {
      age: getAgeFromDate(data.regDate),
      mileage: convertedMileage,
      duration: duration,
      claimLimit: claimLimit,
    };
    const output = DeciderPerformDecision(
      stipulated,
      data.warranty_template,
      data.warranty_offer
    );

    setDeciderOutput(output);
  }, [data.warranty_template, data.warranty_offer]);

  const initialValues: CreateNewRegistrationSummaryShape = {
    salesRep: '',
  };
  const defaultMileageUnit = t('CFG_COBRAND_MILEAGE_UNIT', 'km') as MileageUnit;

  const carRecord = [
    {
      label: t('general.registrationPlate'),
      value: String(data.registrationNo).toUpperCase(),
    },
    { label: t('general.make'), value: data.make },
    { label: t('general.model'), value: data.model },
    { label: t('general.version'), value: data.version },
    { label: t('general.body'), value: data.body || '-' },
    {
      label: t('general.registrationDate'),
      value: data.regDate
        ? formatUnixTime(getUnixTime(new Date(data.regDate)), 'dd/MM/yyyy')
        : '-',
    },
    { label: t('general.engineCc'), value: data.engineCc || '-' },
    { label: t('general.colour'), value: data.colour || '-' },
    { label: t('general.fuel'), value: data.fuel || '-' },
    { label: t('general.transmission'), value: data.transmission || '-' },
    {
      label: t('general.yearOfManufacture'),
      value: data.yearOfManufacture || '-',
    },
    { label: t('general.vin'), value: data.chassisNo || '-' },
    { label: t('general.engineNumber'), value: data.engineNo || '-' },
    { label: t('general.vehicleCategory'), value: data.vehicleCategory || '-' },
    {
      label: t('general.currentMileage'),
      value: data.mileage
        ? Number(data.mileage).toLocaleString(appInfo.country) +
            data.mileageUnit || defaultMileageUnit
        : '-',
    },
    {
      label: t('general.lastServiceDate'),
      value: formatUnixTime(
        getUnixTime(new Date(data.last_service_date)),
        'dd/MM/yyyy'
      ),
    },
    {
      label: t('general.nctDateExpiration'),
      value: data.tech_check_date
        ? formatUnixTime(
            getUnixTime(new Date(data.tech_check_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: t('general.purchasePrice'),
      value: data.purchase_price
        ? getCurrencyFormat(
            data.purchase_price.amount,
            data.purchase_price.currency
          )
        : '-',
    },
  ];

  const handleSubmit = async (values: any) => {
    if (!deciderOutput) throw new Error('Decider output not found');
    // TODO: Do vendable/holding registration
    setLoading(true);
    setIsLoading(true);

    const vehicleInfo: VehicleInfo = {
      ...data,
      brand: data.make,
      engineCc: Number(data.engineCc),
      yearOfManufacture: new Date(data.yearOfManufacture).getTime(),
      mileage: Number(data.mileage),
      mileageUnit:
        (data.mileageUnit as MileageUnit) ||
        (t('CFG_COBRAND_MILEAGE_UNIT', 'km') as MileageUnit), //MileageUnit.Kilometers,
      purchasePrice: {
        amount: Number(data.purchase_price.amount),
        currency: data.purchase_price.currency,
      } as Money,
      lastServiceDate:
        data.last_service_date && data.last_service_date !== '0000-00-00'
          ? formatUnixTime(
              new Date(data.last_service_date).getTime(),
              'dd/MM/yyyy'
            )
          : '',
      techCheckDate:
        data.tech_check_date && data.tech_check_date !== '0000-00-00'
          ? formatUnixTime(
              new Date(data.tech_check_date).getTime(),
              'dd/MM/yyyy'
            )
          : '',
      //date format needs to be 'dd/MM/yyyy' and not 'yyyy-MM-dd' as we have here, once the decider is implemented for WIRE -VP -DC
      regDate:
        data.regDate && data.regDate !== '0000-00-00'
          ? formatUnixTime(new Date(data.regDate).getTime(), 'yyyy-MM-dd')
          : '',
      isManuallyRegistered: data.manualInput,
    };
    const holding: SRVInfo = {
      type: SRVType.Vehicle,
      productInfo: vehicleInfo,
    };

    const warranty: BPIWarranty_WarrantyType = {
      startDate: data.warranty_start_date
        ? new Date(data.warranty_start_date)
        : null,
      options: [
        {
          key: 'duration',
          value: data.warranty_duration.value,
        },
        {
          key: 'claimLimit',
          value: data.warranty_claim_limit.value,
        },
      ],
      salesRep: values.salesRep,
    };
    const consumerInfo: RegisterWarranty_ConsumerInfo = {
      firstName: data.first_name,
      lastName: data.last_name,
      consumerEmail: data.consumer_email,
      phoneNumber: data.phone_number,
    };

    if (data.consumer_address) consumerInfo.address = data.consumer_address;
    let regWarrantyLink: string | undefined;
    let regHoldingLink: string | undefined;

    try {
      const { warrantyLink, holdingLink } =
        await registerSRVHoldingWithWarranty({
          warrantor: getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          ),
          holding,
          templateLink: data.warranty_template.docid,
          consumer: consumerInfo,
          warranty,
          deciderOutput,
          offerLink: data.warranty_offer?.docid,
        });
      regWarrantyLink = warrantyLink;
      regHoldingLink = holdingLink;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      setLoading(false);
      setIsLoading(false);
      return;
    }
    if (regWarrantyLink) {
      const regWarranty = await getWarranty(regWarrantyLink);
      try {
        await SE_Sage_VehicleRegistration(
          data.consumer_email,
          appInfo.email_sender,
          appInfo.name,
          appInfo.email_main_template,
          MANDRILL_TEMPLATES.BODY_SAGE_VEHICLE_REGISTRATION_WIRE,
          data.first_name,
          formatUnixTime(
            getUnixTime(new Date(regWarranty?.startDate || 0)),
            'dd/MM/yyyy'
          ),
          formatUnixTime(
            getUnixTime(new Date(regWarranty?.endDate || 0)),
            'dd/MM/yyyy'
          ),
          formatUnixTime(getUnixTime(new Date()), 'dd/MM/yyyy'),
          String(data.registrationNo).toUpperCase(),
          data.make,
          data.model,
          Number(data.warranty_claim_limit.value)
            ? getCurrencyFormat(
                data.warranty_claim_limit.value,
                appInfo.currency
              )
            : data.warranty_claim_limit.value,
          data.mileage
            ? Number(data.mileage).toLocaleString(appInfo.country) +
                data.mileageUnit || defaultMileageUnit
            : '-',
          data.warranty_type ? data.warranty_template.title : '-',
          data.warranty_duration
            ? formatDuration(parse(data.warranty_duration.value as any))
            : '-',
          '',
          `${getConsumerURL()}`,
          appInfo.templateLanguage
        );

        toast.success(
          'Registration created successfully. Redirecting to detail page...'
        );
        setTimeout(() => {
          navigate(`${SAGE_ROUTE_NAME.REGISTRATIONS}/${regHoldingLink}`);
          handleClose();
        }, 5000);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => (
        <Form className="flex flex-col gap-4 px-5 pt-4">
          {/* Warranty info */}
          <div>
            <Heading kind="h4" className="mb-2">
              Selected warranty plan
            </Heading>
            <div className="flex flex-col gap-4">
              <div
                className={'font-nunito rounded-md border border-gray-300 p-4'}
                onClick={() => 0}
              >
                <div className="font-bold">{data.warranty_template.title}</div>
                <div className="mt-[10px] text-gray-700">
                  Start date:{' '}
                  {format(
                    new Date(data.warranty_start_date || Date.now()),
                    'dd/MM/yyyy'
                  )}
                </div>
                <div className="text-gray-700">
                  Term: {formatDuration(parse(data.warranty_duration.value))}
                </div>
                <div className="text-gray-600">
                  Claim limit:{' '}
                  {Number(data.warranty_claim_limit.value)
                    ? getCurrencyFormat(
                        data.warranty_claim_limit.value,
                        appInfo.currency
                      )
                    : data.warranty_claim_limit.value}
                </div>
              </div>
            </div>
          </div>

          {/* Selected car */}
          <div>
            <Heading kind="h4" className="mb-2">
              Car details
            </Heading>
            <div className="flex flex-col gap-4">
              <div
                className={'font-nunito rounded-md border border-gray-300 p-4'}
                onClick={() => 0}
              >
                <div
                  className="flex h-fit cursor-pointer items-center justify-between"
                  onClick={() => setExpand(!expand)}
                >
                  <div>
                    <div className="font-bold text-black">
                      {data.make} {data.model} {data.version}
                    </div>
                    <div className="uppercase text-black">
                      {BL_Warranty.getVehicleRegistrationNo(data)}
                    </div>
                  </div>
                  <ChevronDownIcon
                    className={
                      'relative h-6 w-6 text-gray-900' +
                      (expand ? ' rotate-180' : '')
                    }
                  />
                </div>
                {expand && (
                  <div className="mt-6 grid grid-cols-3 gap-4">
                    {carRecord.map((record, index) => (
                      <div key={'record' + index} className="flex flex-col">
                        <span className="font-sm text-gray-500">
                          {record.label}
                        </span>
                        <div className="font-medium text-black">
                          {record.value}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Customer car */}
          <div>
            <Heading kind="h4" className="mb-2">
              Customer details
            </Heading>
            <div className="font-nunito flex flex-col rounded-md border border-gray-300 p-4">
              <div className="font-bold text-black">
                {data.first_name} {data.last_name}
              </div>
              <Heading kind="p">{data.consumer_email}</Heading>
              {data.consumer_address && (
                <Heading kind="p">
                  {data.consumer_address?.postcode}{' '}
                  {data.consumer_address?.line1}, {data.consumer_address?.line2}{' '}
                  {data.consumer_address?.town} {data.consumer_address?.state}{' '}
                  {data.consumer_address?.country}
                </Heading>
              )}
              <Heading kind="p">+{data.phone_number}</Heading>
            </div>
          </div>
          <div>
            <Input
              type="text"
              name="salesRep"
              label="Salesperson name"
              settings={{
                id: 'salesRep',
                placeholder: 'Enter salesperson name here',
              }}
            />
          </div>
          <div className="sticky bottom-0 flex w-full gap-4 bg-white pt-4">
            <Button
              kind="primary"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              Register warranty
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
