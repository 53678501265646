import { DTHoldingProxy, VehicleInfo, WarrantyStatus } from '@rabbit/data/types';
import { getProductTitleFromHoldingData, renderWarrantyRemaining } from '../../../../utils/helpers';
import placeholderImg from '../../../../assets/images/car-placeholder.jpg';
import Skeleton from 'react-loading-skeleton';
import { ShieldCheckIcon, UserIcon } from '@heroicons/react/24/solid';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { getWarrantyTerm } from '@rabbit/elements/shared-components';

interface RegistrationsHoldingSummarySectionProps {
  allHoldingsList?: DTHoldingProxy[];
  holding_proxy?: DTHoldingProxy;
  holdingId: string;
  storeAddress: string;
}
export default function CarRegistrationsHoldingSummarySection({
  allHoldingsList,
  holding_proxy,
  holdingId,
  storeAddress,
}: RegistrationsHoldingSummarySectionProps) {
  const latestWarranty = BL_Warranty.getLatestWarranty(
    holding_proxy?.warranties
  );
  const productInfo = holding_proxy?.self_registration?.srvInfo?.productInfo as VehicleInfo;

  return (
    <div className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-gray-100 p-4">
      {!allHoldingsList || !holdingId || !holding_proxy ? (
        <>
          <div className="flex w-full grow">
            <div>
              <div className="h-[138px] w-[188px]">
                {' '}
                <Skeleton
                  count={1}
                  baseColor="#fff"
                  highlightColor="#f1f1f1"
                  className="h-[138px] w-[188px]"
                />
              </div>
            </div>
            <div className="ml-5 flex w-full flex-col items-center justify-center">
              <div className="w-full">
                <Skeleton count={3} baseColor="#fff" highlightColor="#f1f1f1" />
              </div>
            </div>
          </div>

          <div className="flex w-full grow items-center gap-[75px] px-4">
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-full items-start justify-between gap-4">
          <div className="flex w-1/3 grow items-start gap-3">
            <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
              <UserIcon className="h-8 w-8 text-gray-500" />
            </div>
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl">
                {holding_proxy.consumer_name}
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-base opacity-50">
                {holding_proxy.consumer_email}
              </p>
            </div>
          </div>
          <div className="flex w-1/3 grow items-start gap-3">
            <img
              src={
                holding_proxy?.img?.[0] ??
                holding_proxy?.self_registration?.img?.[0] ??
                placeholderImg
              }
              alt={'product '}
              className="h-[84px] w-[84px] shrink-0 rounded-md border border-gray-300 object-cover"
            ></img>
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl font-normal">
                {getProductTitleFromHoldingData(holding_proxy)}
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-lg text-gray-600">
                {BL_Warranty.getVehicleRegistrationNo(productInfo)}
              </p>
            </div>
          </div>
          <div className="flex w-1/3 grow items-start gap-3">
            <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
              <ShieldCheckIcon className="h-8 w-8 text-gray-500" />
            </div>
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl">
                {latestWarranty?.status === WarrantyStatus.VOIDED
                  ? 'Voided'
                  : renderWarrantyRemaining(holding_proxy)}
              </p>
              <p className="text-base opacity-50 text-ellipsis overflow-hidden w-full">
                Warranty plan term: {getWarrantyTerm(holding_proxy)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
