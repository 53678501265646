import { GenericRegistry } from '@rabbit/utils/generic-registry';
import {
  FBDocType,
  FBDocTypeEssentials,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { CollectionReference, DocumentData } from '@firebase/firestore';
import {
  FBA_React_CollectionRefSecret,
  FBA_React_DocumentRefSecret,
} from './initialization';
import {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
} from 'firebase/firestore';
import {
  useFirestoreDocument,
  UseFirestoreHookOptions,
} from '@react-query-firebase/firestore';
import { UseQueryResult } from 'react-query';
import { FBFCollectionRef, FBFDocumentRef } from '@rabbit/firebase/adapter';

// Data type wrappers to extend our type objects with React typescript hooks
/** Firebase Doc Type
 *
 * A way to define a type of document in the NoSql database.
 * Lets us throw documents around without worrying about the type.
 * Instead call generic operations passing in the doc type and typed documents will be returned.
 */

export interface DRDocType<TYPE extends NoSqlDoc>
  extends FBDocTypeEssentials<TYPE> {
  collection: () => CollectionReference<TYPE>;
  doc: (id: string) => DocumentReference<TYPE>;
  get: (id: string) => Promise<TYPE | null>;
  set: (body: TYPE) => Promise<string>;
  delete: (id: string) => Promise<void>;
  useFirestoreDocument: (
    key: string,
    options?: UseFirestoreHookOptions
  ) => UseQueryResult<DocumentSnapshot<TYPE>, FirestoreError>;
}

export const DRDocRegistry = new GenericRegistry<DRDocType<any>>(
  'firebase_react_document_types'
);

export function MakeDRDocType<TYPE extends NoSqlDoc>(
  fbdoctype: FBDocType<TYPE>
): DRDocType<TYPE> {
  const result: DRDocType<TYPE> = {
    name: fbdoctype.name,
    collectionName: fbdoctype.collectionName,
    empty: fbdoctype.empty,
    // @ts-ignore
    validate: fbdoctype.validate,
    type: fbdoctype.type,

    collection: () => {
      const collection = fbdoctype.collection() as FBFCollectionRef<
        TYPE,
        FBA_React_CollectionRefSecret<TYPE>
      >;
      return collection._secret.ref;
    },
    doc: (id: string) => {
      const docref = fbdoctype.doc(id) as FBFDocumentRef<
        TYPE,
        FBA_React_DocumentRefSecret<TYPE>
      >;
      return docref._secret.ref;
    },
    get: fbdoctype.get,
    set: fbdoctype.set,
    delete: fbdoctype.delete,
    keygen: fbdoctype.keygen,
    useFirestoreDocument: (key, options) => {
      return useFirestoreDocument(
        [fbdoctype.collectionName, key],
        result.doc(key),
        options
      );
    },
  };
  DRDocRegistry.Register(result, fbdoctype.collectionName);
  return result;
}
