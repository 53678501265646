import LoadingSpinner from '../loaders/LoadingSpinner/LoadingSpinner';

/* eslint-disable-next-line */
export interface ShadowContainerProps {
  children: React.ReactNode;
  loading?: boolean;
}

export function ShadowContainer({ children, loading }: ShadowContainerProps) {
  return (
    <div className="xs:w-full relative flex flex-col rounded-md md:m-auto md:w-[536px] md:shadow-xl lg:content-center">
      {loading && <LoadingSpinner overlay={true} size="sm" />}
      {children}
    </div>
  );
}

export default ShadowContainer;
