import { z } from '@rabbit/utils/ts';
import { Money, Z_Money } from '../base/basic';

export enum MileageUnit {
  Kilometers = 'km',
  Miles = 'mi',
}

export const MilesToKilometersRatio = 1.609344;

export const Z_MileageUnit = z.nativeEnum(MileageUnit);

export type VehicleInfo = {
  registrationNo: string; // 09C1021
  cherishedRegistrationNo?: string;
  brand?: string | null; // 'Volkswagen';
  model?: string | null; // 'Golf';
  version?: string | null; // 'GT SPT 1.9tdi 105BHP';
  body?: string | null; // 'Hatchback';
  regDate?: string | null; // '2009-01-07';
  engineCc?: number | null; // 1896;
  colour?: string | null; // 'Red';
  fuel?: string | null; // 'Diesel';
  transmission?: string | null; // 'M';
  yearOfManufacture?: number | null; // 2009;
  chassisNo?: string | null; // 'WVWZZZ1KZ9W034002';
  engineNo?: string | null; // 'BXE728394';
  vehicleCategory?: string | null; // 'A';
  mileage?: number | null; // 100000;
  mileageUnit?: MileageUnit | null;
  techCheckDate?: string | null; // '2021-01-07';
  lastServiceDate?: string | null; // '2021-01-07';
  roadTaxDueDate?: string | null; // '2021-01-07';
  insuranceDueDate?: string | null; // '2021-01-07';
  purchasePrice?: Money | null; // 100000;
  driveType?: string | null; // '4x2','4x4';
  isCommercial?: boolean | null; // true;
  riskCategory?: string | null; // 'A', 'B', 'C';
  errors?: any;
  isManuallyRegistered?: boolean | null;
};

export const Z_VehicleInfo = z.object({
  registrationNo: z.string(),
  cherishedRegistrationNo: z.string().optional(),
  brand: z.string().or(z.null()).or(z.undefined()),
  model: z.string().or(z.null()).or(z.undefined()),
  version: z.string().or(z.null()).or(z.undefined()),
  body: z.string().or(z.null()).or(z.undefined()),
  regDate: z.string().or(z.null()).or(z.undefined()),
  engineCc: z.number().or(z.null()).or(z.undefined()),
  colour: z.string().or(z.null()).or(z.undefined()),
  fuel: z.string().or(z.null()).or(z.undefined()),
  transmission: z.string().or(z.null()).or(z.undefined()),
  yearOfManufacture: z
    .number()
    .min(1800)
    .max(2100)
    .or(z.null())
    .or(z.undefined()),
  chassisNo: z.string().or(z.null()).or(z.undefined()),
  engineNo: z.string().or(z.null()).or(z.undefined()),
  vehicleCategory: z.string().or(z.null()).or(z.undefined()),
  mileage: z.number().or(z.null()).or(z.undefined()),
  mileageUnit: Z_MileageUnit.or(z.null()).or(z.undefined()),
  techCheckDate: z.string().or(z.null()).or(z.undefined()),
  lastServiceDate: z.string().or(z.null()).or(z.undefined()),
  roadTaxDueDate: z.string().or(z.null()).or(z.undefined()),
  insuranceDueDate: z.string().or(z.null()).or(z.undefined()),
  purchasePrice: Z_Money.or(z.null()).or(z.undefined()),
  driveType: z.string().or(z.null()).or(z.undefined()),
  isCommercial: z.boolean().or(z.null()).or(z.undefined()),
  riskCategory: z.string().or(z.null()).or(z.undefined()),
  errors: z.any().or(z.null()).or(z.undefined()),
  isManuallyRegistered: z.boolean().or(z.null()).or(z.undefined()),
});

export enum SRVType {
  Vehicle = 'vehicle',
  Product = 'product',
}

export type TableCover = {
  surfaceAreaValue: number;
  surfaceAreaClass: {
    label: string;
    value: string;
  };
  typeofStone: string;
  upsellSealing: boolean;
};

export const Z_TableCover = z.object({
  surfaceAreaValue: z.number(),
  surfaceAreaClass: z.object({ label: z.string(), value: z.string() }),
  typeofStone: z.string(),
  upsellSealing: z.boolean(),
});

export type SRVInfoVehicle = {
  type: SRVType.Vehicle;
  productInfo: VehicleInfo;
};

export type SRVInfoProduct = {
  type: SRVType.Product;
  productInfo: TableCover;
};

export type SRVInfo = SRVInfoVehicle | SRVInfoProduct;

export const Z_SRVInfo = z.object({
  type: z.nativeEnum(SRVType),
  productInfo: Z_VehicleInfo.or(Z_TableCover),
});
