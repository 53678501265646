import { useContext, useState } from 'react';
import {
  ButtonIcon,
  Modal,
  Table,
  formatPhoneNumber,
} from '@rabbit/elements/shared-components';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { ModalNewEditCustomer } from '../../organisms/ModalNewEditCustomer/ModalNewEditCustomer';
import { useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { useGetConsumerList } from '@rabbit/bizproc/react';
import {
  Address,
  DTConsumer_Private,
  DTConsumer_Public,
} from '@rabbit/data/types';
import { formatAddress, useAppInfo } from '../../../utils/helpers';
import { UserContext } from '../../../context/UserContext';
import { useTranslation } from 'react-i18next';

export interface FormValues {
  beginDate: Date | null;
  endDate: Date | null;
}

export const tableheaders = (t: any) => [
  {
    header: t('general.name'),
    accessorKey: 'name',
    size: 150,
  },
  {
    header: t('general.email'),
    accessorKey: 'email',
    size: 15,
  },
  {
    header: t('general.phone'),
    accessorKey: 'phone',
    size: 150,
  },
  {
    header: t('general.productsRegistered'),
    accessorKey: 'productsRegistered',
    size: 20,
  },
  {
    header: t('general.shippingAddress'),
    accessorKey: `shippingAddress`,
    size: 150,
    Cell: ({ cell }: any) => {
      if (
        cell.getValue() &&
        Array.isArray(cell.getValue()) &&
        Array(cell.getValue().length)
      ) {
        return (
          <div className="text-sm">{formatAddress(cell.getValue()[0])}</div>
        );
      } else return '-';
    },
    // Cell: ({
    //   row,
    // }: {
    //   row: {
    //     original: {
    //       shippingAddress: {
    //         street: string;
    //         city: string;
    //         postcode: string;
    //         country: string;
    //       };
    //     };
    //   };
    // }) => (
    //   <>
    //     <p>{row.original.shippingAddress.street}</p>
    //     <p>{row.original.shippingAddress.city}</p>
    //     <p>
    //       {row.original.shippingAddress.postcode}
    //       {row.original.shippingAddress.country}
    //     </p>
    //   </>
    // ),
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'edit',
  },
];

export function CustomersTab() {
  const userContext = useContext(UserContext);
  const { consumerList } = useGetConsumerList(userContext?.user?.accessToken);
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState<boolean>(false);
  const [customerDetails, setCustomerDetails] =
    useState<DTConsumer_Private | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>();

  const handleEditModal = (
    title: string,
    customerDetails: DTConsumer_Private | null
  ) => {
    setModalTitle(title);
    setCustomerDetails(customerDetails);
    setIsNewEditModalOpen(true);
  };

  const addEditModalSettings = {
    title: modalTitle,
    handleClose: () => setIsNewEditModalOpen(false),
  };

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: t('message.deleteConfirmationCustomer'),
      primaryButtonText: t('general.yes'),
      outlineButtonText: t('general.cancel'),
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const sortData = (data: DTConsumer_Public[]) => {
    return data.sort((a: DTConsumer_Public, b: DTConsumer_Public) => {
      const aUpdate = new Date(a.tupdate);
      const bUpdate = new Date(b.tupdate);
      return bUpdate.getTime() - aUpdate.getTime();
    });
  };

  const formatResultsForCustomersTable = () => {
    let dataList =
      consumerList && consumerList.data ? sortData(consumerList.data) : [];

    if (appInfo.environment === 'LIVE') {
      dataList =
        consumerList?.data?.filter(
          (result: DTConsumer_Public) =>
            result?.email &&
            !result.email?.includes('@iwarranty.co') &&
            !result?.email?.includes('@studiographene.com')
        ) ?? [];
    }

    if (consumerList.isSuccess)
      return dataList.map((result: any, i: number) => {
        return {
          id: result?.docid,
          name:
            result?.splitname?.first && result?.splitname?.last // Check if both first and last name exist
              ? result?.splitname?.first + ' ' + result?.splitname?.last
              : result.fullname && result.fullname.trim() !== '' // Check if fullname exists and is not just whitespace
              ? result.fullname
              : '-',
          email: result?.email,
          phone: formatPhoneNumber(result?.phone),
          productsRegistered: getNonSRVHoldings(
            result?.holdings,
            result?.srv_holdings
          ).length,
          shippingAddress:
            (result?.address as Address[])?.length > 1
              ? (result?.address as Address[])?.filter((i) => i.isDefault)
                  .length > 0
                ? (result?.address as Address[])?.filter((i) => i.isDefault)
                : result?.address
              : result?.address,
          edit: (
            <div className="flex justify-end gap-2 px-2 text-right">
              {/* <ButtonIcon
                label={'Edit details'}
                kind={'bgLightGreen'}
                iconLeft={true}
                Icon={PencilSquareIcon}
                onClick={() => handleEditModal('Edit customer', result)}
              /> */}
              <PencilSquareIcon
                className="text-primary-900 h-[20px] w-[20px]"
                onClick={() => {
                  handleEditModal(t('general.editCustomer'), result);
                }}
              />
              {/* TODO: Delete customer is not implemented yet -VP */}
              {/* <TrashIcon
                className='w-[20px] h-[20px] text-primary-900'
                onClick={() => setIsConfirmationModalOpen(true)}
              /> */}
            </div>
          ),
        };
      });
    return dataList;
  };

  return (
    <>
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div className="absolute top-[60px] right-5">
          <div>
            <ButtonIcon
              label={t('general.newCustomer')}
              iconLeft={true}
              onClick={() => handleEditModal(t('general.newCustomer'), null)}
            />
          </div>
        </div>
      </div>
      <Table
        columns={tableheaders(t)}
        data={formatResultsForCustomersTable()}
        initialState={{
          columnPinning: { right: ['actions'] },
          showGlobalFilter: true,
        }}
        muiSearchTextFieldProps={{
          placeholder: t('message.searchEmailAddressCustomerName'),
          sx: {
            minWidth: '600px',
            borderRadius: '20px',
            backgroundColor: '#fff',
            margin: '5px 0',
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderRadius: '10px' },
            },
          },
          variant: 'outlined',
          margin: 'none',
        }}
        useCallbackClickRow={(data: {
          shippingAddress: {
            street: string;
            city: string;
            postcode: string;
          };
          productsRegistered: string;
          phone: string;
          email: string;
          name: string;
          id: string;
        }) =>
          navigate(SAGE_ROUTE_NAME.MANAGE + '/customer/' + data.id, {
            state: { docid: data.id, productName: data.name },
          })
        }
        state={{
          isLoading: consumerList.isLoading,
        }}
      />
      {isNewEditModalOpen && (
        <div>
          <ModalNewEditCustomer
            handleClose={() => setIsNewEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            customerDetails={customerDetails}
            modalSettings={addEditModalSettings}
          />
          {isConfirmationModalOpen && (
            <Modal
              className="h-fit w-[350px] rounded-md bg-white"
              kind={confirmationModalSettings.kind}
              settings={confirmationModalSettings.settings}
            />
          )}
        </div>
      )}
    </>
  );
}

export default CustomersTab;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */
const getNonSRVHoldings = (holdings: string[], srv_holdings: string[]) => {
  return holdings.filter((holding) => !srv_holdings?.includes(holding));
};
