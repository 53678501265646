import { useEffect, useState } from 'react';
import { ButtonIcon, Modal, Table } from '@rabbit/elements/shared-components';
import ModalAddEditItem, {
  ModalAddEditItemKind,
  ModalAddEditItemMode,
} from '../../organisms/ModalNewEditProduct/ModalAddEditItem';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/solid';
import {
  ConsumerIssueTypeHookOrigins,
  useManageConsumerIssueTypes,
} from '@rabbit/bizproc/react';
import { DTConsumerIssueType } from '@rabbit/data/types';
import { t } from 'i18next';

export const tableheaders = (t: any) => [
  {
    header: t('general.label'),
    accessorKey: 'label',
    maxSize: 100,
    muiTableBodyCellProps: {
      className: '',
    },
  },
  {
    header: t('general.details'),
    accessorKey: 'details',
  },
  {
    header: t('general.enabled'),
    accessorKey: 'enabled',
  },
  {
    title: t('general.actions'),
    header: '',
    accessorKey: 'actions',
    size: 15,
  },
];

export default function IssueTypesTab() {
  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE_MANAGE
  );
  const [formattedIssueTypes, setFormattedIssueTypes] = useState<any[]>([]); // todo needs to be typed
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<ModalAddEditItemMode>(
    ModalAddEditItemMode.new
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState<DTConsumerIssueType | null>();

  const handleEditModal = (itemDetails?: DTConsumerIssueType) => {
    setItemDetails(itemDetails || null);
    setModalMode(
      itemDetails ? ModalAddEditItemMode.edit : ModalAddEditItemMode.new
    );
    setEditModalOpen(true);
  };

  //console.log('consumerIssueTypeList in tab', consumerIssueTypeList); //@TEST_REMOVE_LATER
  useEffect(() => {
    if (consumerIssueTypeList.isSuccess && consumerIssueTypeList?.data) {
      const formattedIssueTypeArr = consumerIssueTypeList?.data.map(
        (issueType) => {
          return {
            docid: issueType.docid,
            label: issueType.label,
            enabled: issueType.enabled ? t('Yes') : t('No'),
            details: issueType.description,
            actions: (
              <div className="text-center">
                <ButtonIcon
                  label={t('general.editItem')}
                  kind={'bgLightGreen'}
                  iconLeft={true}
                  Icon={PencilSquareIcon}
                  onClick={() => handleEditModal(issueType)}
                />
              </div>
            ),
          };
        }
      );
      setFormattedIssueTypes(formattedIssueTypeArr);
    } else {
      setFormattedIssueTypes([]);
    }
  }, [consumerIssueTypeList?.isSuccess, consumerIssueTypeList?.data]);

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: t('message.deleteIssueTypeConfirmation'),
      primaryButtonText: t('general.yes'),
      outlineButtonText: t('general.cancel'),
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  return (
    <div className="flex flex-col">
      <div className="relative flex w-full items-center justify-between py-4">
        <div className="absolute right-5 top-[60px] flex w-full justify-end space-x-4">
          <div className="z-10">
            <ButtonIcon
              label={t('general.newIssueType')}
              Icon={PlusIcon}
              iconLeft={true}
              onClick={() => handleEditModal()}
            />
          </div>
        </div>
      </div>
      <Table
        columns={tableheaders(t)}
        data={formattedIssueTypes}
        initialState={{ showGlobalFilter: true }}
        state={{
          isLoading: consumerIssueTypeList.isLoading,
        }}
        muiSearchTextFieldProps={{
          placeholder: t('general.searchByIssueType'),
        }}
      />
      {editModalOpen && (
        <div>
          <ModalAddEditItem
            handleClose={() => setEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            itemDetails={itemDetails}
            kind={ModalAddEditItemKind.issueTypes}
            title={
              modalMode === 'edit'
                ? t('general.editIssueType')
                : t('general.newIssueType')
            }
            mode={modalMode}
          />
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </div>
  );
}
