import { Functions } from '@firebase/functions';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { RabbitEnvironments } from '@rabbit/data/types';
import { getAuth } from 'firebase/auth';
type HttpMethod = 'GET' | 'POST';


const FIREBASE_MODE = import.meta.env['VITE_FIREBASE_MODE'];

export const apiCallable =
  (firebaseFunctions: Functions, path: string) => async (params: any) => {
    // Extract the method if provided in the path, otherwise default to POST
    let [urlPath, method = 'POST'] = path.split('|');
    if (!urlPath.startsWith('/')) {
      urlPath = '/' + urlPath;
    }

    // Replace URL placeholders with actual values from params
    if (params) {
      Object.keys(params).forEach((key) => {
        urlPath = urlPath.replace(`:${key}`, params[key]);
      });
    }

    // Determine the URL prefix based on the environment and host
    let urlPrefix = '';
    if (FIREBASE_MODE !== RabbitEnvironments.EMULATOR) {
      if (window.location.hostname === 'localhost') {
        urlPrefix = `https://${firebaseFunctions.region}-${firebaseFunctions.app.options.projectId}.cloudfunctions.net/api`;
      }
    } else {
      urlPrefix = `http://localhost:5001/${firebaseFunctions.app.options.projectId}/${firebaseFunctions.region}/api`;
    }

    // Fetch the Firebase auth token
    const token = await getAuth(firebaseFunctions.app).currentUser?.getIdToken();

    // Configure the Axios request
    const axiosConfig: AxiosRequestConfig = {
      method: method.toUpperCase() as HttpMethod,
      url: urlPrefix + urlPath,
      headers: { Authorization: 'Bearer ' + token, Accept: 'application/json' },
      ...(method.toUpperCase() !== 'GET' ? { data: params } : {}), // Attach params as data for non-GET requests
    };

    // Make the API call and handle errors
    const response = await axios(axiosConfig).catch((error) => {
      console.error('Error calling API', error.response);
      throw error;
    });

    return response.data;
  };
