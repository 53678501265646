//export interface PrivacyPolicyContentViewProps {}

export function PrivacyPolicyContentView() {
  return (
    <div className="reset-ordered-list font-nunito">
      <h4>
        <strong>1.</strong> <strong>Introduction</strong>
      </h4>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          At iWarranty, we recognise the importance of protecting the privacy of
          our Users and other parties who provide their personal information to
          us.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We understand when you use iWarranty Services, you trust us with your
          personal data and we are committed to keeping that trust. That starts
          with helping you understand our privacy practices. Our privacy policy
          for how we collect and deal with your personal information is
          explained below (
        </span>
        <strong>Privacy Policy</strong>
        <span style={{ fontWeight: 400 }}>).&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Please read the privacy policy carefully to understand how we collect,
          use and protect information that you provide to us, how this data
          might be shared with our Partners and other Third Parties and what
          choices you have in how this data may be used by us. This policy also
          contains sections regarding how you can contact us, withdraw your
          consent and how you can request information on what is held by us
          about you.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          By using our product or services, you agree to our Privacy Policy and
          consent to the collection, use and disclosure of your personal
          information as set out in our Privacy Policy.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Our Privacy Policy was last updated on 17 July&nbsp; 2023 and includes
          disclosure of the matters set out in relevant applicable privacy
          legislation.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <em>
            This Privacy Policy notice applies to users of iWarranty’s services
            anywhere in the world, including users of iWarranty apps, websites,
            features, or other services. See also{' '}
          </em>
        </strong>
        <a href="/terms-and-conditions" target="_blank">
          <strong>
            <em>iWarranty End User Terms and Conditions</em>
          </strong>
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Who We Are</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          The Services are provided by iWarranty Ltd (“We or “iWarranty”) which
          is a limited company registered in England and Wales under company
          number 12014798 with its registered office at 19-21 Crawford Street,
          London, W1H 1PJ, UK. For support, please contact us via:
          support@iwarranty.co. Our main trading address and principal place of
          business is also located at this same address. We are also registered
          with the UK Information Commissioner's Office. If you have any
          questions about how we use your personal data, you can ask for our
          Data Protection Manager or address your communication to our Data
          Protection Manager at{' '}
        </span>
        <a href="mailto:support@iwarranty.co">
          <span style={{ fontWeight: 400 }}>support@iwarranty.co</span>
        </a>
        <span style={{ fontWeight: 400 }}> or </span>
        <a href="mailto:privacy@iwarranty.co">
          <span style={{ fontWeight: 400 }}>privacy@iwarranty.co</span>
        </a>
        <span style={{ fontWeight: 400 }}>.</span>
      </p>
      <p>&nbsp;</p>
      <h4>
        <strong>2. Data Collection and Usage</strong>
      </h4>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We collect information to provide our Services to our users and also
          to improve our Applications and Services. The information we collect
          can range from an email address to information on how you interacted
          with our Applications and used our Services, including as a part of
          registering products and warranty claims, purchasing products or
          uploading content.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Further details about how We collect personal data, as well as the
          types of personal data We collect, are set out below.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>iWarranty collects data:</span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Provided by users to iWarranty during account creation
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Created during the use of iWarranty Services
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Required by our business partners (such as manufacturers, retailers
            or insurers) (“
          </span>
          <strong>Partners</strong>
          <span style={{ fontWeight: 400 }}>
            ") as a part of using the Services
          </span>
        </li>
      </ul>
      <p>
        <strong>User Profile Creation:</strong>
        <span style={{ fontWeight: 400 }}>
          {' '}
          we collect data when users scan a barcode and create an account to
          register their product warranties or update their user accounts. This
          may include&nbsp; name, email, phone number, login name and password,
          address and other information you enter at that time.&nbsp; This
          information is required to create your account, register your product
          warranties with relevant Partners or Third Parties and identify you as
          the owner/s of the products.&nbsp; If you register as a business or
          repairer with us, you may be asked for further information regarding
          your business. This may include supporting evidence which you may
          provide to Us via email or post or via our Applications.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Demographic data:</strong>
        <span style={{ fontWeight: 400 }}>
          {' '}
          We may collect demographic data about users, such as birth date/age,
          gender or occupation, when required for certain product registration
          or requested. We may also collect demographic data, such as age group
          and household composition, home ownership and other relevant data
          appropriate for facilitating a warranty registration process, claim
          submission, verification, validation and claim administration.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>User Content: </strong>
        <span style={{ fontWeight: 400 }}>
          we collect data submitted by users including to register a product,
          process a warranty claim, product fault information and other related
          information to facilitate an efficient and fast processing of your
          claims or repairs.{' '}
        </span>
        <span style={{ fontWeight: 400 }}>
          This may include photographs, or audio or video recordings submitted
          by users in connection with a claim or repair or&nbsp; product related
          issues.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Communications data: </strong>
        <span style={{ fontWeight: 400 }}>
          we collect data regarding phone, text or in-app&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          communications between users that are enabled by iWarranty, including
          interactions between Users and our Partners where this is captured as
          a part of processing an in-or-out of warranty repair. This could
          include monitoring, recording, transcribing, storing and using
          communication between you and our Partners and with Us. It may also
          mean that you and our Partners who call or contact our offices or
          personnel may have their contact information recorded in our systems
          or telecommunication devices. This includes any information you may
          leave in a voicemail when calling our offices or personnel. PLEASE
          NOTE: In operating as a Partner and using our Services you agree that
          We may at our choosing monitor, record, transcribe, store and use
          communication between yourself and a consumer to check details of any
          instructions received as a result of using our Services.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Data created during use of our services</strong>
        <span style={{ fontWeight: 400 }}>
          : this includes geo-location data to facilitate a repair or
          replacement of an item, provide in-warranty services or out of
          warranty repair services. We also collect transaction data about a
          particular repair requests or related services, including purchases .
          When you use our Applications and Services We may collect information
          about how you interacted, including information that your browser
          sends when you visit an Application. This log data may include your
          Internet Protocol address, the address of the web page you visited
          before using our services, and the date and time of use of our
          services.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may use third party tools that, whilst they do not identify
          specific users of our site, aggregate user behaviour in interacting
          with our Website and services. These tools may provide information
          including addresses of web pages visited before using our services and
          the devices and browsers used to access our Website. This data may
          include geo-location information as provided by these third parties.
          One of the tools we use is Google Analytics. Please see click here on
          how Google Analytics uses your data https://policies.google.com.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Billing and other information</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          For Users that purchase or provide products or services, We may store
          your information with our third-party payment processors or third
          party payment processors of our Partners. We may also store other
          details in third party systems to provide Us with accounting and
          customer relationship management abilities. This may include payment
          histories and contact information as well as notes taken by Our staff.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Third party data</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may also receive information from our Partners or others that We
          use to make our Services more effective, tailored, and more useful to
          you. This might be aggregate level information such as which postcodes
          go with which city, or it might be more specific information, such as
          email address, name and profile information passed from them on
          account registration.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Other methods We may use to collect your information</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may collect information about you (name, contact details, etc) if
          you contact our office via telephone, fax, post or via email. We may
          also collect your information at trade shows or during other
          interactions with you in person. This information may then be
          transcribed and uploaded into our systems or recorded in written
          format.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={3}>
        <li>
          <strong>How we use Personal Data</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We will only process your information if there is a reason for doing
          so, and if that reason is permitted by law. We use the information We
          collect from you when you use our Applications or Services, to
          provide, maintain, protect and to improve our Applications and
          Services, to develop new ones and to protect Us and our users. Further
          information about the purposes for which we use your information and
          the legal basis for doing so are detailed below.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Where we need to provide you with the Services you have requested, we
          use your information to:
        </span>
        <span style={{ fontWeight: 400 }}>
          <br />
          <br />
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            enable us to provide you with access to the relevant parts of the
            Sites, we collect necessary cookies from your device;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            supply the services you have requested, we collect your name,
            contact, product, claim, purchase and other relevant details;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            enable us to collect payment from you, we collect your credit or
            debit card information; and
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            contact you where necessary concerning our Services, such as to
            resolve issues you may have, we collect the information listed above
            and any additional information we need to resolve your issue.
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          <br />
        </span>
        <span style={{ fontWeight: 400 }}>
          We also process your information where we have a legitimate interest
          for doing so, which are to:&nbsp;
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            personalise our services, including to make it easier and faster for
            you to register and purchase products and claims;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            improve the effectiveness and quality of service that our customers
            can expect from us in the future;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            tailor content that we or our partners display to you, for example
            so that we can show you partners which are in your area or make sure
            you see the advertising which is most relevant to you, based on
            characteristics determined by us;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            enable our customer support team and our partners to help you with
            any enquiries or complaints that you submit to us or our partners in
            the most efficient way possible and to provide a positive customer
            experience;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            contact you for your comments and feedback regarding our
            Applications and services or our partners’ services and/or products;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            notify you if there are any important changes or developments to the
            Applications or our Services or those of our partners;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            let you know that our services are operating in a new area, where
            you have asked us to do so;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            send you information by post about our products, services,
            promotions and initiatives (if you do not want to receive these, you
            can let us know by getting in touch (Please see “Contact Details”
            section);
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            analyse your activity on the Applications so that we can administer,
            support, improve and develop our business and for statistical and
            analytical purposes and to help us to prevent fraud;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            prepare anonymous and aggregate reports (which do not contain your
            information) used to analyse and improve the performance of our
            services and partnerships; and
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            detect, investigate, report and seek to prevent fraud or crime.
          </span>
          <span style={{ fontWeight: 400 }}>
            <br />
            <br />
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          We also process your information to enforce our contractual terms with
          you and any other agreement, to ensure compliance with our internal
          policies and procedures and for the exercise or defence of legal
          claims and to protect the rights of iWarranty, our partners, or others
          (including to prevent fraud).
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          When you process an in-or-out-of warranty repair via our Application,
          we may ask you to leave a review. We may share this feedback with
          other users and our Partners, particularly any feedback on the
          Partners’ processing of your in-or-out-of-warranty repair.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          When you respond to surveys we may use the comments and/or feedback
          you leave on our Applications in our marketing or advertising
          materials. We will only identify you for this purpose by the name and
          the city you have provided us, unless you have chosen for your name
          not to appear on our Application and in our marketing or advertising
          materials. We also use your survey comments and feedback to:
        </span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            generate aggregated insights to help us to assess and improve our
            services; and&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            share aggregated insights with our partners to enable them to assess
            and improve their services.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Your name will not be used when generating or sharing these aggregated
          insights.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We analyse data about your use of the Applications and the services to
          create profiles relating to you and for you. This means that we make
          certain assumptions about what you may be interested in and use this,
          for example, to send you more tailored marketing communications, to
          present you with partners that we think you will prefer, or to let you
          know about special offers or products which we think you may be
          interested in. This activity is referred to as profiling and you have
          certain rights in relation to this type of processing. Please see
          “Your Rights” section for more details.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>We also use your information:</span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            where we have your consent, for example where you report to us an
            issue or incident related to a claim and you allow us to share this
            with our partners to help them investigate the issue for you,
            and&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            to comply with any legal obligation or regulatory requirement to
            which we are subject.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Your information will also be used to provide you with access to our
          Applications to supply you with Services to meet your particular
          requests with respect to processing in-and-out-of-warranty based
          repairs.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you need something repaired and request that to be performed or
          quoted for via our Application, We will use your product or postcode
          to select repairers who are best placed to respond and We will send
          details of the request, together with your first name, to these
          selected repairers so they can respond. Product details and purchase
          information will be provided to the repairers and may be shared with
          our Third Party partners to help identify the product, and help
          provide services to our Partners in relation to parts data and parts
          information so that the repairers can provide you a quote on your
          service or repair request.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          A Repairer who responds to your request will also be given access to
          your contact details including telephone number so that they can
          contact you to discuss your requirements and their quotation. If you
          select a Repairer to undertake the job, then your chosen Repairer will
          be given access to your other contact details so that they can contact
          you to arrange for the work to be undertaken.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you choose to request a Repairer to conduct a home visit, you may
          be asked to provide the address where you wish the work to be
          completed. The address you provide to us will be passed on to only the
          Repairer you have chosen to complete the work.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Some Repairers use third party suppliers and agencies to help them to
          respond to requests for Quotes and to liaise with customers to arrange
          for work to be undertaken and so references to Repairers in this
          privacy policy includes such third parties acting on their behalf.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          The Service allows Repairers to communicate via messaging/chat to
          consumer Users. This functionality allows you to ask questions and
          transmit information between each other that might be useful to either
          party in assessing and understanding a repair including any costs. The
          chat system allows this flow of information to be exchanged in a
          controlled manner. However, if you provide personal contact or other
          personal information (e.g. credit card information) via the
          messaging/chat functionality of the Website, you do so at your own
          risk.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may also review the messaging/chat system from time to time, and
          may use any or all of the information for various purposes including
          assisting us in contacting you, assisting us in any legal proceedings
          or dispute, assisting us in our analysis of completed bookings,
          assisting us in the development of various enhancements of our systems
          or Services or in any other manner that we may be required under law
          to provide such information.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Legal Basis: This is often necessary in order to be able to fulfil our
          obligations under the contract between us. In other cases this is
          necessary for our legitimate interests (including managing our
          relationship with our Partners, enforcing our contractual rights and
          resolving disputes, keeping records, studying how customers use our
          Website and Services and improving our Website and Services).
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Email and other communications&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We facilitate the sending of a variety of emails and other
          communications to you in our Applications. These may relate to repair
          workflows or other services you may have subscribed to or purchased,
          such as extended warranties, service plans or other products you have
          purchased.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Some of the emails We send you are part of the functionality of how
          the Applications and and our Services work, for instance We inform you
          if you have received a communication from the Repairer. If you are
          registered, we may also send you renewal notices etc. We categorise
          emails of this nature as "transactional emails". They are intended to
          give you information in relation to the requests you are making in the
          system and prompt a response from you to those requests.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Other types of emails or communications that We may send you may be
          marketing-related emails. If you register with us as to receive the
          Services or via another service we offer (breakdown cover etc.) and
          have consented to receive such information, We may use your details to
          provide you with, or permit selected third parties to provide you
          with, information about goods, services and offers that We feel may
          interest you.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          For details on how to change your consent to receive these emails,
          refer to the Your Rights and Choices section of this policy.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>SMS Communications</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may send or facilitate sending of a variety of SMS to you, as a
          part of our Applications or Services or other services you may have
          subscribed to or purchased, such as extended warranties, or services
          plans.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Some of the SMS We send you are part of the functionality of how the
          Website and our Services work, for instance We may send you reminders
          about your repair, or ask you to confirm if you have had the work
          done, if you’re a consumer User. If you are registered as a repairer,
          We may send SMS relating to messages you have received from a User. We
          categorise SMS of this nature as "transactional SMS". They are
          intended to give you information in relation to the requests you are
          making in the system and prompt a response from you or the person with
          whom you are communicating to those requests.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Other types of SMS that We may send you may be marketing-related SMS.
          If you register with us and have consented to receive such
          information, We may use your details to provide you with, or permit
          selected third parties to provide you with, information about goods,
          services and offers that We feel may interest you.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Legal Basis</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In the case of transactional SMS, this is necessary in order to be
          able to fulfil our obligations under the contract between us.
          Transactional SMS (and SMS responding to queries or requesting
          information) are also necessary for our legitimate interests
          (including to manage our relationship with you and to resolve queries
          and disputes).
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Telephone communications</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          As disclosed above, Repairers who respond to a request in relation to
          an in-or-out-of-warranty repair will also be given access to the
          telephone number of a consumer User so that they can contact that User
          to discuss their requirements. Likewise, consumer Users are able to
          access Repairer telephone numbers in order to call them and enquire
          about the repairs they have received.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Users may also be called in connection to other services they actively
          purchase from Us. These calls will be for functional purposes related
          to those purchases, such as liaising with the User on their breakdown,
          or service plan booking. Our staff may also use telephone numbers to
          transfer calls to either party, to assist us in our analysis of
          completed repairs, to make enquiries on behalf of Users to Repairers
          and to assist us in any dispute investigations We may conduct.
          Additionally, We will use Partner telephone numbers to conduct account
          management calls, sales calls, and other calls to Partners on behalf
          of iWarranty or our other partners.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may monitor, record, transcribe, store and use communication
          between Users and our Partners and their communications with Us. We
          will only use this information for the following:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            to help us in our analysis of&nbsp; repairs;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>for training purposes; and</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            for legal reasons, including regulatory requirements.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you register as a Partner with Us you are acknowledging and
          accepting the following: you are only being given the access to
          consumer User telephone numbers for the purposes of responding to
          their repair enquiry and not for any other reasons; you are
          registering with us as a business and as such you will maintain
          business-like conduct with our staff and with Users who you contact,
          or who contact you through Us; you agree to have your telephone number
          used in connection to marketing and sales-related activities from
          iWarranty to your business; and you understand that iWarranty may at
          its choosing monitor, record, transcribe, store and use communication
          between yourself and a consumer User in order to check details of any
          instructions received as a result of using the Website.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Legal Basis:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In some cases this is necessary in order to fulfil our obligations
          under our contract with you. In other cases this is necessary for our
          legitimate interests (including managing our relationship with Users
          and our Partners, enforcing our contractual rights and resolving
          disputes, keeping records, studying how customers use our Applications
          and Services, improving our Applications and Services and growing our
          business). In some cases this may also be necessary to ensure
          compliance with our legal obligations.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>For Feedback purposes</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you are a User and post Feedback, the content of the Feedback may
          be published on our Application. Relevant Partners will also have the
          right to post replies to any Feedback posted and the content will be
          published on our Applications. All Feedback will be posted at Our
          discretion in accordance with our Feedback policy.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you register with Us We may use your information to contact you for
          feedback on your use or our products, services or the Website.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Legal Basis: </strong>
        <span style={{ fontWeight: 400 }}>
          This is necessary for our legitimate interests (including studying how
          our customers use our Applications and Services, monitoring customer
          performance in the case of our Partners and customer satisfaction in
          the case of Users and developing and improving our Applications and
          Services).
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Marketing</strong>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may also share certain data with third party social media platforms
          in order to show you targeted ads when you visit them. We do this by:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            the use of cookies which capture your visits to our Applications.
            Please refer to our Cookies Policy for more information; and
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            We may also provide these platforms with your email address to
            create ‘audiences’ of users fitting within a certain
            demographic/category so that we can target our marketing. Please
            check the social media platforms’ terms for more details of these
            services. Our Cookies Policy explains how you can adjust your
            cookies preferences.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>Legal Basis: </strong>
        <span style={{ fontWeight: 400 }}>
          This is necessary for our legitimate interests (including studying how
          customers use our Applications and Services, improving our
          Applications and Services and growing our business through marketing
          to you or making you offers that we believe will interest you).
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Other ways we may use your data</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may use your data to investigate, resolve and transfer complaints
          between parties.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may receive requests from official government/local government
          bodies and law enforcement authorities and to help Us identify and fix
          issues with our systems.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Legal Basis:</strong>
        <span style={{ fontWeight: 400 }}>
          {' '}
          This is necessary for our legitimate interests (including resolving
          complaints and disputes, maintaining and improving our systems). In
          some cases this may be necessary to comply with legal obligations
          which we are subject to.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          PLEASE NOTE: This policy is not intended to place any limits on what
          We do with data that is aggregated and de-identified, so it is no
          longer associated with an identifiable User or Partner.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>4. How your information may be shared and with whom</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          Our employees who need to have access to your personal data will have
          access to it in order to provide Services to you and for the purposes
          detailed above. We will also share your information with other users
          of the Website and Services in the ways described in the section above
          “How we use your Information”. We may also share your information with
          other third parties in the ways described below.
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <strong>Third parties for marketing purposes</strong>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            If you have consented to receiving marketing information from
            selected third parties, We may share your information with third
            parties so that they can provide you with information about the
            products and services they offer.
          </span>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            We may also share certain data with third party social media
            platforms in order to show you targeted ads when you visit them. We
            do this by:
          </span>
        </li>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              the use of cookies which capture your visits to our Website.
              Please refer to our Cookies Policy below for more information; and
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              We may also provide these platforms with your email address to
              create ‘audiences’ of users fitting within a certain
              demographic/category so that we can target our marketing. Please
              check the social media platforms’ terms for more details of these
              services. This is in our legitimate interests of sending you
              direct marketing. Our Cookies Policy explains how you can adjust
              your cookies preferences.
            </span>
          </li>
        </ul>
        <li style={{ fontWeight: 400 }}>
          <strong>With our third-party service providers and agents</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: 400 }}>
            We may allow third-party providers and contractors who supply
            services to Us or who process information on our behalf (for example
            our payment provider and website hosts and third parties who help us
            to develop and support our Website) access to your information
            limited to the extent that it is necessary for them to perform their
            services. We will make sure we have a contract in place with all
            such suppliers which satisfies the requirements of data protection
            laws. In particular, we will ensure that they keep your information
            secure and do not use it for their own purposes, unless you grant
            them permission to do so.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <strong>During changes to our business structure</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: 400 }}>
            We may share your information with another organisation which buys
            our business or our assets or to whom We transfer our agreement with
            you and in the course of any negotiations which may or may not lead
            to such a transfer or sale.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <strong>To comply with laws</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: 400 }}>
            We will disclose your information if We are required to by law. We
            may disclose your information to enforcement authorities if they ask
            us to, or to a third party in the context of actual or threatened
            legal proceedings, provided We can do so without breaching data
            protection laws.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            In working with other third parties to offer our Partners enhanced
            or further services, and to help manage our accounts
          </span>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            If you are a Partner registered with Us and you have expressed your
            interest joining one of the various networks that We are deploying,
            then your contact information may be passed to the suitable network
            partner to help onboard you to that network.
          </span>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            As a User, your product and purchase details may be shared with our
            partners in order to.
          </span>
        </li>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Help identify parts and other specific information related to your
              product make and model.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Help identify repairs that may be completed by purchasing parts
              related to your product.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Help provide reporting to those partners on what work is being
              done via our Applications.
            </span>
          </li>
        </ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Working with third party referrers of visitors to our website and
            third-party suppliers to our Partners
          </span>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            If you have visited the Website as a result of a referral link or
            integration with a third-party site with which We hold a commercial
            relationship, such as another retailer website or price comparison
            platform, We may share selected elements of your information with
            that party, such as your product purchase information, during the
            process of regular reporting and verification of our commercial
            relationships with them. We will only share this information to the
            extent that it is required to confirm that relationship and only
            with the partners with which a relationship has been tracked by our
            system.
          </span>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            Partners may choose to take advantage of commercial partnerships We
            hold with suppliers of parts, or businesses of a similar nature, to
            procure supplies for the completion of the servicing or repair of
            your product. We may share selected elements of your information
            with those partners, such as your product purchase details, during
            the process of regular reporting and verification of our commercial
            relationships with those partners. We will only share this
            information to the extent that it is required to confirm that
            relationship and transaction.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>T</span>
          <strong>o enforce our rights, prevent fraud, and for safety</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: 400 }}>
            To protect and defend the rights, property, or safety of iWarranty
            or third parties, including enforcing contracts or policies, or in
            connection with investigating and preventing fraud.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            PLEASE NOTE: In registering as a Partner, you agree to the Websites
            Terms and Conditions and Code of Conduct. In line with this,
            iWarranty may disclose your contact details to Users (if requested
            by the User) in the event disputes arise between you and the User.
          </span>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            PLEASE NOTE: We may disclose or use aggregated and de-identified
            information for any purpose. For example, we may share aggregated
            and de-identified information with our partners or others for
            business or research purposes like telling a prospective Partner the
            number of repairs available in a certain area, or partnering with a
            publisher to detail cost of servicing by make and model of product
            to its readers.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>
        <strong>5. Cookie Policy</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          iWarranty uses cookies and similar technologies to provide and support
          the Applications and services.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Cookies are small text files which are transferred from the
          Applications and stored on your computer's hard drive. They enable the
          Applications and analytical software to assign useful information to
          visitors at an individual level. Please refer to our Cookies Policy
          for details of Cookies use on the Website.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          By registering to use the Applications and Services you are consenting
          to Us using cookies in the ways described in our cookies policy.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You may be able to configure your browser to restrict cookies or block
          all cookies if you wish. However, if you disable cookies you may find
          this adversely affects your ability to use certain parts of this
          Website
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>6. Our responsibilities</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          We are responsible for all use We make of the information you provide
          to Us and for ensuring that We only use and disclose your information
          in accordance with the terms of this Privacy Policy and applicable
          laws.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you are a consumer User and request Partners to provide repairs or
          undertake work for you, you understand and agree that those Partners
          (and not iWarranty) are solely responsible for their use of your
          information. Whilst We ask all Partners to act responsibly and to
          comply with all applicable data protection and privacy laws We cannot
          accept any responsibility for their use of your information. If you
          are a consumer User and have questions or concerns about any Partner's
          use of your information, please raise the matter with the Partner in
          question in the first instance.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Similarly, if your are a consumer User or a Partner and you enter into
          a separate contract or arrangement with one of our partner
          organisations or another third party (e.g. to buy products or services
          directly from them) then you understand that they will be solely
          responsible for their use of your information in the context of or as
          a result of that contract or relationship. If you have any questions
          about how they will use your personal data, please raise them with the
          partner organisation or third party in the first instance or review
          their privacy policy or notices.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you would like to then raise your concern to iWarranty please
          contact Us at support@iwarranty.co
        </span>
      </p>
      <h5>
        <strong>7. Your rights and choices</strong>
      </h5>
      <p>
        <span style={{ fontWeight: 400 }}>
          We have to hold certain personal data about you, e.g. email addresses
          and telephone numbers, etc. However, we provide you with certain
          choices about how your personal data can be used. We will also operate
          in accordance with data protection laws in maintaining, protecting,
          and using your personal data and we will respect your rights under
          data protection laws.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>Your Choices:</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We give you the following choices when you use our Applications and
          Services:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Opt-in/out of receiving marketing emails: You have the choice to
            opt-in or out of receiving any marketing email from Us and any third
            party at any time. To change your permissions for marketing email
            please access your account details and select appropriately.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Block Cookies: You have the choice on how your browser or mobile
            phone works with the Applications. You may set your browser to block
            all cookies associated with our services. Please see our Cookie
            Policy for more information about this. However, it is important to
            note that the Website may not function properly if some or all of
            the cookies We use are disabled. For instance, how you can gain
            access to your account regularly.
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          Consumer User choices about use of the telephone number: If you are a
          User and wish to remove your telephone number from the system, you can
          email Us at{' '}
        </span>
        <a href="mailto:support@iwarranty.co">
          <span style={{ fontWeight: 400 }}>support@iwarranty.co</span>
        </a>
        <span style={{ fontWeight: 400 }}>
          {' '}
          and We can remove your active number from our system records. We may
          ask you for supporting evidence of your identity, before We remove the
          telephone number from our main system. Note: This may make it
          difficult for Partners to fulfil repairs and they may request your
          telephone number via our messaging/chat systems. If you put contact
          details into any message you send the Partners We may not be able to
          prevent the Partner from holding your details in their own records.
          Any personal information you supply in our messaging/chat system you
          do at your own risk.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Your Rights under data protection law:
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Under data protection legislation you also have certain rights
          regarding your personal data. In particular, you have the right to:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Request access to any personal data we hold about you. In order to
            do this, you can submit a Subject Access Request Form to Us by post,
            You can request the form and the supporting evidence you will need
            to provide to prove your identity please email support@iwarranty.co;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Have any personal data which we hold about you which is inaccurate
            rectified or to have incomplete personal data completed. You can do
            that via updating your profile information, or requesting that
            changes be made in our systems via email. We might ask you to submit
            supporting evidence of your identity before We comply with any
            request.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>Have personal data erased;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Have the processing of your personal data restricted (for example,
            if you think the data we hold about you is inaccurate you can ask us
            to stop processing it, until we will either correct it or confirm it
            is accurate);
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Be provided with the personal data that you have supplied to us, in
            a portable format that can be transmitted to another data controller
            without difficulty;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Object to certain types of processing, including processing based on
            legitimate interests (see above), automated processing (which
            includes profiling) and processing for direct-marketing purposes;
            and
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Not be subject to a decision that is based solely on automated
            processing which produces a legal effect or which has a similar
            significant effect for you.
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          For more information on what rights you have please see the
          Information Commissioners website at{' '}
        </span>
        <a href="http://www.ico.gov.uk/" target="_blank">
          <span style={{ fontWeight: 400 }}>http://www.ico.gov.uk</span>
        </a>
        <span style={{ fontWeight: 400 }}>.</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In order to exercise any of these rights you can to submit a request
          to us via email to support@iwarranty.co. We might ask you to submit
          supporting evidence of your identity before We comply with any
          request.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Please consult the ICO website at{' '}
        </span>
        <a href="http://www.ico.gov.uk/" target="_blank">
          <span style={{ fontWeight: 400 }}>http://www.ico.gov.uk</span>
        </a>
        <span style={{ fontWeight: 400 }}>
          . For further information concerning your rights, and under what
          conditions iWarranty may refuse certain requests.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>8. Security and Retention of information</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          We will keep your information secure by taking appropriate technical
          and organisational measures against its unauthorised or unlawful
          processing and against its accidental loss, destruction or damage. We
          will retain your information for as long as We need it for the
          purposes set out in this policy, or as otherwise required by law. This
          means that personal data will be destroyed or erased from our systems
          when it is no longer required. For more information on how long
          certain types of data is likely to be kept before being destroyed
          please contact our Data Protection Manager.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>9. Changes to privacy policy</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          We will from time to time update and make changes to this policy. We
          recommend that you revisit this privacy policy frequently, and ideally
          each time you visit the Applications. If you continue to use our
          services after those changes are in effect, you agree to the revised
          policy.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>10. Links from our website to others</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you follow a link from any part of the Applications or Services to
          another site, this policy will no longer apply. We are not responsible
          for the information handling practices of third party sites and We
          encourage you to read the privacy policies appearing on those sites.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>
          11. Transfers of Data Outside the European Economic Area (EEA)
        </strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          We will not transfer your personal data outside the EEA or your home
          country unless such transfer is compliant with data protection laws.
          This means that we cannot transfer any of your personal data outside
          the EEA or your home country unless:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            the EU Commission has decided that another country or international
            organisation ensures an adequate level of protection for your
            personal data;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            the transfer of your personal data is subject to appropriate
            safeguards, which may include binding corporate rules or standard
            data protection clauses adopted by the EU Commission or EU-US
            Privacy Shield framework (see{' '}
          </span>
          <a href="https://www.privacyshield.gov/" target="_blank">
            <span style={{ fontWeight: 400 }}>
              https://www.privacyshield.gov
            </span>
          </a>
          <span style={{ fontWeight: 400 }}>; or</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            one of the derogations under data protection laws applies (including
            if you explicitly consent to the proposed transfer).
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          We currently use a number of suppliers of IT services (including
          cloud- based services, hosting services and software development,
          support and maintenance services) who are based outside the EEA. All
          such suppliers have either entered into an agreement with us
          containing EU Commission approved data protection clauses, or they are
          signed up to the EU/US Privacy Shield framework.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>12. How to Contact Us</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          Feel free to contact Us if you have any questions about this policy or
          if you wish to exercise your information rights about any personal
          data you have shared with Us, or that we might hold.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You can contact by email at support@iwarranty.co.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>You can also write to Us at</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          iWarranty is a limited company registered in England and Wales under
          company number 12014798 with its registered office at 19-21 Crawford
          Street, London, W1H 1PJ, UK.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>13. Right to make a complaint</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you have any issues with our processing of your personal data
          please contact us in the first instance and we will endeavour to
          resolve it for you. However, if you wish to make a formal complaint,
          you can contact the Information Commissioner's Office on 0303 123 1113
          or at Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF.&nbsp;
          This Privacy Policy was last updated on 17 July 2023.
        </span>
      </p>
    </div>
  );
}

export default PrivacyPolicyContentView;
