import {
  DR_Consumer_Issue_Type,
  NewkeyAllocator,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import {
  FBD_Consumer_Issue_Type,
  PrincipalsFieldName,
  TenantLinkFieldName,
} from '@rabbit/data/types';
import { FBDTKeygenGeneric } from '@rabbit/firebase/doctype';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { query, orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { convertRootPersonasFromLexicon2Array } from '@rabbit/bizproc/client';

interface ConsumerIssueTypeFormDataShape {
  label: string;
  enabled: boolean;
  value?: string;
  orderNo?: number;
  description?: string;
}

export enum ConsumerIssueTypeHookOrigins {
  OLIVE = 'olive',
  SAGE = 'sage',
  SAGE_MANAGE = 'sage-manage',
}
/**
 * Used on Sage. This hook is used to fetch a list of product consumerIssueTypes, as well as provide functions for creating and editing them.
 * If no docid is provided, creating a new consumerIssueType is allowed. Otherwise, only editing and fetching is allowed.
 */
export function useManageConsumerIssueTypes(
  origin: ConsumerIssueTypeHookOrigins,
  docid?: string
) {
  // If we have a docId, use it. Otherwise, generate a new one
  const consumerIssueTypeId =
    docid ?? FBDTKeygenGeneric('Random', FBD_Consumer_Issue_Type);
  const [ourDocid, setOurDocid] = useState(
    NewkeyAllocator(consumerIssueTypeId, DR_Consumer_Issue_Type)
  );

  useEffect(() => {
    setOurDocid(NewkeyAllocator(consumerIssueTypeId, DR_Consumer_Issue_Type));
  }, [docid]);

  const { body, commit, isReady, update } = usePortalMultipleDocumentEditor(
    { consumerIssueType: { type: DR_Consumer_Issue_Type, docid: ourDocid } },
    {
      create: docid ? 'DENY' : 'ALLOW',
    }
  );
  /* -------------------------------------------------------------------------- */
  /*                              DB data fetching                              */
  /* -------------------------------------------------------------------------- */
  const tenantLinkVal = t(TenantLinkFieldName);

  const queryParams = [
    orderBy('orderNo'),
    where(TenantLinkFieldName, '==', tenantLinkVal),
  ];
  if (origin !== ConsumerIssueTypeHookOrigins.SAGE_MANAGE)
    queryParams.push(where('enabled', '==', true));

  const consumerIssueTypeQuery = query(
    DR_Consumer_Issue_Type.collection(),
    ...queryParams
  );

  const consumerIssueTypeList = useFirestoreQueryData(
    ['consumerIssueType-list', origin, tenantLinkVal],
    consumerIssueTypeQuery,
    {
      subscribe: true,
    }
  );

  //console.log('consumerIssueTypeList from hook', consumerIssueTypeList); //@TEST_REMOVE_LATER
  /* -------------------------------------------------------------------------- */
  /*                             Creating / Editing                             */
  /* -------------------------------------------------------------------------- */

  async function createConsumerIssueType(
    formData: ConsumerIssueTypeFormDataShape
  ) {
    if (origin !== ConsumerIssueTypeHookOrigins.SAGE_MANAGE) {
      throw new Error('Cannot create a consumerIssueType from this origin');
    }
    if (docid)
      throw new Error(
        'Cannot create a consumerIssueType while editing a different one'
      );
    if (!formData?.label) throw new Error('Invalid form data provided');
    if (!body.consumerIssueType)
      throw new Error('The base document was not generated correctly');

    const { label, orderNo, enabled } = formData;

    update({
      consumerIssueType: {
        ...body.consumerIssueType,
        label,
        //orderNo, // todo
        enabled,
        description: formData.description,
        tenantLink: t(TenantLinkFieldName),
        principals: convertRootPersonasFromLexicon2Array(
          t(PrincipalsFieldName)
        ),
        docid: FBD_Consumer_Issue_Type.keygen(
          body.consumerIssueType,
          FBD_Consumer_Issue_Type
        ),
      },
    });
    await commit();
  }

  async function editConsumerIssueType(
    formData: ConsumerIssueTypeFormDataShape
  ) {
    if (origin !== ConsumerIssueTypeHookOrigins.SAGE_MANAGE) {
      throw new Error('Cannot edit a consumerIssueType from this origin');
    }

    if (!docid)
      throw new Error(
        'Hook requires a docid to be provided in order to locate and edit a consumerIssueType'
      );
    if (!formData?.label) throw new Error('Invalid form data provided');
    if (!body.consumerIssueType)
      throw new Error('The document was not fetched correctly');

    const { label, enabled, orderNo } = formData;

    update({
      consumerIssueType: {
        ...body.consumerIssueType,
        label,
        enabled,
        description: formData.description,
        // orderNo, // todo
      },
    });
    await commit();
  }

  return {
    consumerIssueTypeList,
    createConsumerIssueType,
    editConsumerIssueType,
    consumerIssueTypeData: body,
    isReady,
  };
}
