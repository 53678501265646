import { PrivacyPolicyContentView } from '@rabbit/elements/shared-components';

function PrivacyPolicyView() {
  return (
    <div className="flex flex-grow flex-col justify-start">
      <div className="font-caudex text-primary-900 mb-4 text-3xl">
        Privacy Policy
      </div>
      <PrivacyPolicyContentView />
    </div>
  );
}

export default PrivacyPolicyView;
