import { Field } from 'formik';
import classNames from 'classnames';
import { InputCheckboxSettingsShape } from '@rabbit/elements/shared-types';

export interface InputCheckboxProps {
  name: string;
  settings: InputCheckboxSettingsShape; //any to remove
}

const dynamicStyles = {
  base: 'text-primary-900 focus:ring-transparent h-4 w-4 rounded border-gray-300 bg-gray-100',
  disabled: 'border-gray-300 text-gray-500 opacity-30',
};

export function InputCheckbox({ name, settings }: InputCheckboxProps) {
  const { checkboxLabel, checkboxLabelStyles, onChange, ...rest } = settings;

  return (
    <label className="flex items-center gap-2 leading-[1.2rem]">
      <Field
        className={classNames(dynamicStyles.base, {
          [`${dynamicStyles.disabled}`]: rest.disabled,
        })}
        type="checkbox"
        name={name}
        value={settings?.value}
        onClick={
          onChange && checkboxLabel ? () => onChange(checkboxLabel) : null
        }
        {...rest}
      />
      {checkboxLabel && (
        <span
          className={classNames(checkboxLabelStyles, {
            [`${dynamicStyles.disabled}`]: rest.disabled,
          })}
          dangerouslySetInnerHTML={{
            __html: checkboxLabel,
          }}
        ></span>
      )}
    </label>
  );
}

export default InputCheckbox;
