import { Persistor } from './persistor';
import { AtomBucketPersistor } from './types';

export type AtomBucketConfig = {
  prefix: string;
  postLoad?: () => void;
};

/** A bucket for holding atoms of objects in persistent storage.
 * A simple layer that provides cataloging and a bit of opinionation on top of local storage.
 */
export class AtomBucket<T> {
  prefix: string;

  config: AtomBucketConfig;

  persistor: AtomBucketPersistor<T> = Persistor;

  loaded = false;

  catalog: { [key: string]: 1 } = {};

  constructor(config: AtomBucketConfig) {
    this.prefix = config.prefix;
    this.config = config;
    void this.loadCatalog(); // Kick it off but don't wait for it
  }

  async loadCatalog() {
    const keys = await this.persistor.getAllKeys();
    keys
      .filter((keyWithPrefix) => keyWithPrefix.startsWith(this.prefix))
      .forEach((keyWithPrefix) => {
        const key = keyWithPrefix.substr(this.prefix.length);
        this.catalog[key] = 1;
      });
    this.loaded = true;
    this.config.postLoad?.();
  }

  async load(key: string) {
    await this.isReady();
    if (!this.catalog[key]) return null;
    return await this.persistor.get(this.prefix + key);
  }

  async save(key: string, value: T) {
    await this.isReady();
    // Make sure it's registered in the catalog
    this.catalog[key] = 1;
    return await this.persistor.set(this.prefix + key, value);
  }

  async delete(key: string) {
    await this.isReady();
    await this.persistor.delete(this.prefix + key);
  }

  async isReady() {
    // Rough and ready but won't really happen that much
    while (!this.loaded) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    return true;
  }
}
