import { NoSqlDoc } from '@rabbit/firebase/doctype';
import { useFirestoreDocument } from '@react-query-firebase/firestore';
import { DocumentSnapshot, FirestoreError } from 'firebase/firestore';
import { QueryObserverBaseResult, UseQueryResult } from 'react-query';
import { DRDocType } from '../../../../firebase/react/src';

export type SingleQuery<T extends NoSqlDoc> = {
  type: DRDocType<T>;
  docId: string | null;
};

type ResponseDataCollection<T extends Record<string, SingleQuery<any>>> = {
  [K in keyof T]: T[K]['type']['type'] | null;
};

type QueryResultsDataCollection<T extends Record<string, SingleQuery<any>>> = {
  [K in keyof T]: UseQueryResult<
    DocumentSnapshot<T[K]['type']['type']>,
    FirestoreError
  >;
};

interface ResponseData<T extends Record<string, SingleQuery<any>>>
  extends QueryObserverBaseResult<ResponseDataCollection<T>, string> {
  data: ResponseDataCollection<T>;
  queryResults: QueryResultsDataCollection<T>;
}

export function useMultipleFirestoreQueries<
  T extends Record<string, SingleQuery<any>>
>(input: T): ResponseData<T> {
  const output: ResponseData<T> = {
    dataUpdatedAt: 0,
    error: null,
    failureCount: 0,
    isError: false,
    isFetched: true,
    isFetchedAfterMount: true,
    isFetching: false,
    isIdle: true,
    isLoading: false,
    isLoadingError: false,
    isPlaceholderData: false,
    isPreviousData: false,
    isRefetchError: false,
    isStale: false,
    isSuccess: true,
    refetch: () => {
      throw new Error('TODO');
    },
    remove: () => {
      throw new Error('TODO');
    },
    status: 'success',
    errorUpdateCount: 0,
    errorUpdatedAt: 0,
    isRefetching: false,

    data: {} as ResponseDataCollection<T>,
    queryResults: {} as QueryResultsDataCollection<T>,
  };

  for (const key in input) {
    const entry = input[key];

    const docId = entry.docId;
    const hack_for_empty_document = docId === null;
    const valid_doc_id = hack_for_empty_document ? 'empty' : docId;

    const singleUse = useFirestoreDocument(
      [entry.type.collectionName, docId],
      entry.type.doc(valid_doc_id),
      hack_for_empty_document ? { source: 'cache' } : { subscribe: true }
    );
    output.data[key] =
      (singleUse.data?.data() as T[keyof T]['type']['type']) || null;
    output.queryResults[key] = singleUse;

    // Take the best and worst results from all the queries and merge them
    if (output.dataUpdatedAt > singleUse.dataUpdatedAt) {
      output.dataUpdatedAt = singleUse.dataUpdatedAt;
    }
    if (singleUse.error) {
      output.error = output.error
        ? output.error + ' / ' + singleUse.error.message
        : singleUse.error.message;
    }
    output.failureCount += singleUse.failureCount;
    output.isError = output.isError || singleUse.isError;
    output.isFetched = output.isFetched && singleUse.isFetched;
    output.isFetchedAfterMount =
      output.isFetchedAfterMount && singleUse.isFetchedAfterMount;
    output.isFetching = output.isFetching || singleUse.isFetching;
    output.isIdle = output.isIdle && singleUse.isIdle;
    output.isLoading = output.isLoading || singleUse.isLoading;
    output.isLoadingError = output.isLoadingError || singleUse.isLoadingError;
    output.isPlaceholderData =
      output.isPlaceholderData || singleUse.isPlaceholderData;
    output.isPreviousData = output.isPreviousData || singleUse.isPreviousData;
    output.isRefetchError = output.isRefetchError || singleUse.isRefetchError;
    output.isStale = output.isStale || singleUse.isStale;
    output.isSuccess = output.isSuccess && singleUse.isSuccess;
    output.errorUpdateCount += singleUse.errorUpdateCount;
    if (output.errorUpdatedAt < singleUse.errorUpdatedAt) {
      output.errorUpdatedAt = singleUse.errorUpdatedAt;
    }
    output.isRefetching = output.isRefetching || singleUse.isRefetching;

    // Complicated
    // 'idle' | 'loading' | 'error' | 'success'
    switch (output.status) {
      case 'idle':
        if (singleUse.status === 'loading') output.status = 'loading';
        if (singleUse.status === 'error') output.status = 'error';
        break;
      case 'loading':
        if (singleUse.status === 'error') output.status = 'error';
        break;
      case 'error':
        break;
      case 'success':
        if (singleUse.status === 'loading') output.status = 'loading';
        if (singleUse.status === 'error') output.status = 'error';
        if (singleUse.status === 'idle') output.status = 'idle';
        break;
    }
  }
  return output;
}
