import React, { useEffect, useState } from 'react';

import { GenericLexicon } from '@rabbit/mixmaster/core';
import { BootI18N } from './language';

export const MixMasterWrapper = (props: {
  children: React.ReactNode;
  bootstrap: GenericLexicon;
}) => {
  const [bootWait, setBootWait] = useState(true);
  useEffect(() => {
    const asyncBoot = async () => {
      await BootI18N(props.bootstrap);
      setBootWait(false);
    };
    void asyncBoot();
  });

  if (bootWait) return null;
  return <>{props.children} </>;
};
