import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useSageAPI, } from '@rabbit/bizproc/react';
import { ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

export const validationSchema = Yup.object().shape({
  registrationNo: Yup.string()
    .trim()
    .min(5, 'Enter a valid registration plate')
    .required('Enter a registration plate'),
});

export interface CreateNewSelectCarProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: any;
  setIsLoading: (loading: boolean) => void;
}

interface ConsumerDataShape {
  registrationNo: string;
}

export function CreateNewSelectCar(props: CreateNewSelectCarProps) {
  const { getHoldingVehicleInfo } = useSageAPI();
  const { onChange, handleClose, setIsLoading } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);

  const initialValues: ConsumerDataShape = {} as any;
  const { t } = useTranslation();

  const onSubmit = async (values: ConsumerDataShape) => {
    const { registrationNo } = values;
    setIsLoading(true);
    try {
      const registration = await getHoldingVehicleInfo({
        registrationNo, tenant:t('tenantLink')
      });
      
      setIsLoading(false);
      if (registration && registration.brand) {
        if (registration.brand) {
          const updatedRegistration = {
            ...registration,
            make: registration.brand,
            tech_check_date:
              registration?.techCheckDate !== '0000-00-00'
                ? registration?.techCheckDate
                : '',
            vehicleCategory:
              t('tenantLink') === 'PINNACLEWARRANTIES'
                ? undefined
                : registration?.vehicleCategory,
          };
          onChange(1, updatedRegistration);
        } else {
          onChange(1, registration);
        }
      } else {
        setNotFoundError(true);
      }
    } catch (e) {
      setNotFoundError(true);
      setIsLoading(false);
    }
  };

  const handleManual = () => {
    onChange(1, { manualInput: true }, true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => (
        <Form className="flex-col gap-3 p-5">
          {/* {!notFoundError && (
            <div className="mb-4 flex gap-1 text-gray-500">
              <InformationCircleIcon className="h-[20px] w-[20px] " />
              <div className="italic">
                Let’s start by searching if the car is already in the
                database
              </div>
            </div>
          )} */}
          {notFoundError && (
            <div className="font-nunito mb-4 flex flex-col gap-2 rounded-md bg-red-100 p-4 text-red-700">
              <span className="flex items-center gap-2 font-semibold">
                <ExclamationTriangleIcon className="h-4 w-4 text-red-700" />
                Car not found
              </span>
              <div className="text-red-700">
                Please rewrite your registration plate number or add the car
                manually.
              </div>
            </div>
          )}
          <div className="mb-4 flex">
            <Input
              type="text"
              label={t('general.registrationPlate')+'*'}
              name="registrationNo"
              settings={{
                id: 'plate',
                placeholder: 'Enter registration plate',
              }}
            />
          </div>
          {notFoundError && (
            <Button
              kind="outline"
              type="button"
              icon={<PlusIcon />}
              onClick={handleManual}
            >
              Add manually
            </Button>
          )}
          <div className="mt-5 flex w-full gap-4">
            <Button
              kind="primary"
              type="submit"
              loading={isSubmitting}
              disabled={
                props.errors.registrationNo ||
                Object.keys(props.values).length === 0
                  ? true
                  : false
              }
            >
              Continue{' '}
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateNewSelectCar;
