import { Button, LoadingSpinner } from '@rabbit/elements/shared-components';
import {
  FileStorageContext,
  ReactCaseFlowCase,
  useCaseFlowCase,
  useConsumerHoldingEditor,
} from '@rabbit/bizproc/react';
import { useContext } from 'react';
import { SageFileUploader } from '../../upload-wrapper/SageFileUploader';
import {
  CompletedUploadShape,
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { t } from 'i18next';

export interface CreateNewClaimUploadProps {
  holding_id: string;
  persona_id: string;
  case_id: string;
  handleClose: () => void;
}

export function CreateNewClaimUpload(props: CreateNewClaimUploadProps) {
  const { holding_id, persona_id, handleClose, case_id } = props;
  const { body } = useConsumerHoldingEditor(persona_id, holding_id);
  const { moveCompletedUploadsToAttached } =
    useContext(FileStorageContext) || {};

  const { caseFlowCase } =
    useCaseFlowCase({
      type: 'cfc' as const,
      case: case_id,
      operating_persona: persona_id,
      principal_persona: persona_id,
      isNewCase: false,
    }) || {};

  const alterCaseFacts = async (facts: { [key: string]: any }) => {
    await caseFlowCase?.Alter_Facts(facts);
    await caseFlowCase?.Commit();
  };

  if (!caseFlowCase) return <LoadingSpinner size="xs" />;

  const onProofOfPurchaseUpdated = async (uploads: CompletedUploadShape[]) => {
    const uploadedDocs = uploads.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerProofPurchase &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    if (uploadedDocs.length > 0) {
      try {
        const parsedFiles = JSON.parse(JSON.stringify(uploadedDocs));
        const files = [
          ...(body.private?.receipt ?? []),
          ...(parsedFiles ?? []),
        ];
        caseFlowCase?.Alter_Facts({
          consumer_proof_of_purchase: files?.length ? files : [],
        });
        await caseFlowCase?.Commit();
        moveCompletedUploadsToAttached?.(uploads);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onClaimEvidenceUpdated = async (uploads: CompletedUploadShape[]) => {
    const uploadedDocs = uploads.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerCaseEvidence &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );
    //console.log('onClaimEvidenceUpdated', uploadedDocs)
    if (uploadedDocs.length > 0) {
      try {
        const parsedFiles = JSON.parse(JSON.stringify(uploadedDocs));
        const files = [...(parsedFiles ?? [])];
        caseFlowCase?.Alter_Facts({
          consumer_claim_evidence: files?.length ? files : [],
        });
        await caseFlowCase?.Commit();
        moveCompletedUploadsToAttached?.(uploads);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="px-5">
      {caseFlowCase && (
        <>
          <SageFileUploader
            label={t('Proof of purchase')}
            identifiers={{
              category: UploadedFileCategories.ConsumerProofPurchase,
              docType: { docid: holding_id, type: DocTypeShapeTypes.Case },
              personaId: persona_id ?? '',
            }}
            alterCaseFacts={alterCaseFacts}
            accepts={['image/*', '.pdf']}
            onUploadCompleted={onProofOfPurchaseUpdated}
          />
          <SageFileUploader
            label={t('Supporting photos')}
            identifiers={{
              category: UploadedFileCategories.ConsumerCaseEvidence,
              docType: { docid: holding_id, type: DocTypeShapeTypes.Case },
              personaId: persona_id ?? '',
            }}
            alterCaseFacts={alterCaseFacts}
            accepts={['image/*', 'video/*', '.pdf']}
            onUploadCompleted={onClaimEvidenceUpdated}
          />
          <Button
            className="mt-5 w-full"
            kind="outline"
            onClick={() => {
              handleClose();
            }}
            children={'I’ll add it later'}
          />
        </>
      )}
    </div>
  );
}

export default CreateNewClaimUpload;
