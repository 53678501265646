import { FBDocTypeEssentials } from '@rabbit/firebase/doctype';

export const NEWDOC_PREFIX = '___NEWDOC___';

let createCounter = 1;

export function MakeNewDocumentID() {
  return NEWDOC_PREFIX + new Date().getTime() + '_' + createCounter++;
}

export function isNewDocument(key: string) {
  return key.indexOf(NEWDOC_PREFIX) === 0;
}

const PreviouslyAllocatedNewkeys: { [key: string]: string } = {};

export function NewkeyAllocator(docid: string, type: FBDocTypeEssentials<any>) {
  if (isNewDocument(docid)) {
    if (!PreviouslyAllocatedNewkeys[docid]) {
      PreviouslyAllocatedNewkeys[docid] = type.keygen({}, type);
    }
    return PreviouslyAllocatedNewkeys[docid];
  }
  return docid;
}
