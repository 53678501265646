import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import {
  PersonaTypeSingleLetter,
  Z_DTIdentity,
  Z_DTIdentity_Private,
  Z_Permissions,
} from '@rabbit/data/types';

/** New user declaration */
export const BC_NewUser = z.object({
  // uid: z.string(), // inferred from auth
  displayName: z.string().nullable(),
  email: z.string().nullable(),
  photoURL: z.string().nullable(),
});

/** Any persona document, provided with a type */
const BC_AnyPersona = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PersonaTypeSingleLetter.Manufacturer),
    // TODO: One day...
    public: z.any(), // Z_DTManufacturer_Public,
    private: z.any(), // Z_DTManufacturer_Private,
  }),
  z.object({
    type: z.literal(PersonaTypeSingleLetter.Repairer),
    // TODO: One day...
    public: z.any(), // Z_DTRepairer_Public,
    private: z.any(), // Z_DTRepairer_Private,
  }),
  z.object({
    type: z.literal(PersonaTypeSingleLetter.Retailer),
    // TODO: One day...
    public: z.any(), // Z_DTRetailer_Public,
    private: z.any(), // Z_DTRetailer_Private,
  }),
  z.object({
    type: z.literal(PersonaTypeSingleLetter.Consumer),
    private: z.any(), // Z_DTConsumer_Private
    public: z.any(), // Z_DTConsumer_Public
  }),
]);

/** Identity Creation Cloud Function */

const ICF_RegisterIdentity = z.object({
  user: BC_NewUser,
});

const OCF_RegisterIdentity = z.object({
  private: z.any(),
  public: z.any(),
  // private: Z_DTIdentity_Private,
  // public: Z_DTIdentity,
});

export const CFC_RegisterIdentity = MakeCloudFunctionSpec(
  'api/legacy/cfcregisteridentity',
  ICF_RegisterIdentity,
  OCF_RegisterIdentity
);

/** Persona registration */

const ICF_RegisterMyPersona = z.object({
  persona: BC_AnyPersona,
  parent: z.nullable(z.string()), // The parent of the persona. This is the identity docid.
});

const OCF_RegisterMyPersona = z.object({
  personaId: z.string(),
});

export const CFC_RegisterMyPersona = MakeCloudFunctionSpec(
  'api/legacy/cfcregistermypersona',
  ICF_RegisterMyPersona,
  OCF_RegisterMyPersona
);

/** Persona permissions */

const ICF_SetPersonaPermissions = z.object({
  tenant: z.string(),
  personaId: z.string(),
  permissions: z.array(Z_Permissions), // empty array means remove all permissions
});

const OCF_SetPersonaPermissions = z.object({
  ok: z.boolean(),
});

export const CFC_SetPermissionsToPersona = MakeCloudFunctionSpec(
  'api/legacy/cfcsetpersonapermissions',
  ICF_SetPersonaPermissions,
  OCF_SetPersonaPermissions
);
