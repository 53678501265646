import {
  CompletedUploadShape,
  UploadQueueStateShape,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { CaseFlow_Utils_AlterCaseFacts } from './basic';
import { ReactCaseFlowCase } from '../react-case-flow-case';
import { UserUploadedDocument } from '@rabbit/data/types';
import {
  CFCF_FileUpdateEvent,
  CFCF_FileUpdateEventAction,
  CFCF_FileUpdateEventCategory,
} from '@rabbit/bizproc/core';

interface FileEventFactsToAlterShape {
  consumer_proof_of_purchase?: UserUploadedDocument[];
  consumer_claim_evidence?: UserUploadedDocument[];
  item_shipping_label?: UserUploadedDocument[];
  file_update_event: CFCF_FileUpdateEvent[];
  serial_number_proof?: UserUploadedDocument[];
}

export async function CaseFlow_Utils_RegisterFileUpload(
  caseFlowCase: ReactCaseFlowCase,
  fileStorageHandlers: {
    uploadQueueState: UploadQueueStateShape;
    moveCompletedUploadsToAttached: (completed: CompletedUploadShape[]) => void;
    setShouldRefetch: (value: boolean) => void;
    updateHoldingWithFiles?: (
      holdingId: string,
      CUFiles: UserUploadedDocument[],
      category: UploadedFileCategories
    ) => Promise<void>;
  }
) {
  const caseFacts = caseFlowCase?.GetAllFacts();
  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    setShouldRefetch,
    updateHoldingWithFiles,
  } = fileStorageHandlers;
  const { ongoing, completed } = uploadQueueState;

  if (!ongoing || !completed) return;

  // Wait until all uploads are complete before we do anything
  // We could have multiple different file categories in completed at once, so we use ifs rather than a switch here
  // todo: find way to simplify this?
  if (ongoing?.length === 0 && completed?.length !== 0) {
    const factsToAlter: FileEventFactsToAlterShape = {
      file_update_event: [],
    };
    /* ---------------------------- Proof of purchase --------------------------- */
    const uploadedPoPs = completed.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerProofPurchase &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    if (
      uploadedPoPs.length > 0 &&
      updateHoldingWithFiles &&
      caseFacts.consumer_holding
    ) {
      const parsedFiles = JSON.parse(JSON.stringify(uploadedPoPs));
      const files = [
        ...(caseFacts?.consumer_proof_of_purchase ?? []),
        ...(parsedFiles ?? []),
      ];
      const fileUpdateEvents = uploadedPoPs.map((file) => ({
        file,
        action: CFCF_FileUpdateEventAction.UPLOAD,
        category: CFCF_FileUpdateEventCategory.PROOF_OF_PURCHASE,
      }));

      factsToAlter.file_update_event = [
        ...factsToAlter.file_update_event,
        ...fileUpdateEvents,
      ];
      factsToAlter.consumer_proof_of_purchase = files?.length ? files : [];
      await updateHoldingWithFiles(
        caseFacts.consumer_holding,
        uploadedPoPs,
        UploadedFileCategories.ConsumerProofPurchase
      );
    }

    /* --------------------------- Serial number proof -------------------------- */
    const uploadedSerialProofs = completed.flatMap((file) =>
      file.category === UploadedFileCategories.SerialNumberProof &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    if (
      uploadedSerialProofs.length > 0 &&
      updateHoldingWithFiles &&
      caseFacts.consumer_holding
    ) {
      const parsedFiles = JSON.parse(JSON.stringify(uploadedSerialProofs));
      const files = [
        ...(caseFacts?.serial_number_proof ?? []),
        ...(parsedFiles ?? []),
      ];
      const fileUpdateEvents = uploadedSerialProofs.map((file) => ({
        file,
        action: CFCF_FileUpdateEventAction.UPLOAD,
        category: CFCF_FileUpdateEventCategory.SERIAL_NUMBER_PROOF,
      }));

      factsToAlter.file_update_event = [
        ...factsToAlter.file_update_event,
        ...fileUpdateEvents,
      ];
      factsToAlter.serial_number_proof = files?.length ? files : [];
      await updateHoldingWithFiles(
        caseFacts.consumer_holding,
        uploadedSerialProofs,
        UploadedFileCategories.SerialNumberProof
      );
    }

    /* --------------------------- Supporting evidence -------------------------- */

    const uploadedSupportingEvidence = completed.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerCaseEvidence &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );
    if (uploadedSupportingEvidence.length > 0) {
      const parsedFiles = JSON.parse(
        JSON.stringify(uploadedSupportingEvidence)
      );
      const files = [
        ...(caseFacts?.consumer_claim_evidence ?? []),
        ...(parsedFiles ?? []),
      ];
      const fileUpdateEvents = uploadedSupportingEvidence.map((file) => ({
        file,
        action: CFCF_FileUpdateEventAction.UPLOAD,
        category: CFCF_FileUpdateEventCategory.CLAIM_EVIDENCE,
      }));
      factsToAlter.file_update_event = [
        ...factsToAlter.file_update_event,
        ...fileUpdateEvents,
      ];
      factsToAlter.consumer_claim_evidence = files?.length ? files : [];
    }

    /* --------------------------- Item Shipping Label -------------------------- */

    const uploadedShippingLabel = completed.flatMap((file) =>
      file.category === UploadedFileCategories.ShippingLabel &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    if (uploadedShippingLabel.length > 0) {
      const parsedFiles = JSON.parse(JSON.stringify(uploadedShippingLabel));
      const files = [
        ...(caseFacts?.item_shipping_label ?? []),
        ...(parsedFiles ?? []),
      ];
      const fileUpdateEvents = uploadedShippingLabel.map((file) => ({
        file,
        action: CFCF_FileUpdateEventAction.UPLOAD,
        category: CFCF_FileUpdateEventCategory.SHIPPING_LABEL,
      }));
      factsToAlter.file_update_event = [
        ...factsToAlter.file_update_event,
        ...fileUpdateEvents,
      ];
      factsToAlter.item_shipping_label = files?.length ? files : [];
    }

    /* ------------------------------- Wrap it up ------------------------------- */
    if (Object.keys(factsToAlter).length > 0) {
      try {
        // update the case facts
        await CaseFlow_Utils_AlterCaseFacts(caseFlowCase, factsToAlter);

        // move uploads to attached so they're not removed on unmount
        moveCompletedUploadsToAttached?.(completed);
      } catch (error) {
        console.log(error);
      } finally {
        setShouldRefetch?.(true);
      }
    }
  }
}

export async function CaseFlow_Utils_RegisterFileDeletion(
  category: UploadedFileCategories,
  urlOrPath: string,
  alterCaseFacts: (facts: { [key: string]: any }) => Promise<void>,
  currentFiles: UserUploadedDocument[],
  holdingId?: string,
  removeFileFromHolding?: (
    holdingId: string,
    category: UploadedFileCategories,
    urlOrPath: string
  ) => Promise<void>
) {
  const fileToRemove = currentFiles.find(
    (item) => item.metadata.fullPath === urlOrPath
  );

  const updatedFilesArr = currentFiles.filter(
    (item) => item.metadata.fullPath !== urlOrPath
  );

  if (!fileToRemove)
    throw new Error('Could not find file to delete in current files!');

  switch (category) {
    case UploadedFileCategories.ConsumerProofPurchase: {
      if (holdingId && removeFileFromHolding)
        await removeFileFromHolding(
          holdingId,
          UploadedFileCategories.ConsumerProofPurchase,
          urlOrPath
        );
      if (alterCaseFacts) {
        await alterCaseFacts({
          consumer_proof_of_purchase: updatedFilesArr,
          file_update_event: [
            {
              file: fileToRemove,
              action: CFCF_FileUpdateEventAction.DELETION,
              category: CFCF_FileUpdateEventCategory.PROOF_OF_PURCHASE,
            },
          ],
        });
      }
      break;
    }
    case UploadedFileCategories.SerialNumberProof: {
      if (holdingId && removeFileFromHolding)
        await removeFileFromHolding(
          holdingId,
          UploadedFileCategories.SerialNumberProof,
          urlOrPath
        );
      if (alterCaseFacts) {
        await alterCaseFacts({
          serial_number_proof: updatedFilesArr,
          file_update_event: [
            {
              file: fileToRemove,
              action: CFCF_FileUpdateEventAction.DELETION,
              category: CFCF_FileUpdateEventCategory.SERIAL_NUMBER_PROOF,
            },
          ],
        });
      }
      break;
    }
    //todo double check if really just null here
    case UploadedFileCategories.ShippingLabel: {
      if (alterCaseFacts) {
        await alterCaseFacts({
          item_shipping_label: updatedFilesArr,
          file_update_event: [
            {
              file: fileToRemove,
              action: CFCF_FileUpdateEventAction.DELETION,
              category: CFCF_FileUpdateEventCategory.SHIPPING_LABEL,
            },
          ],
        });
      }
      break;
    }
    case UploadedFileCategories.ConsumerCaseEvidence: {
      if (alterCaseFacts) {
        await alterCaseFacts({
          consumer_claim_evidence: updatedFilesArr,
          file_update_event: [
            {
              file: fileToRemove,
              action: CFCF_FileUpdateEventAction.DELETION,
              category: CFCF_FileUpdateEventCategory.CLAIM_EVIDENCE,
            },
          ],
        });
      }
      break;
    }
    default:
      break;
  }
}
