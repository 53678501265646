import { z, schema } from '@rabbit/utils/ts';
import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
} from '@rabbit/firebase/doctype';
import { Address, Z_Address } from '../base/basic';
import {
  DTPersona,
  DTPersonaPublic,
  FBD_Keygen_Persona,
  Z_DTPersona,
  Z_DTPersonaPublic,
} from './persona';
import { TenantLink, Z_TenantLink } from '../lib/tenant';

export interface DTRepairer_Private extends DTPersona {
  /** Cases being followed ("starred") by the repairer, stored in case proxy docid format ([caseId]_[repairerId]) */
  casesFollowed?: string[];
}

export const Z_DTRepairer_Private = schema<DTRepairer_Private>()(
  z
    .object({
      casesFollowed: z.array(z.string()).optional(),
    })
    .merge(Z_DTPersona)
);
export type RepairerLink = string;

export const FBD_Repairer_Private = MakeFBDocType<DTRepairer_Private>({
  name: 'Repairer',
  collection: 'repairer_private',
  empty: () => {
    const result: DTRepairer_Private = {
      ...Empty_NoSqlDoc(),
      delegates: {},
      identity: '',
      createTime: new Date().getTime(),
    };
    return result;
  },
  keygen: FBD_Keygen_Persona('R'),
});

export interface DTRepairer_Public extends DTPersonaPublic {
  /** Repairer name, used in search */
  name: string;

  /**
   * @deprecated The property should not be used, use array of addresses instead
   */
  address?: Address;

  /** Location of the repairer */
  addresses?: Address[];

  /** Phone number */
  phone?: string;

  /** Email address */
  email?: string;

  /** Url to Photo */
  photoUrl?: string;

  /** Premium tenants this repairer is a partner (external user) to */
  isPartnerTo?: TenantLink[];

  /** Tenant this repairer is an employee of. Could be a premium tenant or not
   *  Eg: This persona could belong to John McWarranties, who is an employee of Pinnacle Warranties
   *  Or it could belong to John Fixcar-raldo, who is an employee of Werner Herzog's Car Garage, who is a partner to Pinnacle Warranties
   *  Root personas should probably be listed as employees of their own tenant.
   */
  isEmployeeOf?: TenantLink;
}

export const Z_DTRepairer_Public = schema<DTRepairer_Public>()(
  z
    .object({
      name: z.string(),
      address: Z_Address.optional(),
      phone: z.string().optional(),
      email: z.string().optional(),
      photoUrl: z.string().optional(),
      isPartnerTo: z.array(Z_TenantLink).optional(),
      isEmployeeOf: Z_TenantLink.optional(),
    })
    .merge(Z_DTPersonaPublic)
);

export const FBD_Repairer_Public = MakeFBDocType<DTRepairer_Public>({
  name: 'Repairer_Public',
  collection: 'repairer_public',
  empty: () => {
    const result: DTRepairer_Public = {
      ...Empty_NoSqlDoc(),
      name: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});
