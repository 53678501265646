import {
  DTIdentity_Private,
  FBD_Consumer_Private,
  FBD_Consumer_Public,
  FBD_Identity,
  FBD_Identity_Private,
  FBD_Manufacturer_Private,
  FBD_Manufacturer_Public,
  FBD_Repairer_Private,
  FBD_Repairer_Public,
  FBD_Retailer_Private,
  FBD_Retailer_Public,
  Permissions,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import {
  CFC_SetPermissionsToPersona,
  CFC_RegisterIdentity,
  CFC_RegisterMyPersona,
  AuthLike,
} from '@rabbit/bizproc/core';
import { getTenantIdFromLexicon } from '../tenant/api';

/** Makes sure that the identity exists in Firebase for a given Auth-like object (firebase auth or mock thereof) */
export async function Identity_EnsureIdentityExistsForAuthLikeObject(
  authDetails: AuthLike
) {
  const uid = authDetails.uid;

  const id_private = await FBD_Identity_Private.get(uid);
  const id_public = await FBD_Identity.get(uid);

  if (!id_private || !id_public) {
    console.log('Creating new identity for user', uid);
    const result = await CFC_RegisterIdentity.call({ user: authDetails });
    if (!result.ok) throw new Error(result.error);

    return {
      identity: result.data.public,
      identity_private: result.data.private,
    };
  }

  return { identity: id_public, identity_private: id_private };
}

/** Given an identity, create the consumer persona and write it to Firebase.
 * Once written, you can modify it further to fill in optional details.
 */
export async function Identity_CreateConsumerPersonaForIdentity(
  identity: DTIdentity_Private
) {
  if (identity.persona.consumer) return identity.persona.consumer; // Already have one

  // Create private consumer object
  const consumer_private = FBD_Consumer_Private.empty();
  consumer_private.fullname = identity.displayname;
  consumer_private.email = identity.email;
  consumer_private.photoUrl = identity.photoUrl;
  consumer_private.identity = identity.docid;

  const consumer_public = FBD_Consumer_Public.empty();
  consumer_public.fullname = identity.displayname;
  consumer_public.photoUrl = identity.photoUrl;

  // Tell server to do it
  const result = await CFC_RegisterMyPersona.call({
    persona: {
      type: PersonaTypeSingleLetter.Consumer,
      private: consumer_private,
      public: consumer_public,
    },
    parent: null,
  });
  if (!result.ok) throw new Error(result.error);
  // Update it in this object in case anything else accesses it afterwards
  identity.persona.consumer = result.data.personaId;

  return identity.persona.consumer;
}

/** Given an identity, create the repairer persona and write it to Firebase.
 * Once written, you can modify it further to fill in optional details.
 *
 * Repairers are initially marked as Unauthorised, and must be authorised by an admin before they can
 * appear in searches or accept repairs.
 *
 * Authorisation can be carried out by iWarranty (independent repair shop sign-up process) or by a parent Repairer
 * who will take the new repairer under their wing (eg they work at the given repairer).
 */
export async function Identity_CreateRepairerPersonaForIdentity(
  identity: DTIdentity_Private,
  parent: string | null = null
) {
  if (identity.persona.repairer) return identity.persona.repairer; // Already have one

  // Create private repairer object
  const repairer_private = FBD_Repairer_Private.empty();
  repairer_private.identity = identity.docid;

  // Create public repairer object
  const repairer_public = FBD_Repairer_Public.empty();

  // Tell server to do it
  const result = await CFC_RegisterMyPersona.call({
    persona: {
      type: PersonaTypeSingleLetter.Repairer,
      private: repairer_private,
      public: repairer_public,
    },
    parent,
  });
  if (!result.ok) throw new Error(result.error);
  // Update it in this object in case anything else accesses it afterwards
  identity.persona.repairer = result.data.personaId;
  return result.data.personaId;
}

/** Given an identity, create the retailer persona and write it to Firebase.
 * Once written, you can modify it further to fill in optional details.
 *
 */
export async function Identity_CreateRetailerPersonaForIdentity(
  identity: DTIdentity_Private,
  parent: string | null = null
) {
  if (identity.persona.retailer) return identity.persona.retailer; // Already have one

  // Create private repairer object
  const retailer_private = FBD_Retailer_Private.empty();
  retailer_private.identity = identity.docid;

  // Create public repairer object
  const retailer_public = FBD_Retailer_Public.empty();

  // Tell server to do it
  const result = await CFC_RegisterMyPersona.call({
    persona: {
      type: PersonaTypeSingleLetter.Retailer,
      private: retailer_private,
      public: retailer_public,
    },
    parent,
  });
  if (!result.ok) throw new Error(result.error);
  // Update it in this object in case anything else accesses it afterwards
  identity.persona.retailer = result.data.personaId;
  return result.data.personaId;
}

/**
 * Given an identity, and Sage sign up form data, create the repairer persona and write it to Firebase.
 * A variation of Identity_CreateRepairerPersonaForIdentity for use in Sage, which might be deprecated in the future.
 */
export async function Sage_Identity_CreatePersonasForIdentity(
  identityId: string,
  formData: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    rememberMe: boolean;
  }
) {
  // BNC: I refactored to use cloud function, but not sure
  try {
    const identity_private = await FBD_Identity_Private.get(identityId);
    if (!identity_private) throw new Error('Failed to get identity');

    // Create private repairer object
    const repairer_private = FBD_Repairer_Private.empty();

    // Create public repairer object
    const repairer_public = FBD_Repairer_Public.empty();
    repairer_public.name = `${formData.first_name ?? ''}${
      formData.first_name && formData.last_name ? ' ' : ''
    }${formData.last_name ?? ''}`;
    repairer_public.docid = repairer_private.docid;
    repairer_public.email = formData.email;

    const parent = await getTenantIdFromLexicon();

    const resultRepairer = await CFC_RegisterMyPersona.call({
      persona: {
        type: PersonaTypeSingleLetter.Repairer,
        private: repairer_private,
        public: repairer_public,
      },
      parent,
    });
    if (!resultRepairer.ok) throw new Error(resultRepairer.error);

    // Create private retailer object
    const retailer_private = FBD_Retailer_Private.empty();

    // Create public retailer object
    const retailer_public = FBD_Retailer_Public.empty();
    retailer_public.name = `${formData.first_name ?? ''}${
      formData.first_name && formData.last_name ? ' ' : ''
    }${formData.last_name ?? ''}`;
    retailer_public.docid = retailer_private.docid;
    retailer_public.email = formData.email;

    const resultRetailer = await CFC_RegisterMyPersona.call({
      persona: {
        type: PersonaTypeSingleLetter.Retailer,
        private: retailer_private,
        public: retailer_public,
      },
      parent,
    });
    if (!resultRetailer.ok) throw new Error(resultRetailer.error);
    // Update doc ids and identity connection in local object since these are going to be returned and used, since we don't get document from server
    identity_private.persona.repairer = resultRepairer.data.personaId;
    identity_private.persona.retailer = resultRetailer.data.personaId;
    repairer_private.docid = resultRepairer.data.personaId;
    repairer_public.docid = resultRepairer.data.personaId;
    retailer_private.docid = resultRetailer.data.personaId;
    retailer_public.docid = resultRetailer.data.personaId;
    return {
      identity_private,
      repairer_private,
      repairer_public,
      retailer_private,
      retailer_public,
    };
  } catch (err) {
    console.log(err);

    throw err; // BNC: Compiler complained that not all code paths return a value
  }
}

/** Given an identity, create the manufacturer persona and write it to Firebase.
 * Once written, you can modify it further to fill in optional details.
 *
 * Manufacturers are initially marked as Unauthorised, and must be authorised by an admin before they can
 * carry out operations.
 *
 * Authorisation can be carried out by iWarranty.
 */
export async function Identity_CreateManufacturerPersonaForIdentity(
  identity: DTIdentity_Private
) {
  if (identity.persona.manufacturer) return identity.persona.manufacturer; // Already have one

  // Create private manufacturer object
  const manufacturer_private = FBD_Manufacturer_Private.empty();
  manufacturer_private.identity = identity.docid;

  // Create public manufacturer object
  const manufacturer_public = FBD_Manufacturer_Public.empty();

  // Tell server to do it
  const result = await CFC_RegisterMyPersona.call({
    persona: {
      type: PersonaTypeSingleLetter.Manufacturer,
      private: manufacturer_private,
      public: manufacturer_public,
    },
    parent: null,
  });
  if (!result.ok) throw new Error(result.error);
  // Update it in this object in case anything else accesses it afterwards
  identity.persona.manufacturer = result.data.personaId;
  return result.data.personaId;
}

export async function Persona_SetPermissions(
  personaId: string,
  permissions: Permissions[]
) {
  const tenant = await getTenantIdFromLexicon();
  try {
    // Tell server to do it
    const result = await CFC_SetPermissionsToPersona.call({
      tenant,
      personaId: personaId,
      permissions: permissions,
    });
    console.log('Persona_SetPermissions result.ok = ', result.ok);
    if (!result.ok) {
      console.error('CFC_SetPermissionsToPersona failed!');
      throw new Error(result.error);
    }
    return result;
  } catch (err) {
    console.log(err);
    throw err;
  }
}
