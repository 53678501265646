import { useEffect, useState } from 'react';
import {
  CaseFlowCaseEditingID,
  ReactCaseFlowCase,
} from './react-case-flow-case';
import {
  GetCaseFlowCaseFromEditingID,
  ReactCaseFlow_OpenAKnownCaseButReturnSynchronously,
} from './create-case';

/** Provides an interface to access a CaseFlowCase object from React. You can use the CaseFlowCase to get information about the current case,
 * and to update the case. All the operations for CaseFlowCase are defined in bizproc/client.
 *
 * This react extension of CaseFlow is just tasked with providing access to the CaseFlowCase object and dealing with keeping the data subscribed
 * and up to date, and making sure renders are triggered at the right time. There is no business logic in the react extension.
 *
 * A case is referenced using an ID. The ID will link to a single CaseFlowCase object, so if you have two instances of useCaseFlowCase and reference the same ID, they will both reference the same CaseFlowCase object.
 *
 * Implementation notes:
 * This is so close to usePortalMultipleDocumentEditor in how it operates that I considered trying to just merge it all together.
 */

export function useCaseFlowCase(caseID: CaseFlowCaseEditingID) {
  const [updateTime, setUpdateTime] = useState(new Date().getTime());
  const [caseFlowCase, setCaseFlowCase] = useState<ReactCaseFlowCase | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const caseActorContext = caseFlowCase?.GetActorContext();

  useEffect(() => {
    // Get our case flow case object
    if (!caseID.case) return;
    let cfc = GetCaseFlowCaseFromEditingID(caseID);
    if (!cfc) {
      // We might have to open the case here. We will not allow this for "new case".
      if (caseID.isNewCase) {
        throw new Error(
          'useCaseFlowCase cannot create a new case for editing. You should create, configure and register it for editing before you call useCaseFlowCase. (BNC)'
        );
      }

      cfc = ReactCaseFlow_OpenAKnownCaseButReturnSynchronously(caseID);
    }
    setCaseFlowCase(cfc);

    const unsubscribe = cfc.Subscribe(() => {
      console.log('SOMETHING HAS CHANGED', cfc);
      setUpdateTime(new Date().getTime());
    });

    return () => {
      unsubscribe();
    };
  }, [caseID]);

  useEffect(() => {
    if (isLoading && caseActorContext) {
      setIsLoading(false);
    }
  }, [caseFlowCase, caseActorContext]);

  return { caseFlowCase, updateTime, isLoading };
}
