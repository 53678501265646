import SkeletonInput from '../../../atoms/loaders/SkeletonInput/SkeletonInput';

export interface SkeletonInputGridProps {
  cols?: number;
  rows?: number;
  wrapperClassName?: string;
}
export function SkeletonInputGrid({
  cols = 2,
  rows = 2,
  wrapperClassName,
}: SkeletonInputGridProps) {
  return (
    <div className={wrapperClassName ?? ''}>
      <div
        className={`mb-10 grid gap-2`}
        style={{ gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))` }}
      >
        {Array.from(Array(rows * cols)).map(() => (
          <SkeletonInput />
        ))}
      </div>
    </div>
  );
}
