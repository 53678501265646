import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';

import { FBD_Identity_Private } from '@rabbit/data/types';
import { firebaseFunctions } from '@rabbit/firebase/adapter-react';
import { httpsCallable } from '@firebase/functions';

export const BuildInformation = {
  buildTime: import.meta.env.VITE_BUILD_TIME || 'undefined',
  firebaseMode: import.meta.env.VITE_FIREBASE_MODE || 'undefined',
  amplitude: import.meta.env.VITE_AMPLITUDE_API_KEY || 'undefined',
  gitLog: (import.meta.env.VITE_GIT_ACTIVITY as string) || 'No git log',
  viteMode: import.meta.env.MODE || 'undefined',
  viteDev: import.meta.env.DEV || 'undefined',
  viteProd: import.meta.env.PROD || 'undefined',
};

// Return the build information for this build - passed into the env by our build script
function BuildInfo() {
  console.log(JSON.stringify(BuildInformation, null, 2));
}

(window as any).BuildInfo = BuildInfo;

// Crude hacking attempt to test the first firebase rules and prove our deploy works (or not)
async function AttemptHack() {
  const query = FBD_Identity_Private.query();
  const items = await query.get();
  console.log('ALL THE IDENTITIES YARR:', items);
}

(window as any).AttemptHack = AttemptHack;

// Get information from our firebase cloud function deploy so we can see its deployment info
const debugFireInfoCloudFunction = httpsCallable(
  firebaseFunctions,
  'debuggetfireinfo'
);

async function FireInfo() {
  const res = await debugFireInfoCloudFunction({});
  console.log(res);
}

(window as any).FireInfo = FireInfo;

// Attempt calling a cloud function to see if that works
const debugInfoCloudFunction = httpsCallable(firebaseFunctions, 'debuggetinfo');

async function AttemptCloudFunction() {
  const res = await debugInfoCloudFunction({});
  console.log(res);
}

(window as any).AttemptCloudFunction = AttemptCloudFunction;

/* -------------------------------------------------------------------------- */
/*                    Test cloud function with the adapter                    */
/* -------------------------------------------------------------------------- */

// Test area
const inShape = z.object({
  a: z.string(),
  b: z.number(),
});

const outShape = z.object({
  c: z.string(),
  d: z.number(),
});

const MyCF = MakeCloudFunctionSpec(
  'debugtestcloudfunctionadapter',
  inShape,
  outShape
);

(window as any).MixMasterTest = async () => {
  console.log('Running mixmaster test');
  const res = await MyCF.call({
    a: 'mixmaster test',
    b: 0,
  });
  console.log('MixMaster test result:', res);
};
