import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                                   Faults                                   */
/* -------------------------------------------------------------------------- */

/** A type of issue or defect with a given product, grouped into fault categories  */
export interface DTFault_Category extends NoSqlDoc {
  /** The name of the fault category */
  title: string;

  /** A description of the fault category */
  description?: string;

  /** An array of fault ids */
  faults: string[];
}

export const FBD_Fault_Category = MakeFBDocType<DTFault_Category>({
  name: 'Fault Category',
  collection: 'fault_category',
  empty: () => {
    const result: DTFault_Category = {
      ...Empty_NoSqlDoc(),
      title: '',
      faults: [],
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});
