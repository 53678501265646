import { z } from 'zod';

// These types only relate to decider data stored in the final warranty (output).
// The rest of the decider data is stringified when stored in firebase - those types have moved to the decider project.

// TODO: WE HAVE SOME DUPES BETWEEN HERE AND libs/bizproc/core/src/decider/types.ts NEED TO SORT

export type DTOptionsSingleOption = {
  label: string;
  value: number | string; // Duration values can be ISO 8601 strings - DC
};

export const Z_DTOptionsSingleOption = z
  .object({
    label: z.string(),
    value: z.union([z.string(), z.number()]),
  })
  .strict();

export type DT_AnyValue = number | string | boolean | DTOptionsSingleOption;
export type DT_AnyValueOrUnknown = DT_AnyValue | null;

export type DeciderStipulated = { [key: string]: DT_AnyValueOrUnknown };
export type DeciderOutputDecided = { [key: string]: DT_AnyValue };

export type DeciderOutput = {
  decided: DeciderOutputDecided;
  stipulated: DeciderStipulated;
};

export const Z_DeciderOutput = z.object({
  decided: z.record(z.union([z.string(), z.number(), z.boolean()])),
  stipulated: z.record(
    z.union([z.string(), z.number(), z.boolean(), z.null()])
  ),
});

// Decider data in documents is stored as strings since multidimensional arrays are too mindblowing for firebase

export type DeciderScheduleStringified = string;
export type DeciderWarrantyOffer_Stringified = string;

export const Z_DeciderWarrantyOffer = z.string();

/* -------------------------------------------------------------------------- */
interface DTypingBase<TYPENAME extends string> {
  type: TYPENAME;
}

const Z_DTypingBase = z
  .object({
    type: z.string(),
  })
  .strict();

/* -------------------------------------------------------------------------- */
export interface DTNumber extends DTypingBase<'number'> {
  min?: number;
  max?: number;
}

export const Z_DTNumber = Z_DTypingBase.extend({
  type: z.literal('number'),
  min: z.number().optional(),
  max: z.number().optional(),
}).strict();

/* -------------------------------------------------------------------------- */
export interface DTCurrency extends DTypingBase<'currency'> {
  min?: number;
  max?: number;
  currency: string;
}

const Z_DTCurrency = Z_DTypingBase.extend({
  type: z.literal('currency'),
  min: z.number().optional(),
  max: z.number().optional(),
  currency: z.string(),
}).strict();

/* -------------------------------------------------------------------------- */

export interface DTOptions extends DTypingBase<'options'> {
  options: DTOptionsSingleOption[];
}

const Z_DTOptions = Z_DTypingBase.extend({
  type: z.literal('options'),
  options: z.array(Z_DTOptionsSingleOption),
});

/* -------------------------------------------------------------------------- */
export type DTBoolean = DTypingBase<'boolean'>;

const Z_DTBoolean = Z_DTypingBase.extend({
  type: z.literal('boolean'),
});

/* -------------------------------------------------------------------------- */
export type DeciderType = DTNumber | DTCurrency | DTOptions | DTBoolean;

function MakeZodUnionForDeciderTypesMergedWithObject(
  object: z.ZodObject<any, any>
) {
  // return Z_DTNumber.merge(object);
  return z.union([
    Z_DTNumber.merge(object),
    Z_DTCurrency.merge(object),
    Z_DTOptions.merge(object),
    Z_DTBoolean.merge(object),
  ]);
}

/* -------------------------------------------------------------------------- */
export type DT_AnyValueMultiDimensional =
  | DT_AnyValue
  | DT_AnyValueMultiDimensional[];

/* -------------------------------------------------------------------------- */
/*             DeciderSteps - The programming language of Decider             */
/* -------------------------------------------------------------------------- */

export type DS_AndOr = {
  a: DT_AnyValue;
  b: DT_AnyValue;
  op: '>' | '<' | '==' | '!=' | '>=' | '<=';
};

export type DS_If = {
  a: DT_AnyValue;
  b: DT_AnyValue;
  and?: DS_AndOr[];
  or?: DS_AndOr[];
  op: '>' | '<' | '==' | '!=' | '>=' | '<=';
  then: DeciderStep | DeciderStep[];
};

export type DS_Set = {
  set: string;
  value:
    | number
    | string
    | boolean
    | {
        source: 'decisionOptions';
        decision: string;
        option: DTOptionsSingleOption;
      }
    | {
        source: 'lookup';
        lookup: string;
      }
    | {
        source: 'math';
        op: '*' | '+' | '-' | '/';
        a: DT_AnyValue;
        b: DT_AnyValue;
      };
};

export type DeciderStep = DS_If | DS_Set;

/* -------------------------------------------------------------------------------------- */
/*      DeciderSchedule - A schedule of what can be calculated and how to calculate it    */
/* -------------------------------------------------------------------------------------- */

export type DeciderTypeWithLayout = {
  label: string;
} & DeciderType;

export const Z_DeciderTypeWithLayout = z.object({
  label: z.string(),
});

export type DStipulation = {
  default?: DT_AnyValue;
} & DeciderTypeWithLayout;

// Note: importing this to elements/shared-types and then exporting
// zod object using it to CF spec creation results in a circular dependency
// so we might want to consider moving these types back to shared-types - DC
export const Z_DStipulation = MakeZodUnionForDeciderTypesMergedWithObject(
  z.object({
    label: z.string(),
    default: z.any().optional(),
  })
);

type DDecision = {
  default: DT_AnyValue;
} & DeciderTypeWithLayout;

export const Z_DDecision = MakeZodUnionForDeciderTypesMergedWithObject(
  z.object({
    default: z.any().optional(),
  })
);

type DLookup = {
  dimensions: string[];
  values: DT_AnyValueMultiDimensional;
} & DeciderTypeWithLayout;

export type DeciderSchedule = {
  stipulations: { [key: string]: DStipulation };
  decisions: { [key: string]: DDecision };
  lookups: { [key: string]: DLookup };
  formula: DeciderStep[];
};

export const Z_DeciderSchedule = z.object({
  stipulations: z.record(Z_DStipulation),
  decisions: z.record(Z_DDecision),
  lookups: z.record(z.object({})),
  formula: z.array(z.object({})),
});

/* -------------------------------------------------------------------------- */
/*               Inputs / Outputs / Workspace used when deciding              */
/* -------------------------------------------------------------------------- */

export type DVariableCollection = { [key: string]: DT_AnyValueOrUnknown };

export type DeciderMenuArray = DeciderOutputDecided | DeciderMenuArray[];

export type DeciderMenuResult = {
  values: DeciderMenuArray;
  dimensionDetail: DeciderDimensionDetails[];
  typing: { [key: string]: DeciderTypeWithLayout };
};

export type DeciderLookupsOverride = {
  [key: string]: { values: DT_AnyValueMultiDimensional };
};

export type DeciderInput = {
  schedule: DeciderSchedule;
  stipulated: DeciderStipulated;
  lookups?: DeciderLookupsOverride;
};

export type DeciderWorkspace = {
  input: DeciderInput;
  output: DeciderOutput;
  variables: DVariableCollection;
};

/* -------------------------------------------------------------------------- */
/*                            Lookup Editing Types                            */
/* -------------------------------------------------------------------------- */

export type DeciderDimensionDetails = {
  label: string;
  indices: DTOptionsSingleOption[];
};

export type DeciderTableValues = {
  key: string;
  dimensionDetail: DeciderDimensionDetails[];
  dimensionLengths: number[];
  values: DT_AnyValueMultiDimensional;
  typing: DeciderTypeWithLayout;
};

export const Z_DeciderTableValues = z.object({
  key: z.string(),
  dimensionDetail: z.array(
    z.object({
      label: z.string(),
      indices: z.array(Z_DTOptionsSingleOption),
    })
  ),
  dimensionLengths: z.array(z.number()),
  values: z.any(), // TODO
  typing: z.any(), // TODO: see MakeZodUnionForDeciderTypesMergedWithObject
});

export interface SingleApprovedOptionInfoPairs {
  //key: string;
  label: string;
  indices: DTOptionsSingleOption[];
}
export interface SingleApprovedOptionInfo {
  option: DTOptionsSingleOption;
  availablePairs: SingleApprovedOptionInfoPairs[];
}

export type DeciderApprovedOptionInfo = {
  label: string;
  optionInfo: SingleApprovedOptionInfo[];
};

export const Z_DeciderApprovedOptionInfo = z.object({
  label: z.string(),
  optionInfo: z.array(
    z.object({
      option: Z_DTOptionsSingleOption,
      availablePairs: z.array(
        z.object({
          label: z.string(),
          indices: z.array(Z_DTOptionsSingleOption),
        })
      ),
    })
  ),
});

/* -------------------------------------------------------------------------- */
/*                           Decider Warranty Offer                           */
/* -------------------------------------------------------------------------- */
export type DeciderWarrantyOffer = {
  lookups: {
    [key: string]: {
      values: DT_AnyValueMultiDimensional;
    };
  };
};
