import { ConfigContext } from '@rabbit/config/context';
import { DTHoldingProxy, Warranty } from '@rabbit/data/types';
import { useContext } from 'react';
import RegistrationsWarrantyDetailsSectionDefault from './RegistrationsWarrantyDetailsSectionDefault';
import { RegistrationsWarrantyDetailsSectionStoneWarranty } from './RegistrationsWarrantyDetailsSectionStoneWarranty';
import RegistrationsWarrantyDetailsSectionCarWarranty from './RegistrationsWarrantyDetailsSectionCarWarranty';
import { LoadingCard } from '@rabbit/elements/shared-components';
import { t } from 'i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { Config_RegistrationDetailsView_WarrantyDetailsSectionType } from '@rabbit/config/enums';

interface RegistrationsWarrantyDetailsSectionProps {
  holding_proxy: DTHoldingProxy;
}

export interface RegistrationsWarrantyDetailsSectionVariationProps
  extends RegistrationsWarrantyDetailsSectionProps {
  title: string;
  warrantyInfo?: Warranty;
}

export function RegistrationsWarrantyDetailsSection(
  props: RegistrationsWarrantyDetailsSectionProps
) {
  const { config } = useContext(ConfigContext);
  const warrantyInfo = BL_Warranty.getLatestWarranty(
    props.holding_proxy.warranties
  );

  function getCardTitle() {
    if (
      config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
        Config_RegistrationDetailsView_WarrantyDetailsSectionType.CAR_WARRANTY ||
      config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
        Config_RegistrationDetailsView_WarrantyDetailsSectionType.STONE_WARRANTY
    ) {
      return t('general.servicePlanDetails');
    }
    return t('general.warrantyDetails');
  }
  const cardTitle = getCardTitle();

  if (!props.holding_proxy) {
    return <LoadingCard title={getCardTitle()} />;
  }
  if (
    config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
    Config_RegistrationDetailsView_WarrantyDetailsSectionType.DEFAULT
  ) {
    return (
      <RegistrationsWarrantyDetailsSectionDefault
        {...props}
        title={cardTitle}
        warrantyInfo={warrantyInfo}
      />
    );
  }

  if (
    config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
    Config_RegistrationDetailsView_WarrantyDetailsSectionType.STONE_WARRANTY
  ) {
    return (
      <RegistrationsWarrantyDetailsSectionStoneWarranty
        {...props}
        title={cardTitle}
        warrantyInfo={warrantyInfo}
      />
    );
  }

  if (
    config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
    Config_RegistrationDetailsView_WarrantyDetailsSectionType.CAR_WARRANTY
  ) {
    return (
      <RegistrationsWarrantyDetailsSectionCarWarranty
        {...props}
        title={cardTitle}
        warrantyInfo={warrantyInfo}
      />
    );
  }
}

export default RegistrationsWarrantyDetailsSection;
