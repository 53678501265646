import classNames from 'classnames';
import { Field } from 'formik';
import { InputSettingsShape } from '@rabbit/elements/shared-types';
import { useState } from 'react';

type InputTypeTimeProps = {
  name: string;
  settings: InputSettingsShape;
};

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 rounded-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 text-red-700',
  disabled: 'border-gray-300 text-gray-400 opacity-60',
};

function StyledInput({ field, form: { touched, errors }, ...props }: any) {
  let errorField = false;
  if (errors[field.name] || props.errors) errorField = true;

  const [time, setTime] = useState('');

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };

  const handleTimeFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.type = 'time';
  };

  const handleTimeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      event.target.type = 'text';
      setTime('');
    }
  };

  return (
    <input
      type={'text'}
      id={props.id}
      placeholder="HH:MM"
      value={time}
      onChange={handleTimeChange}
      onFocus={handleTimeFocus}
      onBlur={handleTimeBlur}
      {...props}
      {...field}
    ></input>
  );
}

export function InputTypeTime({ name, settings }: InputTypeTimeProps) {
  const { disabled, id, placeholder, errors, ...rest } = settings;

  return (
    <Field
      name={name}
      component={StyledInput}
      className={classNames(dynamicStyles.base, {
        [`${dynamicStyles.error}`]: errors,
        [`${dynamicStyles.disabled}`]: disabled,
      })}
      type={'text'}
      id={id}
      {...rest}
    />
  );
}
