import { CaseFlowConfiguration } from '@rabbit/data/types';
import GenericRegistry from '@rabbit/utils/generic-registry';
import { ValidateCaseFlowConfiguration } from '../validation';

export const NoConfiguration: CaseFlowConfiguration = {
  actors: {},
  facts: {},
  stations: {
    _birth: {
      actions: {},
    },
  },
};

export async function CaseFlow_GetConfigurationForCaseType(casetype: string) {
  let config: CaseFlowConfiguration = NoConfiguration;

  try {
    config = CaseFlowConfigurationRegistry.Get(casetype);
  } catch {
    throw new Error('Unknown case type: ' + casetype);
  }
  const validation = ValidateCaseFlowConfiguration(config);
  if (validation.length !== 0) {
    console.error(
      'CaseFlow - configuration ' + casetype + ' failed validation'
    );
    console.error(validation);
    throw new Error(
      'CaseFlow - configuration ' +
        casetype +
        ' failed validation (see prints above or check dump)\n\nDUMP:' +
        JSON.stringify(validation, null, 2)
    );
  }
  return config;
}

export const CaseFlowConfigurationRegistry =
  new GenericRegistry<CaseFlowConfiguration>('caseflow_configurations');
