import {
  DTWarranty_Template,
  DTWarranty_Offer,
  DT_AnyValue,
  DT_AnyValueMultiDimensional,
  DeciderDimensionDetails,
  DeciderInput,
  DeciderTableValues,
  DeciderOutput,
  DeciderStipulated,
  DeciderMenuResult,
} from '@rabbit/data/types';

import {
  CheckValueAgainstTyping,
  ExtractApprovedOptionsFromDeciderMenuResult,
  ExtractDecisionFromDeciderMenuResult,
  GetDimensionDetailFromTyping,
  GetTypingFromPath,
} from './utility';
import { MakeMenu, PerformDecision } from './decision';

/* -------------------------------------------------------------------------- */
/*                       Calls related to LookUp Editor                       */
/* -------------------------------------------------------------------------- */

// export function DeciderListAllLookups(template: DTWarranty_Template) {}

export function DeciderValuesForLookup(
  lookupName: string,
  template: DTWarranty_Template,
  offer?: DTWarranty_Offer
): DeciderTableValues {
  if (!template.decider)
    throw new Error('Committed sudoku due to missing template decider');

  const templateDecider = JSON.parse(template.decider);
  const lookup = templateDecider.lookups[lookupName];
  if (!lookup) {
    throw new Error(`Lookup ${lookupName} not found in template`);
  }

  const offerDecider = offer?.decider ? JSON.parse(offer?.decider) : {};
  const offerLookup = offerDecider.lookups?.[lookupName];

  const valueArray = offerLookup?.values || lookup.values;

  const dimensionDetail: DeciderDimensionDetails[] = [];
  const dimensionLengths: number[] = [];

  for (const dim in lookup.dimensions) {
    // Get the stipulation/decision we are examining
    const typing = GetTypingFromPath(lookup.dimensions[dim], templateDecider);
    const dimDetail = GetDimensionDetailFromTyping(typing);
    dimensionDetail.push(dimDetail);
    dimensionLengths.push(dimDetail.indices.length);
  }

  const typing: any = { ...lookup }; // TODO: Fix typing but doesn't look easy
  delete typing.dimensions;
  delete typing.values;

  return {
    key: lookupName,
    dimensionDetail,
    values: cloneValueArray(valueArray, dimensionLengths),
    dimensionLengths,
    typing,
  };
}

export function DeciderSetLookup(
  lookupName: string,
  lookup: DeciderTableValues,
  offer: DTWarranty_Offer
) {
  // TODO: Tons of validation here
  let offerDecider = offer?.decider ? JSON.parse(offer?.decider) : {};

  if (!offerDecider) {
    offerDecider = {
      lookups: {},
    };
  }

  let offerLookup = offerDecider.lookups?.[lookupName];
  offerLookup = {
    values: cloneValueArray(lookup.values, lookup.dimensionLengths),
  };
  offer.decider = JSON.stringify({
    ...offerDecider,
    lookups: { ...offerDecider.lookups, [lookupName]: offerLookup },
  });
}

/* -------------------------------------------------------------------------- */
/*                            Lookup Value Editing                            */
/* -------------------------------------------------------------------------- */
export function DeciderValuesSetByIndex(
  lookup: DeciderTableValues,
  index: number[],
  value: DT_AnyValue
): DeciderTableValues {
  function RangeCheck(index: number, max: number, dimNo: number) {
    if (index >= max) {
      throw new Error(
        `DeciderValuesSetByIndex: Dimension [${dimNo}] index [${index}] out of range - max is ${max}`
      );
    }
    if (index < 0) {
      throw new Error(
        `DeciderValuesSetByIndex: Dimension [${dimNo}] index [${index}] out of range - starts at zero`
      );
    }
  }

  const typeCheck = CheckValueAgainstTyping(value, lookup.typing);
  if (typeCheck !== 'OK') {
    throw new Error(
      "DeciderValuesSetByIndex: Value doesn't match typing: " + typeCheck
    );
  }
  const clone = cloneDeciderTableValues(lookup);

  if (index.length !== clone.dimensionLengths.length) {
    throw new Error(
      'DeciderValuesSetByIndex: Index specified must match to all dimensions'
    );
  }

  if (clone.dimensionLengths.length === 0) {
    // We are dealing with a zero-dimension lookup
    clone.values = value;
  } else {
    // Walk down the arrays to get to the final array.
    let current = clone.values;
    for (let i = 0; i < index.length - 1; i++) {
      if (!Array.isArray(current)) {
        throw new Error('Tragedy');
      }
      RangeCheck(index[i], clone.dimensionLengths[i], i);
      current = current[index[i]];
    }

    if (!Array.isArray(current)) {
      throw new Error('Tragedy');
    }

    RangeCheck(
      index[index.length - 1],
      clone.dimensionLengths[index.length - 1],
      index.length - 1
    );
    current[index[index.length - 1]] = value;
  }

  return clone;
}

/* -------------------------------------------------------------------------- */
/*                         Calls to list stipulations                         */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                        Calls to calculate decisions                        */
/* -------------------------------------------------------------------------- */

function MakeInputFromDocuments(
  stipulated: DeciderStipulated,
  warrantyTemplate: DTWarranty_Template,
  warrantyOffer?: DTWarranty_Offer
): DeciderInput {
  const decider = warrantyTemplate.decider;
  if (!decider) {
    throw new Error('Template has no decider');
  }
  const parsedDecider = JSON.parse(decider);

  const input: DeciderInput = {
    schedule: parsedDecider,
    stipulated,
  };

  if (warrantyOffer?.decider) {
    const parsedOfferDecider = JSON.parse(warrantyOffer.decider);
    const lookups = parsedOfferDecider.lookups;
    if (lookups) {
      input.lookups = {};
      for (const lookup in lookups) {
        input.lookups[lookup] = lookups[lookup];
      }
    }
  }

  return input;
}

export function DeciderPerformDecision(
  stipulated: DeciderStipulated,
  warrantyTemplate: DTWarranty_Template,
  warrantyOffer?: DTWarranty_Offer
): DeciderOutput {
  const input = MakeInputFromDocuments(
    stipulated,
    warrantyTemplate,
    warrantyOffer
  );
  const result = PerformDecision(input);
  return result;
}

export function DeciderMakeMenu(
  stipulated: DeciderStipulated,
  dimensions: string[],
  warrantyTemplate: DTWarranty_Template,
  warrantyOffer?: DTWarranty_Offer
): DeciderMenuResult {
  const input = MakeInputFromDocuments(
    stipulated,
    warrantyTemplate,
    warrantyOffer
  );
  const result = MakeMenu(input, dimensions);
  return result;
}

// Questions:
// What options do I need to present to user?
// What grids do I need to show so to edit the lookups?
// What's the outcome of various calculations?
// How do I get a range by having something as "undecided"?

export const DeciderExtractDecisionFromDeciderMenuResult =
  ExtractDecisionFromDeciderMenuResult;

export const DeciderExtractApprovedOptionsFromMenuResult =
  ExtractApprovedOptionsFromDeciderMenuResult;
/* -------------------------------------------------------------------------- */
/*                              support functions                             */
/* -------------------------------------------------------------------------- */

function cloneValueArray(
  values: DT_AnyValueMultiDimensional,
  dimensionLengths: number[]
): DT_AnyValueMultiDimensional {
  if (!Array.isArray(values)) {
    return values; // For zero dimension array
  }

  if (dimensionLengths.length === 1) {
    return [...values];
  }

  const result = [];
  for (let i = 0; i < dimensionLengths[0]; i++) {
    result.push(cloneValueArray(values[i], dimensionLengths.slice(1)));
  }
  return result;
}

function cloneDeciderTableValues(
  values: DeciderTableValues
): DeciderTableValues {
  return {
    key: values.key,
    dimensionDetail: [...values.dimensionDetail],
    values: cloneValueArray(values.values, values.dimensionLengths),
    dimensionLengths: [...values.dimensionLengths],
    typing: values.typing,
  };
}
