import {
  CFC_CreateOrUpdateVendable,
  ICF_CreateOrUpdateVendable,
  OCF_CreateOrUpdateVendable,
} from '@rabbit/bizproc/core';
import { APICallResultBase } from '@rabbit/elements/shared-types';
import { z } from 'zod';

export async function Vendable_CreateOrUpdate({
  requestId,
  apiVersion,
  tenantLink,
  publicData,
  privateData,
  id,
  newId,
  isTest = false,
}: z.infer<typeof ICF_CreateOrUpdateVendable>): Promise<
  { data: z.infer<typeof OCF_CreateOrUpdateVendable> } & APICallResultBase
> {
  // console.log('pub', publicData, 'priv', privateData);
  const result = await CFC_CreateOrUpdateVendable.call({
    requestId,
    apiVersion,
    tenantLink,
    publicData,
    privateData,
    id,
    newId,
    isTest,
  });
  if (!result.ok) throw new Error(result.error);
  return result;
}
