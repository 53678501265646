import { useState } from 'react';
import { Modal } from '@rabbit/elements/shared-components';
import { ModalSettingsShape } from '@rabbit/elements/shared-components';
import NewClaimVehicleLicencePlate from '../forms/NewClaimVehicleLicencePlate/NewClaimVehicleLicencePlate';
import NewClaimVehicleSummary from '../forms/NewClaimVehicleSummary/NewClaimVehicleSummary';
import NewClaimDescription from '../forms/NewClaimDescription/NewClaimDescription';
import { NewClaimVehicleChooseWarranty } from '../forms/NewClaimVehicleChooseWarranty/NewClaimVehicleChooseWarranty';

export interface ModalNewClaimVehicleProps {
  handleClose: () => void;
}

function ModalNewClaimVehicle({ handleClose }: ModalNewClaimVehicleProps) {
  const [currentStep, setCurrentStep] = useState<{
    step: number;
    data: any;
  }>({ step: 0, data: null });

  const modalSettings: ModalSettingsShape = {
    title: 'New claim',
    handleClose: handleClose,
  };

  const onChangeStep = (step_in: number, data: any = null) => {
    setCurrentStep({ ...currentStep, step: step_in, data: data });
  };

  const [loading, setLoading] = useState(false);

  const renderSteps = () => {
    switch (currentStep.step) {
      case 0:
        return (
          <NewClaimVehicleLicencePlate
            handleClose={handleClose}
            onChange={onChangeStep}
            setIsLoading={setLoading}
          />
        );
      case 0.5:
        return (
          <NewClaimVehicleChooseWarranty
            handleClose={handleClose}
            onChange={onChangeStep}
            setIsLoading={setLoading}
            data={currentStep.data}
          />
        );
      case 1:
        return (
          <NewClaimVehicleSummary
            handleClose={handleClose}
            onChange={onChangeStep}
            setIsLoading={setLoading}
            data={currentStep.data}
          />
        );
      case 2:
        return (
          <NewClaimDescription
            handleClose={handleClose}
            onChange={onChangeStep}
            setIsLoading={setLoading}
            data={currentStep.data}
          />
        );
      default:
        return (
          <NewClaimVehicleLicencePlate
            handleClose={handleClose}
            onChange={onChangeStep}
            setIsLoading={setLoading}
          />
        );
    }
  };

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      isLoading={loading}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      {renderSteps()}
    </Modal>
  );
}

export default ModalNewClaimVehicle;
