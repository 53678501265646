import { ICC_SeedFirestore_Request } from './commands';
import { FBF_CloudCallable } from '@rabbit/firebase/adapter';

export function IsValidLinkToDocument(link: string | undefined) {
  if (!link) return false;
  if (typeof link !== 'string') return false;
  if (link.length < 5) return false;
  return true;
}

export const CallSeedFirestoreCommand = FBF_CloudCallable<
  ICC_SeedFirestore_Request,
  any
>('api/commands/seed-firestore');
