import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
} from '@rabbit/firebase/doctype';
import { Address } from '../base/basic';
import { DTPersona, DTPersonaPublic, FBD_Keygen_Persona } from './persona';
import { z } from 'zod';
import { TenantLink, Z_TenantLink } from '../lib/tenant';

export type RetailerLink = string;
export type DTRetailer_Private = DTPersona;

export const FBD_Retailer_Private = MakeFBDocType<DTRetailer_Private>({
  name: 'Retailer',
  collection: 'retailer_private',
  empty: () => {
    const result: DTRetailer_Private = {
      ...Empty_NoSqlDoc(),
      delegates: {},
      identity: '',
      createTime: new Date().getTime(),
    };
    return result;
  },
  keygen: FBD_Keygen_Persona('S'),
});

export interface DTRetailer_Public extends DTPersonaPublic {
  /** Retailer name, used in search */
  name: string;

  /**
   * @deprecated The property should not be used, use array of addresses instead
   */
  address?: Address;

  /** Location of the repairer */
  addresses?: Address[];

  /** Phone number */
  phone?: string;

  /** Email address */
  email?: string;

  /** Url to Photo */
  photoUrl?: string;

  /** Url to website */
  websiteUrl?: string;

  /** Premium tenants this retailer is a partner (external user) to */
  isPartnerTo?: TenantLink[];

  /** Tenant this retailer is an employee of. Could be a premium tenant or not
   *  Eg: This persona could belong to Barbie Patios , who is an employee of Shelta
   *  Or it could belong to Pete Sales, who works for Australian Patio Furniture, one of Shelta's retailer partners.
   *  Root personas should probably be listed as employees of their own tenant.
   */
  isEmployeeOf?: TenantLink;
}

export const Z_Retailer_Public = z.object({
  name: z.string(),
  address: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  photoUrl: z.string().optional(),
  websiteUrl: z.string().optional(),
  isPartnerTo: z.array(Z_TenantLink).optional(),
  isEmployeeOf: Z_TenantLink.optional(),
});

export const FBD_Retailer_Public = MakeFBDocType<DTRetailer_Public>({
  name: 'Retailer',
  collection: 'retailer',
  // TODO: One day all public documents will also have a public suffix. One day... - DC
  // name: 'Retailer Public',
  // collection: 'retailer_public',
  empty: () => {
    const result: DTRetailer_Public = {
      ...Empty_NoSqlDoc(),
      name: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});

export const Z_RetailerLink = z.string();
