import { DTVendable } from '@rabbit/data/types';

/**
 * DATA FORMATTING FUNCTIONS
 */

// Formats categories for display as a single, comma separated string. Used on Sage
export const formatVendableCategoriesToString = (catArray: string[]) => {
  // Uppercase the first letter of each item, then join them with a comma
  const formattedCategories = catArray.map((cat) => {
    return cat.charAt(0).toUpperCase() + cat.slice(1);
  });
  return formattedCategories.join(', ');
};

// Formats vendable data for display on edit modals. Used on Sage
export const formatVendableDataForEditModal = (data: DTVendable) => {
  const fileListWithNames =
    data.img?.map((img, i) => {
      return {
        url: img,
        // below: temp
        name: `${data.full}${i}`,
      };
    }) ?? [];
  const formattedData = {
    ...data,
    vendable_name: data.full,
    categories: data.category ?? [],
    vendable_images: fileListWithNames,
  };

  return formattedData;
};

interface CategoryListShape {
  label: string;
  count: number;
}
// Generates filter options for the filter dropdowns on tables. Used on Sage
export const generateFilterOptions = (catList: CategoryListShape[]) => {
  const filterOptionsList = catList.map((category, i) => {
    return {
      checkboxName: `item${i}`,
      label: category.label,
      count: category.count,
    };
  });

  return filterOptionsList;
};

// Formats display category data for ModalAddEditVendable. Used on Sage
export const formatDisplayCategoryOptions = (catArray: string[]) => {
  const options = catArray.map((item: string, index: number) => {
    return {
      id: `option${index}`,
      value: item,
      name: item,
      label: item.charAt(0).toUpperCase() + item.slice(1),
    };
  });
  return options;
};

export const handleStringArrAsObjArr = (options: string[]) => {
    return options.map((value: string) => {
      if (typeof value === 'string') {
        return {
          value: value,
          label: value.toLocaleUpperCase(),
        };
      }
    });
  };
