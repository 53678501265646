import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../atoms/buttons/Button/Button';
import { ModalSettingsShape } from '../Modal/Modal';

export interface TextModalProps {
  kind: 'info' | 'tc';
  settings: ModalSettingsShape;
}

const dynamicStyles = {
  container:
    'flex-col border-gray-200 rounded-lg w-full max-h-[90vh] flex px-1',
  text: 'text-center font-nunito font-normal text-gray-500 text-base px-1',
};

export function TextModal({ kind, settings }: TextModalProps) {
  const { handleClose, handlePrimaryClick, title, text, primaryButtonText } =
    settings;

  if (kind === 'info')
    return (
      <div className={classNames(`${dynamicStyles.container}`)}>
        <div className="flex w-full items-center justify-between rounded-t border-b p-4">
          <h3 className="font-nunito text-xl font-medium text-gray-900">
            {title}
          </h3>
          <div className="ml-auto">
            <XMarkIcon
              onClick={handleClose}
              className="h-[18px] w-[18px] cursor-pointer bg-transparent text-gray-400 hover:bg-gray-200 hover:text-gray-600"
            />
            <span className="sr-only">Close modal</span>
          </div>
        </div>
        <div className="space-y-6 p-4">
          <div className="font-nunito text-left text-base font-normal text-gray-500">
            {text}
          </div>
        </div>
        <div className="flex w-full items-center justify-end space-y-2 rounded-b border-t border-gray-200 dark:border-gray-600">
          <div className="mx-3 mt-5 mb-3 flex h-[37px] w-full items-end">
            {primaryButtonText && (
              <Button
                children={primaryButtonText}
                kind="primary"
                size="md"
                onClick={handlePrimaryClick}
              />
            )}
          </div>
        </div>
      </div>
    );

  if (kind === 'tc')
    return (
      <div className={classNames(`${dynamicStyles.container}`)}>
        <div className="flex w-full items-center justify-between rounded-t border-b p-5">
          <h3 className="font-nunito text-xl font-medium text-gray-900">
            {title}
          </h3>
          <div className="ml-auto">
            <XMarkIcon
              onClick={handleClose}
              className="h-[18px] w-[18px] cursor-pointer bg-transparent text-gray-400 hover:bg-gray-200 hover:text-gray-600"
            />
            <span className="sr-only">Close modal</span>
          </div>
        </div>
        <div className="space-y-6 overflow-auto p-6">
          <div className="font-nunito text-left text-sm font-normal text-gray-500">
            <div className="reset-ordered-list font-nunito text-sm">
              <div>
                <p>
                  <strong>Contractual Relationship</strong>
                </p>
                <p>
                  <strong>
                    PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING
                    THE SERVICES.
                  </strong>
                </p>
                <br />
                <p>
                  <span style={{ fontWeight: 400 }}>
                    These Terms and Conditions apply to you if you register to
                    use the Services as a Consumer End User (the “
                  </span>
                  <strong>User</strong>
                  <span style={{ fontWeight: 400 }}>
                    ”). By using the iWarranty Service you confirm that you
                    accept these Terms and Conditions (the “
                  </span>
                  <strong>Terms</strong>
                  <span style={{ fontWeight: 400 }}>
                    ”) and agree to abide by them. If you are accepting these
                    Terms &amp; Conditions on behalf of a company or other
                    business entity, you represent that you are authorised to do
                    so.&nbsp;
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Your access and use of the Services constitutes your
                    agreement to be bound by these Terms, which establishes a
                    contractual relationship between you and iWarranty. If you
                    do not agree to these Terms, you may not access or use the
                    Services. These Terms expressly supersede any prior
                    agreements or arrangements with you.&nbsp;
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Supplemental terms may apply to certain Services, such as
                    products or services you purchased through the mobile or web
                    applications and such supplemental terms will be disclosed
                    to you in connection with the additional Services. Any such
                    Supplemental terms are in addition to and shall be deemed to
                    part of, the Terms for the Services.&nbsp; Supplemental
                    terms shall prevail over these Terms in the event of a
                    conflict with respect to the applicable Services.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>iWarranty </span>
                  <span style={{ fontWeight: 400 }}>
                    may amend the Terms related to the Services from time to
                    time. Amendments will be effective upon iWarranty’s posting
                    of such updated Terms at this&nbsp; location or the amended
                    policies or supplemental terms on the Services. Your
                    continued access or use of the Services after such posting
                    constitutes your consent to be bound by the Terms, as
                    amended.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Our collection and use of personal information in connection
                    with the Services is as provided in
                  </span>
                  <a href="/privacy-policy">
                    <span style={{ fontWeight: 400 }}>
                      {' '}
                      iWarranty’s&nbsp; Privacy Policy
                    </span>
                  </a>
                  <span style={{ fontWeight: 400 }}>.</span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>The&nbsp; Services</strong>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The Services constitute a SaaS technology platform that
                    connects Users to third parties, such as manufacturers,
                    distributors, retailers, repairers and insurers (“
                  </span>
                  <strong>Third Parties</strong>
                  <span style={{ fontWeight: 400 }}>
                    ”).&nbsp; The use of iWarranty’s mobile applications, web
                    applications or websites provided as part of the the
                    Services (each, an “
                  </span>
                  <strong>Application</strong>
                  <span style={{ fontWeight: 400 }}>”) enables </span>
                  <span style={{ fontWeight: 400 }}>
                    Users to store and access product and warranty information,
                    register product warranties, and initiate a claim or
                    communicate with third parties with whom iWarranty is
                    working with (the “
                  </span>
                  <strong>Services</strong>
                  <span style={{ fontWeight: 400 }}>
                    ”).Unless otherwise specified, the Services are provided to
                    Users at no monetary cost.&nbsp;
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The Services are provided by iWarranty Ltd (“We or
                    “iWarranty”).
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    iWarranty is a limited company registered in England and
                    Wales under company number 12014798 with its registered
                    office at 19-21 Crawford Street, London, W1H 1PJ, UK. For
                    support, please contact us via:{' '}
                  </span>
                  <a href="mailto:support@iwarranty.co">
                    <span style={{ fontWeight: 400 }}>
                      support@iwarranty.co
                    </span>
                  </a>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    While we use reasonable endeavours to make sure the Services
                    are available, our manufacturer, retailer and insurance
                    partners decide their own operating hours. That means that
                    the availability of our Service, and the range of
                    manufacturer, retailer and insurance partners with whom you
                    may interact will depend upon them. If you try to
                    communicate with them outside the delivery area or operating
                    hours of a partner, or the Application is otherwise
                    unavailable for any reason, we are not responsible although
                    we will seek to notify you of any scheduled downtime that
                    may impact on your use of the Services.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Further, if you use the Service, you acknowledge and agree
                    that we do not endorse or recommend any of the products or
                    repairers who are listed or who use the Service and we do
                    not take responsibility for checking or verifying all the
                    information which is posted on or transmitted via the
                    Service or in relation to any product, repair or replacement
                    of any item. You shall be solely responsible for checking
                    and verifying any information which you access via or
                    receive via the Service before acting on it placing any
                    reliance on it. If you choose to use the services of any
                    particular manufacturer, retailer, repairerer or insurer
                    because of information you have received through your use of
                    the Services you do so entirely at your own risk.
                  </span>
                </p>
                <p>
                  <strong>DISCLAIMER:</strong>
                  <span style={{ fontWeight: 400 }}>
                    {' '}
                    We (IWarranty) will not under any circumstances have any
                    responsibility to you, or liability to you, for the
                    performance or quality of any product, part, repair or
                    service which you ask any manufacturer, retailer, repairers
                    or insurer to carry out, or for any acts or omissions on the
                    part of any of the same. You agree that they will at all
                    times remain solely responsible to you for the product,,
                    part, repair or service they undertake and their own acts
                    and omissions.{' '}
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    If you choose to purchase or use any of the products, parts
                    or services of any particular partner{' '}
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    manufacturer, retailer, repairerer or insurer{' '}
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    because of information you have received through your use of
                    the services you do so entirely at your own risk.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Third Party/ Ancillary Products And Services</strong>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    From time to time we may offer, promote or make available
                    via the Service, certain ancillary products and services
                    offered by us (such as extended warranties or service plans)
                    ("Ancillary Products") and/ or products and services offered
                    by third parties ("Third Party Products").
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    If you choose to purchase any such Ancillary Products or
                    Third Party Products, you understand and agree that they
                    will be subject to a separate agreement and terms and
                    conditions, details of which will be provided to you as part
                    of the purchase process.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    All Third Party Products are provided by the relevant third
                    party supplier and they (not iWarranty) shall be solely
                    responsible to you for providing the Third Party Product in
                    question.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>The Terms</strong>
                  <strong>&nbsp;</strong>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Unless otherwise specified, the terms of use detailed in
                    this section apply generally when using the Services. By
                    using the Services, Users confirm they meet the following
                    requirements:
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      There are no restrictions for Users in terms of being
                      Consumers or Business Users;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Users must be recognised as an adult by applicable law;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Users are not located in a country that is subject to a
                      U.S. Government embargo, or that has been designated by
                      the U.S. Government as a “terrorist-supporting” country;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Users are not listed on any U.S. Government list of
                      prohibited or restricted parties.
                    </span>
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                  <strong>Account Registration</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    To use the Services, Users must register or create a User
                    account, providing required data or information in a
                    complete and&nbsp; accurate manner to facilitate the
                    warranty registration process. Where we work with your
                    chosen manufacturers and retailers and are able to offer
                    this as a part of our Services, this information will help
                    you to process a warranty claim faster and more efficiently,
                    including communicating with a manufacturer or retailer if
                    something goes wrong with a product you purchased.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    As a User, you are responsible for keeping your login
                    credentials confidential and safe. For this reason, Users
                    are also required to choose passwords that meet the highest
                    standards of strength permitted by the iWarranty
                    applications.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    By registering, Users agree to be fully responsible for all
                    activities that occur under their username and password and
                    agree not to share their account information.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You must keep any password you create, or other secure login
                    method, secret, and prevent others from accessing your email
                    account or mobile phone. If another person uses these
                    methods to access your account, you will be responsible to
                    pay for any Items they order, and we are not responsible for
                    any other losses you suffer, unless the person using your
                    password obtained it because we did not keep it secure.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    We reserve the right, where necessary and without
                    limitation, to undertake all such necessary action including
                    requiring further verification as to the identity, age, and
                    other relevant details of a customer and as is reasonable to
                    protect itself against fraudulent or illegal behaviour or
                    where we have reasonable grounds to believe that, in dealing
                    with such a customer, we may be in actual or possible breach
                    of a law or regulation that applies to us. You must provide
                    us with any information we reasonably ask for as soon as
                    possible. If you refuse, or we suspect fraudulent or
                    criminal activity of any kind, we might suspend or close
                    your account. We’ll use your information as per our Privacy
                    Policy.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Conditions for Account Registration</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Registration of User accounts on iWarranty is subject to the
                    conditions outlined below. By registering, Users agree to
                    meet such conditions.
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Accounts registered by bots or any other automated methods
                      are not permitted
                    </span>
                  </li>
                </ul>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Unless otherwise specified, each User must register only
                      one account
                    </span>
                  </li>
                </ul>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Unless explicitly permitted, a User account may not be
                      shared with other persons.
                    </span>
                  </li>
                </ul>
                <p>
                  <br />
                  <br />
                </p>
                <p>
                  <strong>User Licence&nbsp;</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Subject to your compliance with these Terms, iWarranty
                    grants Users a limited, non-exclusive, non-sublicensable,
                    revocable, non-transferable licence to: (i) access and use
                    the Applications on your personal device solely in
                    connection with your use of the Services; and (ii) access
                    and use any content, information and related materials that
                    may be made available through the Services, in each case
                    solely for your personal, noncommercial use. Any rights not
                    expressly granted herein are reserved by iWarranty and
                    iWarranty’s licensors.
                  </span>
                </p>
                <h5>&nbsp;</h5>
                <h5>
                  <strong>Restrictions</strong>
                </h5>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You may not: (i) remove any copyright, trademark or other
                    proprietary notices from any portion of the Services; (ii)
                    reproduce, modify, prepare derivative works based upon,
                    distribute, licence, lease, sell, resell, transfer, publicly
                    display, publicly perform, transmit, stream, broadcast or
                    otherwise exploit the Services except as expressly permitted
                    by iWarranty;&nbsp; (iii) decompile, reverse engineer or
                    disassemble the Services except as may be permitted by
                    applicable law; (iv) link to, mirror or frame any portion of
                    the Services; (v) cause or launch any programs or scripts
                    for the purpose of scraping, indexing, surveying, or
                    otherwise data mining any portion of the Services or unduly
                    burdening or hindering the operation and/or functionality of
                    any aspect of the Services; or (vi) attempt to gain
                    unauthorised access to or impair any aspect of the Services
                    or its related systems or networks.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Acceptable Use Policy</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The Service allows registered users to create, edit and
                    submit material for inclusion on or transmission via the
                    Service, including (without limitation) by creating
                    suggestions, posting content, posting feedback and sending
                    messages (collectively “
                  </span>
                  <strong>User Content</strong>
                  <span style={{ fontWeight: 400 }}>
                    ”). Whilst we do not seek to monitor or control the
                    submission of User Content, we do reserve the right to
                    delete, move and edit any User Content submitted where (in
                    our absolute discretion) we consider it prudent or necessary
                    to do so.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You agree to comply with all applicable laws and regulations
                    when using the Service. You are solely responsible for all
                    User Content you submit for inclusion on or transmission via
                    the Service. You must ensure that all User Content you
                    transmit via the Service is lawful and honest. In particular
                    you must not post or transmit via theService any User
                    Content that:
                  </span>
                </p>
                <p>&nbsp;</p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      is fraudulent, dishonest or misleading;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      is unlawful, harassing, libellous, abusive, threatening,
                      harmful, vulgar, obscene, or otherwise objectionable or
                      breaches any laws;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      encourages conduct that constitutes a criminal offence,
                      gives rise to civil liability or otherwise breaches any
                      applicable laws, regulations or code of practice;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      infringes the copyright or other rights of any third
                      party;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      is technically harmful (including, without limitation,
                      computer viruses or other malicious software or harmful
                      data).
                    </span>
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    By submitting any User Content to us, or via the Service,
                    you are granting us a royalty-free, non-exclusive licence to
                    use, reproduce, modify, translate, make available and
                    distribute the User Content on or through the Service for
                    the purpose of operating the Service. Any material you
                    upload to our Service or data that we collect as set out
                    above will be considered non-confidential and
                    non-proprietary, and you acknowledge and agree that we have
                    the right to use, copy, distribute, sell and disclose to
                    third parties any such material or data for any purpose
                    related to our business. To the extent that such material is
                    protected by intellectual property rights, you grant us a
                    perpetual, worldwide, royalty-free licence to use, copy,
                    modify, distribute, sell and disclose to third parties any
                    such material or data for any purpose related to our
                    business.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>General Disclaimers</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Whilst we endeavour to ensure that the information available
                    on or through the Service is correct, up to date and
                    accurate we do not warrant the accuracy and completeness of
                    such material. In particular (but without limitation), the
                    Service permits you to access and receive information
                    supplied by third parties, or which is based on information
                    or data supplied by third parties, including third party
                    User Content. The third parties submitting this information
                    are responsible for ensuring that material submitted is
                    accurate and complies with relevant laws and codes. We will
                    not be responsible to you for the conduct of any such third
                    party or for any error or inaccuracy in any material they
                    supply.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Although we aim to offer you the best service possible, we
                    make no promise that the Service will meet your
                    requirements. We cannot guarantee that the Service will be
                    fault or virus free or continuously available. Your access
                    to the Service may occasionally be restricted to allow for
                    repairs, maintenance or the introduction of new features,
                    facilities or services. We may change or discontinue the
                    Service, or any part thereof, by giving reasonable notice at
                    any time.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Liability</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    To the fullest extent permitted by law, we exclude all
                    liability to you for any loss of profit, loss of
                    opportunity, loss of earnings, loss of anticipated earnings
                    or loss of data or for any indirect or consequential losses
                    howsoever arising out of your use of Service or any fault or
                    problem relating to the service or any content received via
                    the Service.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Nothing in these terms of use shall exclude or limit our
                    liability for death or personal injury due to our negligence
                    or for fraudulent misrepresentation or for any other
                    liability which cannot be excluded or limited under
                    applicable law.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Your statutory rights as a consumer are not affected by
                    these Terms &amp; Conditions.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Termination Of Services</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    We may suspend or cancel your registration and/ or terminate
                    your access to or use of the Service and/ or our agreement
                    with you, if you are in breach of these Terms &amp;
                    Conditions or have made improper use of the Service.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You may cancel your registration at any time by informing us
                    in writing. If you do so you must stop using the Service.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The suspension, cancellation or termination of your
                    registration and your right to use the Services shall not
                    affect either party’s statutory right or liabilities.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Intellectual Property</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Unless otherwise stated, the copyright, database right and
                    other intellectual property rights in the Service (including
                    without limitation all content, data, materials and
                    technology used or appearing in the Service or transmitted
                    through it) belongs to us or our licensors. All rights are
                    reserved. Neither these Terms nor your use of the Services
                    convey or grant to you any rights or related to the Services
                    except for the limited licence granted above.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    By using the Service you acknowledge and agree that we
                    collect aggregated and anonymised data concerning usage of
                    the Service and the products or services purchased,
                    submitted or used via the Service. You agree that all
                    intellectual property rights and other rights in such
                    aggregated and anonymised data shall belong to us and we may
                    use this data for any purpose whatsoever. However, this data
                    will not include any information from which particular users
                    of the Services can be identified.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Privacy And Cookies</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Our applications, websites and the Service includes areas
                    where you are requested to, or can, input information about
                    yourself. Any information you submit to us shall be subject
                    to the terms of our Privacy Policy.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    We use cookies on our website and as part of the Service to
                    optimise the experience. Further information about cookies
                    and how we use them can be found in our Cookies Policy. By
                    registering use you are consenting to our use of cookies in
                    the ways described in our Privacy Policy and Cookies Policy.
                  </span>
                </p>
                <p>
                  <br />
                  <br />
                </p>
                <p>
                  <strong>Third Party Services and Content</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The Services may be made available or accessed in connection
                    with Third Party services and content (including
                    advertising) that iWarranty does not control. You
                    acknowledge that different terms of use and privacy policies
                    may apply to your use of such Third Party services and
                    content. iWarranty does not endorse such third party
                    services and content and in no event shall iWarranty be
                    responsible or liable for any products or services of such
                    Third Party provider that is offered within the iWarranty
                    Application, including any and all products, services plans
                    and / or extended warranties that you purchase via through
                    the iWarranty Application.&nbsp;
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Indemnity</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You agree to indemnify and hold iWarranty and its officers,
                    directors, employees and agents harmless from any and all
                    claims, demands, losses, liabilities, and expenses
                    (including lawyers’ fees) arising out of or in connection
                    with: (i) your use of the Services or services or goods
                    obtained through your use of the Services; (ii) your breach
                    or violation of any of these Terms; (iii) iWarranty’s use of
                    your User Content; or (iv) your violation of the rights of
                    any third party, including Third Party Providers (such as
                    Google, Apple, Retailers and other Service Providers).
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>General</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    All notices shall be given to us via email or by post using
                    the contact details set out in above, or to you at either
                    the email or postal address you provide during registration.
                    Notice will be deemed to be given when an email is received
                    (or else on the next business day if it is received on a
                    weekend or a public holiday in the place of receipt) or 2
                    working days after the date of posting.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    If any provision of these Terms &amp; Conditions is held by
                    any court of competent authority to be void or unenforceable
                    in whole or in part, the other provisions of these Terms
                    &amp; Conditions and the remainder of the affected
                    provisions shall continue to be valid.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The failure to exercise a right or remedy provided by these
                    Terms &amp; Conditions or by the law does not constitute a
                    waiver of other rights or remedies.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    We may revise these Terms &amp; Conditions at any time. You
                    are expected to check these Terms &amp; Conditions from time
                    to time to take notice of any changes we make as they are
                    legally binding on you. Some of the provisions contained in
                    these Terms &amp; Conditions may also be superseded by
                    provisions or notices published elsewhere on our website or
                    the Service.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    These Terms &amp; Conditions will be subject to the laws of
                    England and Wales and the English courts shall have
                    jurisdiction to resolve any disputes between us.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Governing Law &amp; Arbitration</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Except as otherwise set forth in these Terms, these Terms
                    shall be exclusively governed by and construed in accordance
                    with the laws of England &amp; Wales, excluding its rules on
                    conflicts of laws.&nbsp;
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-start space-x-2 rounded-b border-t border-gray-200 p-6">
          <div className="">
            {primaryButtonText && (
              <Button
                children={primaryButtonText}
                kind="primary"
                size="sm"
                onClick={handlePrimaryClick}
              />
            )}
          </div>
        </div>
      </div>
    );

  return null;
}

export default TextModal;
