import {
  LoadingSpinner,
  Input,
  Modal,
  Button,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import { Formik, Form } from 'formik';
import { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const initialValues = {
  external_repairer_comment: '',
};

interface FormValuesShape {
  external_repairer_comment: string;
}

const validationSchema = Yup.object().shape({
  external_repairer_comment: Yup.string().required('Please enter a message'),
});

export function RepairerCommentModal() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
    caseFlowCase,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId ||
    !caseFlowCase
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    setIsSubmitting(true);
    const { external_repairer_comment } = values;

    const factsToAlter = {
      ...(external_repairer_comment && {
        external_repairer_comment: {
          comment: external_repairer_comment,
          author: operatingPersona,
        },
      }),
    };

    if (Object.keys(factsToAlter).length > 0) {
      await alterCaseFacts(factsToAlter);
    }

    setShowModal(false);
    toast.success(t('Comment saved successfully.'));
  };

  return (
    <Modal
      settings={{
        title: 'Add comment',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="flex flex-col px-4 mt-5 gap-3">
            <div className="font-nunito">
              <div className="mt-4">
                <Input
                  type="rich-text"
                  label="Add comment"
                  name="external_repairer_comment"
                  settings={{
                    id: 'internal_comment',
                    placeholder: '',
                    hint: '*required',
                  }}
                />
              </div>
            </div>
            <div className="flex mt-8 gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                Add comment
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
