import { z, schema } from '@rabbit/utils/ts';
import { NoSqlDoc, Z_NoSqlDoc } from '@rabbit/firebase/doctype';
import { FullMetadata } from 'firebase/storage';

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               Document Types                               */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              NoSqlHierarchyDoc                             */
/* -------------------------------------------------------------------------- */

/** Hierarchical document in the NoSql database.
 *
 * The tree style adopted is "family tree" in that each document may have more than one parent, but it
 * cannot cycle (be an ancestor of itself).
 */
export interface NoSqlHierarchyDoc extends NoSqlDoc {
  /** p for Parent - the direct parent of this document.
   * Documents can have more than one parent
   */
  p: string[];
  /** r for Route - the route from the tree root to this document */
  r: string[];

  // TODO: Do we store children in here? It's a dupe but might make tree walking faster,
  // equally it might make documents massive for when there are 10000s of items?
}

export const Z_NoSqlHierarchyDoc = schema<NoSqlHierarchyDoc>()(
  z.object({
    p: z.array(z.string()),
    r: z.array(z.string()),
  })
).merge(Z_NoSqlDoc);

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                Generic Types                               */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
// These are the generics used throughout the system

/* -------------------------------------------------------------------------- */
/*                                  DateTime                                  */
/* -------------------------------------------------------------------------- */
/** How date and time are held. For now, msec since the epoch. */
export type DateTime = number;
// TODO: Change to string? "2022-12-25 15:50" <- more readable?

/* -------------------------------------------------------------------------- */
/*                                  ImageUrls                                 */
/* -------------------------------------------------------------------------- */
/** Multiple URLs of an image.
 *
 * Empty array = no images.
 * Where multiple images, the first one is considered the flagship image and the one to show to the user first. */
export type ImageUrls = string[];

/* -------------------------------------------------------------------------- */
/*                            UploadedDocumentUrls                            */
/* -------------------------------------------------------------------------- */
/** Multiple URLs of documents.
 *
 * They might be images or PDFs.
 *
 * It's for documenting things, like proving purchase or warranty.
 */
export type UploadedDocumentUrls = string[];
export const Z_UploadedDocumentUrls = z.array(z.string());

/* -------------------------------------------------------------------------- */
/*                            UserUploadedDocument                            */
/* -------------------------------------------------------------------------- */
/** The shape of an uploaded document file with relevant metadata.
 *
 * Files will be images or PDFs.
 *
 * Used for user-uploaded receipts, proofs of purchase, claim-related documents, and
 * possibly more in the future.
 */
export interface UserUploadedDocument {
  /** The original filename of the file */
  ogFilename: string;
  /** The URL of the file */
  url: string;
  /** The metadata of the file. Includes several essentials such as file name, type, size, and timestamps */
  metadata: FullMetadata;
  /** The width and height of the image, if applicable */
  dimensions?: {
    width: number;
    height: number;
  };
  /** The file's version number. Tracks updates in case of batch file processing */
  version: number;
}

export const Z_FullMetadata = z.object({
  bucket: z.string(),
  fullPath: z.string(),
  generation: z.string(),
  metageneration: z.string(),
  name: z.string(),
  size: z.number(),
  timeCreated: z.string(),
  downloadTokens: z.any(),
  updated: z.string(),
  contentType: z.string(),
  // TODO: type this. It's self referential and not sure how to handle this in Zod atm. -DC
  ref: z.any().optional(),
});

export const Z_UserUploadedDocument = z.object({
  ogFilename: z.string(),
  url: z.string(),
  metadata: Z_FullMetadata.partial(),
  dimensions: z
    .object({
      width: z.number(),
      height: z.number(),
    })
    .optional(),
  version: z.number(),
});

/* -------------------------------------------------------------------------- */
/*                                    Money                                   */
/* -------------------------------------------------------------------------- */
export type Money = {
  /** What currency is used for the money.
   *
   * Uses the standard definition of currency codes as defined by ISO 4217.
   * Basically, 3 letter codes where the first two letters is the country and the third is the currency.
   *
   * GBP = Great Britain Pound
   * JPY = Japanese Yen
   * etc
   */
  currency: string;

  /** The amount of money. */
  amount: number;
};

export const Z_Money = schema<Money>()(
  z.object({
    currency: z.string(),
    amount: z.number(),
  })
);
/* -------------------------------------------------------------------------- */
/*                              VolumeDimensions                              */
/* -------------------------------------------------------------------------- */
export type VolumeDimensions = {
  /** Length in mm */
  length: number;
  /** Width in mm */
  width: number;
  /** Height in mm */
  height: number;
};

/* -------------------------------------------------------------------------- */
/*                                DTGeolocation                               */
/* -------------------------------------------------------------------------- */
/** A location on planet Earth. */
export type Geolocation = {
  /** Latitude */
  lat: number;
  /** Longitude */
  lng: number;
  /** Google maps reference if appropriate */
  google?: string;
};

const Z_Geolocation = schema<Geolocation>()(
  z.object({
    lat: z.number(),
    lng: z.number(),
    google: z.string().optional(),
  })
);

/* -------------------------------------------------------------------------- */
/*                                   Address                                  */
/* -------------------------------------------------------------------------- */
/** A generic real-world postal address.
 *
 * If a parameter is not applicable then it should be set to an empty string.
 */
export type Address = {
  id?: string;
  label?: string;
  line1: string;
  line2?: string;
  company?: string;
  town: string;
  postcode: string;
  county?: string;
  state?: string;
  country: string;
  isDefault?: boolean;
  geo?: Geolocation;
};

export const Z_Address = schema<Address>()(
  z.object({
    id: z.string().optional(),
    label: z.string().optional(),
    line1: z.string(),
    line2: z.string().optional(),
    company: z.string().optional(),
    town: z.string(),
    postcode: z.string(),
    county: z.string().optional(),
    state: z.string().optional(),
    country: z.string(),
    isDefault: z.boolean().optional(),
    geo: Z_Geolocation.optional(),
  })
);

/* -------------------------------------------------------------------------- */
/*                                  SplitName                                 */
/* -------------------------------------------------------------------------- */

/** A name split into parts for when we need to know first and last name separately */
export type SplitName = {
  /** First name */
  first: string;
  /** Last name */
  last: string;
};

export const Z_SplitName = schema<SplitName>()(
  z.object({
    first: z.string(),
    last: z.string(),
  })
);

/* -------------------------------------------------------------------------- */
/*                                  Colour                                    */
/* -------------------------------------------------------------------------- */

/** A colour with name and RGB info */
export type Colour = {
  /** First name */
  name: string;
  /** Prefix to colour name, e.g. dayglow, dark, matt */
  prefix?: string;
  /** Suffix to colour name, e.g. metallic, gloss */
  suffix?: string;
  /** #RRGGBB */
  hex?: string;
};

export const Z_Colour = schema<Colour>()(
  z.object({
    name: z.string(),
    hex: z.string().optional(),
  })
);

/* -------------------------------------------------------------------------- */
/*                              Uploaded Image                                */
/* -------------------------------------------------------------------------- */

/** DEPRECATED, USE UserUploadedDocument instead!
 * An uploaded image url with a bunch of useful metadata */
export interface UploadedImage {
  /** key is a md5 of the url, useful for local cache */
  key: string; // RWI: deprecated
  /** public url for the image */
  url: string;
  /** filename for the image */
  filename: string;
  /** type, e.g. jpg, png, tiff etc, */
  type: string;
  /** version number is set internally - useful for batch updates */
  version: number;
  /** image size in bytes */
  sizeBytes: number;
  /** width in pixels */
  width: number;
  /** height in pixels */
  height: number;
  /** ms timestamp for last modification */
  lastModifiedMs: number;
}
/** DEPRECATED, USE UserUploadedDocument instead! */
export const Z_UploadedImage = schema<UploadedImage>()(
  z.object({
    key: z.string(),
    url: z.string(),
    filename: z.string(),
    type: z.string(),
    version: z.number(),
    sizeBytes: z.number(),
    width: z.number(),
    height: z.number(),
    lastModifiedMs: z.number(),
  })
);
/* -------------------------------------------------------------------------- */
/*                               App info                                     */
/* -------------------------------------------------------------------------- */

export interface AppInfoShape {
  name: string;
  environment: string;
  country: string;
  currency: string;
  address: Address;
  logo: string;
  stale?: number;
  amplitude_key?: any;
  email_main_template: string;
  email_sender: string;
  templateLanguage: string;
}

export enum RabbitEnvironments {
  DEMO = 'DEMO',
  EMULATOR = 'EMULATOR',
  EULAUNCHPAD = 'EULAUNCHPAD',
  EULIVE = 'EULIVE',
  EUSANDBOX = 'EUSANDBOX',
  HOT = 'HOT',
  LAUNCHPAD = 'LAUNCHPAD',
  LIVE = 'LIVE',
  MOTO = 'MOTO',
  USLAUNCHPAD = 'USLAUNCHPAD',
  USLIVE = 'USLIVE',
  USSANDBOX = 'USSANDBOX',
  WRECK = 'WRECK',
}
export const ShopLifterEnvironments = {
  SHOPLIFTER_LIVE: 'SHOPLIFTER_LIVE',
  SHOPLIFTER_DEV: 'SHOPLIFTER_DEV',
};
