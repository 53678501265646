import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
} from '@rabbit/firebase/doctype';
import { Address } from '../base/basic';
import { DTPersona, DTPersonaPublic, FBD_Keygen_Persona } from './persona';
import { TenantLink } from '../lib/tenant';

export interface DTManufacturer_Private extends DTPersona {
  docid: string;
  /** The tenant this Manufacturer persona is linked to */
  tenantLink: TenantLink;
}

/** ManufacturerLink:: Manufacturer - their persona */
export type ManufacturerLink = string;

export const FBD_Manufacturer_Private = MakeFBDocType<DTManufacturer_Private>({
  name: 'Manufacturer',
  collection: 'manufacturer_private',
  empty: () => {
    const result: DTManufacturer_Private = {
      ...Empty_NoSqlDoc(),
      delegates: {},
      identity: '',
      tenantLink: '',
      createTime: new Date().getTime(),
    };
    return result;
  },
  keygen: FBD_Keygen_Persona('M'),
});

export interface DTManufacturer_Public extends DTPersonaPublic {
  /** Manufacturer name, used in search */
  name: string;

  /** Location of the manufacturer */
  address?: Address;

  /** Phone number */
  phone?: string;

  /** Email address */
  email?: string;

  /** Url to Photo */
  photoUrl?: string;

  /** The tenant this Manufacturer persona is linked to */
  tenantLink: TenantLink;
}

export const FBD_Manufacturer_Public = MakeFBDocType<DTManufacturer_Public>({
  name: 'Manufacturer_Public',
  collection: 'manufacturer_public',
  empty: () => {
    const result: DTManufacturer_Public = {
      ...Empty_NoSqlDoc(),
      name: '',
      tenantLink: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});
