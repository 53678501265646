import { useManageSageProfile } from '@rabbit/bizproc/react';
import {
  Input,
  Button,
  Modal,
  asyncValidateSchema,
} from '@rabbit/elements/shared-components';
import { DTRepairer_Public } from '@rabbit/data/types';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { Formik, Form } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface EditUserFormShape {
  firstname: string;
  lastname: string;
}

export function EditNameModal({
  handleClose,
  user,
}: {
  handleClose: () => void;
  user?: DTRepairer_Public;
}) {
  const { updateProfileData, currentData } = useManageSageProfile();
  const schema = Yup.object({
    firstname: Yup.string().required('Please enter your firstname'),
    lastname: Yup.string().required('Please enter your last name'),
  });

  const { t } = useTranslation();

  const modalSettings = {
    title: t('general.name'),
    handleClose: () => handleClose(),
  };

  const nameSplit = user?.name.split(' ');
  const initialValues: EditUserFormShape = {
    firstname: nameSplit && nameSplit?.length > 0 ? nameSplit[0] : '',
    lastname: nameSplit && nameSplit[1] ? nameSplit[1] : '',
  };

  const onSubmit = async (values: EditUserFormShape) => {
    setLoading(true);
    const currentAddress = currentData.repairer_public?.addresses;
    await updateProfileData({
      first_name: values.firstname,
      last_name: values.lastname,
      phone_number: NaN,
      email: currentData?.repairer_public?.email || '',
      addresses: currentAddress || [],
    });
    setLoading(false);
    toast.success('Name updated successfully');
    handleClose();
  };

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[720px] rounded-md border"
      isLoading={loading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validate={asyncValidateSchema(schema)}
        validateOnBlur
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className="flex flex-col gap-4 px-5 pt-4">
                <Input
                  type="text"
                  label={`${t('general.firstName')}*`}
                  name="firstname"
                  settings={{
                    placeholder: t('general.firstName'),
                    hint: '*required',
                    forceUpperCaseFirstLetter: true,
                  }}
                />
                <Input
                  type="text"
                  label={`${t('general.lastName')}*`}
                  name="lastname"
                  settings={{
                    placeholder: t('general.lastName'),
                    hint: '*required',
                    forceUpperCaseFirstLetter: true,
                  }}
                />
                <div className="flex gap-4">
                  <Button
                    kind="primary"
                    children={t('general.save')}
                    type="submit"
                  />
                  <Button
                    kind="red"
                    children={t('general.cancel')}
                    type="button"
                    onClick={() => handleClose()}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
