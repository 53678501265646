import {
  DTWarranty_Offer,
  DTWarranty_Template,
  DeciderApprovedOptionInfo,
  DeciderTableValues,
  WarrantyOfferLink,
  WarrantyTemplateLink,
  Z_DTWarranty_Offer,
  Z_DTWarranty_Template,
  Z_DeciderApprovedOptionInfo,
  Z_DeciderTableValues,
} from '@rabbit/data/types';
import { z } from 'zod';

/** The data we need on Sage to display the available offers
 * to warranty dealers during the registration process  */
export interface RegistrationsWarrantyOptionShape {
  template: DTWarranty_Template;
  offer?: DTWarranty_Offer;
  title: string;
  key: WarrantyTemplateLink | WarrantyOfferLink; // always the docid for the offer (or template if offer is not present). Here as a separate key for easy access and clarity
  approvedOptions: DeciderApprovedOptionInfo[];
}

export const Z_RegistrationsWarrantyOptionShape = z.object({
  template: Z_DTWarranty_Template,
  offer: Z_DTWarranty_Offer.optional(),
  title: z.string(),
  key: z.string(),
  approvedOptions: z.array(Z_DeciderApprovedOptionInfo),
});

/**
 * The full data for a warranty offer, (used in partner pricing editing) including the offer,
 *  the template, and the lookup tables.
 */
export interface WarrantyOfferFullDataShape {
  offer: DTWarranty_Offer;
  template: DTWarranty_Template;
  lookupTables: DeciderTableValues[];
}

export const Z_WarrantyOfferFullDataShape = z.object({
  offer: Z_DTWarranty_Offer,
  template: Z_DTWarranty_Template,
  lookupTables: z.array(Z_DeciderTableValues),
});
