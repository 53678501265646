import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                                   Request Logs                             */
/* -------------------------------------------------------------------------- */

export interface DT_RequestLogs extends NoSqlDoc {
  requestId: string;
  cfName: string;
}

export const FBD_RequestLogs = MakeFBDocType<DT_RequestLogs>({
  name: 'Request_Logs',
  collection: 'request_logs',
  empty: () => {
    const result: DT_RequestLogs = {
      ...Empty_NoSqlDoc(),
      requestId: '',
      cfName: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED('DT_RequestLogs key should be requestID'),
});
