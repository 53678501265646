import { z } from 'zod';
import {
  DTWarranty,
  FBD_Consumer_Public,
  FBD_Warranty,
  Z_ConsumerInfo,
} from '@rabbit/data/types';

import { useEffect, useState } from 'react';

export interface WarrantyDetailsShape {
  warranty: DTWarranty | null;
  consumer: z.infer<typeof Z_ConsumerInfo> | null;
}

export function useGetWarrantyDetails(
  warrantyLink: string,
) {
  const [warrantyDetails, setWarrantyData] = useState<WarrantyDetailsShape | null>(null);
  if (!warrantyLink) { 
    throw new Error('Warranty Link is required');
  };
  
  const getData = async(): Promise<WarrantyDetailsShape> => {
    const warranty: DTWarranty|null = await FBD_Warranty.get(warrantyLink);
    if (!warranty) {
      throw new Error('Warranty not Found');
    }
    // fetch consumer data of warranty
    const consumerInfo = await FBD_Consumer_Public.get(warranty.consumerLink);
    if (!consumerInfo) {
      throw new Error('Warranty customer data not found');
    }

    let consumer: z.infer<typeof Z_ConsumerInfo> = {
      firstName: consumerInfo.splitname?.first ?? '',
      lastName: consumerInfo.splitname?.last ?? '',
      consumerEmail: consumerInfo.email ?? '',
      phoneNumber: consumerInfo.phone ?? '',
    };
    if (consumerInfo.address) {
      consumer = {
        ...consumer,
        address: consumerInfo.address[consumerInfo.address.length - 1]
      };
    }
    return {
      warranty,
      consumer
    };
  };

  useEffect(() => {
    (async () => {
      const data = await getData();
      setWarrantyData(data);
    })().catch((err) => console.error(err));
  }, [warrantyLink]);

  return warrantyDetails;
};