import { Field, useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import { InputTypeSelectAddonTextSettingsShape } from '@rabbit/elements/shared-types';
import { handleKeyPress } from '@rabbit/elements/shared-components';
//TODO: Turn currency flag into a select element with a default value of currency

export interface InputTypeSelectAddonTextProps {
  name: string;
  settings: InputTypeSelectAddonTextSettingsShape;
}

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 rounded-r-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 focus:ring-red-500 text-red-700',
};

function StyledCurrencyInput({
  field,
  placeholder,
  setFieldValue,
  currency,
  form: { touched, errors },
  parentName,
  ...props
}: any) {
  let errorField = false;
  if (errors[parentName]?.amount) errorField = true;

  return (
    <input
      {...field}
      {...props}
      className={classNames(`${dynamicStyles.base}`, {
        [`${dynamicStyles.error}`]: errorField,
      })}
      // name={field.name}
      value={field.value}
      placeholder={placeholder}
      maxLength={9}
      onInput={(e: any) => {
        setFieldValue(field.name, e.target.value);
      }}

      // fixedDecimalLength={2}
      // transformRawValue={(value) => {
      //   return value.replace(/[^\d.,]/g, '');
      // }}
    />
  );
}

export function InputTypeSelectAddonText({
  name,
  settings,
}: InputTypeSelectAddonTextProps) {
  const { setFieldValue } = useFormikContext();
  const { id, placeholder, addonOptions, ...rest } = settings;
  const [, meta] = useField(name);

  const onCurrencyChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    void setFieldValue(`${name}.addon`, value);
  };

  return (
    <div className="flex">
      <select
        onChange={onCurrencyChange}
        className={
          'font-nunito max-w-[135px] rounded-l-lg border border-gray-300 bg-gray-100 text-base text-gray-500'
        }
        value={meta.value?.addon ? meta.value.addon : meta.value.unit ? meta.value.unit : undefined}
      >
        {addonOptions.map((option) => {
          return (
            <option value={option.value} key={option.id}>
              {option.label}
            </option>
          );
        })}
      </select>
      <div className="relative w-full grow">
        <Field
          name={`${name}.value`}
          parentName={name}
          component={StyledCurrencyInput}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
          onKeyDown={handleKeyPress}
          {...rest}
        />
      </div>
    </div>
  );
}

export default InputTypeSelectAddonText;
