import {
  DR_Fault,
  NewkeyAllocator,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import {
  FBD_Fault,
  PrincipalsFieldName,
  TenantLinkFieldName,
} from '@rabbit/data/types';
import { FBDTKeygenGeneric } from '@rabbit/firebase/doctype';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { query, orderBy, doc, deleteDoc, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firebaseStore } from '@rabbit/firebase/adapter-react';
import { t } from 'i18next';
import {
  convertRootPersonasFromLexicon2Array,
  getTenantIdFromLexicon,
} from '@rabbit/bizproc/client';

interface FaultFormDataShape {
  title: string;
  category?: string[];
  description?: string;
}

// TODO: When fault categories are fully implemented, create Cloud FN to add a fault to all relevant fault categories automatically, and possibly vice versa
// That or doing it in the pre-commit? Will have to think about it - dc

/**
 * Used on Sage. This hook is used to fetch a list of product faults, as well as provide functions for creating and editing them.
 * If no docid is provided, creating a new fault is allowed. Otherwise, only editing and fetching is allowed.
 */
export function useManageFaults(docid?: string) {
  // If we have a docId, use it. Otherwise, generate a new one
  const faultId = docid ?? FBDTKeygenGeneric('Random', FBD_Fault);
  const [ourDocid, setOurDocid] = useState(NewkeyAllocator(faultId, DR_Fault));

  useEffect(() => {
    setOurDocid(NewkeyAllocator(faultId, DR_Fault));
  }, [docid]);

  const { body, commit, isReady, update } = usePortalMultipleDocumentEditor(
    { fault: { type: DR_Fault, docid: ourDocid } },
    {
      create: docid ? 'DENY' : 'ALLOW',
    }
  );
  /* -------------------------------------------------------------------------- */
  /*                              DB data fetching                              */
  /* -------------------------------------------------------------------------- */

  const tenantId = t(TenantLinkFieldName);

  const faultQuery = query(
    DR_Fault.collection(),
    orderBy('tcreate'),
    where('tenantLink', '==', tenantId)
  );

  const faultList = useFirestoreQueryData(['fault-list'], faultQuery, {
    subscribe: true,
  });

  /* -------------------------------------------------------------------------- */
  /*                             Creating / Editing                             */
  /* -------------------------------------------------------------------------- */

  async function createFault(formData: FaultFormDataShape) {
    if (docid)
      throw new Error('Cannot create a fault while editing a different one');
    if (!formData || !formData.title)
      throw new Error('Invalid form data provided');
    if (!body.fault)
      throw new Error('The base document was not generated correctly');

    const { title, category, description } = formData;

    const tenantLink = await getTenantIdFromLexicon();

    update({
      fault: {
        ...body.fault,
        title,
        category: category ?? [],
        description: description ?? '',
        tenantLink, // TODO: this and below shall be moved to server side, so no add directly via client, CF shall be used
        principals: convertRootPersonasFromLexicon2Array(
          t(PrincipalsFieldName)
        ),
      },
    });
    await commit();
  }

  async function editFault(formData: FaultFormDataShape) {
    if (!docid)
      throw new Error(
        'Hook requires a docid to be provided in order to locate and edit a fault'
      );
    if (!formData || !formData.title)
      throw new Error('Invalid form data provided');
    if (!body.fault) throw new Error('The document was not fetched correctly');

    const { title, category, description } = formData;

    update({
      fault: {
        ...body.fault,
        title,
        category: category ?? [],
        description: description ?? '',
      },
    });
    await commit();
  }

  async function deleteFault(fault_docid: string) {
    try {
      await deleteDoc(doc(firebaseStore, 'fault', fault_docid));
    } catch (err) {
      throw new Error('Error deleting fault!');
    }
  }

  return {
    faultList,
    createFault,
    editFault,
    deleteFault,
    faultData: body,
    isReady,
  };
}
