import { ClockIcon } from '@heroicons/react/24/outline';

export interface PillTimeProps {
  label: string;
  className: string;
  icon?: boolean;
}

export function PillTime({ label, icon, className }: PillTimeProps) {
  return (
    <div
      className={`font-nunito flex h-[25px] w-fit items-center justify-center gap-1.5 rounded-md py-1 px-3 text-gray-900 ${className}`}
    >
      {icon && <ClockIcon className="h-4 w-4" />}
      <p className="text-sm font-medium">{label}</p>
    </div>
  );
}

export default PillTime;
