import { DRDocType } from '@rabbit/firebase/react';
import { useEffect, useReducer, useState } from 'react';
import { NoSqlDoc } from '@rabbit/firebase/doctype';
import { AttachEditingInstance } from './editing-instance';
import {
  EditingInstanceConfig,
  EditingInstanceHook,
  PortalDocumentEditorError,
  PortalEditingDocumentEntry,
  PortalEditingDocumentStatus,
} from './types';
import {
  DefaultPortalDocumentEditorOptions,
  FullPortalDocumentEditorOptions,
} from './usePortalDocumentEditor';

type SingleDocumentSelection<T extends NoSqlDoc> = {
  type: DRDocType<T>;
  docid: string;
};

type DocumentBodyCollection<
  T extends Record<string, SingleDocumentSelection<any>>
> = {
  [K in keyof T]: T[K]['type']['type'] | null;
};

type EditingInstanceHookCollection<
  T extends Record<string, SingleDocumentSelection<any>>
> = {
  [K in keyof T]: EditingInstanceHook<T[K]['type']['type']> | null;
};

type PortalEditingDocumentEntryCollection<
  T extends Record<string, SingleDocumentSelection<any>>
> = {
  [K in keyof T]: PortalEditingDocumentEntry<T[K]['type']['type']> | null;
};

export type PortalMultipleDocumentEditorReturn<
  T extends Record<string, SingleDocumentSelection<any>>
> = {
  // /** The body of the document. Will be null if document is not editable. Otherwise it is an object
  //  * of the type set by the incoming type parameter. If the document is new then it will be an empty
  //  * object created by DRDocType.empty.
  //  *
  //  * Any edits to this object will be reverted to the "local record" upon next render.
  //  * To commit your edit, call update(newBody). For convenience you may edit the existing body
  //  * immediately before updating as it is a copy of the "local record"
  //  *  */
  // body: T | null;

  // /** The status of the document, giving more detail of the situation as described in the enum PortalDocumentEditorStatus.
  //  * A few different states indicate document is ready to edit but you can just check for body===null to see if it is ready.
  //  */
  status: PortalEditingDocumentStatus;

  error: PortalDocumentEditorError;

  // /** Update the body, save it to local record.
  //  *
  //  * You may edit the body immediately before calling this function, but the body will be reverted to the "local record" upon next render.
  //  * So make sure to not allow any async stages between your edits and the update.
  //  */
  update: (newBody: DocumentBodyCollection<T>) => void;

  // /** Commit the current local record to Firebase Record. */
  commit: () => Promise<void>;

  // /** The document ID of the document being shown. When creating a new document this will change upon commit. */
  // docid: string;

  /** Simple way to check if it is safe to start editing the document */
  isReady: boolean;

  body: DocumentBodyCollection<T>;

  // /** For debugging */
  entries: PortalEditingDocumentEntryCollection<T>;
};

export type FullPortalMultipleDocumentEditorOptions =
  FullPortalDocumentEditorOptions & {
    pre_commit: (body: any) => Promise<any>;
  };

export function usePortalMultipleDocumentEditor<
  T extends Record<string, SingleDocumentSelection<any>>
>(
  documents: T,
  options?: Partial<FullPortalMultipleDocumentEditorOptions>
): PortalMultipleDocumentEditorReturn<T> {
  const bodyReducer = (
    state: DocumentBodyCollection<T>,
    newState: { key: string; body: any }
  ) =>
    newState.key === 'RESET'
      ? ({} as any)
      : {
          ...state,
          [newState.key]: newState.body,
        };

  const documentEntriesReducer = (
    state: PortalEditingDocumentEntryCollection<T>,
    newState: { key: string; body: any }
  ) =>
    newState.key === 'RESET'
      ? ({} as any)
      : {
          ...state,
          [newState.key]: newState.body,
        };

  const opts = { ...DefaultPortalDocumentEditorOptions, ...options };

  const [editingInstances, setEditingInstances] = useState(
    {} as EditingInstanceHookCollection<T>
  );

  // const [theCopyDocument, setTheCopyDocument] = useState(
  //   {} as DocumentBodyCollection<T>
  // );

  const [theCopyDocument, tcdDispatch] = useReducer(
    bodyReducer,
    {} as DocumentBodyCollection<T>
  );

  // const [documentEntries, setdocumentEntries] = useState(
  //   {} as PortalEditingDocumentEntryCollection<T>
  // );

  const [documentEntries, deDispatch] = useReducer(
    documentEntriesReducer,
    {} as PortalEditingDocumentEntryCollection<T>
  );

  useEffect(() => {
    const newEditingInstances: EditingInstanceHookCollection<T> =
      {} as EditingInstanceHookCollection<T>;

    const registerOneDocument = (key: string) => {
      const { type, docid } = documents[key];
      const Config: EditingInstanceConfig<NoSqlDoc> = {
        entryChanged: (newEntry) => {
          console.log(
            'usePortalMultipleDocumentEditor: one doc updated:',
            key,
            newEntry
          );
          deDispatch({ key, body: newEntry });
          tcdDispatch({
            key,
            body: JSON.parse(JSON.stringify(newEntry.local)),
          });
        },
        allowCreate: opts.create === 'ALLOW',
      };

      (newEditingInstances as any)[key] = AttachEditingInstance(
        type,
        docid,
        Config
      );
    };

    for (const key in documents) {
      registerOneDocument(key);
    }

    setEditingInstances(newEditingInstances);

    return () => {
      console.log('usePortalDocumentEditor: UNMOUNTING');
      for (const key in newEditingInstances) {
        newEditingInstances[key]?.unsubscribe();
      }
      deDispatch({ key: 'RESET', body: null });
      tcdDispatch({ key: 'RESET', body: null });
      setEditingInstances({} as EditingInstanceHookCollection<T>);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(documents)
      .map(
        (key) =>
          `${key}/${documents[key].docid}/${documents[key].type.collectionName}`
      )
      .join(''),
  ]);

  const update = (newBody: DocumentBodyCollection<T>) => {
    // Break it down and see who has changed
    for (const key in documents) {
      const newDoc = newBody[key];
      const entry = documentEntries[key];
      if (!entry) {
        throw new Error('Should not be updating before ready');
      }
      if (JSON.stringify(newDoc) !== JSON.stringify(entry.local)) {
        console.log('Updating', key);
        (editingInstances as any)[key]?.update(newDoc);
        tcdDispatch({ key, body: JSON.parse(JSON.stringify(newDoc)) });
      }
    }
  };
  const commit = async () => {
    const entries: EditingInstanceHook<
      T[Extract<keyof T, string>]['type']['type']
    >[] = [];
    for (const key in documents) {
      const entry = editingInstances[key];
      if (!entry) {
        throw new Error('Should not be committing before ready');
      }
      entries.push(entry);
    }

    if (opts.pre_commit) {
      await opts.pre_commit(theCopyDocument);
    }

    for (let n = 0; n < entries.length; n++) {
      const entry = entries[n];
      await entry.commit();
    }
  };

  // Scan all document entries to make sure we are ready and analyse the status, etc
  let status: PortalEditingDocumentStatus = PortalEditingDocumentStatus.saved;
  let error: PortalDocumentEditorError = null;
  let isReady = true;
  let isBlank = false;
  for (const key in documents) {
    const e = documentEntries[key as any];

    if (!e) {
      isBlank = true;
      break;
    } else {
      const thisIsReady =
        e.status === 'NEW' || e.status === 'SAVED' || e.status === 'EDITED';

      switch (e.status) {
        case PortalEditingDocumentStatus.error:
          status = PortalEditingDocumentStatus.error;
          break;
        case PortalEditingDocumentStatus.preparing:
          if (status !== PortalEditingDocumentStatus.error)
            status = PortalEditingDocumentStatus.preparing;
          break;
        case PortalEditingDocumentStatus.edited:
          if (
            (status as any) !== PortalEditingDocumentStatus.error &&
            (status as any) !== PortalEditingDocumentStatus.preparing
          )
            status = PortalEditingDocumentStatus.edited;
          break;
        case 'NEW':
          if (
            status !== PortalEditingDocumentStatus.error &&
            (status as any) !== PortalEditingDocumentStatus.preparing &&
            (status as any) !== PortalEditingDocumentStatus.edited
          )
            status = PortalEditingDocumentStatus.new;
          break;
        case 'SAVED':
          // It's already down as "saved"
          break;
      }

      if (e.error) error = e.error;

      // An annoying grid for combining statuses

      if (!thisIsReady) isReady = false;
    }
  }

  if (isBlank) {
    const emptyResult: PortalMultipleDocumentEditorReturn<T> = {
      body: {} as any,
      status: PortalEditingDocumentStatus.preparing,
      error: null,
      update,
      commit,
      entries: {} as any,
      isReady: false,
    };
    return emptyResult;
  }

  // Check to see if someone has edited the body object. If they have then replace it with the official
  // document. Lets us use the body object as a local copy rather than creating a whole new object for one change.
  // Might consider using immer but I don't know enough about it.
  // if (JSON.stringify(documentEntry.local) !== JSON.stringify(theCopyDocument)) {
  //   setTheCopyDocument(JSON.parse(JSON.stringify(documentEntry.local)));
  // }

  const result: PortalMultipleDocumentEditorReturn<T> = {
    body:
      (status as any) === PortalEditingDocumentStatus.preparing ||
      (status as any) === PortalEditingDocumentStatus.error
        ? ({} as any)
        : theCopyDocument,
    update,
    commit,
    status,
    error,
    // docid: documentEntry.docid,
    // entry: documentEntry,
    isReady: isReady,
    entries: documentEntries,
  };

  return result;
}
