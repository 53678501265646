import {
  DR_Fault,
  DR_Fault_Category,
  NewkeyAllocator,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import { FBD_Fault_Category } from '@rabbit/data/types';
import { FBDTKeygenGeneric } from '@rabbit/firebase/doctype';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { query, orderBy, doc, deleteDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firebaseStore } from '@rabbit/firebase/adapter-react';

interface FaultCategoryFormDataShape {
  title: string;
  faults?: string[];
  description?: string;
}

// TODO: When fault categories are fully implemented, create Cloud FN to add a fault category to all relevant faults automatically, and possibly vice versa
// That or doing it in the pre-commit? Will have to think about it - dc

/**
 * Used on Sage. This hook is used to fetch a list of product fault categories, as well as provide functions for creating and editing them.
 * If no docid is provided, creating a new fault category is allowed. Otherwise, only editing and fetching is allowed.
 */
export function useManageFaultCategories(docid?: string) {
  // If we have a docId, use it. Otherwise, generate a new one
  const faultCatId = docid ?? FBDTKeygenGeneric('Random', FBD_Fault_Category);
  const [ourDocid, setOurDocid] = useState(
    NewkeyAllocator(faultCatId, DR_Fault_Category)
  );

  useEffect(() => {
    setOurDocid(NewkeyAllocator(faultCatId, DR_Fault));
  }, [docid]);

  const { body, commit, isReady, update } = usePortalMultipleDocumentEditor(
    { fault_category: { type: DR_Fault_Category, docid: ourDocid } },
    {
      create: docid ? 'DENY' : 'ALLOW',
    }
  );
  /* -------------------------------------------------------------------------- */
  /*                              DB data fetching                              */
  /* -------------------------------------------------------------------------- */

  const faultCatQuery = query(
    DR_Fault_Category.collection(),
    orderBy('tupdate')
  );
  const faultCategoryList = useFirestoreQueryData(
    ['fault-category-list'],
    faultCatQuery,
    {
      subscribe: true,
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                             Creating / Editing                             */
  /* -------------------------------------------------------------------------- */

  async function createFaultCategory(formData: FaultCategoryFormDataShape) {
    if (docid)
      throw new Error('Cannot create a fault while editing a different one');
    if (!formData || !formData.title)
      throw new Error('Invalid form data provided');
    if (!body.fault_category)
      throw new Error('The base document was not generated correctly');

    const { title, faults, description } = formData;

    update({
      fault_category: {
        ...body.fault_category,
        title,
        faults: faults ?? [],
        description: description ?? '',
      },
    });
    await commit();
  }

  async function editFaultCategory(formData: FaultCategoryFormDataShape) {
    if (!docid)
      throw new Error(
        'Hook requires a docid to be provided in order to locate and edit a fault'
      );
    if (!formData || !formData.title)
      throw new Error('Invalid form data provided');
    if (!body.fault_category)
      throw new Error('The document was not fetched correctly');

    const { title, faults, description } = formData;

    update({
      fault_category: {
        ...body.fault_category,
        title,
        faults: faults ?? [],
        description: description ?? '',
      },
    });
    await commit();
  }

  async function deleteFaultCategory(fault_cat_docid: string) {
    try {
      await deleteDoc(doc(firebaseStore, 'fault_category', fault_cat_docid));
    } catch (err) {
      throw new Error('Error deleting fault category!');
    }
  }

  return {
    faultCategoryList,
    createFaultCategory,
    editFaultCategory,
    deleteFaultCategory,
    faultCategoryData: body,
    isReady,
  };
}
