import {
  Button,
  Input,
  PasswordValidationPopover,
  asyncValidateSchema,
  handlePopoverData,
} from '@rabbit/elements/shared-components';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { firebaseAuth } from '@rabbit/firebase/adapter-react';
import { toast } from 'react-toastify';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface PWDRNewPasswordProps {
  onChangeStep: (data: string) => void;
  data: any; //todo
}

// Formik
export interface FormValuesShape {
  password: string;
  confirm_password: string;
}

const initialValues: FormValuesShape = {
  password: '',
  confirm_password: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, i18next.t('message.passwordShouldBe8OrMoreCharacters'))
    .matches(
      /(?=.*[a-z])/,
      i18next.t('message.pleaseEnterAtLeast1LowercaseLetter')
    )
    .matches(
      /(?=.*[A-Z])/,
      i18next.t('message.pleaseEnterAtLeast1UppercaseLetter')
    )
    .matches(/^(?=.*[0-9])/, i18next.t('message.pleaseEnterAtLeast1Number'))
    .required(i18next.t('message.pleaseEnterYourPassword')),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], i18next.t('message.passwordsMustMatch'))
    .required(i18next.t('message.pleaseConfirmYourPassword')),
});

function PWDRNewPassword({ onChangeStep, data }: PWDRNewPasswordProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [authErrorMsg, setAuthErrorMsg] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email');
  const oobCode = searchParams.get('oobCode');
  const navigate = useNavigate();

  useEffect(() => {
    if (email && oobCode) {
      verifyPasswordResetCode(firebaseAuth, oobCode).catch((err) => {
        console.log(err);
        navigate('/');
      });
    }
  }, [email, oobCode]);

  const onSubmit = async (values: FormValuesShape) => {
    if (email && oobCode && values.password) {
      confirmPasswordReset(firebaseAuth, oobCode, values.password)
        .then(() => {
          navigate('/');
          toast.success(t('message.passwordUpdatedSuccessfully'));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={asyncValidateSchema(validationSchema)}
      validateOnBlur
      onSubmit={onSubmit}
    >
      {({ errors, values }) => {
        console.log(errors);
        return (
          <Form className="font-nunito flex flex-col gap-5">
            <p className="font-nunito text-base">
              {t('message.enterYourNewPassword')}
            </p>
            <Input
              type="password"
              name="password"
              label={t('general.password')}
              settings={{
                placeholder: t('message.pleaseTypeYourPassword'),
                id: 'password',
                popoverTrigger: 'hover',
                popoverPlacement: 'right',
                popoverTarget: 'popover-password',
                hint: '*required',
              }}
            />
            <Input
              type="password"
              name="confirm_password"
              label={t('general.confirmPassword')}
              settings={{
                placeholder: t('message.pleaseTypeYourPassword'),
                id: 'confirm_password',
                hint: '*required',
              }}
            />
            <PasswordValidationPopover
              id="popover-password"
              errors={handlePopoverData(errors.password, values.password)}
            />

            <Button
              kind="primary"
              type="submit"
              disabled={Object.keys(errors).length > 0 || isLoading}
              loading={isLoading}
            >
              {t('general.submit')}
            </Button>
            <div className="font-nunito mt-4 text-base">
              <NavLink
                to={SAGE_ROUTE_NAME.LOGIN}
                className="text-primary-600"
                reloadDocument
              >
                {t('general.backToLogin')}
              </NavLink>
            </div>
            {authErrorMsg && (
              <p className="font-nunito text-sm text-red-500">{authErrorMsg}</p>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default PWDRNewPassword;
