import { useManageSageProfile } from '@rabbit/bizproc/react';
import {
  Input,
  Button,
  Modal,
  asyncValidateSchema,
} from '@rabbit/elements/shared-components';
import { DTRepairer_Public } from '@rabbit/data/types';
import { Formik, Form, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

interface FormValuesShape {
  phone_number_e164: any;
  phone_number: string;
}

const schema = Yup.object().shape({
  phone_number: Yup.string().trim().required('Please enter a phone number'),
});

export function ChangePhoneModal({
  handleClose,
  user,
}: {
  handleClose: () => void;
  user?: DTRepairer_Public;
}) {
  const { currentData, updateProfileData } = useManageSageProfile();
  const modalSettings = {
    title: 'Change phone number',
    handleClose: () => handleClose(),
  };
  const formikRef = useRef<FormikProps<FormValuesShape>>(null);

  const initialValues: FormValuesShape = {
    phone_number: user?.phone || '',
    phone_number_e164: user?.phone || '',
  };

  const onSubmit = async (values: FormValuesShape) => {
    setLoading(true);
    const nameSplit = currentData?.repairer_public?.name.split(' ');
    const initialValues = {
      firstname: nameSplit && nameSplit?.length > 0 ? nameSplit[0] : '',
      lastname: nameSplit && nameSplit[1] ? nameSplit[1] : '',
    };
    try {
      await updateProfileData({
        first_name: initialValues.firstname,
        last_name: initialValues.lastname,
        phone_number: values.phone_number_e164 ? Number(values.phone_number_e164) : Number(values.phone_number),
        email: currentData?.repairer_public?.email || '',
        addresses: currentData?.repairer_public?.addresses || [],
      });
      toast.success('Phone number updated successfully.');
      setLoading(false);
      handleClose();
    } catch (err: any) {
      setLoading(false);
      toast.error(
        err && err?.message
          ? 'Error: ' + err?.message
          : 'Unable to save changes'
      );
    }
  };

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[720px] rounded-md border"
      isLoading={loading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validate={asyncValidateSchema(schema)}
        validateOnBlur
        innerRef={formikRef}
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className="flex flex-col gap-4 px-5 pt-4">
                <Input
                  type="phone"
                  name="phone_number"
                  label="Phone number"
                  settings={{
                    placeholder: 'Phone number',
                  }}
                />
                <div className="flex gap-4">
                  <Button
                    kind="primary"
                    children="Save"
                    type="submit"
                    onClick={() => 0}
                  />
                  <Button
                    kind="red"
                    children="Cancel"
                    type="button"
                    onClick={() => handleClose()}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
