import { Dispatch, SetStateAction, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  ButtonIcon,
  DragFileUpload,
  Input,
} from '@rabbit/elements/shared-components';
import { TrashIcon } from '@heroicons/react/24/outline';
import { UserUploadedFileShape } from '@rabbit/elements/shared-types';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { useManageFaults } from '@rabbit/bizproc/react';

export interface ModalAddEditPartProps {
  handleClose: () => void;
  handleDelete?: () => void;
  itemDetails?: any;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export function ModalAddEditPart({
  handleClose,
  handleDelete,
  itemDetails,
  setLoading,
}: ModalAddEditPartProps) {
  const appInfo = useAppInfo();
  const [submitAction, setSubmitAction] = useState<string | undefined>('');
  const { faultList } = useManageFaults();
  const faultOptions = !faultList?.data
    ? []
    : faultList.data.map((fault) => ({
        id: fault.docid,
        value: fault.docid,
        label: fault.title,
        name: fault.title,
      }));

  const mock_options = [
    { id: '1', value: 'option1', label: 'option1', name: 'option1' },
    { id: '2', value: 'option2', label: 'option2', name: 'option2' },
  ];

  const schema = Yup.object({
    part_name: Yup.string().required('Please enter a part name'),
    holding_faults: Yup.array().required('Please select a fault'),
    products: Yup.array().required('Please select a product'),
    brand: Yup.string().required('Please enter a brand'),
    price: Yup.string().required('Please enter the price'),
    details: Yup.string().required('Please enter some details'),
    files: Yup.array().required('Please select a file').min(1),
  });

  const noSchema = Yup.object({});

  const previousFiles: UserUploadedFileShape[] = [
    {
      url: itemDetails ? itemDetails?.imageUrl : '',
      name: itemDetails ? itemDetails.productName : '',
    },
  ];

  const initialValues = {
    part_name: itemDetails ? itemDetails.partName : '',
    holding_faults:
      itemDetails && itemDetails.holding_faults
        ? itemDetails.holding_faults
            .split('  ')
            .map((value: string, index: number) => {
              return {
                id: `${index}`,
                value: value,
                label: value,
              };
            })
        : '',
    products:
      itemDetails && itemDetails.product
        ? itemDetails.product.split(',').map((value: string, index: number) => {
            return {
              id: `${index}`,
              value: value,
              label: value,
            };
          })
        : '',
    brand: itemDetails ? itemDetails.brand : '',
    price: itemDetails ? itemDetails.price : '',
    details: itemDetails ? itemDetails.details : '',
    files: previousFiles,
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setLoading(true);
          if (submitAction === 'save') {
            handleClose();
          } else if (submitAction === 'delete') {
            handleDelete && handleDelete();
          }
        }}
        validationSchema={submitAction === 'delete' ? noSchema : schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => (
          <form>
            <div className="mt-4 px-5 pb-2">
              <Input
                type="text"
                label="Part Name*"
                name="part_name"
                settings={{
                  hint: '*required',
                }}
              />
            </div>
            <div className="py-2 px-5">
              <Input
                type="select"
                label="Faults*"
                name="holding_faults"
                settings={{
                  options: faultOptions,
                  placeholder: 'Select',
                  id: 'holding_faults',
                  isMulti: true,
                  errors: props.errors,
                  hint: '*required',
                }}
              />
            </div>
            <div className="py-2 px-5">
              <Input
                type="select"
                label="Product(s)*"
                name="products"
                settings={{
                  options: mock_options,
                  placeholder: 'Select',
                  id: 'products',
                  isMulti: true,
                  errors: props.errors,
                  hint: '*required',
                }}
              />
            </div>
            <div className="flex gap-4 py-2 px-5">
              <div className="flex-1">
                <Input type="text" label="Brand*" name="brand" settings={{}} />
              </div>
              <div className="flex-1">
                <Input
                  type="currency"
                  label="Price*"
                  name="price"
                  settings={{
                    currency:
                      props?.values?.price?.currency ?? appInfo.currency,
                    hint: '*required',
                  }}
                />
              </div>
            </div>
            <div className="py-2 px-5">
              <Input
                type="textarea"
                label="Details*"
                name="details"
                settings={{
                  hint: '*required',
                }}
              />
            </div>
            <div className="py-2 px-5">
              <DragFileUpload
                label="Drop files to upload"
                previousFiles={itemDetails?.imageUrl ? previousFiles : []}
              />
            </div>
            <div className="flex gap-5 py-5 px-5">
              <div>
                <Button
                  kind="primary"
                  size="sm"
                  onClick={() => {
                    setSubmitAction('save');
                    props.handleSubmit();
                  }}
                  children="Save all"
                />
              </div>
              {itemDetails ? (
                <div>
                  <ButtonIcon
                    kind="bgRed"
                    label="Delete Part"
                    Icon={TrashIcon}
                    iconLeft={true}
                    onClick={() => {
                      setSubmitAction('delete');
                      props.handleSubmit();
                    }}
                  />
                </div>
              ) : null}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
