/* eslint-disable @nx/enforce-module-boundaries */
import DragFileUpload from '../DragFileUpload/DragFileUpload';
import UploadedFileCard from '../../molecules/UploadedFileCard/UploadedFileCardv2';
import Modal from '../../molecules/Modals/Modal/Modal';
import {
  DraggedFileShape,
  UploadQueueStateShape,
  UploadedFileCategories,
  UploadActionsShape,
  UploadIdentifiersShape,
} from '@rabbit/elements/shared-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UserUploadedDocument } from '@rabbit/data/types';
import InputSelectFile from '../../atoms/inputs/InputSelectFile/InputSelectFile';
import { t } from 'i18next';

export interface UploadFilesFormV2Props {
  label?: string;
  labelClasses?: string;
  accepts?: string[];
  identifiers: UploadIdentifiersShape;
  currentFiles?: UserUploadedDocument[];
  isUpdating: boolean;
  queue: UploadQueueStateShape;
  actions: UploadActionsShape;
  onUploadCompleted?: (...args: any) => void;
  name?: string;
  onDeleteFile?: (...args: any) => void;
  canDeleteFile?: boolean;
  maxFiles?: number;
  disabled?: boolean;
  formikProps?: {
    fieldValue: UserUploadedDocument[];
    fieldMeta: any; // todo type
    setFieldValue: (name: string, value: UserUploadedDocument[]) => void;
    setFieldTouched: (name: string, isTouched: boolean) => void;
    setErrors: (...args: any) => void; // todo: type if and when actually used
  };
}

export function UploadFilesFormv2(props: UploadFilesFormV2Props) {
  const {
    label = t('Proof of purchase'),
    labelClasses,
    accepts = ['image/*', '.pdf'],
    currentFiles = [],
    isUpdating,
    queue,
    actions,
    identifiers: { personaId, docType, category },
    onDeleteFile,
    canDeleteFile = true,
    onUploadCompleted,
    name,
    disabled,
    maxFiles,
    formikProps,
  } = props;

  const { fieldValue, setFieldValue, setFieldTouched, setErrors, fieldMeta } =
    formikProps || {};

  const usesFormik =
    fieldValue && setFieldTouched && setFieldValue && setErrors && name;

  const { docid: docId, type } = docType || {};
  const { ongoing, completed, attached } = queue;

  const { uploadFiles, deleteFile, alterCaseFacts, setShouldRefetch } =
    actions || {};
  const [isUploading, setUploading] = useState(false);
  const [showDragDropUploadError, setShowDragDropUploadError] = useState(true);

  const completedFilesOfCategory = completed.filter(
    (upload) => upload.category === category
  );

  const attachedFilesOfCategory = attached.filter(
    (upload) => upload.category === category
  );

  const displayUploader = maxFiles
    ? currentFiles.length +
        completedFilesOfCategory.length +
        attachedFilesOfCategory.length <
      maxFiles
    : true;
  const handleUploadFiles = async (files: File[]) => {
    if (!uploadFiles || !category || !type || !docId)
      throw new Error('Missing required data for upload');
    try {
      await uploadFiles(files, personaId, category, {
        type: type,
        docid: docId,
      });

      toast.success(t(`File uploaded successfully!`));

      setUploading(true);
      setShowDragDropUploadError(false);
    } catch (e) {
      setUploading(false);
      setShowDragDropUploadError(true);
    } finally {
      setUploading(false);
    }
  };

  const handleFileEvent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDragDropUploadError(false);
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    await handleUploadFiles(chosenFiles);
    usesFormik && void setFieldTouched(name, true);
  };

  const handleDragFileEvent = async (filesArr: DraggedFileShape[]) => {
    setShowDragDropUploadError(false);
    const chosenFiles = Array.prototype.slice.call(filesArr);
    await handleUploadFiles(chosenFiles);
    usesFormik && void setFieldTouched(name, true);
  };

  const [deleteModal, setDeleteModal] = useState<{
    open: boolean;
    path: string;
    name?: string;
    uploadCategory?: UploadedFileCategories;
    docid?: string;
  }>({
    open: false,
    path: '',
    name: undefined,
    uploadCategory: undefined,
    docid: undefined,
  });

  const handleDeleteFile = async (
    fullPath: string,
    name: string,
    uploadCategory: UploadedFileCategories,
    docid: string
  ) => {
    setDeleteModal({ open: true, path: fullPath, name, uploadCategory, docid });
    setShowDragDropUploadError(false);
  };

  const modalSettings = {
    text: t(`Are you sure you want to delete`) + `${deleteModal.name}?`,
    primaryButtonText: t('Yes'),
    outlineButtonText: t('No, cancel'),
    loading: isUpdating,
    handlePrimaryClick: async () => {
      const { path, uploadCategory, docid } = deleteModal;
      if (!deleteFile || !uploadCategory) return;
      setDeleteModal({
        open: false,
        path: '',
        uploadCategory: undefined,
        docid: undefined,
      });

      try {
        await deleteFile(path, {
          category: uploadCategory,
          docType,
          alterCaseFacts,
          currentFiles,
        });

        if (fieldValue && fieldValue.length > 0) {
          // find the file in the field value and remove it
          const newFieldValues = fieldValue.filter(
            (file: UserUploadedDocument) => file.metadata.fullPath !== path
          );

          usesFormik && void setFieldValue(name, newFieldValues);
        }

        onDeleteFile?.();
        setShouldRefetch?.(true);
        toast.success(t('File deleted successfully'));
      } catch (err: any) {
        console.log(err);
        if (err.message.includes('(storage/object-not-found)')) {
          // It was technically already deleted, so we can just remove it from the UI
          // and say it was a success
          if (fieldValue && fieldValue.length > 0) {
            // find the file in the field value and remove it
            const newFieldValues = fieldValue.filter(
              (file: UserUploadedDocument) => file.metadata.fullPath !== path
            );

            usesFormik && void setFieldValue(name, newFieldValues);
          }

          toast.success(t('File deleted successfully'));
        } else {
          toast.error(t("Couldn't delete file, please try again later."));
        }
      }
    },
    handleOutlineClick: () => setDeleteModal({ open: false, path: '' }),
  };

  // Might delete this in the future if it's not used at all. - dc
  useEffect(() => {
    if (onUploadCompleted) onUploadCompleted(completedFilesOfCategory);
  }, [completedFilesOfCategory.length]);

  useEffect(() => {
    if (!usesFormik) return;
    if (completedFilesOfCategory.length === 0) return;
    const uploadedDocs = completedFilesOfCategory
      .map((upload) => upload.uploadedFile)
      .filter(Boolean) as UserUploadedDocument[];

    usesFormik && void setFieldValue(name, [...fieldValue, ...uploadedDocs]);
  }, [completedFilesOfCategory.length]);

  return (
    <div className="font-nunito mt-4">
      <div className="flex flex-col gap-3">
        {label && (
          <label
            className={`${
              labelClasses
                ? labelClasses
                : 'text-base font-medium text-gray-900'
            }
                 ${fieldMeta?.error ? 'text-red-500' : ''}
                `}
          >
            {label}
          </label>
        )}
        {currentFiles &&
          Array.isArray(currentFiles) &&
          currentFiles?.map((item: UserUploadedDocument, i) => {
            return (
              <div className="" key={item.url + i}>
                <UploadedFileCard
                  data={item}
                  kind="existing"
                  onDeleteFile={canDeleteFile ? handleDeleteFile : undefined}
                  docid={docId ?? ''}
                  uploadCategory={category}
                />
              </div>
            );
          })}
        {attachedFilesOfCategory
          .filter(
            (upload) =>
              !currentFiles.some(
                (file) => file.url === upload.uploadedFile?.url
              )
          )
          .map((upload, i) => {
            return (
              <div className="mb-5" key={upload.key + i}>
                <UploadedFileCard
                  kind="completed"
                  data={upload}
                  onDeleteFile={handleDeleteFile}
                  docid={docId ?? ''}
                  uploadCategory={category}
                />
              </div>
            );
          })}
        {completedFilesOfCategory
          .filter(
            (upload) =>
              !currentFiles.some(
                (file) => file.url === upload.uploadedFile?.url
              )
          )
          .map((upload, i) => {
            return (
              <div className="mb-5" key={upload.key + i}>
                <UploadedFileCard
                  kind="completed"
                  data={upload}
                  onDeleteFile={handleDeleteFile}
                  docid={docId ?? ''}
                  uploadCategory={category}
                />
              </div>
            );
          })}
        {ongoing.map((upload, i) => {
          if (upload.category === category)
            return (
              <div className="mb-5" key={upload.key + i}>
                <UploadedFileCard
                  kind="ongoing"
                  data={upload}
                  docid={docId ?? ''}
                  uploadCategory={category}
                />
              </div>
            );
          else return null;
        })}
        <div>
          <div className="md:hidden">
            <InputSelectFile
              name={name}
              formikProps={formikProps}
              settings={{ accepts }}
              onChange={handleFileEvent}
              disabled={isUploading || isUpdating || disabled}
            />
          </div>
          {displayUploader && (
            <div className="hidden md:block">
              <DragFileUpload
                className={`${fieldMeta?.error ? 'border-red-500' : ''}`}
                accepts={accepts}
                label={'Upload ' + label.toLowerCase()}
                showError={showDragDropUploadError}
                loading={(isUploading || isUpdating) && !deleteModal.open}
                onChange={handleDragFileEvent}
                disabled={isUploading || isUpdating || disabled}
              />
            </div>
          )}
        </div>
      </div>
      {deleteModal.open && <Modal kind={'pop-up'} settings={modalSettings} />}
    </div>
  );
}

export default UploadFilesFormv2;
