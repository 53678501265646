import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import ChevronButton from '../../atoms/buttons/ChevronButton/ChevronButton';

export interface CardWrapperWithHeaderProps {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  headerRight?: ReactNode;
  noPadding?: boolean;
  extraStyles?: string;
  hasUpdate?: boolean;
  canCollapse?: boolean;
  collapsedByDefault?: boolean;
  smaller?: boolean;
  goBack?: () => void;
  canOverflow?: boolean;
}

const dynamicStyles = {
  base: 'rounded-md border border-gray-300 bg-white',
  header:
    'bg-gray-100 p-5 flex justify-between font-caudex overflow-hidden rounded-t-md',
  title: 'font-bold text-xl',
  subtitle: 'mt-2.5',
  children: 'p-5 grow',
  noPadding: 'w-full grow',
};

export function CardWrapperWithHeader({
  title,
  subtitle,
  headerRight,
  children,
  noPadding,
  extraStyles,
  hasUpdate,
  canCollapse = false,
  canOverflow = false,
  collapsedByDefault = false,
  smaller,
  goBack,
}: CardWrapperWithHeaderProps) {
  const [collapsed, setCollapsed] = useState(collapsedByDefault);

  useEffect(() => {
    setCollapsed(collapsedByDefault);
  }, [collapsedByDefault]);

  return (
    <div className={classNames(`${dynamicStyles.base} ${extraStyles ?? ''}`)}>
      <div
        className={classNames(dynamicStyles.header, {
          [`cursor-pointer`]: canCollapse,
          [`p-4`]: smaller,
          [`!overflow-visible`]: canOverflow,
        })}
        onClick={() => canCollapse && setCollapsed(!collapsed)}
      >
        <div className="flex items-center gap-4">
          {goBack && (
            <ChevronButton
              className="flex h-full cursor-pointer flex-wrap content-center"
              kind="back"
              onClick={goBack}
            />
          )}
          <div>
            <div
              className={classNames(dynamicStyles.title, {
                [`!text-lg`]: smaller,
              })}
            >
              {title}
            </div>
            {subtitle && (
              <div className={dynamicStyles.subtitle}>{subtitle}</div>
            )}
          </div>
          {hasUpdate && (
            <div className="h-[12px] w-[12px] rounded-full bg-red-500"></div>
          )}
        </div>
        {headerRight && <div>{headerRight}</div>}
        {canCollapse && (
          <div className="flex items-center">
            {' '}
            {collapsed ? (
              <ChevronDownIcon className="h-5 w-5 text-black" />
            ) : (
              <ChevronUpIcon className="h-5 w-5 text-black" />
            )}
          </div>
        )}
      </div>
      {!collapsed &&
        (noPadding === true ? (
          <div className={dynamicStyles.noPadding}>{children}</div>
        ) : (
          <div className={dynamicStyles.children}>{children}</div>
        ))}
    </div>
  );
}

export default CardWrapperWithHeader;
