import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { DTVendable, DTVendable_Private } from '../lib/vendable';
import { ShopifyCategory } from './shopify-vendable';

export interface ShopifyVendableExportVersionHistory {
  version: number;
  public: Partial<DTVendable>;
  private: Partial<DTVendable_Private>;
  shouldExport: boolean;
  tcreate: number;
  tupdate: number;
}

/* -------------------------------------------------------------------------- */
/*                       Shopify Vendable Export Schema                       */
/* -------------------------------------------------------------------------- */

export interface DTShopifyVendable_Export extends NoSqlDoc {
  /**
   * The Shopify shop URI, which serves as the unique identifier for the shop. E.g bananamart.myshopify.com
   */
  shopLink: string;

  /* DTShopifyVendable link */
  ogVendableLink: string;

  /* Has the product been deprecated, aka removed from the store? */
  deprecated: boolean;

  /* The version number for this vendable. Starts at 1 */
  version: number;

  /* As determined by the Shopify-based Tenant user. Starts as false. */
  shouldExport: boolean;

  /* The category this product belongs to, according to the Shopify taxonomy */
  category: ShopifyCategory | null; // I think I'll likely move or remove this soon - dc

  /* The exported fields for the public vendable document on Rabbit */
  public: Partial<DTVendable>;

  /* The exported fields for the private vendable document on Rabbit */
  private: Partial<DTVendable_Private>;

  /* The version history for this vendable */
  versionHistory?: ShopifyVendableExportVersionHistory[];
}

export const FBD_ShopifyVendable_Export =
  MakeFBDocType<DTShopifyVendable_Export>({
    name: 'Shopify_Vendable_Export',
    collection: 'shopify_vendable_export',
    empty: () => {
      const result: DTShopifyVendable_Export = {
        ...Empty_NoSqlDoc(),
        shopLink: '',
        public: {} as DTVendable,
        private: {} as DTVendable_Private,
        category: null,
        deprecated: false,
        version: 1,
        shouldExport: false,
        ogVendableLink: '',
      };
      return result;
    },
    keygen: FBD_Keygen_BANNED(
      'ShopifyVendable_Export key should be SHOPNAME_SHOPIFYPRODUCTID_SHOPIFYVARIATIONID'
    ),
  });
