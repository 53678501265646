import { DRDocRegistry, DRDocType } from '@rabbit/firebase/react';
import { Unsubscribe } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { NoSqlDoc } from '@rabbit/firebase/doctype';
import { AtomBucket } from '../atom-bucket';

import {
  EditingInstanceConfig,
  EditingInstanceHook,
  PortalDocumentEditorError,
  PortalDocumentEditorErrorClass,
  PortalEditingDocumentEntry,
  PortalEditingDocumentStatus,
} from './types';

const EditingAtomBucket = new AtomBucket<PortalEditingDocumentEntry>({
  prefix: 'P_Edit_',
  postLoad: () => {
    void CleanAtomBucket();
  },
});

async function CleanAtomBucket() {
  // Go thru the atom bucket and delete anything that is old
  const keys = Object.keys(EditingAtomBucket.catalog);
  const loaded: { [key: string]: PortalEditingDocumentEntry } = {};

  const MAX_KEYS_TO_KEEP = 2000; // arbitrary but you shouldnt be editing that many documents
  if (keys.length < MAX_KEYS_TO_KEEP) return;

  for (let n = 0; n < keys.length; n++) {
    const key = keys[n];
    const entry = await EditingAtomBucket.load(key);
    if (entry) {
      loaded[key] = entry;
    }
  }

  // Sort keys by lastTouched ascending
  const sortedKeys = Object.keys(loaded).sort((a, b) => {
    const aEntry = loaded[a];
    const bEntry = loaded[b];
    return aEntry.lastTouched - bEntry.lastTouched;
  });

  // Delete all but the last MAX_KEYS_TO_KEEP
  for (let n = 0; n < sortedKeys.length - MAX_KEYS_TO_KEEP; n++) {
    await EditingAtomBucket.delete(sortedKeys[n]);
    // console.log(
    //   'DELETE ATOMBUCKET ITEM:',
    //   sortedKeys[n],
    //   loaded[sortedKeys[n]].lastTouched
    // );
  }
}

function makeNewEntry<DOCTYPE extends NoSqlDoc>(
  type: DRDocType<DOCTYPE>,
  docid: string
): PortalEditingDocumentEntry<DOCTYPE> {
  return {
    cloudsaved: false,
    lastTouched: Date.now(),
    local: {
      ...(type.empty() as any),
      docid,
    },
    status: PortalEditingDocumentStatus.preparing,
    docid,
    error: null,
    version: 2,
  };
}

/** An instance of editing a Firebase document. Only one instance per document for the whole application.
 * This rule means we can have multiple forms editing a document but they will all talk to one editor.
 */
class EditingInstance<DOCTYPE extends NoSqlDoc> {
  type: DRDocType<DOCTYPE>;
  docid: string;
  allowCreate = false;

  instanceKey: string;

  subscriptions: { [key: string]: EditingInstanceConfig<DOCTYPE> } = {};
  nextSubscriptionID = 1;

  entry: PortalEditingDocumentEntry<DOCTYPE>;

  created = false;

  firebaseUnsubscribe?: Unsubscribe;

  constructor(instanceKey: string, config: EditingInstanceConfig<DOCTYPE>) {
    // console.log('Creating editing instance', instanceKey);
    this.allowCreate = config.allowCreate === true;
    this.instanceKey = instanceKey;
    const TAK = instanceKeyToTypeAndKey<DOCTYPE>(instanceKey);
    this.type = TAK.type;
    this.docid = TAK.docid;
    this.entry = makeNewEntry<DOCTYPE>(TAK.type, this.docid);

    // console.log('Calling create', instanceKey);
    void this.Create(); // Async function is kicked off but we don't care about waiting for it.
  }

  /* -------------------------------------------------------------------------- */
  /*                           Messages to Subscribers                          */
  /* -------------------------------------------------------------------------- */

  async InformSubscriber(subscriptionID: number) {
    if (!this.created) return; // We won't inform subscribers until we have created the instance
    // wait a frame
    await new Promise((resolve) => setTimeout(resolve, 0));
    const callbacks = this.subscriptions[subscriptionID];
    if (callbacks === undefined) return; // Subscription has been cancelled
    callbacks.entryChanged(this.entry);
  }

  InformAllSubscribers() {
    Object.keys(this.subscriptions).forEach((key) =>
      this.subscriptions[key].entryChanged(this.entry)
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                              Persistor Saving                              */
  /* -------------------------------------------------------------------------- */

  /** Rough n Ready Debounced save to persistor, I'm sure a debounce library could go here but don't have a fave at the mo */
  isSaving = false;
  needsSaving = false;
  async saveToPersistor() {
    this.needsSaving = true;
    if (this.isSaving) {
      return;
    }
    // Note that we are saving
    this.isSaving = true;
    // perform the write. needsSaving lets another save operation come in during the async save.
    if (!this.needsSaving) return;
    // console.log('Saving to persistor', this.instanceKey);
    this.needsSaving = false;
    await EditingAtomBucket.save(this.instanceKey, this.entry);
    this.isSaving = false;
    // Check if someone else has updated in the meantime
    if (this.needsSaving) {
      await this.saveToPersistor(); // run it again
    }
  }

  /** Set the entry for this editing instance. Route everything through here instead of updating entry and
   * trying to remember to save and inform subscribers, it's much easier.
   */
  async setEntry(entry: PortalEditingDocumentEntry<DOCTYPE>) {
    this.entry = {
      ...entry,
      lastTouched: Date.now(),
    };
    this.InformAllSubscribers();
    await this.saveToPersistor();
  }

  async setError(
    errorClass: PortalDocumentEditorErrorClass,
    errorDetail: string,
    humanmessage?: string | null,
    fullmessage?: string | null
  ) {
    const theFullMessage = fullmessage ?? `${errorClass} :: ${errorDetail}`;
    const theHumanMessage = humanmessage ?? theFullMessage;
    const error: PortalDocumentEditorError = {
      class: errorClass,
      detail: errorDetail,
      humanmessage: theHumanMessage,
      fullmessage: theFullMessage,
    };
    await this.setEntry({
      ...this.entry,
      status: PortalEditingDocumentStatus.error,
      error,
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                                Subscriptions                               */
  /* -------------------------------------------------------------------------- */

  /** Open a single subscription to this editing instance */
  subscribe(
    config: EditingInstanceConfig<DOCTYPE>
  ): EditingInstanceHook<DOCTYPE> {
    // Subscribe to the instance
    const subscriptionID = this.nextSubscriptionID++;
    this.subscriptions[subscriptionID] = config;
    void this.InformSubscriber(subscriptionID); // Async function triggered but we don't care about waiting for it.
    return {
      unsubscribe: async () => {
        // Unsubscribe
        delete this.subscriptions[subscriptionID];

        if (Object.keys(this.subscriptions).length !== 0) return;

        // Wait a second, in case any new subscriptions are created
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Check if no more subscriptions then self destruct
        if (Object.keys(this.subscriptions).length !== 0) return;
        // console.log('Deleting editing instance', this.instanceKey);
        delete editingInstances[this.instanceKey];

        await this.Shutdown(); // Hopefully still works if object hasn't been deleted properly
      },
      update: async (newBody: DOCTYPE) => {
        await this.Update(newBody);
      },
      commit: async () => {
        return await this.Commit();
      },
    };
  }

  /* -------------------------------------------------------------------------- */
  /*                             Create and Shutdown                            */
  /* -------------------------------------------------------------------------- */

  /** Create is called when the instance is first created, ie not called for subsequent attachments */
  async Create() {
    try {
      if (this.docid === '') {
        await this.setError(
          'CREATION',
          'docid cannot be empty string - use MakeNewDocumentID() to create a new document'
        );
        return;
      }

      // See if we are already in the persistor
      const entry = await EditingAtomBucket.load(this.instanceKey);
      if (
        entry !== null &&
        entry.version === 2 &&
        entry.status !== PortalEditingDocumentStatus.error
      ) {
        this.entry = entry;
      } else {
        await EditingAtomBucket.save(this.instanceKey, this.entry);
      }
      this.created = true;

      // Subscribe to Firebase
      this.firebaseUnsubscribe = onSnapshot(
        this.type.doc(this.docid),
        async (snapshot) => {
          if (!snapshot.exists()) {
            // we are in new document mode
            // Check if new docs are allowed

            if (!this.allowCreate) {
              // We are not allowed to create new documents
              await this.setError(
                'CREATION',
                'Document does not exist and creating is not enabled'
              );
              return;
            }

            await this.setEntry({
              ...this.entry,
              status: PortalEditingDocumentStatus.new,
            });
            return;
          }

          // Verify that the docid is set properly
          console.log('Verifying docid', this.docid, snapshot.ref.path);
          const newData = { ...snapshot.data() } as DOCTYPE;
          if (newData.docid !== this.docid) {
            console.log('Fixing docid', this.docid, snapshot.ref.path);
            newData.docid = this.docid;
          }

          // check if we are different
          if (this.entry.firebase) {
            if (
              JSON.stringify(this.entry.firebase) === JSON.stringify(newData)
            ) {
              return;
            }
          }

          // TODO: We need to merge this data into the local copy.
          // For now we just overwrite it

          await this.setEntry({
            ...this.entry,
            firebase: newData,
            local: newData,
            status: PortalEditingDocumentStatus.saved,
            cloudsaved: true,
          });
        },
        (error) => {
          console.error(
            'Firebase error: ',
            this.type.collectionName,
            this.docid,
            error
          );
        }
      );
      // }

      // Tell all our subscriptions that the entry has changed
      this.InformAllSubscribers();
    } catch (e) {
      console.error('Error creating editing instance', e);
      void this.setError('FIREBASE', (e as any).toString());
      throw e;
    }
  }

  /** Called when no one else is using this editing instance */
  async Shutdown() {
    this.firebaseUnsubscribe?.();
    if (this.needsSaving) {
      this.needsSaving = false;
      await EditingAtomBucket.save(this.instanceKey, this.entry);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                 Alterations                                */
  /* -------------------------------------------------------------------------- */

  async Update(newBody: DOCTYPE) {
    const checkedBody = { ...newBody };
    if (checkedBody.docid !== this.docid) {
      checkedBody.docid = this.docid;
    }

    await this.setEntry({
      ...this.entry,
      local: checkedBody,
      status: PortalEditingDocumentStatus.edited,
    });
  }

  async Commit() {
    if (this.entry.local.docid !== this.docid) {
      this.entry.local.docid = this.docid;
    }
    // TODO: Consider skipping commit if we haven't changed anything
    await this.type.set(this.entry.local);
  }
}

const editingInstances: { [instanceKey: string]: EditingInstance<any> } = {};

/** Attach to an editing instance, creating it if necessary.
 * We need to take a bit of care of the async nature of this function,
 * because it is possible two editing instances may be created at the same time.
 *
 * We will return straight away with the unsubscription method, then the instance will
 * asynchronously create and initialise itself.
 */
export function AttachEditingInstance<TYPE extends NoSqlDoc>(
  type: DRDocType<TYPE>,
  docid: string,
  config: EditingInstanceConfig<TYPE>
): EditingInstanceHook<TYPE> {
  const instanceKey = `${type.collectionName}.${docid}`;
  if (!editingInstances[instanceKey]) {
    // Create it
    editingInstances[instanceKey] = new EditingInstance(instanceKey, config);
  }

  // Subscribe to the instance
  return editingInstances[instanceKey].subscribe(config);
}

function instanceKeyToTypeAndKey<DOCTYPE extends NoSqlDoc = any>(
  instanceKey: string
) {
  const parts = instanceKey.split('.');
  // Only the first . is a separator. Subsequent ones must be included in the key.
  const docid = parts.slice(1).join('.');
  const docType = DRDocRegistry.Get(parts[0]) as DRDocType<DOCTYPE>;
  if (!docType)
    throw new Error(
      'Cannot find doc type: ' + parts[0] + ' for instanceKey: ' + instanceKey
    );
  return { type: docType, docid: docid };
}
