import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { DateTime } from '../base/basic';
import {
  DTVendable,
  DTVendable_Private,
  Z_Vendable,
  Z_Vendable_Private,
} from './vendable';
import { ManufacturerLink } from '../persona';
import { z } from 'zod';

/* -------------------------------------------------------------------------- */
/*                           Vendable History Public                          */
/* -------------------------------------------------------------------------- */

export interface VendableHistoryBasic {
  version: number;
  tupdate: DateTime;
  updatedBy: string;
}

export interface VendablePublicHistoryItem extends VendableHistoryBasic {
  content: DTVendable;
}

export interface VendablePrivateHistoryItem extends VendableHistoryBasic {
  content: DTVendable_Private;
}

/** Version history for vendable public documents. */
export interface DTVendable_History_Public extends NoSqlDoc {
  /** The current version number */
  version: number;

  /** Who last updated this vendable. TenantLink or PersonaLink */
  updatedBy: string;

  /** The manufacturer of the vendable */
  mfr: ManufacturerLink;

  /** Array of the history for the public vendable document */
  history: VendablePublicHistoryItem[];

  /** Is this vendable active? I.e, is it available for search on Sage? */
  published: boolean;
}

export const FBD_Vendable_History_Public =
  MakeFBDocType<DTVendable_History_Public>({
    name: 'Vendable History Public',
    collection: 'vendable_history_public',
    empty: () => {
      const result: DTVendable_History_Public = {
        ...Empty_NoSqlDoc(),
        version: 1,
        history: [],
        updatedBy: '', // 'tenantLink' or 'personaLink
        mfr: '', // 'manufacturerLink
        published: false, // needs to be activated manually by the manufacturer
      };
      return result;
    },
    keygen: FBD_Keygen_BANNED(
      'docid should be set to that of the related vendable.'
    ),
  });

export const Z_Vendable_History_Public = z.object({
  version: z.number(),
  updatedBy: z.string(),
  mfr: z.string(),
  history: z.array(
    z.object({
      version: z.number(),
      tupdate: z.number(),
      updatedBy: z.string(),
      content: Z_Vendable,
    })
  ),
  published: z.boolean(),
});

/* -------------------------------------------------------------------------- */
/*                          Vendable History Private                          */
/* -------------------------------------------------------------------------- */

/** Version history for vendable private documents. */
export interface DTVendable_History_Private extends NoSqlDoc {
  /** The current version number */
  version: number;

  /** Who last updated this vendable. TenantLink or PersonaLink */
  updatedBy: string;

  /** The manufacturer of the vendable */
  mfr: ManufacturerLink;

  /** Array of the history for the private vendable document */
  history: VendablePrivateHistoryItem[];

  /** Is this vendable active? I.e, is it available for search on Sage? */
  published: boolean;
}

export const FBD_Vendable_History_Private =
  MakeFBDocType<DTVendable_History_Private>({
    name: 'Vendable History Private',
    collection: 'vendable_history_private',
    empty: () => {
      const result: DTVendable_History_Private = {
        ...Empty_NoSqlDoc(),
        version: 1,
        history: [],
        updatedBy: '', // 'tenantLink' or 'personaLink
        mfr: '', // 'manufacturerLink
        published: false, // needs to be activated manually by the manufacturer
      };
      return result;
    },
    keygen: FBD_Keygen_BANNED(
      'docid should be set to that of the related vendable.'
    ),
  });

export const Z_Vendable_History_Private = z.object({
  version: z.number(),
  updatedBy: z.string(),
  mfr: z.string(),
  history: z.array(
    z.object({
      version: z.number(),
      tupdate: z.number(),
      updatedBy: z.string(),
      content: Z_Vendable_Private,
    })
  ),
  published: z.boolean(),
});

export interface VendableHistoryFull {
  pub: DTVendable_History_Public;
  priv: DTVendable_History_Private;
}

export const Z_Vendable_History_Full = z.object({
  pub: Z_Vendable_History_Public,
  priv: Z_Vendable_History_Private,
});
