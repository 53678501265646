import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
  Toggle,
} from '@rabbit/elements/shared-components';
import { useContext, useState } from 'react';
import * as Yup from 'yup';
import {
  COVERAGE_GOODWILL_NONE_OPTIONS,
  COVERAGE_GOODWILL_PARTIAL_OPTIONS,
  COVERAGE_OPTIONS,
  useManageFaults,
} from '@rabbit/bizproc/react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import { generateFaultOptions } from '@rabbit/sage/utils/helpers';

export interface DetermineCoverageModalProps {}

export interface FormValuesShape {
  warr_assessment_initial: string;
  internal_comment: string;
  goodwill_warranty_initial: string;
  holding_faults: string[] | null;
}

const initialValues: FormValuesShape = {
  warr_assessment_initial: '',
  internal_comment: '',
  goodwill_warranty_initial: '',
  holding_faults: null,
};

const validationSchema = Yup.object().shape({
  warr_assessment_initial: Yup.string()
    .trim()
    .required('Please select an assessment'),
  internal_comment: Yup.string().trim(),
  goodwill_warranty_initial: Yup.string().trim(),
  holding_faults: Yup.array()
    .typeError('Please select one or more faults')
    .of(Yup.string().trim())
    .min(1, 'Please select one or more faults')
    .required('Please select one or more faults'),
});

export function DetermineCoverageModal({}: DetermineCoverageModalProps) {
  const [goodwillOverride, setGoodwillOverride] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);
  const { faultList } = useManageFaults();
  const faultOptions = generateFaultOptions(faultList?.data);

  const {
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !faultList?.data
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const {
      internal_comment,
      warr_assessment_initial,
      goodwill_warranty_initial,
      holding_faults,
    } = values;

    setIsSubmitting(true);
    await moveSpotlight(operatingPersonaSingleKey);
    executeAction('preliminary_assessment');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        holding_faults: holding_faults ?? [],
        preliminary_assessment: warr_assessment_initial,
        goodwill_override_initial: goodwillOverride,
        goodwill_warranty_initial: goodwill_warranty_initial,
      };
      if (Object.keys(factsToAlter).length > 0)
        await alterCaseFacts(factsToAlter);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      setShowModal(false);
    }
  };

  return (
    <Modal
      settings={{
        title: 'Preliminary assessment',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values, touched }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="select"
              name="warr_assessment_initial"
              label="Warranty assessment*"
              settings={{
                id: 'warr_assessment_initial',
                placeholder: 'Please select an option',
                options: COVERAGE_OPTIONS,
                errors: errors,
                hint: '*required',
              }}
            />
            {(values.warr_assessment_initial === 'Partially covered' ||
              values.warr_assessment_initial === 'Not covered') && (
              <Toggle
                checked={false}
                onChange={() => setGoodwillOverride(!goodwillOverride)}
                label="Goodwill override"
                //disabled={values.warr_assessment === 'Full' || values.warr_assessment ===  'Unknown'}
              />
            )}
            {goodwillOverride && (
              <Input
                type="select"
                name="goodwill_warranty_initial"
                label="Goodwill warranty assessment*"
                settings={{
                  id: 'goodwill_warranty_initial',
                  placeholder: 'Coverage',
                  options:
                    values.warr_assessment_initial === 'Partially covered'
                      ? COVERAGE_GOODWILL_PARTIAL_OPTIONS
                      : COVERAGE_GOODWILL_NONE_OPTIONS,
                  errors: errors,
                  hint: '*required',
                }}
              />
            )}
            <Input
              type="select"
              name="holding_faults"
              label="Faults*"
              settings={{
                id: 'holding_faults',
                options: faultOptions,
                isMulti: true,
                placeholder: faultList?.data ? 'Select...' : 'Loading...',
                disabled: !faultList?.data,
                hint: '*required',
              }}
            />
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  Add internal comment
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label="Internal comment"
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button
                kind="primary"
                type="submit"
                loading={isSubmitting}
                // disabled={
                //   Object.keys(errors).length > 0 || Object.keys(touched).length === 0
                // }
              >
                Continue
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default DetermineCoverageModal;
