import { InformationCircleIcon } from '@heroicons/react/24/solid';

export interface InfoTextProps {
  children: React.ReactNode;
  containerStyles?: string;
  iconStyles?: string;
  textStyles?: string;
}

export function InfoText({
  children,
  containerStyles = 'mb-4 flex gap-1 text-gray-500',
  iconStyles = 'h-[20px] w-[20px]',
  textStyles = 'italic',
}: InfoTextProps) {
  return (
    <div className={containerStyles}>
      <InformationCircleIcon className={iconStyles} />
      <div className={textStyles}>{children}</div>
    </div>
  );
}

export default InfoText;
