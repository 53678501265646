/* A list of types and interfaces for caseflow facts, stations, etc.
 *
 * Not currently used for the most part, but listing them here both provides documentation for the
 * actual shape of the data used in facts and allows them to be used right away once support for them is added.
 *
 * The CFCF prefix stands for CaseFlow Case Fact.
 * So things don't end up all over the place when it comes to casing and naming conventions again, I'm now deciding that all CFCF types
 * will have their object keys using snake_case, while the interfaces themselves are in CamelCase save for the prefix.
 *
 * A WIP. Some of these might end up being common to all caseflow configurations, and should be moved to a shared location.
 */

import {
  ManufacturerLink,
  Money,
  PersonaLink,
  UserUploadedDocument,
} from '@rabbit/data/types';

/* -------------------------------------------------------------------------- */
/*                                  Stations                                  */
/* -------------------------------------------------------------------------- */

export enum CaseFlowStations_Lite {
  BIRTH = '_birth',
  PRELIMINARY_ASSESSMENT = 'preliminary_assessment',
  INITIALLY_ASSESSED = 'initially_assessed',
  IN_REPAIR = 'in_repair',
  REPAIRED = 'repaired',
  CLOSED = 'closed',
}
