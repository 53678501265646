import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                       Fetch consumer private document                      */
/* -------------------------------------------------------------------------- */

/* -------------------------------- Using id -------------------------------- */

export const ICF_FetchConsumerPrivate_WithId = z.object({
  consumerId: z.string(),
});

export const OCF_FetchConsumerPrivate_WithId = z.object({
  consumer_private: z.any(),
  //   consumer_private: Z_DTConsumer_Private,
});

export const CFC_FetchConsumerPrivate_WithId = MakeCloudFunctionSpec(
  'api/legacy/cfcfetchconsumerprivatewithid',
  ICF_FetchConsumerPrivate_WithId,
  OCF_FetchConsumerPrivate_WithId
);
