import {
  PersonaIdTypeSplitter,
  PersonaLink,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';

export function getRootPersonaFromLexicon(
  commaSeparatedPrincipals: string,
  type: PersonaTypeSingleLetter
): PersonaLink {
  const personas = convertRootPersonasFromLexicon2Array(
    commaSeparatedPrincipals
  ).filter((x: string) => x.startsWith(type + PersonaIdTypeSplitter));
  return personas.length > 0 ? personas[0] : '';
}

export function convertRootPersonasFromLexicon2Array(
  commaSeparatedPrincipals: string
): PersonaLink[] {
  const personas = commaSeparatedPrincipals.split(',');
  return personas;
}
