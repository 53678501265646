import { GenericRegistry } from '@rabbit/utils/generic-registry';
import {
  FBF,
  FBFCollectionRef,
  FBFDocumentRef,
  FBFQuery,
} from '@rabbit/firebase/adapter';
import { SchemaOf, object, string, number } from 'yup';
import { NoSqlDoc } from './nosql-doc';
import {
  FBDT_KeygenFunction,
  FBTreeTraverseDocAndType,
  FBDocType,
} from './types';

export const FBDocRegistry = new GenericRegistry<FBDocType<any>>(
  'firebase_document_types'
);

export function MakeFBDocType<TYPE extends NoSqlDoc>(params: {
  name: string;
  collection: string;
  empty: () => TYPE;
  keygen: FBDT_KeygenFunction<TYPE>;
  validationSchema?: SchemaOf<TYPE>;
}): FBDocType<TYPE> {
  const result: FBDocType<TYPE> = {
    name: params.name,
    collectionName: params.collection,
    empty: params.empty,
    keygen: params.keygen,
    validationSchema: params.validationSchema || null,
    type: params.empty(),
    collection: () => FBF.collection<TYPE>(params.collection),
    doc: (id: string) => FBF.doc<TYPE>(params.collection, id),
    get: async (id: string) => {
      const doc = await FBF.getDoc(result.doc(id));
      return doc.body;
    },
    set: async (body: TYPE) => {
      // Generate a docid if one has not been provided.
      // We do this with the provided keygen function.
      // We need to check docid before validation as validation schemas will reject empty docids.
      if (body.docid === '') {
        const newKey = result.keygen(body, result);
        body.docid = newKey;
      }

      // Validate the document if a validation schema has been provided.
      // Validation is with yup.
      if (params.validationSchema) {
        // console.log('validating', body);
        await params.validationSchema.validate(body, {
          abortEarly: false,
        });
      }

      // Set the update time.
      // TODO: There's a way to do this on the server instead which is more secure.
      body.tupdate = new Date().getTime();

      // Set (or maybe create) the document.
      const doc = result.doc(body.docid);
      await FBF.setDoc(doc, body);
      return body.docid;
    },
    query: () => FBF.query<TYPE>(params.collection),
    delete: async (id) => {
      await FBF.deleteDoc(result.doc(id));
    },

    /* -------------------------------------------------------------------------- */
    /*                                  PolyTree                                  */
    /* -------------------------------------------------------------------------- */

    PolyTreeAddForeignChild: async (
      parent,
      child,
      foreigntype,
      callName = 'PolyTreeAddForeignChild'
    ) => {
      // Get the Parent and Child documents.
      // We reload the documents even if they are provided because we do not trust the client to
      // give us the latest document.

      const parentKey = typeof parent === 'string' ? parent : parent.docid;
      const fullParentKey = result.collectionName + '/' + parentKey;
      const parentDoc = await result.get(parentKey);
      if (!parentDoc) {
        throw new Error(`${callName}: Parent doc not found: ${parent}`);
      }

      const childKey = typeof child === 'string' ? child : child.docid;
      const fullChildKey = foreigntype.collectionName + '/' + childKey;
      const childDoc = await foreigntype.get(childKey);
      if (!childDoc) {
        throw new Error(`${callName}: Child doc not found: ${childDoc}`);
      }

      // Make sure hierarchy arrays exist
      if (!parentDoc.c) {
        parentDoc.c = [];
      }

      if (!childDoc.p) {
        childDoc.p = [];
      }

      // Add the link and save. This isn't carried out if the link already exists.
      if (parentDoc.c.indexOf(fullChildKey) === -1) {
        parentDoc.c.push(fullChildKey);
        await result.set(parentDoc);
      }
      if (childDoc.p.indexOf(fullParentKey) === -1) {
        childDoc.p.push(fullParentKey);
        await foreigntype.set(childDoc);
      }

      // If parent or child was an object, we fill that object with whatever came from the server.
      // This can be considered naughty I guess but either way if the caller uses the object after
      // performing this operation (which they shouldn't) then at least they have some hope of
      // preventing a problem

      if (typeof parent !== 'string') {
        Object.assign(parent, parentDoc);
      }
      if (typeof child !== 'string') {
        Object.assign(child, childDoc);
      }
    },

    /** PolyTree hierarchy: Add a child to a parent */
    PolyTreeAddChild: async (parent, child) => {
      // Aw what a swiz! We are just treating our child like it's foreign anyway!
      return result.PolyTreeAddForeignChild(
        parent,
        child,
        result,
        'PolyTreeAddChild'
      );
    },

    PolyTreeAddParent(child, parent) {
      // In simpler times it was just: return result.PolyTreeAddChild(parent, child);
      return result.PolyTreeAddForeignChild(
        parent,
        child,
        result,
        'PolyTreeAddParent'
      );
    },

    PolyTreeAddForeignParent(child, parent, type) {
      // Wrap your head around this chunk of mental gymnasics
      return type.PolyTreeAddForeignChild(
        parent,
        child,
        result,
        'PolyTreeAddForeignParent'
      );
    },

    PolyTreeGetHomotypicChildren: async (parent) => {
      const parentKey = typeof parent === 'string' ? parent : parent.docid;
      const fullParentKey = result.collectionName + '/' + parentKey;
      const parentDoc = await result.get(parentKey);
      if (!parentDoc) {
        throw new Error(`PolyTreeGetChildren: Parent doc not found: ${parent}`);
      }

      if (!parentDoc.c) {
        return [];
      }

      // Load all children - but only if they are of MY type.
      const children = await Promise.all(
        parentDoc.c
          .map((childKey) => {
            const [collectionName, docid] = childKey.split('/');
            if (collectionName !== result.collectionName) return null;
            return result.get(docid);
          })
          .filter((child) => child !== null)
      );

      const output = children.filter((child) => child !== null) as TYPE[];
      return output;
    },

    PolyTreeTraverse(params): AsyncGenerator<FBTreeTraverseDocAndType> {
      let remaining = params.limit === undefined ? 1000000000 : params.limit;
      const maxDepth = params.maxDepth === undefined ? 1000 : params.maxDepth;
      const downwards = params.downwards ? true : false;

      async function* traverseHelper(): AsyncGenerator<FBTreeTraverseDocAndType> {
        const hits: { [key: string]: 1 } = {};
        const rootKey =
          typeof params.root === 'string' ? params.root : params.root.docid;
        hits[result.collectionName + '/' + rootKey] = 1;
        // Load the document anyway since we don't trust anyone outside of our own universe
        const rootDoc = await result.get(rootKey);
        if (!rootDoc) {
          throw new Error(`PolyTreeTraverse: Root doc not found: ${rootKey}`);
        }

        type LoadQueueEntry = {
          level: number;
          collection: string;
          docid: string;
          relativeType: FBDocType<TYPE>;
          relativeDocId: string;
          relativeDoc: NoSqlDoc;
        };
        const LoadQueue: LoadQueueEntry[] = [];

        function LoadDocToQueue(
          doc: NoSqlDoc,
          curLevel: number,
          doctype: FBDocType<any>
        ) {
          const target = downwards ? doc.c : doc.p;
          if (!target) {
            return;
          }
          target.forEach((childKey) => {
            if (hits[childKey]) return;
            hits[childKey] = 1;

            const [collection, docid] = childKey.split('/');
            if (params.collectionFilter) {
              if (!params.collectionFilter[collection]) {
                return;
              }
            }
            LoadQueue.push({
              relativeDocId: doc.docid,
              relativeType: doctype as any,
              level: curLevel + 1,
              collection,
              docid,
              relativeDoc: doc,
            });
          });
        }
        LoadDocToQueue(rootDoc, 0, result);

        while (LoadQueue.length > 0 && remaining > 0) {
          const curDocEntry = LoadQueue.shift();
          if (!curDocEntry) {
            continue;
          }

          const doctype = FBDocRegistry.Get(curDocEntry.collection);
          const curDoc = await doctype.get(curDocEntry.docid);

          if (!curDoc) {
            throw new Error(
              `PolyTreeTraverse: Traversed doc not found: ${curDocEntry}`
            );
          }

          yield {
            doc: curDoc,
            type: doctype,
            distanceFromRoot: curDocEntry.level,
            relativeType: curDocEntry.relativeType,
            relativeDocId: curDocEntry.relativeDocId,
            relativeDoc: curDocEntry.relativeDoc,
          };

          if (curDocEntry.level < maxDepth) {
            LoadDocToQueue(curDoc, curDocEntry.level, doctype);
          }
          remaining--;
        }
      }
      return traverseHelper();
    },

    PolyTreeIterateChildren(root: TYPE | string): AsyncGenerator<TYPE> {
      async function* processor() {
        for await (const doc of result.PolyTreeTraverse({
          root,
          downwards: true,
          collectionFilter: { [result.collectionName]: 1 },
        })) {
          // Make sure doc is the right type
          if (doc.type === result) {
            yield doc.doc as TYPE;
          }
        }
      }

      return processor();
    },

    PolyTreeIterateParents(root: TYPE | string): AsyncGenerator<TYPE> {
      async function* processor() {
        for await (const doc of result.PolyTreeTraverse({
          root,
          downwards: false,
          collectionFilter: { [result.collectionName]: 1 },
        })) {
          // Make sure doc is the right type
          if (doc.type === result) {
            yield doc.doc;
          }
        }
      }

      return processor();
    },
  };

  FBDocRegistry.Register(result, result.collectionName);

  return result;
}
