import { t } from 'i18next';
import { useState } from 'react';

interface ExpandableTextProps {
  text: string;
  maxLength: number;
}

export function ExpandableText({ text, maxLength }: ExpandableTextProps) {
  const [expanded, setExpanded] = useState(false);

  if (text.length <= maxLength) return <p className="font-medium">{text}</p>;

  return (
    <p className="break-all font-medium">
      {expanded ? text : `${text.substring(0, maxLength)}...`}
      <span
        className="font-nunito text-primary-600 ml-2 cursor-pointer font-medium"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? t('Show less') : t('Show more')}
      </span>
    </p>
  );
}

export default ExpandableText;
