import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
  UploadFilesForm,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { UserUploadedDocument } from '@rabbit/data/types';
import { toast } from 'react-toastify';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { useFileStorage } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';

// export interface InternalCommentModalProps {}

interface FormValuesShape {
  internal_comment: string;
}

const initialValues = {
  internal_comment: '',
};

const validationSchema = Yup.object().shape({
  internal_comment: Yup.string().required('Please enter a message'),
});

export function InternalCommentModal() {
  // {}: InternalCommentModalProps
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedInternalCaseFiles, setUploadedInternalCaseFiles] = useState<
    UserUploadedDocument[]
  >([]);

  const { t } = useTranslation();
  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
    caseFlowCase,
  } = useContext(CaseflowContext) || {};

  const {
    uploadFiles,
    uploadTaskCat,
    isUpdating,
    uploadProgress,
    deleteFile,
    deleteUnusedTempFiles,
    updateHoldingWithFiles,
    uploadedTempFiles,
    clearFileFromHolding,
    clearFileFromState,
    clearAllTempFilesFromState,
  } = useFileStorage();

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId ||
    !caseFlowCase
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    setIsSubmitting(true);
    void alterCaseFacts({
      internal_comment: {
        comment: values.internal_comment,
        author: operatingPersona,
      },
    });
    executeAction('internal_comment_generic');
    setShowModal(false);
    toast.success(t('message.messageSentSuccessfully'));
  };

  const onInternalCommentFilesUploaded = async (
    files: UserUploadedDocument[],
    added = true
  ) => {
    if (files && caseFlowCase) {
      try {
        clearAllTempFilesFromState();
        const parsedFiles = JSON.parse(JSON.stringify(files));
        caseFlowCase.Alter_Facts({
          internal_case_files: parsedFiles?.length ? parsedFiles : [],
        });
        await caseFlowCase.Commit();
        setUploadedInternalCaseFiles(files);
        added && toast.success(t('message.fileUploadedSuccessfully'));
      } catch (error) {
        toast.error(t('message.errorAddingFile'));
      }
    }
  };

  return (
    <Modal
      settings={{
        title: t('message.addInternalComment'),
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <div className="font-nunito">
              <div className="mt-4">
                <Input
                  type="rich-text"
                  label={t('general.internalComment')}
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    placeholder: '',
                    hint: '*required',
                  }}
                />
                <UploadFilesForm
                  label={t('message.uploadFiles')}
                  type="case"
                  docId={caseId ?? ''}
                  personaId={operatingPersona ?? ''}
                  currentFiles={uploadedInternalCaseFiles ?? []}
                  onFilesUploaded={onInternalCommentFilesUploaded}
                  uploadHandlers={{
                    uploadedTempFiles,
                    category: 'internal_case_files',
                    uploadFiles,
                    isUpdating,
                    uploadTaskCat,
                    uploadProgress,
                    deleteFile,
                    updateHoldingWithFiles,
                    clearFileFromHolding,
                    clearFileFromState,
                    deleteUnusedTempFiles,
                  }}
                />
              </div>
            </div>
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                {t('general.addComment')}
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                {t('general.cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default InternalCommentModal;
