import chatSubmitArrow from '../../../assets/icons/chat-submit-arrow.svg';
import imageUploadIcon from '../../../assets/icons/image-upload-icon.svg';

export interface ChatInputWrapperProps {
  handleImgSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  className?: string;
  children: React.ReactNode;
}

export function ChatInputWrapper({
  handleImgSelection,
  handleSubmit,
  className,
  children,
}: ChatInputWrapperProps) {
  return (
    <div className={className}>
      <div className="my-auto w-8">
        <label className="cursor-pointer">
          <img src={imageUploadIcon} alt="Upload an image" />
          <input
            className="hidden"
            type="file"
            name="proof_purchase"
            onChange={handleImgSelection}
          />
        </label>
      </div>
      {children}
      <div onClick={handleSubmit} className="my-auto w-8 cursor-pointer">
        <img src={chatSubmitArrow} alt="Chat arrow" />
      </div>
    </div>
  );
}

export default ChatInputWrapper;
