import { SchemaOf, object, string, number, array } from 'yup';
import { z, schema, OurDateTime } from '@rabbit/utils/ts';
/* -------------------------------------------------------------------------- */
/*                                  NoSqlDoc                                  */
/* -------------------------------------------------------------------------- */
// Basic types for NoSQL Documents

/** The base NoSql document type. */
export interface NoSqlDoc {
  /** ID of the document, so it knows its own identity */
  docid: string;

  /** Timestamp of when the document was created. */
  tcreate: number;

  /** Timestamp of when the document was updated. */
  tupdate: number;

  /** PolyTree array of Parent documents. These are of the form "collectionName/docID" */
  p?: string[];

  /** PolyTree array of Child documents. These are of the form "collectionName/docID" */
  c?: string[];
}

export const Z_NoSqlDoc = schema<NoSqlDoc>()(
  z.object({
    docid: z.string(),
    tcreate: z.number(),
    tupdate: z.number(),
    p: z.array(z.string()).optional(),
    c: z.array(z.string()).optional(),
  })
);

export function Empty_NoSqlDoc(): NoSqlDoc {
  return {
    docid: '',
    tcreate: OurDateTime.nowUTCTimestamp(),
    tupdate: OurDateTime.nowUTCTimestamp(),
  };
}

export const NoSqlDocYupSchema: SchemaOf<NoSqlDoc> = object({
  docid: string().required(),
  tcreate: number().required().positive().integer(),
  tupdate: number().required().positive().integer(),
  p: array().of(string().required()).optional(),
  c: array().of(string().required()).optional(),
});
