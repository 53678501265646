import { CFAGoStation } from '@rabbit/data/types';
import { ActionStepImplementation, ActionStepRegistry } from '../registry';

export const ASI_GoStation: ActionStepImplementation<CFAGoStation> = {
  validate: (location, validator, step) => {
    if (!step.station) {
      validator.AddIssue(location, `No station is defined.`);
    } else {
      validator.ReferenceAStation(location, step.station);
    }
  },
  interior: (cfc, step) => {
    // Empty function - this action interior is handled within the caseflow case action executor
  },
};

ActionStepRegistry.Register(ASI_GoStation, 'go_station');
