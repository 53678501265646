import classNames from 'classnames';
import { formatUnixTime } from '../../../utils/helpers';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export interface ChatMessageProps {
  wasSentByUser: boolean;
  message?: string;
  timestamp: number;
  image?: string;
}

const dynamicStyles = {
  baseContainer:
    'flex flex-col border border-gray-300 rounded-md font-nunito py-2 px-3 my-5 max-w-fit',
  sentByUserContainer: 'bg-gray-50 rounded-br-none ml-auto',
  notSentByUserContainer: 'bg-gray-300 rounded-bl-none mr-auto',
};

export function ChatMessage({
  wasSentByUser,
  message,
  timestamp,
  image,
}: ChatMessageProps) {
  return (
    <div
      className={classNames(`${dynamicStyles.baseContainer}`, {
        [`${dynamicStyles.sentByUserContainer}`]: wasSentByUser,
        [`${dynamicStyles.notSentByUserContainer}`]: !wasSentByUser,
      })}
    >
      <div className="mb-3 break-words text-base"> {message}</div>
      {image && (
        <div className="">
          <img
            src={image}
            alt={'attachment'}
            className="max-h-[150px] max-w-[200px]"
          ></img>
          <a href={image} target="blank">
            <ArrowTopRightOnSquareIcon className="mt-2 h-5 w-5 cursor-pointer" />
          </a>
        </div>
      )}
      <div className="text-right text-sm text-gray-500">
        {formatUnixTime(timestamp, 'h:mmaaa')}
      </div>
    </div>
  );
}

export default ChatMessage;
