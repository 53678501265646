import { CardContent } from '../../atoms/card-pieces/CardContent/CardContent';
import { Heading } from '../../atoms/Heading/Heading';
import { Modal } from '../Modals/Modal/Modal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { HTMLAttributes, useContext, useState } from 'react';
import classNames from 'classnames';

import placeholderImage from '../../assets/images/picture_placeholder.png';
import carImagePlaceholder from '../../assets/images/car-placeholder.jpg';
import {
  AppInfoShape,
  DTHoldingProxy,
  SelfRegistration,
  TableCover,
  VehicleInfo,
} from '@rabbit/data/types';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import { useTranslation } from 'react-i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { calcWarrantyTimeLeft } from '@rabbit/bizproc/react';
import { ConfigContext } from '@rabbit/config/context';
import { useNavigate } from 'react-router-dom';

export interface ProductDataShape {
  prodId: string;
  prodName: string;
  category: string;
  brand: string;
  img: string;
  remainingWarrantyDays: number;
  purchasedFrom: string;
}

export interface RepairDataShape extends ProductDataShape {
  //temporary
  //lastStatusUpdate: Date;
  repairer: string;
  lastStatusUpdate: string;
  repairProgress: string;
  title: string;
  purchase_location: string;
  //repairProgress: number; --- Double-check
}

export interface CardGeneralProps {
  holdingData?: DTHoldingProxy | ConsumerHoldingSummaryShape;
  repairData?: RepairDataShape;
  imageUrl?: string;
  onClick?: () => void;
  appInfo?: AppInfoShape;
  hideRemainingWarranty?: boolean;
}

const dynamicStyles = {
  container:
    'relative flex-grow w-full rounded-md bg-gray-50 inline-flex pr-4 p-[19px_10px_19px_20px] gap-2',
};

export function CardGeneral({
  onClick,
  holdingData,
  repairData,
  imageUrl,
  appInfo,
  hideRemainingWarranty = false,
  ...rest
}: CardGeneralProps & HTMLAttributes<HTMLDivElement>) {
  const [modalWarrantyInfo, setModalWarrantyInfo] = useState(false);
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);
  const CFG_WARRANTY_INFO_URL = t('CFG_WARRANTY_INFO_URL');
  const tenantLink = t('tenantLink');

  const modalSettings = {
    kind: 'generic' as const,
    settings: {
      title: 'Warranty information',
      handleClose: () => setModalWarrantyInfo(false),
    },
  };

  const self_registration = holdingData?.self_registration as SelfRegistration;
  const srvInfo =
    typeof self_registration === 'boolean'
      ? (holdingData as ConsumerHoldingSummaryShape)?.srvInfo
      : self_registration?.srvInfo;
  const productInfo = srvInfo?.productInfo as VehicleInfo;
  const navigate = useNavigate();
  const tableProductInfo = srvInfo?.productInfo as TableCover;

  const warrantyInfo = BL_Warranty.getLatestWarranty(holdingData?.warranties);
  const remainingTime = !hideRemainingWarranty
    ? calcWarrantyTimeLeft(warrantyInfo?.endDate)
    : null;

  const serialNumberRequired =
    !location.pathname.match('details') &&
    config.HOLDINGS.REGISTRATION.SERIAL_NUMBER_REQUIRED &&
    (holdingData?.serial == undefined || holdingData?.serial === '');

  const renderCarDetails = () => {
    return (
      <div className="font-nunito flex flex-col justify-center gap-1">
        <div className="font-bold">
          {productInfo.brand} {productInfo.model} {productInfo.version}{' '}
          {productInfo.yearOfManufacture}
        </div>
        <p className="text-gray-400">
          {BL_Warranty.getVehicleRegistrationNo(productInfo)}
        </p>
      </div>
    );
  };

  const renderStoneDetails = () => {
    return (
      <div className="font-nunito flex flex-col justify-center gap-1">
        <div className="font-bold">{tableProductInfo?.typeofStone}</div>
        <div>
          {((holdingData && holdingData.self_registration) ||
            !appInfo?.name
              .toLowerCase()
              .includes(String(holdingData?.brand)?.toLowerCase())) && (
            <p>{holdingData && getHoldingBrand(holdingData)}</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      {...rest}
      className={classNames(dynamicStyles.container, rest.className)}
    >
      <div className="flex w-full">
        {onClick !== undefined && (
          <div
            className="absolute z-10 h-full w-full cursor-pointer"
            onClick={() =>
              serialNumberRequired && !onClick
                ? navigate(
                    '/products/preview/' +
                      (holdingData as any)?.vendable +
                      '?holdingId=' +
                      (holdingData as any)?.holdingLink
                  )
                : onClick()
            }
          ></div>
        )}

        <div
          style={{
            backgroundImage:
              'url(' +
              (imageUrl ||
                (productInfo?.registrationNo
                  ? carImagePlaceholder
                  : placeholderImage)) +
              ')',
          }}
          className="mr-4 h-[90px] w-[90px] flex-shrink-0 rounded bg-white bg-contain bg-center bg-no-repeat"
        ></div>
        <div className="flex w-full justify-between">
          {!productInfo?.registrationNo ? (
            !tableProductInfo?.typeofStone ? (
              <CardContent
                repairData={repairData}
                holdingData={holdingData}
                appInfo={appInfo}
              />
            ) : (
              renderStoneDetails()
            )
          ) : (
            renderCarDetails()
          )}
          {serialNumberRequired ? (
            <div className="font-nunito flex flex-col items-center justify-center gap-1">
              <ExclamationTriangleIcon className="h-11 w-11 text-red-500" />
              <p className="max-w-[80px] text-center text-sm text-red-500">
                {t('Serial number required')}
              </p>
            </div>
          ) : (
            <>
              {remainingTime?.amount && remainingTime.amount > 0 && (
                <div className="flex items-center">
                  <div className="font-nunito -mt-2 flex flex-col items-center font-medium">
                    <Heading kind={'h2'} color="text-primary-700">
                      {remainingTime.amount.toString()}
                    </Heading>
                    <div className="text-center">
                      <p className="text-primary-700 mt-[-8px] mb-[5px] text-[8px]">
                        {remainingTime.unit}
                      </p>
                      <p className="text-[10px]">
                        {tenantLink === 'NUCOVER' ? 'Service plan' : 'Warranty'}
                        <br />
                        {t('Remaining')}
                      </p>
                    </div>
                  </div>
                  {appInfo?.name
                    .toLowerCase()
                    .includes(String(holdingData?.brand)?.toLowerCase()) &&
                  CFG_WARRANTY_INFO_URL?.length ? (
                    <div
                      className="relative z-20 cursor-pointer"
                      onClick={() => setModalWarrantyInfo(true)}
                    >
                      <InformationCircleIcon className="relative top-2 h-5 w-5 text-black" />
                    </div>
                  ) : null}
                </div>
              )}
              {remainingTime && remainingTime.amount === -1 && (
                <div className="font-nunito flex flex-col items-center font-medium">
                  <p className="text-primary-700 text-2xl font-normal">∞</p>
                  <p className="w-[75px] text-center text-[10px]">
                    {t('Lifetime Warranty')}
                  </p>
                </div>
              )}
              {remainingTime?.amount &&
                (remainingTime.amount < -1 || remainingTime.amount === 0) && (
                  <div className="font-nunito flex flex-col items-center font-medium">
                    <ExclamationTriangleIcon className="h-10 w-10 text-red-600" />
                    <p className="w-[75px] text-center text-[10px]">
                      {t('Warranty Expired')}
                    </p>
                  </div>
                )}
            </>
          )}
          {modalWarrantyInfo && (
            <Modal
              className="h-fit w-[350px] rounded-md bg-white"
              kind={modalSettings.kind}
              settings={modalSettings.settings}
            >
              <div className="font-nunito p-5 text-sm">
                For certain {appInfo?.name} products, component parts may have
                different warranties, please{' '}
                <a
                  href={CFG_WARRANTY_INFO_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  click here
                </a>{' '}
                for more information.
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}

export default CardGeneral;

const getHoldingBrand = (
  holdingData: DTHoldingProxy | ConsumerHoldingSummaryShape
) => {
  if (
    holdingData.self_registration &&
    typeof holdingData.self_registration !== 'boolean'
  ) {
    return holdingData?.self_registration?.brand;
  } else return holdingData?.brand;
};
