import { CaseFlowConfiguration } from '@rabbit/data/types';
import { CaseFlowConfigurationRegistry } from '../configuration';
import {
  CaseFlowConfig_Facts_CaseStatus,
  CaseFlowConfig_Facts_Comments,
  CaseFlowConfig_Facts_Consumer,
  CaseFlowConfig_Facts_Delegates,
  CaseFlowConfig_Facts_DocLinks,
  CaseFlowConfig_Facts_Holding,
  CaseFlowConfig_Facts_IssueDetails,
  CaseFlowConfig_Facts_UploadedFiles,
} from '../_common/facts';
import { CaseflowCaseTypes } from '../_common/types';

/* -------------------------------------------------------------------------- */
/*                                 Case Facts                                 */
/* -------------------------------------------------------------------------- */

// TODO: Finish registering fact types in the fact-types.ts files
const caseFacts: CaseFlowConfiguration['facts'] = {
  ...CaseFlowConfig_Facts_DocLinks,
  ...CaseFlowConfig_Facts_Comments,
  ...CaseFlowConfig_Facts_IssueDetails,
  ...CaseFlowConfig_Facts_Delegates,
  ...CaseFlowConfig_Facts_Holding,
  ...CaseFlowConfig_Facts_Consumer,
  ...CaseFlowConfig_Facts_CaseStatus,
  ...CaseFlowConfig_Facts_UploadedFiles,

  holding_warranty_term: {
    type: 'WarrantyDuration',
    label: 'Length of holding warranty',
  },

  // todo: probably not needed any more as we have tupdate on the case document
  case_last_updated: {
    type: 'Number',
    label: 'Case last update time',
  },
  // TODO: Can get it from the case's tcreate, remove this eventually
  case_time_created: {
    type: 'Number',
    label: 'Case creation time',
  },

  // consumer_address: {
  //   type: 'Address',
  //   label: 'Customer Address',
  // TODO: Maybe we will do overrides like this:
  // consumer: {
  //   label: 'My Address',
  // }
  // },

  repairer_tracking_id: {
    type: 'String',
    label: 'Tracking ID for repairer',
  },

  repairer_id: {
    // could be same as repairer_tracking_id
    type: 'String',
    label: 'Repairer',
  },

  total_repairing_time: {
    type: 'String', // HH:MM
    label: 'Time spent repairing',
  },
  //TODO: removed as not used anymore
  parts_used_for_repair: {
    type: 'PartsId[]',
    label: 'Parts used for repair',
  },

  parts_to_replace: {
    type: 'PartsId[]',
    label: 'Parts used to replace',
  },

  parts_to_post: {
    type: 'Parts[]',
    label: 'Parts to post',
  },

  repairer_business_name: {
    type: 'String',
    label: 'Repairer business name',
  },

  repairer_name: {
    type: 'String',
    label: 'Repairer name',
  },

  repairer_contact_details: {
    type: 'CFCF_RepairerContactDetails',
    label: 'Repairer contact details',
  },
  repairer_address: {
    type: 'Address',
    label: 'Repairer address',
  },
  cost_to_repair: {
    type: 'Money',
    label: 'Estimate cost to repair/replacement',
  },
  cost_to_repair_accepted: {
    type: 'String',
    label: 'Quote cost accepted or denied',
  },
  collection_address: {
    type: 'Address',
    label: 'Collection address',
  },
  collection_times: {
    type: 'String', // todo:confirm
    label: 'Collection times',
  },
  outbound_tracking_number: {
    type: 'String',
    label: 'Tracking number',
  },

  outbound_postage_cost: {
    type: 'Money',
    label: 'Cost of postage',
  },
  outbound_carrier: {
    type: 'String',
    label: 'Carrier',
  },

  postage_receipts: {
    type: 'String[]',
    label: 'Proof of postage',
  },
  receipts: {
    type: 'String[]',
    label: 'Claim cost receipts',
  },
  administrative_time: {
    type: 'String',
    label: 'Administrative time',
  },

  time_spent_assessing: {
    type: 'String',
    label: 'Time spent assessing',
  },
  repair_work_registry: {
    type: 'CFCF_RepairWorkRegistry',
    label: 'List of repair work conducted',
  },
  local_repairer: {
    type: 'String',
    label: 'Local repairer',
  },
  postage_registry: {
    type: 'PostageRegistryItem[]',
    label: 'List of log postage',
  },
  fault_category: {
    type: 'String',
    label: 'Fault category',
  },
  fault_type: {
    type: 'String',
    label: 'Fault type',
  },
  additional_comments: {
    type: 'String',
    label: 'Additional comments',
  },
  item_shipping_label: {
    type: 'UserUploadedDocument',
    label: 'Shipping label',
  },
};

export const SheltaConfig: CaseFlowConfiguration = {
  actors: {
    consumer: {
      tech_description: 'holder of broken item',
    },
    repairer: {
      tech_description: 'fixer of broken item',
      proxy: {
        facts: [
          // "case_last_updated", // TODO: <-- IS THIS A FACT????
          'consumer_holding',
          'consumer_persona_id',
          'consumer_name',
          'consumer_email',
          'consumer_issue_type', // Used for unit test
          'consumer_issue_type_ref',
          'repairer_tracking_id',
          'consumer_holding_name',
          'consumer_telephone',
          'consumer_preferred_contact',
          'consumer_holding_purchase_country',
          'consumer_holding_purchase_location',
          'consumer_holding_purchase_location_other',
          'consumer_holding_purchase_price',
          'consumer_holding_serial_number',
          'consumer_issue_description',
          'consumer_proof_of_purchase',
          'preliminary_assessment',
          'final_assessment',
          'goodwill_warranty_initial',
          'goodwill_warranty_final',
          'delegate_repairer_id',
          'delegate_repairer_name',
          'case_time_created',
          'claim_outcome',
          'purchase_date',
          'holding_warranty_term',
          'consumer_holding_category',
          'holding_faults',
          'item_shipping_label',
          'vendable_version_number',
        ],
      },
    },
  },
  facts: caseFacts,
  stations: {
    _birth: {
      actions: {
        _onEnter: {
          available_to: ['consumer', 'repairer'],
          steps: [
            {
              type: 'go_station',
              station: 'initiate',
            },
          ],
        },
      },
    },
    initiate: {
      editable: {
        all: [
          'consumer_name',
          'consumer_email',
          'consumer_holding_name',
          'purchase_date',
          'consumer_issue_type',
          'consumer_issue_type_ref',
          'consumer_issue_description',
          'consumer_proof_of_purchase',
          'consumer_claim_evidence',
          'consumer_holding_purchase_location',
          'consumer_telephone',
          'consumer_preferred_contact',
        ],
      },
      actions: {
        submit: {
          available_to: ['consumer', 'repairer'],
          label: 'Submit claim',
          params: {
            repairer_param: {
              type: 'String',
              label: 'Repairer parameter',
              required: true,
            },
          },
          steps: [
            {
              type: 'req_facts',
              facts: [
                'consumer_name',
                'consumer_email',
                'consumer_holding_name',
                'purchase_date',
                'consumer_issue_type',
                'consumer_issue_type_ref',
                'consumer_issue_description',
              ],
            },
            {
              type: 'go_station',
              station: 'preliminary_assessment',
            },
            {
              type: 'set_actor',
              role: 'repairer',
              persona_value: '[[repairer_param]]',
            },
            {
              type: 'alter_spotlight',
              clear: true,
              add: '[[repairer_param]]',
            },
          ],
        },
        // set_repairer: {
        //   repairer_persona: "R:LKFASJKLAFSJ"
        // }
      },
    },
    preliminary_assessment: {
      editable: {
        all: [
          'fault_category',
          'fault_type',
          'preliminary_assessment',
          'goodwill_warranty_initial',
          'goodwill_warranty_final',
          'goodwill_override_initial',
          'goodwill_override_final',
          'additional_comments',
          'consumer_name',
          'consumer_email',
          'consumer_holding_name',
          'purchase_date',
          'consumer_issue_type',
          'consumer_issue_type_ref',
          'consumer_issue_description',
          'consumer_proof_of_purchase',
          'consumer_claim_evidence',
          'consumer_holding_purchase_location',
          'consumer_telephone',
          'consumer_preferred_contact',
          'consumer_holding',
          'goodwill_warranty_initial',
          'goodwill_warranty_final',
        ],
      },
      actions: {
        assign_case: {
          label: 'Assign case',
          available_to: ['repairer'],
          params: {
            delegate_repairer_id: {
              type: 'String',
              label: 'Delegate repairer persona',
              required: true,
            },
            delegate_repairer_name: {
              type: 'String',
              label: 'Delegate repairer name',
              required: true,
            },
          },
          steps: [
            {
              type: 'set_fact',
              fact: 'delegate_repairer_id',
              value: '[[delegate_repairer_id]]',
            },
            {
              type: 'set_fact',
              fact: 'delegate_repairer_name',
              value: '[[delegate_repairer_name]]',
            },
            {
              type: 'alter_spotlight',
              clear: true,
              add: '[[delegate_repairer_id]]',
            },
          ],
        },
        preliminary_assessment: {
          label: 'Preliminary assessment',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'fault_category',
                'fault_type',
                'preliminary_assessment',
                'goodwill_override_initial',
                'goodwill_warranty_initial',
                'delegate_repairer_id',
              ],
            },
            {
              type: 'go_station',
              station: 'initially_assessed',
            },
          ],
        },
      },
    },
    initially_assessed: {
      editable: {
        all: ['additional_comments', 'comment_to_customer'],
      },
      actions: {
        request_customer_send_item: {
          label: 'Request customer to send item',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                // TODO: have comment_to_customer as a parameter
                // (facts are something that will not change over time)
                'comment_to_customer',
              ],
            },
            {
              type: 'go_station',
              station: 'inbound_post',
            },
          ],
        },
        quote_customer: {
          label: 'Quote customer',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'comment_to_customer', // TODO move this to parameters
                'cost_to_repair',
              ],
            },
            {
              type: 'go_station',
              station: 'confirm_quotation_response',
            },
          ],
        },
        assign_to_local_repairer: {
          label: 'Assign to internal technician',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: ['comment_to_customer'],
            },
            { type: 'go_station', station: 'repair' },
          ],
        },
        replace_part_or_product: {
          label: 'Replace parts or product',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'comment_to_customer',
                'outbound_tracking_number',
                'outbound_address',
                'outbound_postage_cost',
                'outbound_carrier',
              ],
            },
            {
              type: 'go_station',
              station: 'pending_outbound',
            },
          ],
        },
        assign_to_authorised_repairer: {
          label: 'Assign to authorised repairer',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'comment_to_customer', // TODO move this to parameters
                'repairer_business_name',
                'repairer_name',
                'repairer_contact_details',
              ],
            },
            // TODO: have a step for send email
            {
              type: 'go_station',
              station: 'third_party_repair',
            },
          ],
        },
        reject_claim: {
          label: 'Reject claim and close',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: ['comment_to_customer', 'preliminary_assessment'],
            },
            {
              type: 'go_station',
              station: 'closed',
            },
          ],
        },
      },
    },
    inbound_post: {
      editable: {
        all: ['comment_to_customer'],
      },
      actions: {
        /* ---------------------------------- Olive --------------------------------- */
        mark_item_sent: {
          label: 'Mark item as sent', // when customer has sent the item
          available_to: ['consumer'],
          params: {
            repairer: {
              type: 'String',
              label: 'repairer persona',
              required: true,
              get_from: ['fact:delegate_repairer_id', 'actor:repairer'],
            },
            consumer_marked_item_sent: {
              type: 'Boolean',
              label: 'Customer marked item as sent',
              required: true,
            },
          },
          steps: [
            {
              type: 'set_fact',
              fact: 'consumer_marked_item_sent',
              value: '[[consumer_marked_item_sent]]',
            },
            {
              type: 'alter_spotlight',
              clear: true,
              add: '[[repairer]]',
            },
          ],
        },
        /* ---------------------------------- Sage ---------------------------------- */
        log_item_received: {
          label: 'Log item as received', // when shelta has received the item
          available_to: ['repairer'],
          steps: [
            { type: 'req_facts', facts: ['comment_to_customer'] },
            { type: 'go_station', station: 'final_assessment' },
            // TODO we should have type for spotlight
          ],
        },
        close_case: {
          label: 'Close case', // when shelta did not receive the item after certain time
          available_to: ['repairer'],
          steps: [
            { type: 'req_facts', facts: ['comment_to_customer'] },
            { type: 'go_station', station: 'closed' },
            // TODO we should have type for spotlight --- in all action -> steps
          ],
        },
      },
    },
    confirm_quotation_response: {
      editable: {
        all: ['comment_to_customer'],
      },
      actions: {
        quote_customer: {
          label: 'Requote customer',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'comment_to_customer', // TODO move this to parameters
                'cost_to_repair',
              ],
            },
          ],
        },
        approve_case_for_repair: {
          label: 'Approve case for repair',
          available_to: ['repairer'],
          steps: [
            // no required facts on caseflow v2
            // {
            //   type: 'req_facts',
            //   facts: ['internal_comment', 'comment_to_customer'],
            // },
            { type: 'go_station', station: 'repair' },
          ],
        },
        replace_part_or_product: {
          // if customer has accepted the quote
          label: 'Replace parts or product',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'comment_to_customer',
                'outbound_tracking_number',
                'outbound_address',
                'outbound_postage_cost',
                'outbound_carrier',
              ],
            },
            {
              type: 'go_station',
              station: 'pending_outbound',
            },
          ],
        },
        request_customer_send_item: {
          // if customer has accepted the quote
          label: 'Request customer to send item',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: ['comment_to_customer'],
            },
            {
              type: 'go_station',
              station: 'inbound_post',
            },
          ],
        },
        assign_to_local_repairer: {
          label: 'Assign to internal technician',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: ['comment_to_customer'],
            },
            { type: 'go_station', station: 'repair' },
          ],
        },
        close_case: {
          // when user did not accept the quote from shelta
          label: 'Close case',
          available_to: ['repairer'],
          steps: [
            { type: 'req_facts', facts: ['comment_to_customer'] },
            { type: 'go_station', station: 'closed' },
          ],
        },
      },
    },
    final_assessment: {
      editable: {
        all: ['internal_comment'],
      },
      actions: {
        final_assessment: {
          available_to: ['repairer'],
          label: 'Final assessment',
          params: {
            assessment: {
              type: 'String',
              label: 'Final assessment',
              required: true,
            },
          },
          steps: [
            { type: 'req_facts', facts: ['preliminary_assessment'] },
            {
              type: 'set_fact',
              fact: 'final_assessment',
              value: '[[assessment]]',
            },
          ],
        },
        approve_case_for_repair: {
          label: 'Approve case for repair',
          available_to: ['repairer'],
          steps: [
            // no required facts on caseflow v2
            // {
            //   type: 'req_facts',
            //   facts: ['internal_comment', 'comment_to_customer'],
            // },
            { type: 'go_station', station: 'repair' },
          ],
        },
        quote_customer: {
          label: 'Quote customer for claim',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'comment_to_customer', // TODO move this to parameters
                'cost_to_repair',
              ],
            },
            {
              type: 'go_station',
              station: 'confirm_quotation_response',
            },
          ],
        },
        close_case: {
          label: 'Close case', // when shelta did not receive the item after certain time
          available_to: ['repairer'],
          steps: [
            { type: 'req_facts', facts: ['comment_to_customer'] },
            { type: 'go_station', station: 'closed' },
            // TODO we should have type for spotlight --- in all action -> steps
          ],
        },
        //WIP: complete later when modal is added to Notion
        // return_item_to_customer: {
        //   label: 'Return item to customer',
        //   steps: [
        //     {
        //       type: 'req_facts',
        //       facts: [
        //         'comment_to_customer',
        //         'outbound_tracking_number',
        //         'outbound_address',
        //         'outbound_postage_cost',
        //         'outbound_carrier',
        //       ],
        //     },
        //   ],
        // },
      },
    },
    repair: {
      editable: {
        all: ['repairer_id', 'total_repairing_time', 'parts_used_for_repair'],
      },
      actions: {
        log_repair_work: {
          label: 'Log repair work',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'repairer_id',
                'total_repairing_time',
                // 'parts_used_for_repair',
              ],
            },
            // there is no change in station
          ],
        },
        complete_repair: {
          label: 'Complete repair',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: ['comment_to_customer'],
            },
            // TODO: how to ensure repair work is logged
            { type: 'go_station', station: 'pending_outbound' },
          ],
        },
        // Only if case is assigned to local repairer, i.e. local_repairer fact exists
        complete_local_repair_and_close_case: {
          label: 'Log as complete and close case',
          available_to: ['repairer'],
          steps: [
            { type: 'req_facts', facts: ['comment_to_customer'] },
            { type: 'go_station', station: 'closed' },
          ],
        },
      },
    },
    third_party_repair: {
      editable: {
        all: ['cost_to_repair'],
      },
      actions: {
        log_authorised_repairer_repair_complete: {
          // as per doc, we will have a workflow for 3rd party repairs
          label: 'Log repair as complete and close claim',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: ['cost_to_repair'],
            },
            {
              type: 'go_station',
              station: 'closed',
            },
          ],
        },
      },
    },
    pending_outbound: {
      editable: {
        all: [
          'comment_to_customer',
          'internal_comment',
          'outbound_tracking_number',
          'outbound_address',
          'outbound_postage_cost',
          'outbound_carrier',
        ],
      },
      actions: {
        log_postage: {
          label: 'Log postage',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'comment_to_customer',
                'outbound_tracking_number',
                'outbound_address',
                'outbound_postage_cost',
                'outbound_carrier',
              ],
            },
            { type: 'go_station', station: 'outbound' },
          ],
        },
        customer_collection: {
          label: 'Customer collection',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: ['comment_to_customer'],
            },
            { type: 'go_station', station: 'outbound' },
          ],
        },
      },
    },
    outbound: {
      editable: {
        all: [
          'comment_to_customer',
          'outbound_tracking_number',
          'outbound_address',
          'outbound_postage_cost',
          'outbound_carrier',
        ],
      },
      actions: {
        log_postage: {
          label: 'Log postage',
          available_to: ['repairer'],
          steps: [
            {
              type: 'req_facts',
              facts: [
                'comment_to_customer',
                'outbound_tracking_number',
                'outbound_address',
                'outbound_postage_cost',
                'outbound_carrier',
              ],
            },
          ],
        },
        confirm_customer_received_item: {
          label: 'Confirm customer received item',
          available_to: ['repairer'],
          steps: [
            // no required facts on caseflow v2
            // {
            //   type: 'req_facts',
            //   facts: ['comment_to_customer'],
            // },
            { type: 'go_station', station: 'closed' },
          ],
        },
      },
    },
    closed: {
      // No steps or actions are required. This station marks the end of Journey for a case
      actions: {},
    },
  },
  global_actions: {
    //todo: add params?
    contact_customer_generic: {
      label: 'Contact customer for more info',
      available_to: ['repairer'],
      steps: [
        {
          type: 'req_facts',
          facts: ['comment_to_customer'],
        },
      ],
    },
    internal_comment_generic: {
      label: 'Leave internal comment',
      available_to: ['repairer'],
      steps: [
        {
          type: 'req_facts',
          facts: ['internal_comment'],
        },
      ],
    },
    assign_case: {
      label: 'Assign case',
      available_to: ['repairer'],
      params: {
        delegate_repairer_id: {
          type: 'String',
          label: 'Delegate repairer persona',
          required: true,
        },
        delegate_repairer_name: {
          type: 'String',
          label: 'Delegate repairer name',
          required: true,
        },
      },
      steps: [
        {
          type: 'set_fact',
          fact: 'delegate_repairer_id',
          value: '[[delegate_repairer_id]]',
        },
        {
          type: 'set_fact',
          fact: 'delegate_repairer_name',
          value: '[[delegate_repairer_name]]',
        },
        {
          type: 'alter_spotlight',
          clear: true,
          add: '[[delegate_repairer_id]]',
        },
      ],
    },
    //Set the spotlight to point at the consumer
    spotlight_on_consumer: {
      label: 'Spotlight on consumer',
      available_to: ['repairer'],
      params: {
        consumer: {
          type: 'String',
          label: 'Consumer persona',
          required: true,
          get_from: ['fact:consumer_persona_id', 'actor:consumer'],
        },
      },
      steps: [
        {
          type: 'alter_spotlight',
          clear: true,
          add: '[[consumer]]',
        },
      ],
    },
    // Set the spotlight to point at the repairer, which may be the delegate repairer if it has been set as a fact
    spotlight_on_repairer: {
      label: 'Spotlight on repairer',
      available_to: ['repairer'],
      params: {
        repairer: {
          type: 'String',
          label: 'Repairer persona',
          required: true,
          get_from: ['fact:delegate_repairer_id', 'actor:repairer'],
        },
      },
      steps: [
        {
          type: 'alter_spotlight',
          clear: true,
          add: '[[repairer]]',
        },
      ],
    },
  },
};

// todo: check how easy it'd be to rename this right now (to "Shelta" or something)
CaseFlowConfigurationRegistry.Register(SheltaConfig, CaseflowCaseTypes.SHELTA); // TODO: One day repair will be generic.
