import {
  DeciderSetLookup,
  DeciderValuesSetByIndex,
} from '@rabbit/bizproc/core';

import {
  DTWarranty_Offer,
  DTWarranty_Template,
  FBD_Warranty_Offer,
  FBD_Warranty_Template,
  TenantLink,
  WarrantorLink,
  WarrantyTemplateLink,
  DT_AnyValue,
  DeciderTableValues,
  PersonaTypeSingleLetter,
  PersonaIdTypeSplitter,
} from '@rabbit/data/types';
import { WarrantyOfferFullDataShape } from '@rabbit/elements/shared-types';
import { useEffect, useState } from 'react';
import { useSageAPI } from '../api/useSageAPI';

export function useManageWarrantyOffers(
  warrantorLink: WarrantorLink,
  partnerTenantLink: TenantLink
) {
  const {
    getAllWarrantyOffers,
    createWarrantyOfferForPartner: createOffer,
    isReady,
  } = useSageAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [availableOffers, setAvailableOffers] = useState<
    WarrantyOfferFullDataShape[]
  >([]);

  // Fetch available offers
  useEffect(() => {
    if (isReady && shouldRefresh) {
      setIsLoading(true);
      (async () => {
        const res = await getAllWarrantyOffers({
          partnerTenantLink,
          warrantorLink,
        });
        setAvailableOffers(res as WarrantyOfferFullDataShape[]); // todo confirm
        setIsLoading(false);
        setShouldRefresh(false);
        // test
      })().catch((e) => console.log(e));
    }
  }, [warrantorLink, partnerTenantLink, shouldRefresh, isReady]);

  /** Fetch all warrantor templates for the warrantor using the hook. */
  async function getAllWarrantorTemplates() {
    const templates = await FBD_Warranty_Template.query()
      .where('warrantorLink', '==', warrantorLink)
      .getContents();

    if (!templates) {
      return [];
    }

    /* sorting array of warranty templates objects based on their `orderNo` property. */
    templates.sort((a: DTWarranty_Template, b: DTWarranty_Template) => {
      if (a.orderNo === b.orderNo) {
        return 0;
      }
      return a.orderNo > b.orderNo ? 1 : -1;
    });
    return templates;
  }

  /**
   * Creates a warranty offer for a given partner-tenant (the one passed to the hook).
   * @param templateLink The template to use as the basis for the warranty offer
   * @param lookupKeys The lookup keys to use for the warranty offer. If not provided, all lookups will be used.
   * @returns The new offer's docid
   */
  async function createWarrantyOfferForPartner(
    templateLink: WarrantyTemplateLink,
    title: string,
    lookupKeys?: string[]
  ) {
    console.log(
      'Creating warranty offer for partner',
      templateLink,
      title,
      lookupKeys
    );

    // First check if an offer already exists for this template/partner combo.
    // It needs to both have the same templateLink and match the lookupKeys exactly

    const existingOffer = await getExistingOffer(
      availableOffers.map((offer) => offer.offer),
      templateLink,
      title,
      lookupKeys
    );

    console.log('Existing offer', existingOffer);
    // If it already exists and is not enabled, re-enable it
    if (existingOffer) {
      if (existingOffer.enabled) {
        console.log('Offer already exists and is enabled');
        return {
          docid: existingOffer.docid,
          enabled: true,
          message: 'Offer already exists and is enabled',
        };
      } else {
        existingOffer.enabled = true;
        try {
          await FBD_Warranty_Offer.set(existingOffer);
          console.log('Offer re-enabled');
          return {
            docid: existingOffer.docid,
            enabled: false,
            message: 'Offer re-enabled',
          };
        } catch (e) {
          console.log('Error re-enabling offer', e);
          throw new Error('Error re-enabling offer');
        }
      }
    }

    // If not, let's create a new one
    try {
      const res = await createOffer({
        warrantorLink,
        tenantLink: partnerTenantLink,
        templateLink,
        title,
        lookupKeys,
      });
      console.log('Offer created', res);
      return res;
    } catch (e) {
      console.log('Error creating offer', e);
      throw new Error('Error creating offer');
    }
  }

  function getLookupsFromDecider(decider: string) {
    const parsedDecider = JSON.parse(decider);
    if (!parsedDecider.lookups) return [];

    const lookups = Object.keys(parsedDecider.lookups);
    return lookups;
  }

  async function disableWarrantyOffer(offer: DTWarranty_Offer) {
    offer.enabled = false;
    try {
      await FBD_Warranty_Offer.set(offer);
      console.log('Offer disabled');
      return offer;
    } catch (e) {
      console.log('Error disabling offer', e);
      throw new Error('Error disabling offer');
    }
  }

  function setValueByIndex(
    lookup: DeciderTableValues,
    indices: number[],
    value: DT_AnyValue
  ) {
    console.log('Setting value by index', lookup, indices, value);
    const updatedLookup = DeciderValuesSetByIndex(
      lookup,
      indices,
      value
    ); // always going to be number for now, update later as needed - DC
    return updatedLookup;
  }

  async function saveLookupTableValues(
    lookup: DeciderTableValues,
    offer: DTWarranty_Offer
  ) {
    console.log('Saving lookup table values', lookup, offer);
    DeciderSetLookup(lookup.key, lookup, offer);
    console.log('offer after save: ', offer);
    try {
      await FBD_Warranty_Offer.set(offer);
      console.log('Lookup table values saved');
    } catch (e) {
      console.log('Error saving lookup table values', e);
    }
  }

  /** Generate risk categories based on the tenant link */
  function generateRiskCategories(warrantorLink: string) {
    let categories;
    let showRiskCategoryDropdown = true;
    let defaultRiskCategory = null;

    if (warrantorLink === PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + 'NUCOVER') {
      categories = [
        {
          label: '',
          value: 'duration_surfaceAreaClass',
        },
      ];
      showRiskCategoryDropdown = false;
      defaultRiskCategory = 'duration_surfaceAreaClass';
    } else if (warrantorLink === PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + 'WARRANTYIRELAND') {
      categories = [
        {
          label: '',
          value: 'claimLimit_duration',
        },
      ];
      showRiskCategoryDropdown = false;
      defaultRiskCategory = 'claimLimit_duration';
    } else {
      categories = [
        {
          label: 'No risk',
          value: 'claimLimit_duration_default',
        },
        {
          label: 'Risk Category B',
          value: 'claimLimit_duration_riskB',
        },
        {
          label: 'Risk Category C',
          value: 'claimLimit_duration_riskC',
        },
      ];
      showRiskCategoryDropdown = true;
      defaultRiskCategory = 'claimLimit_duration_default';
    }
  
    return {
      categories,
      showRiskCategoryDropdown,
      defaultRiskCategory,
    };
  }

  return {
    isLoading,
    setShouldRefresh,
    availableOffers,
    getAllWarrantorTemplates,
    createWarrantyOfferForPartner,
    disableWarrantyOffer,
    setValueByIndex,
    saveLookupTableValues,
    getLookupsFromDecider,
    generateRiskCategories
  };
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

// I think I might be able to simplify this slightly, review soon - DC

async function getExistingOffer(
  existingOffers: DTWarranty_Offer[] | null,
  templateLink: string,
  title: string,
  lookupKeys?: string[]
): Promise<DTWarranty_Offer | null> {
  if (!existingOffers) return null;

  console.log(
    'Getting existing offer',
    existingOffers,
    templateLink,
    title,
    lookupKeys
  );

  // first filter out offers that don't match the templateLink or the title
  existingOffers = existingOffers.filter(
    (offer) => offer.templateLink === templateLink && offer.title === title
  );
  console.log('Existing offers after filtering', existingOffers);
  if (lookupKeys) {
    // If lookupKeys are provided, we need to find an offer that matches them exactly
    const matchingOffer = existingOffers.find((offer) => {
      if (offer.decider) {
        const parsedDecider = JSON.parse(offer.decider);
        const offerKeys = Object.keys(parsedDecider.lookups);
        return lookupKeys.every((key) => offerKeys.includes(key));
      } else return false;
    });

    return matchingOffer || null;
  } else {
    // If no lookupKeys are provided, then we need to find an offer that matches the template lookups exactly
    const template: DTWarranty_Template | null =
      await FBD_Warranty_Template.get(templateLink);

    if (!template || !template?.decider) throw new Error('Template not found');

    const templateLookups = JSON.parse(template?.decider).lookups;

    const matchingOffer = existingOffers.find((offer) => {
      if (offer.decider) {
        const parsedDecider = JSON.parse(offer.decider);
        const offerKeys = Object.keys(parsedDecider.lookups);
        return Object.keys(templateLookups).every((key) =>
          offerKeys.includes(key)
        );
      } else return false;
    });

    return matchingOffer || null;
  }
}
