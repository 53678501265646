import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { useGetPersonaData } from '@rabbit/bizproc/react';
import {
  Address,
  DTRepairer_Public,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LabelledAddressItem,
  LabelledItem,
} from '@rabbit/elements/shared-components';
import { ContactExternalPartnerModal } from '@rabbit/sage/components/organisms/case-flow-modals';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import { t } from 'i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export function RepairerDetailsCard() {
  const { caseFacts, setModalSettings, setShowModal } =
    useContext(CaseflowContext) || {};
  const { delegate_repairer_name, delegate_repairer_id } = caseFacts || {};
  const { personaPublic } = useGetPersonaData(delegate_repairer_id ?? '', [
    'public',
  ]) as { personaPublic: DTRepairer_Public | null };

  const modalSettings = {
    kind: 'generic' as const,
    children: <ContactExternalPartnerModal />,
    settings: {},
  };

  if (
    !delegate_repairer_id ||
    !delegate_repairer_name ||
    delegate_repairer_id === 'Unassigned'
  )
    return null;

  return (
    <CardWrapperWithHeader
      title={t('Repairer details')}
      headerRight={
        <div className="flex gap-4">
          <ButtonIcon
            type="primary"
            label={`Contact repairer`}
            Icon={EnvelopeIcon}
            iconLeft
            onClick={() => {
              if (setModalSettings && setShowModal) {
                setModalSettings(modalSettings);
                setShowModal(true);
              }
            }}
          />
        </div>
      }
    >
      <div className="flex flex-col gap-3">
        <LabelledItem
          label={t('Name')}
          value={delegate_repairer_name}
          arrowLink={`/manage/partner/${
            delegate_repairer_id.split(PersonaIdTypeSplitter)[1]
          }`}
        ></LabelledItem>
        <LabelledItem
          label={t('Email address')}
          value={personaPublic?.email ?? '-'}
        ></LabelledItem>
        <LabelledItem
          label={t('Phone number')}
          value={personaPublic?.phone ? `+ ${personaPublic?.phone}` : '-'}
        ></LabelledItem>
        <LabelledAddressItem
          addresses={(personaPublic?.addresses ?? []) as Address[]}
        />
      </div>
    </CardWrapperWithHeader>
  );
}

export default RepairerDetailsCard;
