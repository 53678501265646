import Skeleton from 'react-loading-skeleton';

export interface SkeletonInputProps {
  showLabel?: boolean;
}
export function SkeletonInput({ showLabel = true }: SkeletonInputProps) {
  return (
    <div>
      {showLabel && <Skeleton className="!w-1/3" count={1} />}
      <Skeleton className="py-4" count={1} />
    </div>
  );
}

export default SkeletonInput;
