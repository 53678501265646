import { DTIdentity_Private, FBD_Identity_Private } from '@rabbit/data/types';
import { firebaseAuth, firebaseStore } from '@rabbit/firebase/adapter-react';
import { Unsubscribe, doc, onSnapshot } from 'firebase/firestore';

let _identityPrivate: DTIdentity_Private;
export let Identity_UnsubscribeFromChanges: Unsubscribe | null = null;

export function Identity_SubscribeToChanges(
  identityPrivate: DTIdentity_Private
): Unsubscribe | null {
  _identityPrivate = identityPrivate;
  const unsub = onSnapshot(
    doc(
      firebaseStore,
      FBD_Identity_Private.collectionName,
      identityPrivate.docid
    ),
    async (doc) => {
      if (!doc.exists()) {
        return;
      }
      const newIdPrivate = doc.data() as DTIdentity_Private;
      if (
        newIdPrivate.claimsRefreshTime !== _identityPrivate.claimsRefreshTime
      ) {
        // there is a backend listener/trigger that refreshes claims, so let him do the work first before we get the new token
        await firebaseAuth.currentUser?.getIdToken(true);
      }
      _identityPrivate = newIdPrivate;
    }
  );

  Identity_UnsubscribeFromChanges = unsub;
  return unsub;
}
