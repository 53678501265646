import { useContext, useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
  getCurrencyFormat,
  formatPhoneNumber,
  getClaimLimit,
  getWarrantyTerm,
  getIncreasedLabourRate,
} from '@rabbit/elements/shared-components';
import {
  SageConsumerDataShape,
  useGetConsumerData,
} from '@rabbit/bizproc/react';
import { Address, VehicleInfo, WarrantyStatus } from '@rabbit/data/types';
import {
  formatAddress,
  formatDate,
  getProductTitleFromHoldingData,
  getUserPermissions,
  useAppInfo,
} from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { Permissions } from '@rabbit/data/types';
import { format } from 'date-fns';
import placeholderImg from '../assets/images/car-placeholder.jpg';
import voidSVG from '../assets/images/Void.svg';
import { BL_Warranty } from '@rabbit/bizproc/core';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RegistrationsPrintSummaryViewProps {}

export function RegistrationsPrintSummaryView(
  props: RegistrationsPrintSummaryViewProps
) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, userContext);
  const appInfo = useAppInfo();

  const { pathname } = useLocation();
  const holdingId = pathname.split('/')[2];

  const data: SageConsumerDataShape | null = useGetConsumerData(holdingId);
  const { holding_proxy, consumer_private } = data ?? {};

  const warrantyInfo = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);
  const voided = warrantyInfo?.status === WarrantyStatus.VOIDED;
  const labourRateValue = getIncreasedLabourRate(
    warrantyInfo,
    appInfo.currency
  );

  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (holding_proxy && consumer_private) {
      // Delay the print to allow logo to load
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [holding_proxy, consumer_private]);

  if (
    !userPermissions?.includes(Permissions.RegistrationsEdit) &&
    !userPermissions?.includes(Permissions.RegistrationsView) &&
    !userPermissions?.includes(Permissions.Owner)
  )
    return <Navigate to={SAGE_ROUTE_NAME.MANAGE_ACCOUNT} />;

  if (!holding_proxy || !consumer_private) return null;

  const productInfo = holding_proxy.self_registration?.srvInfo
    ?.productInfo as VehicleInfo;

  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';

  return (
    <div className="flex flex-col gap-3 py-6" ref={printRef}>
      {voided && (
        <div className="fixed left-0 top-0 z-10 flex h-full w-full items-center justify-center">
          <img src={voidSVG} alt="void" className="-mt-[200px] w-[400px]" />
        </div>
      )}
      <div className="flex items-start justify-between">
        <div className="font-caudex text-2xl">Warranty Summary</div>
        <div className="flex flex-col items-end justify-end gap-2 text-gray-600">
          <img
            src={appInfo.logo}
            alt={appInfo.name}
            className="mr-1 max-h-[40px] max-w-[220px]"
          />
          <div>{t('CFG_COBRAND_EMAIL')}</div>
        </div>
      </div>
      <div className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-gray-100 p-2">
        <div className="flex">
          <img
            src={
              holding_proxy?.img?.[0] ??
              holding_proxy?.self_registration?.img?.[0] ??
              placeholderImg
            }
            alt={'product '}
            className="h-[70px] w-[70px] rounded-md border border-gray-300 object-cover"
          ></img>
          <div className="ml-3 flex flex-col justify-center">
            <p className="font-caudex pb-1 text-xl font-normal">
              {getProductTitleFromHoldingData(holding_proxy)}
            </p>
            <p className="font-nunito text-gray-600">
              {productInfo?.registrationNo?.toUpperCase() || '-'}
            </p>
          </div>
        </div>
      </div>
      <div className="rounded-md border border-gray-300 bg-white py-2 px-3">
        <div>
          <div className="font-caudex border-b border-gray-300 pb-2 text-lg">
            Customer
          </div>
          <div className="font-nunito flex flex-col gap-4 py-2">
            <div className="grid grid-cols-3 gap-1">
              {renderData(
                'Name',
                `${consumer_private.splitname?.first} ${consumer_private.splitname?.last}`
              )}
              {renderData(
                'Phone number',
                formatPhoneNumber(consumer_private.phone)
              )}
              {renderAddressData(
                'Address',
                consumer_private.address as Address[]
              )}
            </div>
            <div>{renderData('Email', consumer_private.email)}</div>
          </div>
        </div>
      </div>
      <div className="rounded-md border border-gray-300 bg-white py-2 px-3">
        <div>
          {/* // TODO: Add the dealer details here - For now is showing consumer data -VP */}
          <div className="font-caudex border-b border-gray-300 pb-2 text-lg">
            Dealer
          </div>
          <div className="font-nunito flex flex-col gap-4 py-2">
            <div className="grid grid-cols-3 gap-1">
              {renderData('Name', warrantyInfo?.retailerName)}
              {renderData(
                'Phone number',
                warrantyInfo?.retailerPhone
                  ? formatPhoneNumber(warrantyInfo?.retailerPhone)
                  : '-'
              )}
              {renderAddressData(
                'Address',
                warrantyInfo?.retailerAddresses as Address[]
              )}
            </div>
            {/* <div>
              {renderData('Email', warrantyInfo.retailerEmail)}
            </div> */}
          </div>
        </div>
      </div>
      <div className="rounded-md border border-gray-300 bg-white py-2 px-3">
        <div>
          <div className="font-caudex border-b border-gray-300 pb-2 text-lg">
            Warranty
          </div>
          <div className="font-nunito flex justify-between gap-4 py-2">
            <div className="flex flex-col gap-4">
              {renderData(
                'Warranty ID',
                holding_proxy.docid.slice(0, 5).toUpperCase()
              )}
            </div>
            <div className="flex flex-col gap-4">
              {renderData(
                'Plan',
                warrantyInfo?.templateTitle ? warrantyInfo?.templateTitle : '-'
              )}
            </div>
            <div className="flex flex-col gap-4">
              {renderData('Term', getWarrantyTerm(holding_proxy) || '-')}
            </div>
            <div className="flex flex-col gap-4">
              {renderData(
                'Claim limit',
                getClaimLimit(
                  warrantyInfo,
                  appInfo.currency,
                  CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT
                )
              )}
            </div>
            {Array.isArray(labourRateValue) && labourRateValue.length > 0 && (
              <div className="flex flex-col gap-4">
                {renderData('Labour rate', labourRateValue ?? '-')}
              </div>
            )}
            <div></div>
          </div>
          <div className="font-nunito flex justify-between gap-4 py-2">
            <div className="flex flex-col gap-4">
              {renderData(
                'Start date',
                format(
                  new Date(warrantyInfo?.startDate ?? Date.now()),
                  'dd/MM/yyyy'
                )
              )}
            </div>
            <div className="flex flex-col gap-4">
              {renderData(
                'End date',
                format(
                  new Date(warrantyInfo?.endDate ?? Date.now()),
                  'dd/MM/yyyy'
                )
              )}
            </div>
            <div className="flex flex-col gap-4">
              {renderData('Terms & conditions', t('CFG_WARRANTY_INFO_URL'))}
            </div>
            <div className="flex flex-col gap-4">
              {renderData('For claims, please email:', t('CFG_COBRAND_EMAIL'))}
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-md border border-gray-300 bg-white py-2 px-3">
        <div>
          <div className="font-caudex border-b border-gray-300 pb-2 text-lg">
            Vehicle details
          </div>
          <div className="font-nunito grid grid-cols-6 gap-8 py-2">
            {renderData('Registration plate', productInfo?.registrationNo)}
            {renderData('Make', holding_proxy.self_registration?.brand)}
            {renderData('Model', productInfo.model)}
            {renderData('Version', productInfo?.version)}
            {renderData(
              'Purchase date',
              productInfo?.regDate && new Date(productInfo?.regDate).getTime()
                ? formatDate(new Date(productInfo?.regDate))
                : '-'
            )}
            {renderData('Engine cc', productInfo?.engineCc)}
          </div>
          <div className="font-nunito grid grid-cols-6 gap-8 py-2">
            {renderData('Fuel', productInfo?.fuel)}
            {renderData('Transmission', productInfo?.transmission)}
            {renderData('Engine number', productInfo?.engineNo)}
            {renderData('Chassis number', productInfo?.chassisNo)}
            {renderData(
              'Current mileage',
              productInfo?.mileage &&
                productInfo?.mileage + '' + productInfo?.mileageUnit
            )}
            {renderData(
              'Purchase price',
              holding_proxy?.purchase_price &&
                holding_proxy?.purchase_price?.amount !== 0 &&
                holding_proxy?.purchase_price?.currency !== '-'
                ? getCurrencyFormat(
                    holding_proxy.purchase_price.amount,
                    holding_proxy.purchase_price.currency
                  )
                : '-'
            )}
          </div>
        </div>
      </div>
      {tenantLink === 'PINNACLEWARRANTIES' && (
        <div className="rounded-md border border-gray-300 bg-white py-2 px-3">
          <div className="flex justify-around text-xs">
            <span>
              <strong>To Activate your Free Roadside Assistance</strong>: please
              call 0330 057 7101 <strong>within 48 hours</strong>
            </span>{' '}
            <span>•</span> <span>Press Option 2</span> <span>•</span>{' '}
            <span>Monday - Friday 9am - 5pm</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegistrationsPrintSummaryView;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const renderData = (label: string, value: any) => {
  let renderValue = value;
  if (value === 'undefined') renderValue = '-';
  //else if (value && value !== 'undefined' && label === 'Terms & conditions' && value?.includes('https://')) renderValue = value.replace('https://', '');
  return (
    <div>
      <div className="mb-[5px] text-sm text-gray-500">{label}</div>
      <div className="overflow-hidden text-ellipsis font-medium">
        {renderValue ?? '-'}{' '}
      </div>
    </div>
  );
};

const renderAddressData = (label: string, value: Address[]) => {
  const address =
    value?.length > 1
      ? value?.filter((i) => i.isDefault).length > 0
        ? value?.filter((i) => i.isDefault)
        : value
      : value;
  return (
    <div>
      <div className="mb-[5px] text-sm text-gray-500">{label}</div>
      <div className="font-medium">
        {address?.[0] ? formatAddress(address[0]) : '-'}
      </div>
    </div>
  );
};
