import { StartFirebasePure } from '@rabbit/firebase/adapter-client-pure';
export * from '@rabbit/firebase/adapter-client-pure';

const CLOUD_REGION = import.meta.env['VITE_CLOUD_REGION'];
const FIREBASE_MODE = import.meta.env['VITE_FIREBASE_MODE'];

const EMULATE_AUTH =
  import.meta.env['VITE_YES_MEANS_EMULATE_AUTHENTICATION'] === 'YES';

const { app, analytics, db, auth, storage, functions } = StartFirebasePure({
  cloudRegion: CLOUD_REGION,
  firebaseMode: FIREBASE_MODE,
  emulateAuth: EMULATE_AUTH,
});

// Export for all to see - one per app.
export const firebaseApp = app;
export const firebaseAnalytics = analytics;
export const firebaseStore = db;
export const firebaseAuth = auth;
export const firebaseStorage = storage;
export const firebaseFunctions = functions;
