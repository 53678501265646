import { Button, Modal } from '@rabbit/elements/shared-components';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { DTWarranty_Offer } from '@rabbit/data/types';
import { WarrantyOfferFullDataShape } from '@rabbit/elements/shared-types';
import { PricePlansShape } from '../TabPartnerPricing/TabPartnerPricing';

export function ModalManageWarrantyPlan({
  handleClose,
  plans,
  onRemovePlan,
  savePlans,
}: {
  handleClose: () => void;
  plans?: PricePlansShape[];
  onRemovePlan?: (offer: DTWarranty_Offer) => void;
  savePlans?: (plans: Array<object>) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [currentPlans, setCurrentPlans] = useState(plans);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<number>();

  const modalSettings = {
    title: 'Manage warranty price plan',
    handleClose: () => handleClose(),
    childrenClassName: 'overflow-visible',
  };

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this price plan. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () => {
        removePlan(selectedPlan!);
        setIsConfirmationModalOpen(false);
      },
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const removePlan = (index: number) => {
    const planToDelete = currentPlans?.[index]?.originalOffer
      ?.offer as DTWarranty_Offer;
    if (planToDelete) onRemovePlan?.(planToDelete);
  };

  // const onSavePlans = () => {
  //   // setLoading(true);
  //   // savePlans?.(currentPlans || []);
  //   handleClose();
  // };

  if (isConfirmationModalOpen)
    return (
      <Modal
        className="h-fit w-[350px] rounded-md bg-white"
        kind={confirmationModalSettings.kind}
        settings={confirmationModalSettings.settings}
      />
    );

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[512px] rounded-md border"
      isLoading={loading}
    >
      <div>
        <div className="flex flex-col gap-5 px-5 pt-5">
          <div className="flex flex-col gap-3">
            {currentPlans?.map((plan: any, index: number) => (
              <div
                key={index}
                className="flex flex-row items-center justify-between rounded-md border px-3 py-3"
              >
                <div>{plan.title}</div>
                <div>
                  <TrashIcon
                    className="h-6 w-6 cursor-pointer rounded-md p-1 text-red-600 hover:bg-gray-100"
                    onClick={() => {
                      setIsConfirmationModalOpen(true);
                      setSelectedPlan(index);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          {/* <div className='flex gap-2'>
            <Button kind='primary' size='sm' onClick={onSavePlans}>
              Close
            </Button>
            <Button kind="outline_red" size="sm" onClick={() => handleClose()}>
              Cancel
            </Button>
          </div> */}
        </div>
      </div>
    </Modal>
  );
}
