import { FBD_Holding_Public } from '@rabbit/data/types';

export async function updateHoldingOnClose(caseId: string, holdingId: string) {
  //validation here

  const holdingDoc = await FBD_Holding_Public.get(holdingId);

  if (!holdingDoc) throw new Error('No holding document found with that id');

  const filteredActive =
    holdingDoc?.active_cases?.filter(
      (singleCase: string) => singleCase !== caseId
    ) ?? [];
  holdingDoc.active_cases = filteredActive;
  holdingDoc.closed_cases = [...(holdingDoc?.closed_cases ?? []), holdingId];
  await FBD_Holding_Public.set(holdingDoc);
}
