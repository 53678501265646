// NOTE: Duplicated from libs/data/portal/src/document/editing/specific/holding.ts to avoid breaking things
// TODO: Remove old file if warranted

import { useEffect, useState } from 'react';
import {
  DR_Holding_Public,
  DR_Holding_Manufacturer,
  DR_Holding_Private,
  NewkeyAllocator,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import { Data_Holding_AttachToConsumer_SaveConsumer } from '@rabbit/bizproc/client';
import { FBD_Consumer_Private } from '@rabbit/data/types';
import { IsValidLinkToDocument } from '@rabbit/bizproc/core';

export function useConsumerHoldingEditor(
  consumerPersonaId: string,
  docid: string
) {
  const [ourDocid, setOurDocid] = useState(
    NewkeyAllocator(docid, DR_Holding_Private)
  );

  useEffect(() => {
    setOurDocid(NewkeyAllocator(docid, DR_Holding_Private));
  }, [docid]);

  const pmde = usePortalMultipleDocumentEditor(
    {
      public: {
        type: DR_Holding_Public,
        docid: ourDocid,
      },
      private: { type: DR_Holding_Private, docid: ourDocid },
      manufacturer: { type: DR_Holding_Manufacturer, docid: ourDocid },
    },
    {
      create: 'ALLOW',
      pre_commit: async () => {
        // If we aren't attached to a consumer, attach now.
        const holding_public = pmde.body.public;
        if (!holding_public) throw new Error('No holding public');
        if (!IsValidLinkToDocument(holding_public.consumer)) {
          const consumer_private = await FBD_Consumer_Private.get(
            consumerPersonaId
          );
          if (!consumer_private)
            throw new Error('No persona data consumer private');

          await pmde.update({
            ...pmde.body,
            public: await Data_Holding_AttachToConsumer_SaveConsumer(
              holding_public,
              consumer_private
            ),
          });
        }
      },
    }
  );
  return pmde;
}
