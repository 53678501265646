import { useContext, useEffect, useState } from 'react';
import {
  CardWrapperWithHeader,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import RepairTimeModal from '@rabbit/sage/components/organisms/claim-costs-modals/RepairTimeModal/RepairTimeModal';
import PartsUsedModal from '@rabbit/sage/components/organisms/claim-costs-modals/PartsUsedModal/PartsUsedModal';
import AdministrativeTimeModal from '@rabbit/sage/components/organisms/claim-costs-modals/AdministrativeTimeModal/AdministrativeTimeModal';
import ClaimCostModal, {
  LogForms,
} from '@rabbit/sage/components/organisms/claim-costs-modals/ClaimCostModal/ClaimCostModal';
import DocumentsEditModal from '@rabbit/sage/components/organisms/claim-costs-modals/DocumentsEditModal/DocumentsEditModal';
import EditClaimCostModal from '@rabbit/sage/components/organisms/claim-costs-modals/EditClaimCostModal/EditClaimCostModal';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '@rabbit/config/context';
import { Config_ClaimCosts_LogType } from 'libs/configs/src/types/config-types';
import ClaimCostsSectionDefault from './ClaimCostsSectionDefault';
import ClaimCostsSectionType1 from './ClaimCostsSectionType1';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

export interface ClaimCostsModalDataShape {
  index?: number;
  data?: any;
  type?: string;
  remove?: boolean;
}

export interface LogClaimEditShape {
  data: object;
  name: LogForms<any>['name'];
  index: number;
  remove: boolean;
}
export interface DocumentModalShape {
  name: LogForms<any>['name'];
  data: object;
  index: number;
}

export interface ClaimCostsSectionProps {}

export function ClaimCostsSection(props: ClaimCostsSectionProps) {
  const { config } = useContext(ConfigContext);
  const costLoggingFlowType =
    config?.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_COSTS.COST_LOGGING_FLOW;
  const context = useContext(CaseflowContext);
  const {
    caseFacts,
    caseFlowCase,
    holdingData,
    setShowModal,
    setModalSettings,
  } = context || {};

  /* -------------------------------------------------------------------------- */
  /*                               Modal Handling                               */
  /* -------------------------------------------------------------------------- */
  const [documentModal, setDocumentModal] = useState<DocumentModalShape>();
  const [editModal, setEditModal] = useState<LogClaimEditShape>();
  const [logCostModal, setLogCostModal] = useState(false);
  const [modalData, setModalData] = useState<ClaimCostsModalDataShape>({});
  const { t } = useTranslation();

  const modalSettingOptions = {
    repair_time: {
      kind: 'generic' as const,
      settings: {},
      children: (
        <RepairTimeModal
          data={modalData?.data ?? null}
          index={modalData?.index ?? null}
          remove={modalData?.remove ?? false}
        />
      ),
    },
    parts_used: {
      kind: 'generic' as const,
      settings: {},
      children: (
        <PartsUsedModal
          data={modalData?.data ?? null}
          index={modalData?.index ?? null}
          remove={modalData?.remove ?? false}
        />
      ),
    },
    administrative_time: {
      kind: 'generic' as const,
      settings: {},
      children: (
        <AdministrativeTimeModal
          data={modalData?.data ?? null}
          index={modalData?.index ?? null}
          remove={modalData?.remove ?? false}
        />
      ),
    },
  };

  useEffect(() => {
    if (modalData && modalData.type && setShowModal && setModalSettings) {
      const type = modalData.type as keyof typeof modalSettingOptions;
      setModalSettings(modalSettingOptions[type]);
      setShowModal(true);
    }
  }, [modalData]);

  /* -------------------------------------------------------------------------- */
  /*                             Component rendering                            */
  /* -------------------------------------------------------------------------- */

  if (!context || !caseFacts || !caseFlowCase || !holdingData)
    return (
      <CardWrapperWithHeader title={t('Claim costs')}>
        <LoadingSpinner size="xs" />
      </CardWrapperWithHeader>
    );

  return (
    <>
      {costLoggingFlowType === Config_ClaimCosts_LogType.DEFAULT && (
        <ClaimCostsSectionDefault
          caseFacts={caseFacts}
          setDocumentModal={setDocumentModal}
          setLogCostModal={setLogCostModal}
          setEditModal={setEditModal}
        />
      )}
      {costLoggingFlowType === Config_ClaimCosts_LogType.TYPE1 && (
        <ClaimCostsSectionType1
          caseFacts={caseFacts}
          setModalData={setModalData}
        />
      )}
      {logCostModal && (
        <ClaimCostModal handleClose={() => setLogCostModal(false)} />
      )}
      {editModal && (
        <EditClaimCostModal
          {...editModal}
          handleClose={() => setEditModal(undefined)}
        />
      )}
      {documentModal && (
        <DocumentsEditModal
          {...documentModal}
          handleClose={() => setDocumentModal(undefined)}
        />
      )}
    </>
  );
}

export default ClaimCostsSection;
