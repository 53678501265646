import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { TenantLink } from './tenant';
import { PersonaLink } from '../persona';

/* -------------------------------------------------------------------------- */
/*                          Consumer issue types                              */
/* -------------------------------------------------------------------------- */

/** A type of issue or defect with a given product, used by consumers */
export interface DTConsumerIssueType extends NoSqlDoc {
  /** The type of the issue */
  label: string;

  /** A description of the issue */
  description?: string;

  /** A link to the tenant that owns this issue type */
  tenantLink: TenantLink;

  /** A list of principals who can edit this issue type */
  principals: PersonaLink[];

  /** Whether this issue type has been deleted */
  enabled: boolean;

  /** The order number of this issue type */
  orderNo: number;
}

export const FBD_Consumer_Issue_Type = MakeFBDocType<DTConsumerIssueType>({
  name: 'ConsumerIssueType',
  collection: 'consumer_issue_type',
  empty: () => {
    const result: DTConsumerIssueType = {
      ...Empty_NoSqlDoc(),
      label: '',
      description: '',
      tenantLink: '',
      principals: [],
      enabled: true,
      orderNo: -1,
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});
