import algoliasearch from 'algoliasearch';
import { SearchIndex } from 'algoliasearch';
import {
  FBDocTypeEssentials,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import {
  GetAlgoliaCreds,
  GetSearchIndexName,
} from './config';

const gSearchIndexCache: Record<string, SearchIndex> = {};

export async function GetAlgoliaSearchIndex<TYPE extends NoSqlDoc>(
  type: FBDocTypeEssentials<TYPE>
  ): Promise<SearchIndex> {
    
  const indexName = GetSearchIndexName(type);
  
  if (gSearchIndexCache[indexName]) {
    return gSearchIndexCache[indexName];
  }

  console.log(`getAlgoliaSearchClient: initIndex - new indexName=${indexName}`);

  const { appId, searchApiKey } = GetAlgoliaCreds(type);
  const client = algoliasearch(appId, searchApiKey);

  gSearchIndexCache[indexName] = client.initIndex(indexName);
  return gSearchIndexCache[indexName];
}
