import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { Shopify_Order_Shape } from './base-types';

//Docs here: https://shopify.dev/docs/api/admin-rest/2023-07/resources/webhook#event-topics-orders-create

// todo: confirm which we actually need
export enum ShopifyOrderStatus {
  EMPTY = 'empty', // when document is first created
  CREATED = 'created',
  CANCELLED = 'cancelled',
  DELETED = 'deleted',
  FULFILLED = 'fulfilled',
  PARTIAL = 'partial',
  PAID = 'paid',
}

export interface ShopifyOrderVersionHistory {
  version: number;
  order: Shopify_Order_Shape;
  status: ShopifyOrderStatus;
  tcreate: number;
  tupdate: number;
}
/* -------------------------------------------------------------------------- */
/*                            Shopify Order Schema                            */
/* -------------------------------------------------------------------------- */

export interface DTShopifyOrder extends NoSqlDoc {
  /**
   * The Shopify shop URI, which serves as the unique identifier for the shop. E.g bananamart.myshopify.com
   */
  shopLink: string;

  /* Original Shopify fields */
  order: Shopify_Order_Shape;

  /* The current order status */
  status: ShopifyOrderStatus;

  /* The version number for this order. Starts at 1 */
  version: number;

  versionHistory?: ShopifyOrderVersionHistory[];
}

export const FBD_ShopifyOrder = MakeFBDocType<DTShopifyOrder>({
  name: 'Shopify_Order',
  collection: 'shopify_order',
  empty: () => {
    const result: DTShopifyOrder = {
      ...Empty_NoSqlDoc(),
      shopLink: '',
      status: ShopifyOrderStatus.EMPTY,
      order: {} as Shopify_Order_Shape,
      version: 1,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'Shopify_Order key should be SHOPNAME_SHOPIFYORDERID'
  ),
});
