import {
  APICallParamsBase,
  APICallResultBase,
} from '@rabbit/elements/shared-types';
import {
  CFC_BusinessUserSignup,
  CFC_DecodeBusinessUserInviteLink,
  CFC_GenerateBusinessUserInvite,
  CFC_ResendBusinessUserInvite,
  ICF_GenerateBusinessUserInvite,
  ICF_ResendBusinessUserInvite,
  OCF_ResendBusinessUserInvite,
} from '@rabbit/bizproc/core';
import { DTBusiness_User_Invite } from '@rabbit/data/types';
import { z } from 'zod';

export async function UserInvites_Business_GenerateInvite({
  inviteData,
  baseUrl,
  emailParams,
  tenantLink
}: z.infer<typeof ICF_GenerateBusinessUserInvite>) {
  const result = await CFC_GenerateBusinessUserInvite.call({
    inviteData,
    baseUrl,
    emailParams,
    tenantLink
  });
  if (!result.ok) throw new Error(result.error);
  return result;
}
// export async function UserInvites_Business_GenerateInvite({
//   requestId,
//   apiVersion,
//   inviteData,
//   baseUrl,
// }: z.infer<typeof ICF_GenerateBusinessUserInvite>) {
//   const result = await CFC_GenerateBusinessUserInvite.call({
//     requestId,
//     apiVersion,
//     inviteData,
//     baseUrl,
//   });
//   if (!result.ok) throw new Error(result.error);
//   return result.data;
// }

export async function UserInvites_Business_DecodeInviteLink(
  c: string,
  iv: string
) {
  const result = await CFC_DecodeBusinessUserInviteLink.call({
    c,
    iv,
  });
  return result;
}

export async function UserInvites_Business_Signup(
  inviteData: Partial<DTBusiness_User_Invite>,
  premiumTenant: string,
  password: string,
  firstName: string,
  lastName: string
) {
  const result = await CFC_BusinessUserSignup.call({
    inviteData,
    premiumTenant,
    password,
    firstName,
    lastName,
  });
  return result;
}

export async function UserInvites_Business_ResendInvite({
  requestId,
  apiVersion,
  docid,
  baseUrl,
  emailParams,
  tenantLink
}: z.infer<typeof ICF_ResendBusinessUserInvite> & APICallParamsBase): Promise<
  {
    data: z.infer<typeof OCF_ResendBusinessUserInvite>;
  } & APICallResultBase
> {
  const result = await CFC_ResendBusinessUserInvite.call({
    requestId,
    apiVersion,
    docid,
    baseUrl,
    emailParams,
    tenantLink
  });
  if (!result.ok) throw new Error(result.error);
  return result as {
    data: z.infer<typeof OCF_ResendBusinessUserInvite>;
  } & APICallResultBase;
}
