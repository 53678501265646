import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, ButtonIcon, Input } from '@rabbit/elements/shared-components';
import { useRef, useState } from 'react';
import { PencilIcon } from '@heroicons/react/24/solid';
import { ModalEditAddAddress } from '../../ModalEditAddAddress/ModalEditAddAddress';
import { Address } from '@rabbit/data/types';
import { CarRegistrationShape } from '../../ModalNewRegistration/ModalNewRegistration';
import { TenantType } from '@rabbit/sage/views/RegistrationsView';

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('Please enter a first name'),
  last_name: Yup.string().trim().required('Please enter a last name'),
  consumer_email: Yup.string()
    .email('Please enter a valid email address')
    .required("Email can't be empty"),
  phone_number: Yup.string().trim().required('Please enter a phone number'),
});

export interface CreateNewConsumerDetailsProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape & ConsumerDetailsShape;
  tenantType: TenantType;
}

export interface ConsumerDetailsShape {
  first_name: string;
  last_name: string;
  consumer_email: string;
  phone_number: string;
  phone_number_e164?: string;
}

export function CreateNewConsumerDetails(props: CreateNewConsumerDetailsProps) {
  const { onChange, handleClose, data, tenantType } = props;
  const [showAddressModal, setShowAddressModal] = useState(false);
  const formikRef = useRef<FormikProps<ConsumerDetailsShape>>(null);
  const [consumerAddress, setConsumerAddress] = useState<Address>();

  const onSubmit = (values: any) => {
    const result = values;
    result.consumer_address = consumerAddress;
    result.phone_number = values.phone_number_e164 ?? values.phone_number;
    onChange(tenantType === 'carTenants' ? 5 : 3, result);
  };

  const initialValues: ConsumerDetailsShape = {
    first_name: data.first_name || '',
    last_name: data.last_name || '',
    consumer_email: data.consumer_email || '',
    phone_number: data.phone_number || '',
    phone_number_e164: data.phone_number || '',
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        {(props) => (
          <Form className="flex-col gap-3 p-5">
            <div className="flex gap-4">
              <Input
                type="text"
                name="first_name"
                label="First name*"
                settings={{
                  id: 'first_name',
                  placeholder: 'Enter consumer first name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
              <Input
                type="text"
                name="last_name"
                label="Last name*"
                settings={{
                  id: 'last_name',
                  placeholder: 'Enter consumer last name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="email"
                name="consumer_email"
                label="Consumer email*"
                settings={{
                  id: 'consumer_email',
                  placeholder: 'Enter consumer email',
                }}
              />
              <Input
                type="phone"
                name="phone_number"
                label="Phone number*"
                settings={{
                  id: 'phone_number',
                  placeholder: 'Enter consumer phone number',
                }}
              />
            </div>
            {!consumerAddress ? (
              <Button
                kind="outline"
                type="button"
                className="mt-4"
                onClick={() => setShowAddressModal(true)}
              >
                Add an address
              </Button>
            ) : (
              <div className="z10 relative mt-2 flex items-end gap-4">
                <div className="flex w-full flex-col">
                  <label className="font-nunito mb-2 text-base text-gray-900">
                    Consumer address
                  </label>
                  <div className="font-nunito focus:border-primary-600 w-full rounded-lg border-[1px] border-gray-300 bg-gray-50 px-4 py-3 text-base text-gray-500 placeholder-gray-400 !shadow-none !outline-none !ring-0">
                    {consumerAddress?.postcode} {consumerAddress?.line1},{' '}
                    {consumerAddress?.line2} {consumerAddress?.town}{' '}
                    {consumerAddress?.state} {consumerAddress?.country}
                  </div>
                </div>
                <div className="mb-2">
                  <ButtonIcon
                    Icon={PencilIcon}
                    label=""
                    onClick={() => setShowAddressModal(true)}
                  />
                </div>
              </div>
            )}
            <div className="mt-5 flex w-full gap-4">
              <Button
                kind="primary"
                type="submit"
                disabled={Object.keys(props.errors).length > 0}
              >
                Continue
              </Button>
              <Button kind="red" type="button" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {showAddressModal && (
        <ModalEditAddAddress
          handleClose={() => setShowAddressModal(false)}
          address={consumerAddress || ({} as any)}
          handleSubmit={(address) => setConsumerAddress(address)}
        />
      )}
    </div>
  );
}

export default CreateNewConsumerDetails;
