import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
} from '@rabbit/firebase/doctype';
import { TenantLink } from '../lib/tenant';
import { DTPersona, DTPersonaPublic, FBD_Keygen_Persona } from './persona';
import { WarrantyTemplateLink } from '../lib/warranty-template';
import { Address } from '../base/basic';
import { z } from 'zod';

/* -------------------------------------------------------------------------- */
/*                                  Warrantor                                 */
/* -------------------------------------------------------------------------- */

/** The entity that provides and manages a warranty offer (or template), and handles associated claims.
 *  Usually a retailer or manufacturer.
 *  Tenants will usually be Warrantors of their own products,
 *  but their retailers can also be warrantors when offering extended warranties and such.
 */
export interface DTWarrantor_Private extends DTPersona {
  /** Warrantor name, used in search and other UI elements */
  name: string;

  /** The tenant this warrantor is dealing warranty offers for. */
  tenantLink: TenantLink;

  /** The warranty templates this warrantor offers. */
  warranties?: WarrantyTemplateLink[];
}

export interface DTWarrantor_Public extends DTPersonaPublic {
  /** Warrantor name, used in search and other UI elements */
  name: string;

  /** Location of the Warrantor */
  address?: Address;

  /** Warrantor contact phone number */
  phone?: string;

  /** Warrantor contact email address */
  email?: string;

  /** Warrantor contact phone number dedicated to claims handling */
  claimPhone?: string;

  /** Warrantor contact email address dedicated to claims handling */
  claimEmail?: string;

  /** Url to website */
  websiteUrl?: string;

  /** The tenant this warrantor is dealing warranty offers for. */
  tenantLink: TenantLink;
}

export const FBD_Warrantor_Private = MakeFBDocType<DTWarrantor_Private>({
  name: 'Warrantor Private',
  collection: 'warrantor_private',
  empty: () => {
    const result: DTWarrantor_Private = {
      ...Empty_NoSqlDoc(),
      name: '',
      tenantLink: '',
      delegates: {},
      identity: '',
      warranties: [],
      createTime: new Date().getTime(),
    };
    return result;
  },
  keygen: FBD_Keygen_Persona('W'),
});

export const FBD_Warrantor_Public = MakeFBDocType<DTWarrantor_Public>({
  name: 'Warrantor Public',
  collection: 'warrantor_public',
  empty: () => {
    const result: DTWarrantor_Public = {
      ...Empty_NoSqlDoc(),
      name: '',
      tenantLink: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});

export type WarrantorLink = string;

export const Z_WarrantorLink = z.string();
