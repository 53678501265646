import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  ConsumerIssueTypeHookOrigins,
  ReactCaseFlowCase,
  useManageConsumerIssueTypes,
  useManageFaults,
} from '@rabbit/bizproc/react';
import { SharedValuesShape } from '../../ModalEditClaimDetails/ModalEditClaimDetails';
import UpdateClaimDetailsFormDefault from './variations/default/UpdateClaimDetailsFormDefault';
import UpdateClaimDetailsFormType1 from './variations/type1/UpdateClaimDetailsFormType1';
import { FBD_Retailer_Public, FBD_Vendable } from '@rabbit/data/types';
import { t } from 'i18next';
import { LoadingSpinner } from '@rabbit/elements/shared-components';
import { Config_UpdateClaimDetails_ClaimDetailsFormType } from 'libs/configs/src/types/config-types';

export interface UpdateClaimDetailsFormProps {
  claimDetails: any; // todo type
  handleClose: () => void;
  caseFlowCase: ReactCaseFlowCase;
  setLoading: Dispatch<SetStateAction<boolean>>;
  sharedValues: SharedValuesShape;
  setSharedValues: Dispatch<SetStateAction<SharedValuesShape>>;
  type: Config_UpdateClaimDetails_ClaimDetailsFormType;
}

export interface ClaimDetailsFormShape {
  holding_vendable_id: string;
  consumer_holding_purchase_location: {
    country: string;
    docid: string;
  } | null;
  store_not_listed: boolean;
  consumer_holding_purchase_location_other: string;
  consumer_holding_purchase_country: string;
  consumer_holding_purchase_price: { amount: string; currency: string };
  purchase_date: Date | number; // todo:recheck right now it's a date or 0.
  status: string;
  holding_warranty_term: number;
  holding_faults: string[];
  preliminary_assessment: string;
  final_assessment: string;
  consumer_issue_description: string;
  consumer_issue_type_ref: string;
}

export function UpdateClaimDetailsForm(props: UpdateClaimDetailsFormProps) {
  const [vendableLabel, setVendableLabel] = useState('Loading...');
  const [locationLabel, setLocationLabel] = useState('Loading...');

  /* -------------------------- Load and format data -------------------------- */
  const { faultList } = useManageFaults();
  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE
  );
  const consumerIssueTypeOptions = !consumerIssueTypeList?.data
    ? []
    : consumerIssueTypeList.data.map((issue) => ({
        id: issue.docid,
        value: issue.docid,
        label: issue.label,
      })) ?? [];

  const faultOptions = !faultList?.data
    ? []
    : faultList.data.map((fault) => ({
        id: fault.docid,
        value: fault.docid,
        label: fault.title,
        name: fault.title,
      }));

  useEffect(() => {
    if (props.type === 'NONE') return;

    const { claimDetails } = props;

    /* --------------------------- Get retailer label --------------------------- */
    const getRetailerLabel = async (locationId: string) => {
      if (!locationId) return '';
      const retailer = await FBD_Retailer_Public.get(locationId || '');
      const town = retailer?.address?.town || '';
      if (town) {
        return retailer?.name ? retailer?.name : '' + ' - ' + town;
      } else {
        return retailer?.name ? retailer?.name : '';
      }
    };

    // only run for default
    if (props.type === Config_UpdateClaimDetails_ClaimDetailsFormType.DEFAULT) {
      (async () => {
        if (claimDetails.consumer_holding_purchase_location) {
          const locationLabel = await getRetailerLabel(
            claimDetails.consumer_holding_purchase_location || ''
          );
          setLocationLabel(locationLabel ?? t('Enter store name & location'));
        } else {
          setLocationLabel(t('Enter store name & location'));
        }
      })().catch((err) => console.log(err));
    }

    /* --------------------------- Get vendable label --------------------------- */
    const getVendableLabel = async (vendableId: string) => {
      if (!vendableId) return undefined;
      const vendable = await FBD_Vendable.get(vendableId || '');
      return vendable?.title ?? undefined;
    };

    (async () => {
      if (claimDetails?.holding_vendable_id) {
        if (claimDetails.holding_vendable_id !== 'SRV') {
          const vendableLabel = await getVendableLabel(
            claimDetails?.holding_vendable_id || ''
          );

          setVendableLabel(vendableLabel ?? 'Product name');
        } else {
          const holdingBrand = claimDetails?.srvProductInfo?.brand || '';
          const holdingName =
            claimDetails?.consumer_holding_name || 'Product name';
          // Concatenate brand and name with a space in between
          const fullProductName = `${holdingBrand} ${holdingName}`.trim();
          setVendableLabel(fullProductName);
        }
      } else {
        setVendableLabel('Product name');
      }
    })().catch((err) => console.log(err));
  }, [
    props.claimDetails,
    props.claimDetails.holding_vendable_id,
    props.claimDetails.consumer_holding_purchase_location,
  ]);

  /* ----------------------------------- TSX ---------------------------------- */
  if (props.type === Config_UpdateClaimDetails_ClaimDetailsFormType.NONE)
    return null;

  if (!faultList?.data || !consumerIssueTypeList?.data)
    return <LoadingSpinner size="sm" />;

  if (props.type === Config_UpdateClaimDetails_ClaimDetailsFormType.DEFAULT) {
    return (
      <UpdateClaimDetailsFormDefault
        {...props}
        vendableLabel={vendableLabel}
        locationLabel={locationLabel}
        faultOptions={faultOptions}
        consumerIssueTypeOptions={consumerIssueTypeOptions}
      />
    );
  }
  if (props.type === Config_UpdateClaimDetails_ClaimDetailsFormType.TYPE1)
    return (
      <UpdateClaimDetailsFormType1
        {...props}
        vendableLabel={vendableLabel}
        faultOptions={faultOptions}
        consumerIssueTypeOptions={consumerIssueTypeOptions}
      />
    );
}

export default UpdateClaimDetailsForm;
