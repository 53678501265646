import {
  Input,
  Button,
  Modal,
  asyncValidateSchema,
} from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

interface ModalAddWarrantyPlanShape {
  policy_type: string;
  risk_category?: string;
}

export function ModalAddWarrantyPlan({
  handleClose,
  onAddPlan,
  setShouldRefresh,
  isLoading,
  POLICY_TYPES_OPTIONS,
  RISK_CATEGORIES_OPTIONS,
  showRiskCategoryDropdown,
  defaultRiskCategory,
}: {
  handleClose: () => void;
  onAddPlan: (
    templateLink: string,
    lookupKey: string,
    offerTitle: string
  ) => Promise<void>;
  setShouldRefresh: (value: boolean) => void;
  isLoading: boolean;
  POLICY_TYPES_OPTIONS: {
    label: string;
    value: string;
  }[];
  RISK_CATEGORIES_OPTIONS: {
    label: string;
    value: string;
  }[];
  showRiskCategoryDropdown: boolean;
  defaultRiskCategory: string | null;
}) {
  const schema = Yup.object({
    policy_type: Yup.mixed().required('Please select a policy type'),
    risk_category: Yup.mixed().required('Please select a risk category'),
  });

  const modalSettings = {
    title: 'Add warranty price plan',
    handleClose: () => handleClose(),
    childrenClassName: 'overflow-visible',
  };

  const initialValues: ModalAddWarrantyPlanShape = {
    policy_type: undefined as any,
    risk_category: defaultRiskCategory || undefined,
  };

  const onSubmit = async (values: any) => {
    let planTitle: string;

    if (showRiskCategoryDropdown) {
      planTitle = `${
        POLICY_TYPES_OPTIONS.find((option) => option.value === values.policy_type)
          ?.label
      } (${
        RISK_CATEGORIES_OPTIONS.find(
          (option) => option.value === values.risk_category
        )?.label
      })`;
    } else {
      planTitle = POLICY_TYPES_OPTIONS.find(
        (option) => option.value === values.policy_type
      )?.label || '';
    }
    
    /*
    await onAddPlan(values.policy_type, values.risk_category, planTitle);
    */
    await onAddPlan(
      values.policy_type,
      values.risk_category,
      planTitle
    );
    setShouldRefresh(true);
  };

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[512px] rounded-md border"
      isLoading={isLoading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validate={asyncValidateSchema(schema)}
        validateOnBlur
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className="flex flex-col gap-5 px-5 pt-5">
                <div className="flex gap-4">
                  <Input
                    type="select"
                    label="Policy type"
                    name="policy_type"
                    settings={{
                      options: POLICY_TYPES_OPTIONS,
                      placeholder: 'Please select an option',
                      errors: errors,
                      hint: '*required',
                    }}
                  />
                  {showRiskCategoryDropdown && (
                  <Input
                    type="select"
                    label="Special risk category"
                    name="risk_category"
                    settings={{
                      options: RISK_CATEGORIES_OPTIONS,
                      placeholder: 'Please select an option',
                      errors: errors,
                      hint: '*required',
                    }}
                  />
                )}
                </div>
                <div className="flex gap-4 pt-6">
                  <Button
                    className="w-1/3"
                    kind="primary"
                    children="Add"
                    type="submit"
                    disabled={
                      isLoading ||
                      Object.keys(errors).length > 0 ||
                      !values.policy_type ||
                      !values.risk_category
                    }
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
