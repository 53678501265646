import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import BaseConfig from '../base';
import { CaseflowCaseTypes } from '@rabbit/bizproc/core';

// Acronyms defined here so naming doesn't get TOO verbose:
// RCO = Render Control Options
// RCFN = Render Control Functions
// SRCF = Sage Render Control Function
// SRO = Sage Render Option
// SRP = Sage Render Params
// ORCF = Olive Render Control Function
// ORO = Olive Render Option
// ORP = Olive Render Params

// Declare each option here
export enum RCO {
  /* ---------------------------------- Olive --------------------------------- */
  // add here
  /* ---------------------------------- Sage ---------------------------------- */
  SRO_ClaimCostsSection = 'SRO_ClaimCostsSection',
  SRO_ClaimActionsSection = 'SRO_ClaimActionsSection',
}

// Define the mapping of options to their parameters
export interface RCOParamMap {
  /* ---------------------------------- Olive --------------------------------- */
  // add here
  /* ---------------------------------- Sage ---------------------------------- */
  [RCO.SRO_ClaimCostsSection]: SRP_ClaimCostsSection;
  [RCO.SRO_ClaimActionsSection]: SRP_ClaimActionsSection;
}

// Map the options to their corresponding functions
export const RCFN: {
  [K in RCO]: (config: typeof BaseConfig, params: RCOParamMap[K]) => boolean;
} = {
  /* ---------------------------------- Olive --------------------------------- */
  // add here
  /* ---------------------------------- Sage ---------------------------------- */
  [RCO.SRO_ClaimCostsSection]: SRCF_ClaimCostsSection,
  [RCO.SRO_ClaimActionsSection]: SRCF_ClaimActionsSection,
};

/* -------------------------------------------------------------------------- */
/*                             Sage Render Control                            */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              Claim Detail View                             */
/* -------------------------------------------------------------------------- */
/* --------------------------- Claim Costs Section -------------------------- */

interface SRP_ClaimCostsSection {
  operatingPersonaSingleKey?: PersonaTypeSingleLetter;
  final_assessment_fact?: string;
}

function SRCF_ClaimCostsSection(
  config: typeof BaseConfig,
  params: SRP_ClaimCostsSection
) {
  const { operatingPersonaSingleKey, final_assessment_fact } = params;
  if (
    !operatingPersonaSingleKey ||
    !config.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_COSTS.SHOW_SECTION.includes(
      operatingPersonaSingleKey
    )
  ) {
    return false;
  }

  if (
    config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES &&
    operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer
  ) {
    if (final_assessment_fact !== 'Not covered') {
      return false;
    }
  }
  return true;
}

/* -------------------------- Claim Actions Section ------------------------- */

interface SRP_ClaimActionsSection {
  operatingPersonaSingleKey?: PersonaTypeSingleLetter;
  caseState: string;
}

function SRCF_ClaimActionsSection(
  config: typeof BaseConfig,
  params: SRP_ClaimActionsSection
) {
  const { operatingPersonaSingleKey, caseState } = params;
  if (
    operatingPersonaSingleKey &&
    config?.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_ACTIONS.ENABLED.includes(
      operatingPersonaSingleKey
    )
  ) {
    return caseState !== 'closed';
  }
  return false;
}

/* -------------------------------------------------------------------------- */
/*                                    Olive                                   */
/* -------------------------------------------------------------------------- */
// add here
