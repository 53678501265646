import { useContext, useState } from 'react';
import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import {
  PersonaTypeSingleLetter,
  UserUploadedDocument,
} from '@rabbit/data/types';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  Modal,
  Table,
  formatUnixTime,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import ModalAddEditInvoice, {
  ModalAddEditInvoiceModes,
} from '@rabbit/sage/components/organisms/ModalAddEditInvoice/ModalAddEditInvoice';
import { titleCase, useAppInfo } from '../../../../utils/helpers';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import {
  CaseFlowStations_Fatbikes,
  CFCF_ExternalRepairInvoice,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import { FileStorageContext } from '@rabbit/bizproc/react';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';

interface InvoiceDataShape {
  document: UserUploadedDocument;
  timeStamp: number;
  totalCost: number;
  status: ExternalRepairInvoiceStatus;
  rejectionReason: string;
}

// TODO: ADD APPROVE / REJECT FOR WARRANTORS

export default function ClaimInvoiceSection() {
  const { t } = useTranslation();
  const caseFlowContext = useContext(CaseflowContext);
  const {
    caseFacts,
    alterCaseFacts,
    operatingPersonaSingleKey,
    caseState,
    caseAlterability,
  } = caseFlowContext || {};
  const fileStorageContext = useContext(FileStorageContext);
  const { deleteFile } = fileStorageContext || {};
  const external_repair_invoices = caseFacts?.external_repair_invoices;

  // this should really only be one state but I can't be bothered to refactor it right now. - DC
  const [deleteModal, setDeleteModal] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [modalData, setModalData] = useState<{
    data: CFCF_ExternalRepairInvoice;
    mode?: ModalAddEditInvoiceModes;
  } | null>(null);
  const appInfo = useAppInfo();

  function renderNoInvoiceText() {
    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer) {
      if (caseState === CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION) {
        return t('message.noInvoicesAddedMessage');
      } else {
        return t('message.noInvoicesAdded');
      }
    }
    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor) {
      return t('message.noInvoicesAddedByRepairer');
    }
  }

  if (!external_repair_invoices)
    return (
      <>
        <CardWrapperWithHeader
          title={t('general.invoices')}
          noPadding={true}
          collapsedByDefault={false}
          headerRight={
            operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer &&
            caseState ===
              CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION && (
              <ButtonIcon
                type="primary"
                label={t('general.addInvoice')}
                Icon={PlusIcon}
                iconLeft
                onClick={() => {
                  setModalData(null);
                  setInvoiceModal(true);
                }}
              />
            )
          }
        >
          <p className="p-5">{renderNoInvoiceText()}</p>
        </CardWrapperWithHeader>
        {invoiceModal && (
          <ModalAddEditInvoice
            handleClose={() => setInvoiceModal(false)}
            data={modalData?.data}
            mode={modalData?.mode}
          />
        )}
      </>
    );

  // order invoices by timestamp, just in case
  external_repair_invoices.sort((a, b) => b.timestamp - a.timestamp);

  /* ------------------------------- Table setup ------------------------------ */
  const invoiceColumns = [
    {
      header: t('general.document'),
      accessorKey: 'document',
      size: 180,
    },
    {
      header: t('general.date'),
      accessorKey: 'date',
      size: 20,
    },
    {
      header: t('general.cost'),
      accessorKey: 'cost',
      size: 20,
    },
    {
      header: t('general.status'),
      accessorKey: 'status',
      size: 20,
    },
    {
      header: '',
      title: t('actions'),
      accessorKey: 'actions',
      size: 15,
      Cell: ({ cell }: any) => {
        // Invoices can only be edited or deleted by the repairer, but only when caseState === ASSIGNED and the invoice is UNSUBMITTED

        if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer) {
          if (
            external_repair_invoices[cell.row.index].status ===
              ExternalRepairInvoiceStatus.REJECTED ||
            external_repair_invoices[cell.row.index].status ===
              ExternalRepairInvoiceStatus.APPROVED
          ) {
            return (
              <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                <MagnifyingGlassIcon
                  className="h-[20px] w-[20px]"
                  onClick={() => {
                    setModalData({
                      data: external_repair_invoices[cell.row.index],
                      mode: 'repairer-review',
                    });
                    setInvoiceModal(true);
                  }}
                />
              </div>
            );
          } else {
            return (
              <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                <PencilSquareIcon
                  className="h-[20px] w-[20px]"
                  onClick={() => {
                    setModalData({
                      data: external_repair_invoices[cell.row.index],
                      mode: 'add-edit',
                    });
                    setInvoiceModal(true);
                  }}
                />
                <TrashIcon
                  className="h-[20px] w-[20px]"
                  onClick={() => {
                    setModalData({
                      data: external_repair_invoices[cell.row.index],
                      mode: 'add-edit',
                    });
                    setDeleteModal(true);
                  }}
                />
              </div>
            );
          }
        }

        if (
          operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor ||
          external_repair_invoices[cell.row.index].status !==
            ExternalRepairInvoiceStatus.UNSUBMITTED ||
          caseState !== CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION
        ) {
          return (
            <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
              <MagnifyingGlassIcon
                className="h-[20px] w-[20px]"
                onClick={() => {
                  setModalData({
                    data: external_repair_invoices[cell.row.index],
                    mode: 'add-edit',
                  });
                  setInvoiceModal(true);
                }}
              />
            </div>
          );
        }
      },
    },
  ];

  const renderStatus = (status: InvoiceDataShape['status']) => (
    <div
      className={`inline-flex rounded-md py-[2px] px-3 ${
        status === ExternalRepairInvoiceStatus.REJECTED
          ? 'bg-red-100 text-red-800'
          : ''
      } ${
        status === ExternalRepairInvoiceStatus.APPROVED
          ? 'bg-green-100 text-green-800'
          : ''
      } ${
        status === ExternalRepairInvoiceStatus.IN_REVIEW ||
        status === ExternalRepairInvoiceStatus.UNSUBMITTED
          ? 'bg-orange-100 text-orange-800'
          : ''
      } `}
    >
      {status ? titleCase(status) : '-'}
    </div>
  );

  const data: Record<string, string | JSX.Element>[] =
    external_repair_invoices.map((invoice) => {
      return {
        document: (
          <div
            className="flex w-full cursor-pointer items-center gap-2 break-all text-green-600 underline underline-offset-4"
            onClick={(e) => {
              e.stopPropagation();
              setModalData({
                data: invoice,
                mode: 'repairer-review',
              });
              setInvoiceModal(true);
            }}
          >
            <DocumentIcon className="h-4 w-4" />
            {invoice.file.ogFilename}
          </div>
        ),
        date: formatUnixTime(invoice.timestamp, 'dd/MM/yyyy'),
        status: renderStatus(invoice.status),
        cost: getCurrencyFormat(invoice.totalCost.amount, appInfo.currency),
      };
    });

  /* ---------------------------- Submission handling --------------------------- */
  // async function submitInvoice() {
  //   if (
  //     executeAction &&
  //     moveSpotlight &&
  //     external_repair_invoices &&
  //     caseAlterability
  //   ) {
  //     try {
  //       // Alter the case facts to update the invoice status
  //       alterCaseFacts &&
  //         alterCaseFacts({
  //           external_repair_invoices: external_repair_invoices.map(
  //             (invoice) => {
  //               if (
  //                 invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED
  //               ) {
  //                 return {
  //                   ...invoice,
  //                   status: ExternalRepairInvoiceStatus.IN_REVIEW,
  //                 };
  //               }
  //               return invoice;
  //             }
  //           ),
  //         });

  //       await executeAction('submit_invoice');
  //       await moveSpotlight(PersonaTypeSingleLetter.Warrantor);
  //       toast.success(t('Invoice submitted successfully!'));
  //     } catch (e) {
  //       console.log(e);
  //       toast.error(t('Failed to submit invoice'));
  //     }
  //   }
  // }

  /* ---------------------------- Deletion handling --------------------------- */
  async function deleteInvoice() {
    // Filter the invoices to remove the one that matches the modal data
    if (external_repair_invoices) {
      const newInvoices = external_repair_invoices.filter(
        (invoice) => invoice.timestamp !== modalData?.data.timestamp
      );
      // Update the case facts
      alterCaseFacts &&
        alterCaseFacts({
          external_repair_invoices: newInvoices,
        });

      // delete the file from the file storage
      deleteFile &&
        modalData?.data.file.metadata.fullPath &&
        deleteFile(modalData?.data.file.metadata.fullPath, {
          category: UploadedFileCategories.ExternalRepairInvoices,
        });
    }
  }

  const unsubmittedInvoices = external_repair_invoices.filter(
    (invoice) => invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED
  );

  if (uploadSuccessful && unsubmittedInvoices.length > 0) {
    setUploadSuccessful(false);
    setModalData({
      data: unsubmittedInvoices[0],
      mode: 'submit',
    });
    setInvoiceModal(true);
  }

  const renderSectionHeaderRight = () => {
    if (
      operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer &&
      caseState === CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION
    ) {
      if (unsubmittedInvoices.length === 0) {
        return (
          <ButtonIcon
            type="primary"
            label={t('general.addInvoice')}
            Icon={PlusIcon}
            iconLeft
            onClick={() => {
              setModalData(null);
              setInvoiceModal(true);
            }}
          />
        );
      }
      if (unsubmittedInvoices.length > 0) {
        if (caseAlterability) {
          return (
            <ButtonIcon
              type="primary"
              label={t('general.submitInvoice')}
              iconLeft
              onClick={() => {
                setModalData({
                  data: unsubmittedInvoices[0],
                  mode: 'submit',
                });
                setInvoiceModal(true);
              }}
            />
          );
        } else {
          return (
            <ButtonIcon
              type="primary"
              label={`${t('general.loading')}...`}
              Icon={PlusIcon}
              iconLeft
              onClick={() => void 0}
              disabled
            />
          );
        }
      }
    }
  };

  /* ----------------------------------- TSX ---------------------------------- */

  return (
    <CardWrapperWithHeader
      title={t(`general.claimInvoices`)}
      noPadding={true}
      collapsedByDefault={false}
      headerRight={renderSectionHeaderRight()}
    >
      <div>
        <Table
          columns={invoiceColumns}
          data={data}
          inner={true}
          enablePagination={data.length > 10}
          initialState={{ showGlobalFilter: true }}
          muiSearchTextFieldProps={{
            sx: {
              display: 'none',
            },
          }}
          muiTopToolbarProps={{
            sx: {
              display: 'none',
            },
          }}
          muiTableHeadCellProps={{
            className: 'relative bg-gray-200 uppercase font-light',
          }}
          muiTableBodyCellProps={{
            className: 'px-4 py-0',
          }}
        />

        {invoiceModal && (
          <ModalAddEditInvoice
            handleClose={(submitted) => {
              setInvoiceModal(false);
              setModalData(null);
              if (submitted === true) {
                setUploadSuccessful(submitted);
              }
            }}
            data={modalData?.data}
            mode={modalData?.mode}
          />
        )}
        {deleteModal && (
          <Modal
            kind={'pop-up'}
            settings={{
              text: t(`message.confirmDeleteInvoice`),
              primaryButtonText: t('general.yes'),
              outlineButtonText: t('general.cancel'),
              loading: false,
              handlePrimaryClick: async () => {
                await deleteInvoice();
                setDeleteModal(false);
              },
              handleOutlineClick: () => setDeleteModal(false),
            }}
          />
        )}
      </div>
    </CardWrapperWithHeader>
  );
}
