import { useContext, useState } from 'react';
import {
  Button,
  CardWrapperWithHeader,
  getStationByKey,
  LoadingSpinner,
  StepsProgress,
} from '@rabbit/elements/shared-components';
import AssignCaseInternalModal from '../../../organisms/case-flow-modals/AssignCaseInternalModal/AssignCaseInternalModal';
import {
  DocumentCheckIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { ConfigContext } from '@rabbit/config/context';
import {
  CaseFlowCaseStations,
  CaseflowCaseStatus,
  CaseflowCaseTypes,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import { t } from 'i18next';
import ClaimStatusModal from '@rabbit/sage/components/organisms/case-flow-modals/ClaimStatusModal/ClaimStatusModal';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimStatusSectionProps {}

export function ClaimStatusSection(props: ClaimStatusSectionProps) {
  const context = useContext(CaseflowContext);
  const { config } = useContext(ConfigContext);
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const {
    caseState,
    caseFacts,
    setModalSettings,
    setShowModal,
    alterCaseFacts,
    executeAction,
    operatingPersona,
    operatingPersonaSingleKey,
  } = context || {};

  const currentStation = getStationByKey(caseState);

  const modalSettingsOptions = {
    assign_case: {
      kind: 'generic' as const,
      settings: {
        title: t('Assign case'),
      },
      children: <AssignCaseInternalModal />,
    },
  };

  const steps = [
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 1 ? true : false,
      title: CaseflowCaseStatus.WAITING_FOR_REVIEW,
      // content: (
      //   <div className="mt-2 text-xs text-gray-400 font-nunito">
      //     18/04/2023
      //     <br />
      //     9:30 am
      //   </div>
      // ),
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 2 ? true : false,
      title: CaseflowCaseStatus.ASSESSMENT,
      // content: (
      //   <div className="mt-2 text-xs text-gray-400 font-nunito">
      //     19/04/2023
      //     <br />
      //     01:27 pm
      //   </div>
      // ),
    },
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 6 ? true : false,
      title: CaseflowCaseStatus.REPAIR_REPLACE,
      //content: '',
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 10 ? true : false,
      title: CaseflowCaseStatus.COMPLETE,
      //content: '',
    },
  ];

  if (
    !context ||
    !setModalSettings ||
    !setShowModal ||
    !alterCaseFacts ||
    !executeAction ||
    !operatingPersona ||
    !operatingPersonaSingleKey
  )
    return <LoadingSpinner size="xs" />;

  const isRepairer = operatingPersona.startsWith(
    PersonaTypeSingleLetter.Repairer + PersonaIdTypeSplitter
  );

  let hideStatus = false;
  if (isRepairer) {
    if (!caseFacts?.external_repair_invoices) return;
    const invoiceNotApproved =
      (
        caseFacts?.external_repair_invoices
          ?.map((invoice) => {
            return invoice?.status !== ExternalRepairInvoiceStatus.APPROVED;
          })
          .filter((i) => i) || []
      )?.length > 0;

    if (invoiceNotApproved) {
      hideStatus = true;
    } else if (caseFacts?.final_assessment === 'Not covered') {
      hideStatus = true;
    }
  }

  return (
    <CardWrapperWithHeader
      title={t('general.claimStatus')}
      headerRight={
        <div className="flex items-center gap-5">
          {(config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.LITE ||
            config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES) &&
            !hideStatus && (
              <Button
                size="sm"
                kind="outline"
                children={t('general.updateStatus')}
                onClick={() => setStatusModalOpen(true)}
              />
            )}
          {config.CLAIMS.CLAIMS_FLOW.CAN_ASSIGN_REPAIRER.includes(
            operatingPersonaSingleKey
          ) && (
            <Button
              size="sm"
              kind="primary"
              children={`${
                caseFacts?.delegate_repairer_name !== 'Unassigned'
                  ? t('general.assignedTo') +
                    ': ' +
                    caseFacts?.delegate_repairer_name
                  : t('general.unassigned')
              }`}
              onClick={() => {
                setModalSettings(modalSettingsOptions.assign_case);
                setShowModal(true);
              }}
              // TODO: handle this better without caseflow type dependency
              disabled={
                config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES &&
                caseState !== CaseFlowCaseStations.PRELIMINARY_ASSESSMENT
              }
            />
          )}
        </div>
      }
    >
      <div className="px-10">
        <StepsProgress steps={steps} />
      </div>
      {statusModalOpen && (
        <ClaimStatusModal onClose={() => setStatusModalOpen(false)} />
      )}
    </CardWrapperWithHeader>
  );
}

export default ClaimStatusSection;
