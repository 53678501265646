import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { Modal } from '@rabbit/elements/shared-components';
import {
  CaseflowContext,
  CaseflowInterface,
} from '@rabbit/sage/context/CaseflowContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CFCF_ExternalRepairInvoice } from '@rabbit/bizproc/core';
import AddEditInvoiceFormRepairer from '../../molecules/AddEditInvoiceFormRepairer/AddEditInvoiceFormRepairer';
import AddEditInvoiceFormWarrantor from '../../molecules/AddEditInvoiceFormWarrantor/AddEditInvoiceFormWarrantor';

export type ModalAddEditInvoiceModes =
  | 'add-edit'
  | 'submit'
  | 'repairer-review';
interface ModalAddEditInvoiceProps {
  handleClose: (submitted?: boolean) => void;
  data?: CFCF_ExternalRepairInvoice;
  mode?: ModalAddEditInvoiceModes;
}

export default function ModalAddEditInvoice({
  handleClose,
  data,
  mode = 'add-edit',
}: ModalAddEditInvoiceProps) {
  const { t } = useTranslation();
  const { operatingPersonaSingleKey } =
    useContext(CaseflowContext) || ({} as CaseflowInterface);

  function getModalTitle() {
    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer) {
      if (mode === 'submit') {
        return t('general.submitInvoice');
      } else if (mode === 'repairer-review') {
        return t('general.reviewInvoice');
      } else return !data ? t('general.addInvoice') : t('general.editInvoice');
    }
    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor) {
      return t('general.reviewInvoice');
    }
  }

  return (
    <Modal
      settings={{
        title: getModalTitle(),
        handleClose,
      }}
      className="w-full max-w-[1024px]"
      kind="generic"
    >
      {operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer && (
        <AddEditInvoiceFormRepairer
          handleClose={handleClose}
          data={data}
          mode={mode}
        />
      )}
      {operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor && (
        <AddEditInvoiceFormWarrantor handleClose={handleClose} data={data} />
      )}
    </Modal>
  );
}
