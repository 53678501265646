import classNames from 'classnames';
import {
  ExclamationCircleIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { Button } from '@rabbit/elements/shared-components';
import { ModalSettingsShape } from '../Modal/Modal';

export interface IconModalProps {
  kind: 'pop-up' | 'search';
  settings: ModalSettingsShape;
}
const dynamicStyles = {
  container:
    'flex-col border-gray-200 rounded-lg w-full max-h-[90vh] flex py-6 px-1',
  text: 'text-center font-nunito font-normal text-gray-500 text-base px-1',
};

export function IconModal({ kind, settings }: IconModalProps) {
  const {
    handleClose,
    handlePrimaryClick,
    handleOutlineClick,
    text,
    primaryButtonText,
    outlineButtonText,
    loading,
  } = settings;

  if (kind === 'pop-up')
    return (
      <div className={classNames(`${dynamicStyles.container}`)}>
        <div className="relative flex flex-col items-center justify-center">
          {handleClose ? (
            <div className="absolute right-1 top-[-15px] inline-flex items-center">
              <XMarkIcon
                onClick={handleClose}
                className='"text-gray-400 h-[18px] w-[18px] cursor-pointer bg-transparent hover:bg-gray-200 hover:text-gray-900'
              />
              <span className="sr-only">Close modal</span>
            </div>
          ) : null}
          <div className="mb-4 h-[35px] w-[35px]">
            <ExclamationCircleIcon />
          </div>
          <p className={classNames(`${dynamicStyles.text}`)} dangerouslySetInnerHTML={{ __html: text ?? '' }}></p>
        </div>
        <div className="flex justify-center pt-4 align-middle">
          <div className="mr-2 w-fit">
            {primaryButtonText && (
              <Button
                children={primaryButtonText}
                kind="primary"
                size="sm"
                onClick={handlePrimaryClick}
                disabled={loading}
              />
            )}
          </div>
          {outlineButtonText && (
            <div className="w-fit">
              <Button
                children={outlineButtonText}
                kind="outline"
                size="sm"
                onClick={handleOutlineClick}
                disabled={loading}
              />
            </div>
          )}
        </div>
      </div>
    );

  if (kind === 'search')
    return (
      <div className={classNames(`${dynamicStyles.container}`)}>
        <div className="relative flex flex-col items-center justify-center">
          {handleClose ? (
            <div className="absolute right-1 top-[-15px] inline-flex items-center">
              <XMarkIcon
                onClick={handleClose}
                className='"text-gray-400 h-[18px] w-[18px] cursor-pointer bg-transparent hover:bg-gray-200 hover:text-gray-900'
              />
              <span className="sr-only">Close modal</span>
            </div>
          ) : null}
          <div className="mb-4 h-[35px] w-[35px]">
            <MagnifyingGlassIcon />
          </div>
          <p className={classNames(`${dynamicStyles.text}`)}>{text}</p>
        </div>
        <div className="flex justify-center pt-4 align-middle">
          <div className="mr-2 w-fit">
            {primaryButtonText && (
              <Button
                children={primaryButtonText}
                kind="primary"
                size="sm"
                onClick={handlePrimaryClick}
              />
            )}
          </div>
          {outlineButtonText && (
            <div className="w-fit">
              <Button
                children={outlineButtonText}
                kind="outline"
                size="sm"
                onClick={handleOutlineClick}
              />
            </div>
          )}
        </div>
      </div>
    );

  return null;
}

export default IconModal;
