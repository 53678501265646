import { CFAAlterSpotlight } from '@rabbit/data/types';
import { ActionStepImplementation, ActionStepRegistry } from '../registry';

export const ASI_AddSpotlight: ActionStepImplementation<CFAAlterSpotlight> = {
  validate: (location, validator, step, action, art) => {
    if (step.add) art.ValidatePotentialParams(step.add, location, validator);
    if (step.remove)
      art.ValidatePotentialParams(step.remove, location, validator);
  },

  interior: (cfc, step, art) => {
    art.cfc.Alter_CaseSpotlight({
      add: step.add ? art.FillParamsInString(step.add) : undefined,
      remove: step.remove ? art.FillParamsInString(step.remove) : undefined,
      clear: step.clear,
    });
  },
};

ActionStepRegistry.Register(ASI_AddSpotlight, 'alter_spotlight');
