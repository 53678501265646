import {
  AddressOptions,
  SelectAddressShape,
} from '@rabbit/elements/shared-types';
import { useEffect, useRef, useState } from 'react';
import { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  onPostCodeChange,
  onRetreiveFullAddress,
} from '../../../utils/helpers';
import { generateSelectStyles } from '../../../utils/consts';

export interface InputTypeSelectAddressProps {
  onChange: (address: SelectAddressShape) => void;
  label?: string;
  errors: any;
  country?: string;
}

export function InputTypeSelectAddress(props: InputTypeSelectAddressProps) {
  const { onChange, label, errors, country } = props;

  const selectInputRef = useRef<any>(null);

  const FETCHIFY_KEY = import.meta.env.VITE_FETCHIFY_KEY;

  const [selectedAddress, setSelectedAddress] =
    useState<SelectAddressShape | null>({} as SelectAddressShape);

  const parseAddresses = (data: Array<any>) => {
    const addressesOptions: any[] = [];
    data.map((item: { count: number; id: any; labels: string[] }) => {
      item.count === 1 &&
        addressesOptions.push({
          value: item.id,
          label: item.labels[0] + ' ' + item.labels[1],
        });
    });
    return addressesOptions;
  };

  const getAddressSuggestions = async (value: string) => {
    const data = await onPostCodeChange(value, FETCHIFY_KEY, country);
    if (data) return parseAddresses(JSON.parse(data).results);
    else return [];
  };

  const getAddressDetails = async (value: string) => {
    const data = await onRetreiveFullAddress(value, FETCHIFY_KEY, country);
    if (data) setSelectedAddress(JSON.parse(data).result);
  };

  //get address details when address is selected
  const onSelectedAddress = (selected: SingleValue<AddressOptions>) => {
    if (selected?.value) void getAddressDetails(selected.value);
    else setSelectedAddress(null);
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: AddressOptions[]) => void
  ) => {
    if(inputValue === '') return callback([]);
    setTimeout(async () => {
      callback(await getAddressSuggestions(inputValue));
    }, 500);
  };

  useEffect(() => {
    selectInputRef?.current?.clearValue();
  }, [country]);

  useEffect(() => {
    if (selectedAddress) onChange(selectedAddress);
  }, [selectedAddress]);

  return (
    <>
      <label
        htmlFor={'address-selector'}
        className={'font-nunito text-base text-gray-900'}
      >
        {label ?? 'Find an address'}
      </label>
      <div className="mb-2"></div>
      <AsyncSelect 
        ref={selectInputRef}
        cacheOptions 
        loadOptions={loadOptions} 
        defaultOptions
        classNames={generateSelectStyles()}
        isMulti={false}
        placeholder="Start with postcode or street"
        onChange={onSelectedAddress}
        isDisabled={!country || country === '' ? true : false}
      />
      {/* <Select
        ref={selectInputRef}
        name="address-selector"
        options={options}
        isMulti={false}
        classNames={generateSelectStyles()}
        //styles={customStyles}
        //onChange={onSelectedAddress}
        onInputChange={onInputChange}
        //isClearable={true}
        placeholder="Start with postcode or street"
        // noOptionsMessage={({ inputValue }) =>
        //   !inputValue
        //     ? 'Start with postcode or street'
        //     : query === debouncedQuery && !isLoadingResults
        //     ? 'No results found'
        //     : 'Loading...'
        // }
        isDisabled={!country || country === '' ? true : false}
      /> */}
    </>
  );
}

export default InputTypeSelectAddress;
