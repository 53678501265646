import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { updateHoldingOnClose } from 'apps/sage/src/utils/firestore-helpers';
import { toast } from 'react-toastify';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

// TODO: merge with the regular close case modal
// Leaving the email stuff commented out since it should be needed in the nearish future
export interface CloseCaseFatbikesModalProps {}
interface FormValuesShape {
  // comment_to_customer: string;
  external_repairer_comment?: string;
}

const initialValues = {
  // comment_to_customer: '',
  external_repairer_comment: '',
};

const validationSchema = Yup.object().shape({
  // comment_to_customer: Yup.string()
  //   .trim()
  //   .required('Please enter a message')
  //   .test('remove-html-tags', 'Please insert a message.', (value) => {
  //     const div = document.createElement('div');
  //     div.innerHTML = value || '';
  //     return !value || div.textContent?.trim() !== '';
  //   }),
  external_repairer_comment: Yup.string().trim(),
});

export function CloseCaseFatbikesModal({}: CloseCaseFatbikesModalProps) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showExternalRepairerComment, setShowExternalRepairerComment] =
    useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const {
      // comment_to_customer,
      external_repairer_comment,
    } = values;
    setIsSubmitting(true);
    await moveSpotlight(PersonaTypeSingleLetter.Consumer);
    executeAction('close_case');

    try {
      const factsToAlter = {
        ...(external_repairer_comment && {
          external_repairer_comment: {
            comment: external_repairer_comment,
            author: operatingPersona,
          },
        }),
        // comment_to_customer: comment_to_customer,
      };
      // const to = caseFacts?.consumer_email ?? '',
      //   from = appInfo.email_sender,
      //   template = MANDRILL_TEMPLATES.BODY_GENERIC_CLOSE_CASE,
      //   business_name = appInfo.name ?? '',
      //   claim_id = caseId,
      //   first_name = caseFacts?.consumer_name ?? '',
      //   product_name = caseFacts.consumer_holding_name ?? '',
      //   subject = business_name + t(' has closed your claim - ') + product_name,
      //   link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        // await alterCasePublicEmail({
        //   context: 'generic_close_case',
        //   from,
        //   to,
        //   subject,
        //   template,
        //   substitutions: {
        //     subject,
        //     business_name,
        //     claim_id,
        //     first_name,
        //     comment_to_customer,
        //     product_name,
        //     link_to_claim,
        //   },
        //   shallBeSentViaBackend: true,
        // });
      }
      await updateHoldingOnClose(caseId, caseFacts?.consumer_holding ?? '');

      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Close case',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="font-nunito mt-5 flex flex-col px-4">
            <div className="mt-3">
              <Input
                type="rich-text"
                label="Comments to customer*"
                name="comment_to_customer"
                settings={{
                  id: 'comment_to_customer',
                  hint: '*required',
                }}
              />
            </div>
            {!showExternalRepairerComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowExternalRepairerComment(true)}
                >
                  {t('Add comment')}
                </Button>
              </div>
            )}
            {showExternalRepairerComment && (
              <div className="mt-3">
                <Input
                  type="rich-text"
                  label="Comment"
                  name="external_repairer_comment"
                  settings={{
                    id: 'external_repairer_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                {t('Close the case')}
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default CloseCaseFatbikesModal;
