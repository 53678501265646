import {
  SetStateAction,
  createContext,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import { LoadingSpinner } from '../../../elements/shared-components/src/index';
import { merge } from 'ts-deepmerge';
import BaseConfig from '../../../configs/src/base';
import React from 'react';
import { RCFN, RCO, RCOParamMap } from './render-control';

export interface ConfigInterface {
  config: typeof BaseConfig;
  setConfig: Dispatch<SetStateAction<typeof BaseConfig>>;
  RenderControl: <K extends keyof RCOParamMap>(
    option: K,
    params: RCOParamMap[K]
  ) => boolean;
}

const ConfigContext = createContext<ConfigInterface>({
  config: BaseConfig,
  setConfig: () => {},
  RenderControl: () => false,
});

type ConfigProviderWrapperProps = {
  children: React.ReactNode;
};

const ConfigProviderWrapper = ({ children }: ConfigProviderWrapperProps) => {
  const [config, setConfig] = useState<typeof BaseConfig>(BaseConfig);
  // const isEmulator = import.meta.env.VITE_FIREBASE_MODE === 'EMULATOR';
  // const localConfig = localStorage.getItem('CONFIG');
  const [activeTenantLink, setActiveTenantLink] = useState(
    localStorage.getItem('activeTenant')
  );

  // console.debug('activeTenantLink', activeTenantLink); // @TEST_REMOVE_LATER

  useEffect(() => {
    async function getTenantConfig(tenantLink: string) {
      const tenant = tenantLink.toLowerCase();
      try {
        return await import(`../${tenant}.ts`);
      } catch (e) {
        console.warn(`File ${`../${tenant}`} not found`);
        return await import(`../base.ts`);
      }
    }

    function updateActiveTenantLink() {
      console.debug('Active Tenant Link Updated');
      setActiveTenantLink(localStorage.getItem('activeTenant'));
    }

    window.addEventListener('activeTenantChange', updateActiveTenantLink);

    if (activeTenantLink) {
      void getTenantConfig(activeTenantLink).then((config) => {
        const finalConfig = merge(
          BaseConfig,
          config.default as typeof BaseConfig
        );

        setConfig(finalConfig);
      });

      // console.debug('config', config || '{}'); // @TEST_REMOVE_LATER
      return () => {
        window.removeEventListener(
          'activeTenantChange',
          updateActiveTenantLink
        );
      };
    }
  }, [activeTenantLink]);

  if (!activeTenantLink) return <LoadingSpinner size="md" />;

  const RenderControl: ConfigInterface['RenderControl'] = (option, params) => {
    return RCFN[option](config, params);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Provider return                              */
  /* -------------------------------------------------------------------------- */

  if (!RenderControl || !setConfig) return <LoadingSpinner size="lg" />;

  return (
    <ConfigContext.Provider
      value={{
        config,
        setConfig,
        RenderControl,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export {
  ConfigContext as ConfigContext,
  ConfigProviderWrapper as ConfigProviderWrapper,
};

/* -------------------------------------------------------------------------- */
/*                                  Old code                                  */
/* -------------------------------------------------------------------------- */

// Old approach for caching config in localStorage. Leaving it here for reference once we move config to the server and need to cache it in the client.
// // always refresh config if in emulator mode
// if (activeTenantLink) {
//   if (isEmulator) {
//     void getTenantConfig(activeTenantLink).then((config) => {
//       const finalConfig = merge(
//         BaseConfig,
//         config.default as typeof BaseConfig
//       );
//       localStorage.setItem('CONFIG', JSON.stringify(finalConfig));
//       setConfig(finalConfig);
//     });
//   } else {
//     if (!localConfig) {
//       void getTenantConfig(activeTenantLink).then((config) => {
//         const finalConfig = merge(
//           BaseConfig,
//           config.default as typeof BaseConfig
//         );
//         localStorage.setItem('CONFIG', JSON.stringify(finalConfig));
//         setConfig(finalConfig);
//       });
//     }

//     if (localConfig) {
//       setConfig(JSON.parse(localConfig));
//     }
//   }
//   // console.debug('config', JSON.parse(localConfig || '{}')); // @TEST_REMOVE_LATER}
//   return () => {
//     window.removeEventListener(
//       'activeTenantChange',
//       updateActiveTenantLink
//     );
//   };
// }
// }, [localConfig, activeTenantLink]);
